import Dashboard from '../pages/Dashboard/index';
import Login from '../pages/Login';
import Profile from '../pages/Profile';

// News
import New from '../pages/News/new';
import Contents from '../pages/News/contents';
import Headline from '../pages/News/headline';
import QuickContent from '../pages/News/quickcontent';
import NewNews from '../pages/News/newNews';
import Agencies from '../pages/News/agencies';

// TV
import AjanssporTvBranches from '../pages/AjanssporTv/branches';
import AjanssporTvLeagues from '../pages/AjanssporTv/leagues';
import AjanssporTvVideos from '../pages/AjanssporTv/videos';
import AjanssporTvBestOfTheWeek from '../pages/AjanssporTv/bestOfTheWeek';
import AjanssporTvBanner from '../pages/AjanssporTv/banner';

//Videos
import Videos from '../pages/Videos';
import AddVideo from '../pages/Videos/add';
import EditVideo from '../pages/Videos/edit';
import Play from '../pages/Videos/play';
import Upload from '../pages/Videos/upload';

// Comments
import AjanssporComments from '../pages/Ajansspor/Comments';
import AjanssporContacts from '../pages/Ajansspor/Contacts';

// Users
// import Writers from '../pages/Users/Writers';
// import Editors from '../pages/Users/Editors';
import Members from '../pages/Users/Members';

// Tags
import CombineTags from '../pages/Ajansspor/CombineTags';

// Developers
import LogTypes from '../pages/Developer/Logs/LogTypes';
import Logs from '../pages/Developer/Logs/index';
import EditorNewsLogs from '../pages/Developer/Logs/EditorNewsLogs';
import ServerInfo from '../pages/Developer/Tools/ServerInfo';
import ServerStatus from '../pages/Developer/Tools/ServerStatus';
import Analytics from '../pages/Developer/Tools/Analytics';

//Soccer
import SoccerSquads from '../pages/Tools/SoccerSquads';
import SoccerPlayers from '../pages/Tools/SoccerPlayers';

// Tools
import Cache from '../pages/cache';
import ComingSoon from '../pages/ComingSoon';
import NotFound from '../pages/NotFound';
import Lock from '../pages/Lock';
import Notification from '../pages/Tools/Notification';

// Slowtime
import SlowtimeEvents from '../pages/Slowtime/Events';
import SlowtimeNewEvents from '../pages/Slowtime/Events/New';
import SlowtimeEditEvents from '../pages/Slowtime/Events/Edit';

import SlowtimeNews from '../pages/Slowtime/News';
import SlowtimeNewNews from '../pages/Slowtime/News/New';
import SlowtimeEditNews from '../pages/Slowtime/News/Edit';

import SlowtimeAlbums from '../pages/Slowtime/Albums';
import SlowtimeNewAlbum from '../pages/Slowtime/Albums/New';
import SlowtimeEditAlbums from '../pages/Slowtime/Albums/Edit';

import SlowtimeVideo from '../pages/Slowtime/VideoWeek';
import SlowtimeNewVideo from '../pages/Slowtime/VideoWeek/New';
import SlowtimeEditVideo from '../pages/Slowtime/VideoWeek/Edit';

//Radyospor
import RadyosporDashboard from '../pages/Radyospor/Dashboard';
import RadyosporFrequencies from '../pages/Radyospor/Frequencies';
import RadyosporPrograms from '../pages/Radyospor/Programs';
import RadyosporBroadcasters from '../pages/Radyospor/Broadcasters';
import RadyosporStreams from '../pages/Radyospor/Streams';
import RadyosporPodcasts from '../pages/Radyospor/Podcasts';
import RadyosporPrivatePodcasts from '../pages/Radyospor/PrivatePodcasts';
import RadyosporPrivatePrograms from '../pages/Radyospor/PrivatePrograms';
import RadyosporContact from '../pages/Radyospor/Contact';
import RadyosporSettings from '../pages/Radyospor/Settings';

import { getTokenDecoded } from '../utils/auth';
import { getRoles } from '../utils/getRoles';

const login = localStorage.ajstoken ? true : false;
const user = login ? getTokenDecoded(localStorage.ajstoken) : null;
const userRoles = user ? getRoles(user.Roles) : { app: [], role: [] };

let AuthRoutes = [
	{ path: '/', component: Dashboard },
	{ path: '/maintenance', component: ComingSoon },
	{ path: '/404', component: NotFound },
	{ path: '/lock', component: Lock },
	{ path: '/Profile', component: Profile },
];

if (userRoles.app.includes('Ajansspor')) {
	if (userRoles.role.includes('A-Editör') || userRoles.role.includes('A-Writer') || userRoles.role.includes('A-Admin') || userRoles.role.includes('Administrator')) {
		AuthRoutes = AuthRoutes.concat([
			{ path: '/cache', component: Cache },
			{ path: '/notification', component: Notification },

			// Comments
			{ path: '/web/comments', component: AjanssporComments },
			{ path: '/web/contacts', component: AjanssporContacts },

			// Tags
			{ path: '/tags/combine', component: CombineTags },

			// News
			{ path: '/news/content', component: New },
			{ path: '/news/content/:id', component: New },
			{ path: '/news', component: Contents },
			{ path: '/news/headline', component: Headline },
			{ path: '/news/quick-content', component: QuickContent },
			{ path: '/news/news', component: NewNews },
			{ path: '/news/news/:id', component: NewNews },
			{ path: '/news/agencies', component: Agencies },

			// Videos
			{ path: '/videos', component: Videos },
			{ path: '/videos/add', component: AddVideo },
			{ path: '/videos/edit/:id', component: EditVideo },
			{ path: '/videos/play/:id', component: Play },
			{ path: '/videos/upload', component: Upload },

			// TV
			{ path: '/tv/branches', component: AjanssporTvBranches },
			{ path: '/tv/leagues', component: AjanssporTvLeagues },
			{ path: '/tv/videos', component: AjanssporTvVideos },
			{ path: '/tv/best-of-the-week', component: AjanssporTvBestOfTheWeek },
			{ path: '/tv/banner', component: AjanssporTvBanner },

			// Soccer
			{ path: '/soccer/players', component: SoccerPlayers },
			{ path: '/soccer/squads', component: SoccerSquads },
		]);
	}

	if (userRoles.role.includes('A-Admin') || userRoles.role.includes('Administrator')) {
		AuthRoutes = AuthRoutes.concat([
			{ path: '/logs/log', component: Logs },
			{ path: '/logs/editorNews', component: EditorNewsLogs },
			// { path: '/users/writers', component: Writers },
			// { path: '/users/editors', component: Editors },
			{ path: '/users/normal', component: Members },
		]);
	}

	if (userRoles.role.includes('Administrator')) {
		// Developers
		AuthRoutes = AuthRoutes.concat([
			{ path: '/logs/logtypes', component: LogTypes },
			{ path: '/tools/servers', component: ServerInfo },
			{ path: '/tools/status', component: ServerStatus },
			{ path: '/tools/analytics', component: Analytics },
		]);
	}
}

if (userRoles.app.includes('Slowtime')) {
	AuthRoutes = AuthRoutes.concat([
		// Slowtime
		{ path: '/slowtime/events', component: SlowtimeEvents },
		{ path: '/slowtime/events/new', component: SlowtimeNewEvents },
		{ path: '/slowtime/events/edit/:id', component: SlowtimeEditEvents },
		{ path: '/slowtime/news', component: SlowtimeNews },
		{ path: '/slowtime/news/new', component: SlowtimeNewNews },
		{ path: '/slowtime/news/edit/:id', component: SlowtimeEditNews },
		{ path: '/slowtime/albums', component: SlowtimeAlbums },
		{ path: '/slowtime/albums/new', component: SlowtimeNewAlbum },
		{ path: '/slowtime/albums/edit/:id', component: SlowtimeEditAlbums },
		{ path: '/slowtime/video', component: SlowtimeVideo },
		{ path: '/slowtime/video/new', component: SlowtimeNewVideo },
		{ path: '/slowtime/video/edit/:id', component: SlowtimeEditVideo },
	]);
}

if (userRoles.app.includes('Radyospor')) {
	AuthRoutes = AuthRoutes.concat([
		{ path: '/radyospor', component: RadyosporDashboard },
		{ path: '/radyospor/frequencies', component: RadyosporFrequencies },
		{ path: '/radyospor/programs', component: RadyosporPrograms },
		{ path: '/radyospor/broadcasters', component: RadyosporBroadcasters },
		{ path: '/radyospor/streams', component: RadyosporStreams },
		{ path: '/radyospor/podcasts', component: RadyosporPodcasts },
		{ path: '/radyospor/special-podcasts', component: RadyosporPrivatePodcasts },
		{ path: '/radyospor/special-programs', component: RadyosporPrivatePrograms },
		{ path: '/radyospor/contacts', component: RadyosporContact },
		{ path: '/radyospor/settings', component: RadyosporSettings },
	]);
}

const PublicRoutes = [{ path: '/login', component: Login }];

export { AuthRoutes, PublicRoutes };
