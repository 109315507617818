/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Label, UncontrolledTooltip, ButtonGroup } from 'reactstrap';
import { Link } from 'react-router-dom';

//Image Cropper
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import api from '../../api';

class UiImageCropper extends PureComponent {
	state = {
		src: '',
		cropResult: null,
		ratio1: 16,
		ratio2: 9,
		zoom: 0.5,
		dragMode: 'crop',
		moveX: 0,
		moveY: 0,
		rotate: 0,
		scaleX: 1,
		scaleY: 1,
		enable: true,
		disable: false,
		modal_1: false,
		modal_2: false,
		modal_3: false,
		imgWidth: '',
		imgHeight: '',
		viewMode: 0,
		croppedHistory: [''],
		croppedIndex: 0,
		imagesData: { count: 0, images: [] },
		croppedImage: false,
		searchedImage: '',
		limit: 21,
		totalPage: 0,
		currentPage: 1,
		cropFromGallery: true,
		lastMediaId: 0,
	};

	componentDidMount() {
		this.fetchImages();
		if (this.props.defaultSrc !== '' && this.props.defaultSrc !== undefined) {
			this.setState({ src: `https://ajssarimg.mediatriple.net/${this.props.defaultSrc}.jpg`, cropFromGallery: false });
		}
	}

	fetchImages = () => {
		// api.image.getMedia(this.state.limit, this.state.limit * (this.state.currentPage - 1)).then((res) => {
		// 	this.setState({ imagesData: res, totalPage: Math.ceil(res.count / this.state.limit) });
		// });

		api.redis.getMedia().then((res) => {
			if (res.success) {
				this.setState({ lastMediaId: res.lastId });
			}
		});
	};

	searchImage = (param) => {
		this.setState(
			{
				searchedImage: param,
			},
			() => {
				if (param.length > 4) {
					api.image.getMediaSearch(param, this.state.limit, this.state.limit * (this.state.currentPage - 1)).then((res) => {
						this.setState({ imagesData: res, totalPage: Math.ceil(res.count / this.state.limit) });
					});
				} else if (param === '') {
					api.image.getMedia(this.state.limit, this.state.limit * (this.state.currentPage - 1)).then((res) => {
						this.setState({ imagesData: res, totalPage: Math.ceil(res.count / this.state.limit) });
					});
				}
			},
		);
	};

	gallery_toggle = () => {
		this.setState((prevState) => ({
			galleryModal: !prevState.galleryModal,
		}));
	};

	tog_1 = () => {
		this.setState((prevState) => ({
			modal_1: !prevState.modal_1,
		}));
	};

	onChange = (e) => {
		e.preventDefault();

		this.setState({
			galleryModal: false,
			cropFromGallery: true,
		});

		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.props.imageChanged(reader.result);
			this.setState({ src: reader.result });
		};

		reader.readAsDataURL(files[0]);
	};

	cropImage = (width, height) => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}
		this.cropper.getCroppedCanvas().setAttribute('crossorigin', 'anonymous');
		this.setState(
			{
				cropResult: this.cropper.getCroppedCanvas().toDataURL(),
				src: this.cropper.getCroppedCanvas().toDataURL(),
			},
			() => {
				this.props.imageChanged(this.state.src);
			},
		);

		this.setState({
			imgWidth: width,
			imgHeight: height,
		});

		let tempCropperHistory = this.state.croppedHistory;
		let croppedIndex = this.state.croppedIndex + 1;
		if (typeof tempCropperHistory[croppedIndex] === 'undefined') {
			tempCropperHistory.push(this.cropper.getCroppedCanvas().toDataURL());
		} else {
			tempCropperHistory[croppedIndex] = this.cropper.getCroppedCanvas().toDataURL();
		}
		this.setState({
			croppedHistory: tempCropperHistory,
			croppedIndex,
		});
	};

	changeRatio = (e, r1, r2) => {
		if (e.target.checked) {
			this.setState({ ratio1: r1, ratio2: r2 });
		}
	};

	previousCrop = () => {
		if (this.state.croppedIndex === 0) {
			return;
		}
		let index = this.state.croppedIndex - 1;
		this.setState(
			{
				croppedIndex: index,
				src: this.state.croppedHistory[index],
			},
			() => {
				this.props.imageChanged(this.state.src);
			},
		);
	};

	gallery_toggle = () => {
		this.setState((prevState) => ({
			galleryModal: !prevState.galleryModal,
		}));
	};

	selectFromGallery = (path) => {
		let croppedHistory = this.state.croppedHistory;
		croppedHistory[0] = path;

		this.setState(
			{
				croppedHistory,
				src: path,
				cropFromGallery: false,
			},
			() => {
				this.props.imageChanged(this.state.src);
			},
		);

		this.gallery_toggle();
	};

	nextSearchPage = () => {
		this.setState(
			{
				currentPage: this.state.currentPage + 1,
			},
			() => {
				this.searchImage(this.state.searchedImage);
			},
		);
	};

	previousSearchPage = () => {
		this.setState(
			{
				currentPage: this.state.currentPage - 1,
			},
			() => {
				this.searchImage(this.state.searchedImage);
			},
		);
	};

	render() {
		return (
			<React.Fragment>
				{this.state.src === '' && (
					<div className="video__error" onClick={this.gallery_toggle}>
						<Label className="btn btn-upload video__picture__error" title="Fotoğraf yükle">
							<span>Fotoğraf Yükle</span>
						</Label>
					</div>
				)}

				{this.state.src !== '' && (
					<div>
						<Row className="p-0">
							<Col xl="12">
								{/* {this.props.minicropper ? (
									''
								) : (
									<div className="docs-preview image-crop-preview clearfix">
										<div className="img-preview preview-lg"></div>
										<div className="img-preview preview-md"></div>
										<div className="img-preview preview-sm"></div>
										<div className="img-preview preview-xs"></div>
									</div>
								)} */}
								<div className="docs-toggles">
									<div className="img-crop-preview-toggles mt-4 mt-xl-2">
										<div className="btn-group d-flex flex-nowrap" data-toggle="buttons">
											<Label className={this.state.ratio1 === 16 && this.state.ratio2 === 9 ? 'btn btn-primary active' : 'btn btn-primary'}>
												<input
													type="radio"
													className="sr-only"
													id="aspectRatio1"
													name="aspectRatio"
													onChange={(e) => {
														this.changeRatio(e, 16, 9);
													}}
												/>
												<span className="docs-tooltip" id="aspectRatio1T">
													16:9
												</span>
												<UncontrolledTooltip placement="top" target="aspectRatio1T">
													aspectRatio: 16 / 9
												</UncontrolledTooltip>
											</Label>
											<Label className={this.state.ratio1 === 9 && this.state.ratio2 === 16 ? 'btn btn-primary active' : 'btn btn-primary'}>
												<input
													type="radio"
													className="sr-only"
													id="aspectRatio2"
													name="aspectRatio"
													onChange={(e) => {
														this.changeRatio(e, 9, 16);
													}}
												/>
												<span className="docs-tooltip" id="aspectRatio1T">
													9 : 16
												</span>
												<UncontrolledTooltip placement="top" target="aspectRatio1T">
													aspectRatio: 9 / 16
												</UncontrolledTooltip>
											</Label>
											<Label className={this.state.ratio1 === 4 && this.state.ratio2 === 3 ? 'btn btn-primary active' : 'btn btn-primary'}>
												<input
													type="radio"
													className="sr-only"
													id="aspectRatio3"
													name="aspectRatio"
													onChange={(e) => {
														this.changeRatio(e, 4, 3);
													}}
												/>
												<span className="docs-tooltip" id="aspectRatio2T">
													4:3
												</span>
												<UncontrolledTooltip placement="top" target="aspectRatio2T">
													aspectRatio: 4 / 3
												</UncontrolledTooltip>
											</Label>
											<Label className={this.state.ratio1 === 3 && this.state.ratio2 === 4 ? 'btn btn-primary active' : 'btn btn-primary'}>
												<input
													type="radio"
													className="sr-only"
													id="aspectRatio4"
													name="aspectRatio"
													onChange={(e) => {
														this.changeRatio(e, 3, 4);
													}}
												/>
												<span className="docs-tooltip" id="aspectRatio2T">
													3:4
												</span>
												<UncontrolledTooltip placement="top" target="aspectRatio2T">
													aspectRatio: 3 / 4
												</UncontrolledTooltip>
											</Label>
											<Label className={this.state.ratio1 === 1 && this.state.ratio2 === 1 ? 'btn btn-primary active' : 'btn btn-primary'}>
												<input
													type="radio"
													className="sr-only"
													id="aspectRatio5"
													name="aspectRatio"
													value="1"
													onChange={(e) => {
														this.changeRatio(e, 1, 1);
													}}
												/>
												<span className="docs-tooltip" id="aspectRatio3T">
													1:1
												</span>
												<UncontrolledTooltip placement="top" target="aspectRatio3T">
													aspectRatio: 1 / 1
												</UncontrolledTooltip>
											</Label>
										</div>
									</div>
								</div>
							</Col>
							<Col xl="12">
								<div className="img-container mb-2">
									<Cropper
										style={{ height: '400', width: '100%' }}
										aspectRatio={this.state.ratio1 / this.state.ratio2}
										preview={this.props.minicropper ? '' : '.img-preview'}
										guides={true}
										src={this.state.src}
										zoomTo={this.state.zoom}
										dragMode={this.state.dragMode}
										moveTo={[this.state.moveX, this.state.moveY]}
										rotateTo={this.state.rotate}
										scaleX={this.state.scaleX}
										scaleY={this.state.scaleY}
										enable={this.state.enable}
										disable={this.state.disable}
										viewMode={this.state.viewMode}
										checkCrossOrigin={false}
										ref={(cropper) => {
											this.cropper = cropper;
										}}
									/>
								</div>
							</Col>
						</Row>

						<Row>
							<Col xl="12">
								{this.state.cropFromGallery === true && (
									<Button className="w-100" type="button" color="success" onClick={() => this.cropImage('', '')}>
										<span className="docs-tooltip" id="imgResult1">
											Kırp
										</span>
									</Button>
								)}
							</Col>
						</Row>

						<Row className="mt-2">
							<Col xl="6">
								<Button className="btn btn-warning mr-2 w-100" onClick={this.gallery_toggle}>
									Galeriden Seç
								</Button>
							</Col>

							<Col xl="6">
								<Label className="btn btn-primary btn-upload w-100" htmlFor="inputImage" title="Fotoğraf seçin.">
									<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={this.onChange} />
									<span>Fotoğraf Yükle</span>
								</Label>
							</Col>
						</Row>

						{/* <Row id="actions">
							<Col xl="9" className="img-crop-preview-btns docs-buttons">
								<div className="button-items mt-2">
									<ButtonGroup>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ dragMode: 'move' });
											}}
										>
											<span className="docs-tooltip" id="dragMode1">
												<span className="mdi mdi-arrow-all"></span>
											</span>
											<UncontrolledTooltip placement="top" target="dragMode1">
												dragMode(&apos;move&apos;)
											</UncontrolledTooltip>
										</Button>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ dragMode: 'crop' });
											}}
										>
											<span className="docs-tooltip" id="dragMode2">
												<span className="mdi mdi-crop"></span>
											</span>
											<UncontrolledTooltip placement="top" target="dragMode2">
												dragMode(&apos;crop&apos;)
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<ButtonGroup>
										<Button type="button" color="primary" onClick={() => this.setState({ zoom: this.state.zoom + 0.1 })}>
											<span className="docs-tooltip" id="zoom1">
												<span className="mdi mdi-magnify-plus-outline"></span>
											</span>
											<UncontrolledTooltip placement="top" target="zoom1">
												zoomTo(0.1)
											</UncontrolledTooltip>
										</Button>
										<Button type="button" color="primary" onClick={() => this.setState({ zoom: this.state.zoom - 0.1 })}>
											<span className="docs-tooltip" id="zoom2">
												<span className="mdi mdi-magnify-minus-outline"></span>
											</span>
											<UncontrolledTooltip placement="top" target="zoom2">
												zoomTo(-0.1)
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<ButtonGroup>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ moveX: this.state.moveX - 10, moveY: this.state.moveY });
											}}
											title="Move Left"
										>
											<span className="docs-tooltip" id="move1">
												<span className="mdi mdi-arrow-left"></span>
											</span>
											<UncontrolledTooltip placement="top" target="move1">
												moveTo(-10, 0)
											</UncontrolledTooltip>
										</Button>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ moveX: this.state.moveX + 10, moveY: this.state.moveY });
											}}
											title="Move Right"
										>
											<span className="docs-tooltip" id="move2">
												<span className="mdi mdi-arrow-right"></span>
											</span>
											<UncontrolledTooltip placement="top" target="move2">
												moveTo(10, 0)
											</UncontrolledTooltip>
										</Button>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ moveX: this.state.moveX, moveY: this.state.moveY - 10 });
											}}
										>
											<span className="docs-tooltip" id="move3">
												<span className="mdi mdi-arrow-up"></span>
											</span>
											<UncontrolledTooltip placement="top" target="move3">
												moveTo(0, -10)
											</UncontrolledTooltip>
										</Button>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.setState({ moveX: this.state.moveX, moveY: this.state.moveY + 10 });
											}}
										>
											<span className="docs-tooltip" id="move4">
												<span className="mdi mdi-arrow-down"></span>
											</span>
											<UncontrolledTooltip placement="top" target="move4">
												moveTo(0, 10)
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<ButtonGroup>
										<Button type="button" color="primary" onClick={() => this.setState({ rotate: this.state.rotate - 45 })} title="Rotate Left">
											<span className="docs-tooltip" id="rotate1">
												<span className="mdi mdi-rotate-left"></span>
											</span>
											<UncontrolledTooltip placement="top" target="rotate1">
												rotate(-45)
											</UncontrolledTooltip>
										</Button>
										<Button type="button" color="primary" onClick={() => this.setState({ rotate: this.state.rotate + 45 })} title="Rotate Right">
											<span className="docs-tooltip" id="rotate2">
												<span className="mdi mdi-rotate-right"></span>
											</span>
											<UncontrolledTooltip placement="top" target="rotate2">
												rotate(45)
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<ButtonGroup>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.state.scaleX === 1 ? this.setState({ scaleX: -1 }) : this.setState({ scaleX: 1 });
											}}
											title="Flip Horizontal"
										>
											<span className="docs-tooltip" id="scale1">
												<span className="mdi mdi-arrow-left-right"></span>
											</span>
											<UncontrolledTooltip placement="top" target="scale1">
												scaleX(-1)
											</UncontrolledTooltip>
										</Button>
										<Button
											type="button"
											color="primary"
											onClick={() => {
												this.state.scaleY === 1 ? this.setState({ scaleY: -1 }) : this.setState({ scaleY: 1 });
											}}
											title="Flip Vertical"
										>
											<span className="docs-tooltip" id="scale2">
												<span className="mdi mdi-arrow-up-down"></span>
											</span>
											<UncontrolledTooltip placement="top" target="scale1">
												scaleY(-1)
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<ButtonGroup>
										<Button type="button" color="primary" onClick={() => this.setState({ enable: false, disable: true })} title="Disable">
											<span className="docs-tooltip" id="disable">
												<span className="mdi mdi-lock"></span>
											</span>
											<UncontrolledTooltip placement="top" target="disable">
												disable
											</UncontrolledTooltip>
										</Button>
										<Button type="button" color="primary" onClick={() => this.setState({ enable: true, disable: false })} title="Enable">
											<span className="docs-tooltip" id="enable">
												<span className="mdi mdi-lock-open"></span>
											</span>
											<UncontrolledTooltip placement="top" target="enable">
												enable
											</UncontrolledTooltip>
										</Button>
									</ButtonGroup>

									<Label className="btn btn-primary btn-upload" htmlFor="inputImage" title="Fotoğraf seçin.">
										<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={this.onChange} />
										<span className="docs-tooltip" id="uploadImage">
											<span className="mdi mdi-upload"></span>
										</span>
										<UncontrolledTooltip placement="top" target="uploadImage">
											Import image with Blob URLs
										</UncontrolledTooltip>
									</Label>
									<Modal isOpen={this.state.modal_1} toggle={this.tog_1} autoFocus={true}>
										<ModalHeader toggle={this.tog_1}>Cropped</ModalHeader>
										<ModalBody>
											<img style={{ width: '100%' }} src={this.state.cropResult} height={this.state.imgHeight} width={this.state.imgWidth} alt="cropped result" />
										</ModalBody>
										<ModalFooter>
											<Button type="button" color="secondary" onClick={this.tog_1}>
												Close
											</Button>
											<Link to="#" className="btn btn-primary">
												Download
											</Link>
										</ModalFooter>
									</Modal>
									<Button className="btn btn-warning mr-2" onClick={this.gallery_toggle}>
										Galeriden Seç
									</Button>

									<Button
										type="button"
										color="secondary"
										onClick={() => {
											this.setState({ moveX: 0, moveY: 0 });
										}}
									>
										<span className="docs-tooltip" id="move5">
											Move to [0,0]
										</span>
										<UncontrolledTooltip placement="top" target="move5">
											moveTo(0)
										</UncontrolledTooltip>
									</Button>
									<Button type="button" color="secondary" onClick={() => this.setState({ zoom: 1 })}>
										<span className="docs-tooltip" id="zoom3">
											Zoom to 100%
										</span>
										<UncontrolledTooltip placement="top" target="zoom3">
											zoomTo(1)
										</UncontrolledTooltip>
									</Button>
									<Button type="button" color="secondary" onClick={() => this.setState({ rotate: 180 })}>
										<span className="docs-tooltip" id="rotate3">
											Rotate 180°
										</span>
										<UncontrolledTooltip placement="top" target="rotate3">
											rotate(180)
										</UncontrolledTooltip>
									</Button>
									<Button
										type="button"
										color="secondary"
										onClick={() => {
											this.setState({ scaleX: -2, scaleY: -1 });
										}}
									>
										<span className="docs-tooltip" id="scale3">
											Scale (-2, -1)
										</span>
										<UncontrolledTooltip placement="top" target="scale3">
											scale(-2, -1)
										</UncontrolledTooltip>
									</Button>
									{this.state.cropFromGallery === true && (
										<Button type="button" color="success" onClick={() => this.cropImage('', '')}>
											<span className="docs-tooltip" id="imgResult1">
												Kırp
											</span>
										</Button>
									)}

									{this.state.croppedIndex > 0 && (
										<Button type="button" color="success" onClick={() => this.previousCrop()}>
											<span className="docs-tooltip" id="imgResult1">
												Geri Al
											</span>
										</Button>
									)}
								</div>
							</Col>
						</Row> */}
					</div>
				)}

				<Modal isOpen={this.state.galleryModal} toggle={this.gallery_toggle} centered={true} size={'lg'}>
					<div className="modal-header">
						<Label className="btn btn-primary btn-upload import" htmlFor="inputImage" title="Fotoğraf seçin.">
							<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={this.onChange} />
							<span className="docs-tooltip" id="uploadImage">
								<span className="mdi mdi-upload"></span>
							</span>
							<h5 className="ml-1 mt-2">Fotoğraf Seçin</h5>
						</Label>
						<button type="button" onClick={() => this.setState({ galleryModal: false })} className="close" data-dismiss="modal" aria-label="Kapat">
							<span aria-hidden="true">
								<i className="bx bx-x"></i>
							</span>
						</button>
					</div>
					<div className="modal-body">
						{/* <div className="row">
							<label className="col-md-2 col-form-label">Arama: </label>
							<div className="col-md-10">
								<input className="form-control" type="text" onChange={(e) => this.searchImage(e.target.value)} placeholder="Fotoğraf Ara..."></input>
							</div>
						</div> */}
						<div className="row text-center mt-4">
							{this.state.lastMediaId === 0 ? (
								<span>Gösterilecek medya yok...</span>
							) : (
								[...Array(21)].map((_, i) => {
									return (
										<div
											key={this.state.lastMediaId - i}
											className="col-lg-4 col-6 mt-2"
											onClick={() => this.selectFromGallery(`https://ajssarimg.mediatriple.net/${this.state.lastMediaId - i}.jpg`)}
										>
											<img className="mb-2" src={`https://ajssarimg.mediatriple.net/${this.state.lastMediaId - i}.jpg`} style={{ width: '100%' }} />
											{/* <span>{item.title}</span> */}
										</div>
									);
								})
							)}

							{/* {this.state.imagesData.images.map((item) => {
								return (
									<div key={item.MediaId} className="col-lg-4 col-6 mt-2" onClick={() => this.selectFromGallery(`https://ajssarimg.mediatriple.net/${item.MediaId}.jpg`)}>
										<img className="mb-2" src={`https://ajssarimg.mediatriple.net/${item.MediaId}.jpg`} style={{ width: '100%' }} />
										<span>{item.title}</span>
									</div>
								);
							})} */}
						</div>

						<nav className="mt-4" aria-label="Page navigation example">
							<ul className="pagination justify-content-center">
								{this.state.searchedImage.length > 4 && this.state.currentPage > 1 && (
									<button
										className="page-item"
										onClick={() => {
											this.previousSearchPage();
										}}
									>
										<span className="page-link" to="#">
											Önceki
										</span>
									</button>
								)}
								{this.state.searchedImage.length > 4 && (
									<li className="page-item">
										<span className="page-link" to="#">
											{this.state.currentPage} / {this.state.totalPage}
										</span>
									</li>
								)}
								{this.state.currentPage !== this.state.totalPage && this.state.searchedImage.length > 4 && this.state.totalPage !== 0 && (
									<button
										className="page-item"
										onClick={() => {
											this.nextSearchPage();
										}}
									>
										<span className="page-link" to="#">
											Sonraki
										</span>
									</button>
								)}
							</ul>
						</nav>
					</div>
				</Modal>
			</React.Fragment>
		);
	}
}

UiImageCropper.propTypes = {
	imageChanged: PropTypes.func,
	defaultSrc: PropTypes.string,
};

export default UiImageCropper;
