import { gql } from 'apollo-boost';

export const SCHEDULED_CONTENT_TWITS = gql`
	query ScheduledContentTwits($ContentId: Int!) {
		scheduledContentTwits(ContentId: $ContentId) {
			id
			contentId
			title
			accounts
			createdBy {
				username
				fullname
				date
			}
			updatedBy {
				username
				fullname
				date
			}
			scheduleTime
			isSended
			isDeleted
			contentTitle
		}
	}
`;

export const CREATE_SCHEDULED_TWIT = gql`
	mutation createScheduledTwit($input: CreateScheduledTwitInput) {
		createScheduledTwit(input: $input) {
			success
			message
		}
	}
`;
