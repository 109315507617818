import { gql } from 'apollo-boost';

/* Queries */

// export const CONTENTS = gql`
// 	{
// 		contents {
// 			ContentId
// 			Title
// 			TagList {
// 				TagId
// 				Name
// 			}
// 			IsMainPage
// 			IsHeadline
// 			PublishDate
// 			PublishEndDate
// 			CreatedDate
// 			UpdatedDate
// 			GalleryContent {
// 				Title
// 				Spot
// 				Order
// 				MediaId
// 				Id
// 				IsDeleted
// 			}
// 		}
// 	}
// `;

export const CONTENT_SEARCH = gql`
	query contentSearch($Term: String!, $Skip: Int!, $Limit: Int!) {
		contentSearch(Term: $Term, Limit: $Limit, Skip: $Skip) {
			ContentId
			Title
			TagList {
				TagId
				Name
			}
			IsMainPage
			IsHeadline
			IsDeleted
			PublishDate
			PublishEndDate
			CreatedDate
			ReadCount
			UpdatedDate
			MainPageTitle
			HeadlineOrder
		}
	}
`;

export const HEADLINES = gql`
	{
		headlines {
			ContentId
			Title
			MainPageTitle
			MediaId
			Order
			UpdatedDate
			ReadCount
		}
	}
`;

export const CONTENT_DETAIL = gql`
	query Content($ContentId: Int!) {
		content(ContentId: $ContentId) {
			Title
			SecondTitle
			ThirthTitle
			TitleType
			HideTitle
			IsLive
			IsVideoNews
			Video
			Source
			RelatedNews {
				Title
				SpotMediaId
				Url
				Order
			}
			RelatedNewList {
				Title
				SpotMediaId
				Url
				Order
			}
			Spot
			ContentId
			TagList {
				TagId
				Name
			}
			MainPageTitle
			SocialMediaTitle
			SeoTitle
			ContentType
			Body
			IsHeadline
			HeadlineOrder
			IsMainPage
			PublishDate
			PublishEndDate
			SpotMediaId
			MediaTitle
			GalleryContent {
				Title
				Spot
				Order
				MediaId
				IsDeleted
				CreatedAt
				UpdatedAt
			}
			Twits {
				title
				contentId
				accounts {
					name
					title
					createdBy {
						fullname
						username
						date
					}
				}
			}
		}
	}
`;

export const TWITTER_ACCOUNTS = gql`
	{
		twitterAccounts {
			name
			createdBy {
				username
				fullname
			}
			updatedBy {
				username
				fullname
			}
		}
	}
`;

export const AGENCY_NEWS = gql`
	query ajansContents($Limit: Int, $Skip: Int, $Ajans: String!, $Term: String, $StartDate: Date, $EndDate: Date) {
		ajansContents(Limit: $Limit, Skip: $Skip, Ajans: $Ajans, Term: $Term, StartDate: $StartDate, EndDate: $EndDate) {
			Title
			AjansType
			AjansName
			AjansId
			CreatedDate
			ContentId
		}
	}
`;

export const AGENCY_NEW = gql`
	query ajansContent($ContentId: Int!) {
		ajansContent(ContentId: $ContentId) {
			AjansName
			AjansType
			AjansId
			IsPublished
			ContentId
			IsDeleted
			ReadCount
			Title
			ContentType
			TagList {
				TagId
				Name
			}
			SpotMediaId
			MediaTitle
			Spot
			Body
			CreatedDate
			UpdatedDate
			PublishDate
			PublishEndDate
			GalleryContent {
				Title
				Spot
				Order
				MediaId
				Location
				IsDeleted
				CreatedAt
				UpdatedAt
			}
			SocialMediaTitle
			MainPageTitle
			SeoTitle
			IsMainPage
			IsSeoNews
			IsHeadline
			Success
			Message
		}
	}
`;

/* Mutations */

export const CREATE_CONTENT = gql`
	mutation createContent($input: ContentInput) {
		createContent(input: $input) {
			content {
				Title
				ContentId
			}
			url
			success
			message
		}
	}
`;

export const UPDATE_CONTENT = gql`
	mutation updateContentInfo($input: UpdateContentInfoInput) {
		updateContentInfo(input: $input) {
			content {
				Title
				ContentId
			}
			url
			success
			message
		}
	}
`;

export const CREATE_GALLERY = gql`
	mutation createGallery($input: CreateGalleryInput) {
		createGallery(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_GALLERY = gql`
	mutation updateGallery($input: UpdateGalleryInput) {
		updateGallery(input: $input) {
			success
			message
		}
	}
`;

export const DELETE_GALLERY = gql`
	mutation deleteGallery($input: DeleteGalleryInput) {
		deleteGallery(input: $input) {
			success
			message
		}
	}
`;

export const DELETE_AGENCY_GALLERY = gql`
	mutation deleteAgencyGallery($input: DeleteGalleryInput) {
		deleteAgencyGallery(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_SETTINGS = gql`
	mutation updateContentSettings($input: UpdateContentSettingsInput) {
		updateContentSettings(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_CONTENT_ISMAINPAGE = gql`
	mutation updateContentIsMainPage($input: UpdateContentIsMainPageInput) {
		updateContentIsMainPage(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_CONTENT_HEADLINE_ORDER = gql`
	mutation updateContentHeadlineOrder($input: UpdateContentHeadlineOrderInput) {
		updateContentHeadlineOrder(input: $input) {
			success
			message
		}
	}
`;

export const DELETE_CONTENT_TO_HEADLINE = gql`
	mutation deleteContentToHeadline($input: DeleteContentToHeadlineInput) {
		deleteContentToHeadline(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_MAINPAGE_TITLE = gql`
	mutation updateContentMainPageTitle($input: UpdateContentMainPageTitle) {
		updateContentMainPageTitle(input: $input) {
			success
			message
		}
	}
`;

export const ADD_CONTENT_TO_HEADLINE = gql`
	mutation addContentToHeadline($input: AddContentToHeadlineInput) {
		addContentToHeadline(input: $input) {
			success
			message
		}
	}
`;

export const DELETE_CONTENT = gql`
	mutation deleteContent($input: DeleteContentInput) {
		deleteContent(input: $input) {
			success
			message
		}
	}
`;

export const UPDATE_SERVER_STATUS = gql`
	mutation updateServerStatus($input: UpdateServerStatusInput) {
		updateServerStatus(input: $input) {
			success
			message
		}
	}
`;

export const CREATE_NEW_CONTENT = gql`
	mutation createNewContent($input: NewContentInput) {
		createNewContent(input: $input) {
			content {
				Title
				ContentId
			}
			url
			success
			message
		}
	}
`;

export const UPDATE_NEW_CONTENT = gql`
	mutation updateNewContent($input: UpdateNewContentInput) {
		updateNewContent(input: $input) {
			content {
				Title
				ContentId
			}
			url
			success
			message
		}
	}
`;

export const POST_TWEET = gql`
	mutation postTwit($input: PostTwitInput) {
		postTwit(input: $input) {
			success
			message
		}
	}
`;

export const POST_SCHEDULED_TWEET = gql`
	mutation createScheduledTwit($input: CreateScheduledTwitInput) {
		createScheduledTwit(input: $input) {
			success
			message
		}
	}
`;
