import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Select from 'react-select';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const days = [
	{ label: 'Pazartesi', value: 1 },
	{ label: 'Salı', value: 2 },
	{ label: 'Çarşamba', value: 3 },
	{ label: 'Perşembe', value: 4 },
	{ label: 'Cuma', value: 5 },
	{ label: 'Cumartesi', value: 6 },
	{ label: 'Pazar', value: 7 },
];

const Streams = () => {
	const [streams, setStreams] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [programId, setProgramId] = React.useState(0);

	const [programs, setPrograms] = React.useState([]);
	const [selectedProgram, setSelectedProgram] = React.useState('');

	const [broadcasters, setBroadcasters] = React.useState([]);
	const [selectedBroadcaster, setSelectedBroadcaster] = React.useState('');

	const [startTime, setStartTime] = React.useState('');
	const [endTime, setEndTime] = React.useState('');

	const [selectedDays, setSelectedDays] = React.useState('');

	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		fetch();

		api.radyospor.programs.list().then((res) => {
			let data = [];

			res.programs.filter((x) => x.status === true).map((item) => data.push(item));
			setPrograms(data);
		});

		api.radyospor.broadcaster.list().then((res) => {
			let data = [];

			res?.map((item) => data.push(item));
			setBroadcasters(data);
		});
	}, []);

	const data = {
		columns: [
			{
				label: '#',
				field: 'id',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Program adı',
				field: 'name',
				sort: 'asc',
				width: 20,
			},
			{
				label: 'Yayıncılar',
				field: 'broadcasters',
				sort: 'asc',
				width: 150,
			},
			{
				label: 'Günler',
				field: 'days',
				sort: 'asc',
				width: 100,
			},
			{
				label: 'Başlangıç / Bitiş Saati',
				field: 'dates',
				sort: 'asc',
				width: 50,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: streams,
	};

	const fetch = () => {
		api.radyospor.streaming.list().then((res) => {
			let data = [];

			res.streaming.map((item, i) => {
				data.push({
					...item,
					id: i + 1,
					name: item.programInfo[0].name,
					broadcasters: (
						<div className="d-flex flex-row">
							{item.broadcasters.map((broadcaster, i) => (
								<h5 className="mr-2" key={i}>
									<span className="badge badge-xl badge-info">{broadcaster.name}</span>
								</h5>
							))}
						</div>
					),
					days: (
						<div className="d-flex flex-row">
							{item.streamDays.map((day, i) => (
								<h5 className="mr-2" key={i}>
									<span className="badge badge-xl badge-success">{day.label}</span>
								</h5>
							))}
						</div>
					),
					dates: `${item.startTime} / ${item.endTime}`,
					settings: (
						<div className="btn-group mr-2">
							<button className="btn btn-secondary" onClick={() => editProgram(item.id, item.programInfo, item.broadcasters, item.streamDays, item.startTime, item.endTime)}>
								<i className="bx bxs-pencil"></i>
							</button>
							<button
								className={`btn ${!item.status ? 'btn-danger' : 'btn-secondary'}`}
								onClick={() => {
									if (!item.status) {
										window.confirm('Geri almak istediğinize emin misiniz?') && deleteStream(item.id);
									} else {
										window.confirm('Silmek istediğinize emin misiniz?') && deleteStream(item.id);
									}
								}}
							>
								<i className="bx bx-trash"></i>
							</button>
						</div>
					),
				});
			});

			setStreams(data);
		});
	};

	const deleteStream = (id) => {
		api.radyospor.streaming.delete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const editProgram = (id, programInfo, broadcasters, streamDays, startTime, endTime) => {
		setEditStatus(true);
		setProgramId(id);
		setSelectedProgram(programInfo);
		setSelectedBroadcaster(broadcasters);
		setSelectedDays(streamDays);
		setStartTime(startTime);
		setEndTime(endTime);
	};

	const update = (id) => {
		let programId = selectedProgram[0].id;
		let broadcasters = [];

		if (programId === undefined || typeof programId === 'undefined') {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Program seçimi yapmalısınız.');
		} else if (selectedBroadcaster.length <= 0) {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Yayıncı seçimi yapmalısınız.');
		} else if (selectedDays.length <= 0) {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Yayın günü seçimi yapmalısınız.');
		} else if (startTime === '' || endTime === '') {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Başlangıç ve bitiş saati seçmelisiniz.');
		} else {
			setLoading(true);

			selectedBroadcaster.map((broadcaster) => broadcasters.push({ id: broadcaster.id, name: broadcaster.name }));

			api.radyospor.streaming.update({ programId, broadcasters, streamDays: selectedDays, startTime, endTime, id }).then((res) => {
				setLoading(false);
				setEditStatus(false);
				setProgramId(0);
				setSelectedDays([]);
				setSelectedBroadcaster([]);
				setStartTime('');
				setEndTime('');

				if (res.success) {
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(res.message);
					fetch();
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.error(res.message);
				}
			});
		}
	};

	const add = () => {
		let programId = selectedProgram.id;
		let broadcasters = [];

		if (programId === undefined || typeof programId === 'undefined') {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Program seçimi yapmalısınız.');
		} else if (selectedBroadcaster.length <= 0) {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Yayıncı seçimi yapmalısınız.');
		} else if (selectedDays.length <= 0) {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Yayın günü seçimi yapmalısınız.');
		} else if (startTime === '' || endTime === '') {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Başlangıç ve bitiş saati seçmelisiniz.');
		} else {
			setLoading(true);

			selectedBroadcaster.map((broadcaster) => broadcasters.push({ id: broadcaster.id, name: broadcaster.name }));

			api.radyospor.streaming.add({ programId, broadcasters, streamDays: selectedDays, startTime, endTime }).then((res) => {
				setLoading(false);
				setStatus(false);
				setProgramId(0);
				setSelectedDays([]);
				setSelectedBroadcaster([]);
				setStartTime('');
				setEndTime('');

				if (res.success) {
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(res.message);
					fetch();
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.error(res.message);
				}
			});
		}
	};

	const selectProgram = (program) => setSelectedProgram(program);

	const selectBroadcaster = (broadcaster) => setSelectedBroadcaster(broadcaster);
	const selectDays = (day) => setSelectedDays(day);

	return (
		<>
			<Helmet>
				<title>{`Yayın Akışı - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Yayın Akışı Yönetimi" breadcrumbItem="Yayın Akışı" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Yayın Akışı</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Yayın Akışı Ekle
										</button>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal isOpen={editStatus} toggle={() => setEditStatus(!editStatus)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yayın akışını düzenleyin.</h5>
							<button type="button" onClick={() => setEditStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="program">Program</label>
								{programs.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={programs}
										classNamePrefix="select2-selection"
										placeholder="Program seçin"
										onChange={selectProgram}
										value={selectedProgram}
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayıncı(lar)</label>
								{broadcasters.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={broadcasters}
										classNamePrefix="select2-selection"
										placeholder="Yayıncı seçin"
										onChange={selectBroadcaster}
										value={selectedBroadcaster}
										isMulti
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayın Günleri</label>

								<Select options={days} classNamePrefix="select2-selection" placeholder="Günleri seçin" onChange={selectDays} value={selectedDays} isMulti />
							</div>

							<div className="form-group">
								<label htmlFor="name">Başlangıç ve Bitiş Saati</label>

								<div className="mb-3 row">
									<label htmlFor="startTime" className="col-md-2 col-form-label">
										Başlangıç
									</label>
									<div className="col-md-10">
										<input className="form-control" type="time" onChange={(e) => setStartTime(e.target.value)} defaultValue={startTime} id="startTime" />
									</div>
								</div>

								<div className="mb-3 row">
									<label htmlFor="endTime" className="col-md-2 col-form-label">
										Bitiş
									</label>
									<div className="col-md-10">
										<input className="form-control" type="time" onChange={(e) => setEndTime(e.target.value)} defaultValue={endTime} id="endTime" />
									</div>
								</div>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? update(programId) : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Düzenle'}
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yayın akışı ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="program">Program</label>
								{programs.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={programs}
										classNamePrefix="select2-selection"
										placeholder="Program seçin"
										onChange={selectProgram}
										value={selectedProgram}
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayıncı(lar)</label>
								{broadcasters.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={broadcasters}
										classNamePrefix="select2-selection"
										placeholder="Yayıncı seçin"
										onChange={selectBroadcaster}
										value={selectedBroadcaster}
										isMulti
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayın Günleri</label>

								<Select options={days} classNamePrefix="select2-selection" placeholder="Günleri seçin" onChange={selectDays} value={selectedDays} isMulti />
							</div>

							<div className="form-group">
								<label htmlFor="name">Başlangıç ve Bitiş Saati</label>

								<div className="mb-3 row">
									<label htmlFor="startTime" className="col-md-2 col-form-label">
										Başlangıç
									</label>
									<div className="col-md-10">
										<input className="form-control" type="time" onChange={(e) => setStartTime(e.target.value)} defaultValue={startTime} id="startTime" />
									</div>
								</div>

								<div className="mb-3 row">
									<label htmlFor="endTime" className="col-md-2 col-form-label">
										Bitiş
									</label>
									<div className="col-md-10">
										<input className="form-control" type="time" onChange={(e) => setEndTime(e.target.value)} defaultValue={endTime} id="endTime" />
									</div>
								</div>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? add() : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Ekle'}
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Streams;
