import { createActions, handleActions } from 'redux-actions';

const types = {
	TOGGLE_DARK_THEME: 'TOGGLE_DARK_THEME',
};

export const { toggleDarkTheme } = createActions(types.TOGGLE_DARK_THEME);

export const actions = { toggleDarkTheme };

const defaultState = {
	isDarkTheme: false,
};

export const reducer = handleActions(
	{
		[toggleDarkTheme]: (state) => ({
			...state,
			isDarkTheme: !state.isDarkTheme,
		}),
	},
	defaultState,
);
