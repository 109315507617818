import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Modal, Alert } from 'reactstrap';
import Helmet from 'react-helmet';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';

import { addLog } from '../../utils/addLog';

class Videos extends React.Component {
	state = {
		filter: {
			branch: '',
			tournament: '-1',
			matchId: null,
		},
		isEmpty: false,
		data: [],
		branches: [],
		leaguesParse: [],
		leagues: [],
		videoid: 0,
		matchId: '',
		name: '',
		matchIdModalStatus: false,
		error: null,
		branchChange: false,
		video: {},
		typeId: 0,
		categoryId: -1,
	};

	componentDidMount() {
		this.fetchVideos('?limit=1000');
		this.fetchBranches();
		this.fetchLeagues();
		addLog(22, 'Tv video listeleme ekranı görüntülendi.');
	}

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	matchUpdateToggle = () => {
		this.setState((prevState) => ({
			matchIdModalStatus: !prevState.matchIdModalStatus,
			matchId: '',
		}));
		this.removeBodyCss();
	};

	fetchBranches = () => {
		api.tv.getAllBranches().then((res) => this.setState({ branches: res.branches }));
	};

	fetchLeagues = () => {
		api.tv.getAllLeagues().then((res) => this.setState({ leaguesParse: res.tournaments }));
	};

	copyToClipboard = (e) => {
		navigator.clipboard.writeText(e);
		toastr.options = {
			positionClass: 'toast-top-center',
			progressBar: true,
			timeOut: 2000,
		};
		toastr.success('Video Iframe Olarak Kopyalandı.');
	};

	fetchVideos = (e) => {
		api.tv.getAllVideos(e).then((res) => {
			let data = [];

			if (res) {
				res.videos.map((item) => {
					data.push({
						...item,
						setting: (
							<span>
								{/* <button onClick={() => this.onLeagueEdit(item.id, item.name, item.branchId)} className="btn btn-success">
									Düzenle
								</button>{' '}
								<button onClick={() => this.setState({ deleteStatus: true, name: item.name, deletingId: item.id })} className="btn btn-danger">
									Sil
								</button>
								<a href="#!" className="btn btn-info" style={{ marginLeft: 4, marginRight: 4 }} target="_blank" rel="noreferrer">
									Video İzle
								</a> */}
								{item.MatchId ? (
									<button
										onClick={() =>
											this.setState({
												matchIdModalStatus: true,
												name: item.Title,
												videoid: item.VideoId,
												video: item,
												typeId: item.Type.id,
												leagues: this.state.leaguesParse.filter((i) => i.branchId === item.Type.id),
											})
										}
										className="btn btn-danger"
									>
										Maç ID Güncelle
									</button>
								) : (
									<button
										onClick={() => this.setState({ matchIdModalStatus: true, name: item.Title, videoid: item.VideoId, video: item, typeId: item.Type.id })}
										className="btn btn-info"
									>
										Maç ID Ekle
									</button>
								)}
								{document.queryCommandSupported('copy') && (
									<>
										<button
											style={{ marginLeft: 10 }}
											onClick={() =>
												this.copyToClipboard(
													'<iframe src="https://ajansspor.com/tv/embed/player?videoid=' +
														item.VideoId +
														'" rolling="no" frameborder="0" allowfullscreen="true" width="100%" height="390"></iframe>',
												)
											}
											className="btn btn-info"
										>
											<i className={'bx bx-clipboard'}></i>
										</button>
									</>
								)}
							</span>
						),
						branchName: item.Type.name,
						status: (
							<span>
								{item.ProgressStatus ? (
									'İşlendi'
								) : (
									<span>
										<i className="bx bx-loader bx-spin"></i> işleniyor{' '}
									</span>
								)}
							</span>
						),
					});
				});

				this.setState({ data });
			}
		});
	};

	onFilter = (e) => {
		e.preventDefault();

		if (this.state.isEmpty) {
			this.fetchVideos('?limit=1000&matchId=');
		} else {
			this.fetchVideos('?limit=1000');
		}
	};

	onChangeBranch = (e) => {
		let id = parseInt(e.target.value, 10);

		this.setState({
			filter: {
				branch: e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text,
			},
			leagues: this.state.leaguesParse.filter((i) => i.branchId === id),
		});
	};

	updateVideo = (e) => {
		e.preventDefault();
		api.tv.updateVideo(this.state.videoid, this.state.matchId, this.state.categoryId).then((res) => {
			this.setState(
				{
					videoid: '',
					matchId: '',
					error: res.message,
					categoryId: -1,
				},
				() => {
					let logData = `${this.state.videoid} id'li videonun maç id'si, ${this.state.matchId} id'si ile değiştirildi.`;
					addLog(24, logData);
					let _this = this;
					setTimeout(() => {
						_this.setState({
							error: null,
							matchIdModalStatus: false,
						});
						_this.fetchVideos('?limit=1000');
					}, 2000);
				},
			);
		});
	};

	updateMatchID = (e) => {
		e.preventDefault();

		api.tv.updateMatchId(this.state.videoid, this.state.matchId).then((res) => {
			this.setState(
				{
					videoid: '',
					matchId: '',
					error: res.message,
				},
				() => {
					let logData = `${this.state.videoid} id'li videoya, ${this.state.matchId} id'si, maç id olarak eklendi`;
					addLog(23, logData);
					let _this = this;

					setTimeout(() => {
						_this.setState({
							error: null,
							matchIdModalStatus: false,
						});

						_this.fetchVideos('?limit=1000');
					}, 2000);
				},
			);
		});
	};

	render() {
		const data = {
			columns: [
				{
					label: 'Video Başlığı',
					field: 'Title',
					sort: 'asc',
				},
				{
					label: 'Branş Adı',
					field: 'branchName',
					sort: 'asc',
					attributes: {
						'data-width': '',
					},
				},
				{
					label: 'Video Durumu',
					field: 'status',
					sort: 'asc',
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
				},
			],
			rows: this.state.data,
		};

		return (
			<>
				<Helmet>
					<title>{`Video Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor TV" breadcrumbItem="Videolar" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Videolar </CardTitle>
												<CardSubtitle className="mb-3">Videoları gizlemediğiniz sürece her zaman anasayfada görünür.</CardSubtitle>
											</div>
										</div>

										<div className="card-header mb-4">
											<form onSubmit={this.onFilter} className="form-inline justify-content-end">
												<div className="d-flex align-items-center">
													<div className="custom-control custom-checkbox mr-4">
														<input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.isEmpty} />
														<label
															className="custom-control-label"
															onClick={() => {
																this.setState({ isEmpty: !this.state.isEmpty });
															}}
														>
															Maç ID&lsquo;si olmayanlar
														</label>
													</div>
												</div>

												<button className="btn btn-success">Ara</button>
											</form>
										</div>

										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					<Modal isOpen={this.state.matchIdModalStatus} toggle={this.matchUpdateToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Video Güncelle</h5>
							<button type="button" onClick={() => this.setState({ matchIdModalStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<Alert color="secondary">{this.state.name}</Alert>

							<form method="post" onSubmit={this.updateVideo}>
								<div className="form-group">
									<div className="custom-control custom-checkbox mb-3">
										<input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.branchChange} />
										<label
											className="custom-control-label"
											onClick={() => {
												this.setState({ branchChange: !this.state.branchChange });
											}}
										>
											Branş Değiştir
										</label>
									</div>
								</div>
								{this.state.branchChange && (
									<>
										<div className="form-group">
											<label htmlFor="">Branş</label>
											<select name="" id="" className="form-control" onChange={(e) => this.onChangeBranch(e)}>
												<option value="-1">Seçin</option>
												{this.state.branches.map((item, i) => (
													<option value={item.id} key={i}>
														{item.name}
													</option>
												))}
											</select>
										</div>

										{/* <div className="form-group">
											<label htmlFor="">Maç ID</label>
											<input type="number" className="form-control" placeholder="Giriniz" />
										</div> */}
									</>
								)}
								<div className="form-group">
									<label htmlFor="">Lig</label>
									<select name="" id="" className="form-control" onChange={(e) => this.setState({ categoryId: e.target.value })} value={this.state.video.CategoryId}>
										<option value="-1">Seçin</option>
										{console.log(this.state.leagues)}
										{this.state.leagues.map((item, i) => (
											<option value={item.id} key={i}>
												{item.name}
											</option>
										))}
									</select>
								</div>
								{/* <div className="form-group">
									<label htmlFor="name">Kategori</label>
									<select
										className="form-control"
										name="categoryid"
										id="category"
										onChange={(e) => this.setState({ categoryId: e.target.value })}
										value={this.state.video.CategoryId}
									>
										<option value="-1">Seçin</option>
										{this.state.leaguesParse.map((item, i) => (
											<option key={i} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div> */}
								{this.state.video !== {} && this.state.typeId === 1 && (
									<div className="form-group">
										<label htmlFor="name">Maç ID</label>
										<input type="text" value={this.state.matchId} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ matchId: e.target.value })} />
									</div>
								)}

								<button className="btn btn-info">Kaydet</button>
							</form>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default Videos;
