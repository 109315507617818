import React from 'react';
// import { Container, Row, Col, Alert, Card, CardBody, CardTitle, Media } from 'reactstrap';
import { Container, Row, Col } from 'reactstrap';
import Helmet from 'react-helmet';

import 'chartist/dist/scss/chartist.scss';
import '../../assets/scss/chartist.scss';

import Breadcrumbs from '../../components/Common/Breadcrumb';
//import LineAreaChart from '../../components/Charts/Chartist/LineAreaChart';
// import PieChart from '../../components/Charts/Chartist/PieChart';
// import LineColumnArea from '../../components/Charts/Apex/LineColumnArea';
// import LineColum from '../../components/Charts/Apex/LineColum';

import WelcomeComp from './WelcomeComp';

// import { numberFormat } from '../../utils/Constant';
import api from '../../api';

class Dashboard extends React.Component {
	state = {
		liveUser: '0',
		totalUser: '0',
		totalToday: '0',
		totalUserData: [],
		mobileDevice: '0',
		desktopDevice: '0',
		consoleDevice: '0',
		notSetDevice: '0',
		newUser: [],
		returningUser: [],
		todayNewUser: '0',
		todayReturningUser: '0',
		totalNewUser: '0',
		totalReturningUser: '0',
		dailyDevice: [],
		ajansspor: {
			ios: {
				daily: '0',
				weekly: '0',
				monthly: '0',
			},
		},
		radyospor: {
			ios: {
				daily: '0',
				weekly: '0',
				monthly: '0',
			},
			android: {
				daily: '0',
				weekly: '0',
				monthly: '0',
			},
		},
		slowtime: {
			ios: {
				daily: '0',
				weekly: '0',
				monthly: '0',
			},
			android: {
				daily: '0',
				weekly: '0',
				monthly: '0',
			},
		},
		brand: localStorage.getItem('currentBrand') ? localStorage.getItem('currentBrand') : 'Ajansspor',
		isOpen: true,
	};

	componentDidMount() {
		// this.fetchLiveUser();
		// this.lastMonthUser();
		// this.operatingSystem();
		// this.userTypes();
		// this.fetchAppcenter();
		// this.interval = setInterval(() => {
		// 	this.fetchLiveUser();
		// }, 30000);
		//api.getSiteStatus().then((res) => console.log(res.status));
	}

	fetchLiveUser = () => {
		api.stats.liveVisitor().then((res) => {
			this.setState({
				liveUser: res.user,
			});
		});
	};

	lastMonthUser = () => {
		api.stats.lastMonth().then((res) => {
			this.setState({
				totalUser: res.total,
				//totalToday: res.rows[res.rows?.length - 1].count,
				totalToday: 0,
				totalUserData: res.rows,
			});
		});
	};

	operatingSystem = () => {
		api.stats.devices().then((res) => {
			this.setState({
				mobileDevice: res?.total?.rows?.mobile,
				desktopDevice: res?.total?.rows?.desktop,
				consoleDevice: res?.total?.rows?.consol,
				notSetDevice: res?.total?.rows?.notSet,
				dailyDevice: res?.daily,
			});
		});
	};

	userTypes = () => {
		api.stats.usertype().then((res) => {
			this.setState(
				{
					newUser: res?.rows?.New,
					returningUser: res?.rows?.Returning,
					todayNewUser: res?.rows?.New[res?.rows?.New?.length - 1].count,
					todayReturningUser: res?.rows?.Returning[res.rows?.Returning?.length - 1].count,
					totalUser: res?.total,
					totalToday: res?.rows?.DailyTotal[res.rows?.DailyTotal?.length - 1].total,
					totalUserData: res?.rows?.DailyTotal,
				},
				() => {
					let totalNewUser = 0;
					let totalReturningUser = 0;

					for (let i = 0; i < this.state.newUser.length; i++) {
						totalNewUser = totalNewUser + this.state.newUser[i].count;
					}

					for (let i = 0; i < this.state.returningUser.length; i++) {
						totalReturningUser = totalReturningUser + this.state.returningUser[i].count;
					}

					this.setState({
						totalNewUser,
						totalReturningUser,
					});
				},
			);
		});
	};

	fetchAppcenter = () => {
		// ajansspor ios
		api.stats.appcenter(1).then((res) =>
			this.setState({
				ajansspor: {
					ios: {
						daily: res?.data?.daily[res.data.daily.length - 1].count,
						weekly: res?.data?.weekly[res.data.weekly.length - 1].count,
						monthly: res?.data?.monthly[res.data.monthly.length - 1].count,
					},
				},
			}),
		);

		// radyospor ios
		api.stats.appcenter(2).then((res) =>
			this.setState({
				radyospor: {
					...this.state.radyospor,
					ios: {
						daily: res?.data?.daily[res.data.daily.length - 1].count,
						weekly: res?.data?.weekly[res.data.weekly.length - 1].count,
						monthly: res?.data?.monthly[res.data.monthly.length - 1].count,
					},
				},
			}),
		);

		// radyospor android
		api.stats.appcenter(3).then((res) =>
			this.setState({
				radyospor: {
					...this.state.radyospor,
					android: {
						daily: res?.data?.daily[res.data.daily.length - 1].count,
						weekly: res?.data?.weekly[res.data.weekly.length - 1].count,
						monthly: res?.data?.monthly[res.data.monthly.length - 1].count,
					},
				},
			}),
		);

		// slowtime ios
		api.stats.appcenter(4).then((res) =>
			this.setState({
				slowtime: {
					...this.state.slowtime,
					ios: {
						daily: res?.data?.daily[res.data.daily.length - 1].count,
						weekly: res?.data?.weekly[res.data.weekly.length - 1].count,
						monthly: res?.data?.monthly[res.data.monthly.length - 1].count,
					},
				},
			}),
		);

		// slowtime android
		api.stats.appcenter(5).then((res) =>
			this.setState({
				slowtime: {
					...this.state.slowtime,
					android: {
						daily: res?.data?.daily[res.data.daily.length - 1].count,
						weekly: res?.data?.weekly[res.data.weekly.length - 1].count,
						monthly: res?.data?.monthly[res.data.monthly.length - 1].count,
					},
				},
			}),
		);
	};

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	render() {
		// let { ajansspor, radyospor, slowtime, brand } = this.state;
		let { brand } = this.state;

		return (
			<div className="page-content">
				<Helmet>
					{brand === 'ajansspor' && <title>{`${process.env.REACT_APP_TITLE}`}</title>}

					{brand === 'radyoslowtime' && <title>{`${process.env.REACT_APP_SLOW_TITLE}`}</title>}
				</Helmet>

				<Container fluid>
					<Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

					<Row>
						<Col xl="4">
							<WelcomeComp />

							{/* <Modal
								isOpen={this.state.isOpen}
								toggle={() => {
									this.setState({ isOpen: !this.state.isOpen });
								}}
								centered={true}
							>
								<div className="modal-header">
									<h5 className="modal-title mt-0">Önemli Uyarı</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Kapat" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
										<span aria-hidden="true">&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<p>
										Şu an görüntülediğiniz <b>admin.dev.ajansspor.com</b> alanı geliştirici ekibinin Yönetim Paneli geliştirmesinde kullandığı, her geliştirmede otomatik olarak
										güncellendiği bir alandır.
									</p>
									<br />
									<p>
										Bu alanda yüksek performans elde edebilirsiniz ancak bu alanda yapılan güncelleştirmeler önceden kimseye haber verilmez. Bu nedenle haber girişlerinizde
										dikkatli olmanızı tavsiye ederiz. Haber girişi sırasında yapılan güncellemelerde kaybolan haberlerde sorumluluk kabul etmiyoruz. Haberleri öncelikle Word, Not
										Defteri, Pages gibi araçlarda yazmanız daha sonra panele eklemeniz haber kayıplarının önüne geçecektir.
									</p>
									<br />
									<p>
										* Bu alan adı üzerinde yaşanılan teknik problemlerde (haber girilirken güncelleme olması, haber düzenlenirken güncelleme olması, haber silinirken güncelleme
										olması) şikayetler dikkate alınmayacaktır. Bu durumun devam etmesi üzerine <b>admin.dev.ajansspor.com</b> alanı tüm editörlere tekrar kapatılacaktır.
									</p>
									<br />
									<p>Anlayışınız için teşekkür ederiz.</p>

									<button className="btn btn-info mt-4" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
										Anladım.
									</button>
								</div>
							</Modal> */}

							{/* <Card className="mini-stats-wid">
								<CardBody>
									<Media>
										<Media body>
											<p className="text-muted font-weight-medium">Anlık Ziyaretçi</p>
											<h4 className="mb-0">{numberFormat(this.state.liveUser)}</h4>
										</Media>
										<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
											<span className="avatar-title">
												<i className="fas fa-user-clock font-size-24"></i>
											</span>
										</div>
									</Media>
								</CardBody>
							</Card> */}

							{/* <Card className="mini-stats-wid">
								<CardBody>
									<Media>
										<Media body>
											<p className="text-muted font-weight-medium">Ajansspor</p>

											<Row>
												<Col sm={6}>
													<p>iOS Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>{numberFormat(ajansspor.ios.daily)}</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>{numberFormat(ajansspor.ios.weekly)}</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>{numberFormat(ajansspor.ios.monthly)}</span>
														</li>
													</ul>
												</Col>
												<Col sm={6}>
													<p>Android Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>-</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>-</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>-</span>
														</li>
													</ul>
												</Col>
											</Row>
										</Media>
									</Media>
								</CardBody>
							</Card> */}

							{/* <Card className="mini-stats-wid">
								<CardBody>
									<Media>
										<Media body>
											<p className="text-muted font-weight-medium">Radyospor</p>

											<Row>
												<Col sm={6}>
													<p>iOS Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>{numberFormat(radyospor.ios.daily)}</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>{numberFormat(radyospor.ios.weekly)}</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>{numberFormat(radyospor.ios.monthly)}</span>
														</li>
													</ul>
												</Col>
												<Col sm={6}>
													<p>Android Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>{numberFormat(radyospor.android.daily)}</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>{numberFormat(radyospor.android.weekly)}</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>{numberFormat(radyospor.android.monthly)}</span>
														</li>
													</ul>
												</Col>
											</Row>
										</Media>
									</Media>
								</CardBody>
							</Card> */}

							{/* <Card className="mini-stats-wid">
								<CardBody>
									<Media>
										<Media body>
											<p className="text-muted font-weight-medium">Slowtime</p>

											<Row>
												<Col sm={6}>
													<p>iOS Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>{numberFormat(slowtime.ios.daily)}</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>{numberFormat(slowtime.ios.weekly)}</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>{numberFormat(slowtime.ios.monthly)}</span>
														</li>
													</ul>
												</Col>
												<Col sm={6}>
													<p>Android Kullanıcısı</p>

													<ul className="analytics__inline-list">
														<li>
															<span>Günlük</span>
															<span>{numberFormat(slowtime.android.daily)}</span>
														</li>

														<li>
															<span>Haftalık</span>
															<span>{numberFormat(slowtime.android.weekly)}</span>
														</li>

														<li>
															<span>Aylık</span>
															<span>{numberFormat(slowtime.android.monthly)}</span>
														</li>
													</ul>
												</Col>
											</Row>
										</Media>
									</Media>
								</CardBody>
							</Card> */}

							{/* <Card>
								<CardBody>
									<CardTitle className="mb-4">Son 30 Günlük Ziyaretçi</CardTitle>

									<Row className="justify-content-center">
										<Col sm={6}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.mobileDevice)}</h5>
												<p className="text-muted">Mobil Ziyaretçi</p>
											</div>
										</Col>
										<Col sm={6}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.desktopDevice)}</h5>
												<p className="text-muted">Masaüstü Ziyaretçisi</p>
											</div>
										</Col>
										<Col sm={6}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.consoleDevice)}</h5>
												<p className="text-muted">Konsol Ziyaretçisi</p>
											</div>
										</Col>
										<Col sm={6}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.notSetDevice)}</h5>
												<p className="text-muted">Belirsiz Ziyaretçi</p>
											</div>
										</Col>
									</Row>
									{parseInt(this.state.mobileDevice, 10) !== 0 && (
										<PieChart
											mobile={parseInt(this.state.mobileDevice, 10)}
											desktop={parseInt(this.state.desktopDevice, 10)}
											notset={parseInt(this.state.notSetDevice, 10)}
											console={parseInt(this.state.consoleDevice, 10)}
										/>
									)}
								</CardBody>
							</Card> */}
						</Col>
						<Col xl="8">
							{/* <Row>
								<Col md="4">
									<Card className="mini-stats-wid">
										<CardBody>
											<Media>
												<Media body>
													<p className="text-muted font-weight-medium">Bugün Toplam Ziyaretçi</p>
													<h4 className="mb-0">{numberFormat(this.state.totalToday)}</h4>
												</Media>
												<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
													<span className="avatar-title">
														<i className={'bx bx-user font-size-24'}></i>
													</span>
												</div>
											</Media>
										</CardBody>
									</Card>
								</Col>

								<Col md="4">
									<Card className="mini-stats-wid">
										<CardBody>
											<Media>
												<Media body>
													<p className="text-muted font-weight-medium">Bugün Yeni Gelen Ziyaretçi</p>
													<h4 className="mb-0">{numberFormat(this.state.todayNewUser)}</h4>
												</Media>
												<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
													<span className="avatar-title">
														<i className={'bx bx-user-plus font-size-24'}></i>
													</span>
												</div>
											</Media>
										</CardBody>
									</Card>
								</Col>

								<Col md="4">
									<Card className="mini-stats-wid">
										<CardBody>
											<Media>
												<Media body>
													<p className="text-muted font-weight-medium">Bugün Tekrar Gelen Ziyaretçi</p>
													<h4 className="mb-0">{numberFormat(this.state.todayReturningUser)}</h4>
												</Media>
												<div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
													<span className="avatar-title">
														<i className={'bx bx-user-check font-size-24'}></i>
													</span>
												</div>
											</Media>
										</CardBody>
									</Card>
								</Col>
							</Row> */}

							{/* <Card>
								<CardBody>
									<CardTitle className="mb-4">Son 30 Günlük Ziyaretçi</CardTitle>

									<Row className="justify-content-center">
										<Col sm={4}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.totalUser)}</h5>
												<p className="text-muted">Toplam Ziyaretçi</p>
											</div>
										</Col>
										<Col sm={4}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.totalNewUser)}</h5>
												<p className="text-muted">Yeni Gelen Ziyaretçi</p>
											</div>
										</Col>
										<Col sm={4}>
											<div className="text-center">
												<h5 className="mb-0 font-size-20">{numberFormat(this.state.totalReturningUser)}</h5>
												<p className="text-muted">Tekrar Gelen Ziyaretçi</p>
											</div>
										</Col>
									</Row>
									{this.state.totalUserData.length > 0 && this.state.newUser.length > 0 && this.state.returningUser.length > 0 && (
										<LineColumnArea data={this.state.totalUserData} newUser={this.state.newUser} returningUser={this.state.returningUser} />
									)}
								</CardBody>
							</Card>

							<Card>
								<CardBody>
									<CardTitle className="mb-4">Son 30 Günlük Cihaz (Günlük)</CardTitle>
									{this.state.dailyDevice.length > 0 && <LineColum data={this.state.dailyDevice} />}
								</CardBody>
							</Card> */}
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}

export default Dashboard;
