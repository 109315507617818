import React from 'react';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap'; //Modal,
import api from '../../api';
import moment from 'moment';

class Contacts extends React.Component {
	state = {
		contacts: [],
	};

	componentDidMount() {
		this.fetchContacts();
	}

	fetchContacts = () => {
		api.web.getContacts('?limit=1000').then((res) => {
			// this.setState({contacts: res.contactList});
			let data = [];
			if (res) {
				res.contactList
					.sort((a, b) => b.ContactId - a.ContactId)
					.map((item) => {
						data.push({
							...item,
							fixedDate: <span>{moment(item.CreatedDate).format('DD/MM/YYYY HH:mm')}</span>,
							info: (
								<span>
									{item.Name} {item.Surname}
								</span>
							),
							cnt: (
								<span>
									{item.Email} <br /> {item.Phone}
								</span>
							),
						});
					});

				this.setState({ contacts: data });
			}
		});
	};

	render() {
		const data = {
			columns: [
				{
					label: 'İsim',
					field: 'info',
					sort: 'asc',
					width: '200px',
				},
				{
					label: 'İletişim',
					field: 'cnt',
					sort: 'asc',
					width: '200px',
				},
				{
					label: 'Mesaj',
					field: 'Message',
					sort: 'asc',
					width: '100px',
				},
				{
					label: 'Tarih',
					field: 'fixedDate',
					sort: 'asc',
					width: '100px',
				},
			],
			rows: this.state.contacts,
		};
		return (
			<>
				<Helmet>
					<title>{`İletişim Formu | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor Web" breadcrumbItem="İletişim Formu" />

						<Row>
							<Col className="md-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>İletişim Formu </CardTitle>
											</div>
										</div>
										<div className="contacts-table">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default Contacts;
