import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TextEditor from '../../../components/TextEditor';
import api from '../../../api';

const NewNews = () => {
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [image, setImage] = useState(null);
	const [imageHolder, setImageHolder] = useState(null);
	const [validateError, setValidateError] = useState({});
	const [loading, setLoading] = useState(false);

	const onSubmit = () => {
		const errors = validate({ title, body });
		setValidateError(errors);

		if (Object.keys(validateError).length === 0) {
			setLoading(true);

			let data = {
				Content: body,
				Title: title,
			};

			api.slowtime.news.create(data).then((res) => {

				if (res.success) {
					if (image) {
						let imageData = new FormData();

						imageData.append('profile_image', image);
						imageData.append('id', res.data.Id);

						api.slowtime.news.upload(imageData).then((res) => {
							if (res.success) {
								toastr.options = {
									positionClass: 'toast-top-center',
								};
								toastr.success('Haber başarıyla kaydedildi.');
								setTimeout(() => window.location.reload(), 2000);
							} else {
								toastr.options = {
									positionClass: 'toast-top-center',
								};
								toastr.error('Haber kaydedilirken bir hata meydana geldi.');
							}
						});
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.success('Haber başarıyla kaydedildi. Haber fotoğrafı seçmediniz.');
					}
				}else{
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error('Haber kaydedilirken bir hata meydana geldi.');
				}
			});
		}
	};

	const onChangeImageInput = (e) => {
		e.preventDefault();

		const reader = new FileReader();

		reader.onload = () => {
			setImageHolder(reader.result);
		};

		reader.readAsDataURL(e.target.files[0]);

		setImage(e.target.files[0]);
	};

	const validate = (data) => {
		const errors = {};
		if (!data.title) errors.title = 'Başlık boş olamaz.';
		if (!data.body) errors.body = 'İçerik boş olamaz.';
		return errors;
	};

	return (
		<>
			<Helmet>
				<title>{`Etkinlikler - Slowtime Yönetimi | ${process.env.REACT_APP_SLOW_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Etkinlik Yönetimi" breadcrumbItem="Etkinlik Ekle" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Etkinlik Ekle</CardTitle>
										</div>
									</div>

									<Form>
										<Row>
											<Col xl={12}>
												<FormGroup>
													<Label for="Title">Başlık</Label>
													<Input id="Title" name="Title" onChange={(text) => setTitle(text.target.value)} value={title} />
													{validateError.title && <div className="invalid-feedback d-block">{validateError.title}</div>}
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xl={12}>
												<FormGroup>
													<TextEditor onChange={(text) => setBody(text)} value={body} />
												</FormGroup>
											</Col>
										</Row>

										{image && (
											<Row>
												<Col xl={12} className="d-flex flex-column justify-content-center mb-3">
													<div className="text-center">
														<img height="200px" width="auto" src={imageHolder ? imageHolder : ''} />
													</div>
												</Col>
											</Row>
										)}

										<Row>
											<Col xl={12}>
												<Label className="btn btn-primary btn-upload w-100" htmlFor="inputImage" title="Fotoğraf seçin.">
													<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => onChangeImageInput(e)} />
													<span>Fotoğraf Yükle</span>
												</Label>
											</Col>
										</Row>

										{image && (
											<Row className="mb-3">
												<Col xl={12}>
													<Button
														className="btn btn-danger mt-4 w-100"
														onClick={() => {
															setImage(null);
															setImageHolder(null);
														}}
													>
														Fotoğrafı Kaldır
													</Button>
												</Col>
											</Row>
										)}

										<button type="button" className="btn btn-primary" onClick={() => onSubmit()}>
											{loading ? <i className="bx bx-loader bx-spin"></i> : 'Haberi Kaydet'}
										</button>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default NewNews;
