import React from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import ReactHlsPlayer from 'react-hls-player';

const Play = () => {
	const id = window.location.pathname.split('/').length > 3 && window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

	console.log(id);

	return (
		<div className="page-content">
			<Container fluid>
				<Row>
					<Col className="col-12">
						<Card>
							<CardBody>
								<div className="align-center">
									<ReactHlsPlayer
										src={`https://videoonly.mediatriple.net/auto_generated_58795f3207c15/segment/${id}.m3u8`}
										hlsConfig={{
											maxLoadingDelay: 4,
											minAutoBitrate: 0,
											lowLatencyMode: true,
										}}
										autoPlay
										controls
										height={420}
									/>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Play;
