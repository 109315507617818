import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody, Media, NavItem, NavLink, TabContent, TabPane, Button } from 'reactstrap';
import Avatar from 'react-avatar';
import { Query, withApollo } from 'react-apollo';
import classnames from 'classnames';

import Breadcrumb from '../components/Common/Breadcrumb';
import { GET_PROFILE } from '../queries/UserQuery';
// import getRoles from '../utils/getRoles';
import api from '../api';
import PersonalInfo from '../components/Forms/Users/PersonalInfo';
import PasswordInfo from '../components/Forms/Users/PasswordInfo';

class UserProfile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			fullname: '',
			activeTab: window.location.search.replace('?s=', '') !== '' ? parseInt(window.location.search.replace('?s=', ''), 10) : 1,
			activeTabProgress: 1,
			progressValue: 25,
			loading: false,
		};
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			if (tab >= 1 && tab <= 3) {
				this.setState({
					activeTab: tab,
				});
			}
		}
	}

	refreshProfile = () => {
		this.setState({loading: true});
		api.user.refreshProfile().then(res => {
			localStorage.setItem('ajstoken', res.Token);
			window.location.reload();
		});
	}

	render() {
		return (
			<React.Fragment>
				<div className="page-content">
					<Container fluid>
						<Breadcrumb title="Dashboard" breadcrumbItem="Profil" />

						<Query query={GET_PROFILE}>
							{({ loading, data }) => {
								if (loading) return null;

								const profile = data.getProfile;

								return (
									<>
										<Row>
											<Col lg="12">
												<Card>
													<CardBody>
														<div className="d-flex justify-content-between">
															<Media>
																<div className="mr-3">
																	<Avatar name={profile.Fullname} className="rounded-circle" size={72} />
																</div>
																<Media body className="align-self-center">
																	<div className="text-muted">
																		<h5>{profile.Fullname}</h5>
																		<p className="mb-1">{profile.Email}</p>
																		{/* <p className="mb-0">{getRoles(profile.Role)}</p> */}
																	</div>
																</Media>
															</Media>
															<div className="align-self-center">
																<Button onClick={(()=> this.refreshProfile())} disabled={this.state.loading}>
																	{this.state.loading ? <i className="bx bx-loader bx-spin"></i> : 'Profili Yenile'}
																</Button>
															</div>
														</div>
													</CardBody>
												</Card>
											</Col>
										</Row>

										<h4 className="card-title mb-4">Kişisel Bilgileriniz</h4>

										<Card>
											<CardBody>
												<div id="basic-pills-wizard" className="twitter-bs-wizard">
													<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === 1 })}
																onClick={() => {
																	this.toggleTab(1);
																}}
															>
																<span className="step-number mr-2">01</span>
																Kişisel Bilgileriniz
															</NavLink>
														</NavItem>
														<NavItem>
															<NavLink
																className={classnames({ active: this.state.activeTab === 2 })}
																onClick={() => {
																	this.toggleTab(2);
																}}
															>
																<span className="step-number mr-2">02</span>
																<span>Şifre Yönetimi</span>
															</NavLink>
														</NavItem>
														{/* <NavItem>
													<NavLink
														className={classnames({ active: this.state.activeTab === 3 })}
														onClick={() => {
															this.toggleTab(3);
														}}
													>
														<span className="step-number mr-2">03</span>
														Haber Bilgileriniz
													</NavLink>
												</NavItem> */}
													</ul>
												</div>

												<TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
													<TabPane tabId={1}>
														<PersonalInfo data={profile} client={this.props.client} />
													</TabPane>
													<TabPane tabId={2}>
														<PasswordInfo client={this.props.client} />
													</TabPane>
												</TabContent>
											</CardBody>
										</Card>
									</>
								);
							}}
						</Query>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

UserProfile.propTypes = {
	client: PropTypes.object,
};

export default withApollo(UserProfile);
