import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Modal, Alert } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';
import { addLog } from '../../utils/addLog';
class Branches extends React.Component {
	state = {
		addLeagueModal: false,
		name: '',
		branchId: '-1',
		data: [],
		branches: [],
		onEditing: false,
		editingId: null,
		deleteStatus: false,
		deletingId: null,
		searchStatus: false,
		leagues: [],
		broadageId: 0,
		isLeague: false
	};

	addLeagueModalToggle = () => {
		this.setState((prevState) => ({
			addLeagueModal: !prevState.addLeagueModal,
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	componentDidMount() {
		this.fetchLeagues();
		this.fetchBranches();
		addLog(29, 'Tv Kategori Listeleme ekranı görüntülendi.');
	}

	fetchBranches = () => {
		api.tv.getAllBranches().then((res) => this.setState({ branches: res.branches }));
	};

	fetchLeagues = () => {
		api.tv.getAllLeagues().then((res) => {
			let data = [];

			if (res) {
				res.tournaments.map((item) => {
					data.push({
						...item,
						setting: (
							<span>
								<button onClick={() => this.onLeagueEdit(item.id, item.name, item.branchId)} className="btn btn-success">
									Düzenle
								</button>{' '}
								<button onClick={() => this.setState({ deleteStatus: true, name: item.name, deletingId: item.id })} className="btn btn-danger">
									Sil
								</button>
							</span>
						),
						branchName: item.Branch.name,
					});
				});

				this.setState({ data });
			}
		});
	};

	addLeague = (e) => {
		e.preventDefault();

		let name = this.state.name;
		let branchId = this.state.branchId;
		let broadageId = -1;
		if(this.state.isLeague){
			broadageId = this.state.broadageId;
		}

		if (name && branchId && branchId !== '-1') {
			if (!this.state.onEditing) {
				api.tv.addLeague(name, branchId, broadageId).then((res) => {
					if(res.success) {
						let logData = `${name} isminde kategori oluşturuldu.`;
						addLog(30, logData);
					}
					this.setState(
						{
							name: '',
							branchId: '-1',
							broadageId: 0,
							error: res.message,
						},
						() => {
							let _this = this;
							setTimeout(() => {
								_this.setState({
									error: null,
									addLeagueModal: false,
								});

								_this.fetchLeagues();
							}, 2000);
						},
					);
				});
			} else {
				api.tv.updateLeague(this.state.editingId, name, branchId).then((res) => {
					this.setState(
						{
							name: '',
							branchId: '-1',
							error: res.message,
							editingId: null,
							onEditing: false,
						},
						() => {
							let _this = this;
							setTimeout(() => {
								_this.setState({
									error: null,
									addLeagueModal: false,
								});

								_this.fetchLeagues();
							}, 2000);
						},
					);
				});
			}
		}
	};

	onLeagueEdit = (id, name, branchId) => {
		this.setState({
			name,
			onEditing: true,
			editingId: id,
			addLeagueModal: true,
			branchId,
		});
	};

	onBranchDelete = (id) => {
		api.tv.deleteLeague(id).then((res) => {
			if (!res.success) {
				alert(res.message);

				this.setState({ deleteError: res.message });
			} else {
				let logData = `${id} li kategori silindi.`;
				addLog(32, logData);
				this.setState({
					deleteStatus: false,
					deletingId: null,
					deleteError: null,
				});
				this.fetchLeagues();
			}
		});
	};

	searchLeague = (e) => {
		let value = e.target.value;

		if(parseInt(this.state.branchId) === 1 && this.state.isLeague){
			this.setState({ name: value }, () => {
				if (this.state.name.length > 3) {
					api.tv.searchLeague(this.state.name).then((res) => this.setState({ leagues: res, searchStatus: true }));
				}
			});
		}else{
			this.setState({ name: value });
		}
	};

	selectLeague = (broadageId, name) => {
		this.setState({
			name,
			searchStatus: false,
			broadageId,
		});
	};

	render() {
		const data = {
			columns: [
				{
					label: 'ID',
					field: 'id',
					sort: 'asc',
				},
				{
					label: 'KAtegori Adı',
					field: 'name',
					sort: 'asc',
				},
				{
					label: 'Branş Adı',
					field: 'branchName',
					sort: 'asc',
					attributes: {
						'data-width': '',
					},
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
				},
			],
			rows: this.state.data,
		};

		return (
			<>
				<Helmet>
					<title>{`Lig Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor TV" breadcrumbItem="KAtegoriler" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>TV Kategorileri </CardTitle>
												<CardSubtitle className="mb-3">Kategorileri gizlemediğiniz sürece her zaman anasayfada görünür.</CardSubtitle>
											</div>

											<button className="btn btn-primary" onClick={this.addLeagueModalToggle}>
												Yeni Kategori Ekle
											</button>
										</div>

										<div className="mini-table">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					<Modal isOpen={this.state.addLeagueModal} toggle={this.addLeagueModalToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yeni Kategori Ekle</h5>
							<button type="button" onClick={() => this.setState({ addLeagueModal: false, searchStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<form method="post" onSubmit={this.addLeague}>

								<div className="form-group">
									<label htmlFor="branch">Branş Adı - {this.state.branchId}</label>
									<select className="form-control" name="brancid" id="branch" onChange={(e) => this.setState({ branchId: e.target.value })} value={this.state.branchId}>
										<option value="-1">Seçin</option>
										{this.state.branches.map((item, i) => (
											<option key={i} value={item.id}>
												{item.name}
											</option>
										))}
									</select>
								</div>
								<div className='form-group'>
									<label>Lig Mi?</label>
									<div className="custom-control custom-checkbox mb-3">
										<input type="checkbox" className="custom-control-input" id="CustomCheck1" onChange={() => false} checked={this.state.isLeague} />
										<label className="custom-control-label" onClick={() => { this.setState({ isLeague: !this.state.isLeague }); }} >Lig Seçimi Mi?</label>
									</div>
								</div>
								{parseInt(this.state.branchId) !== -1 && 
									<div className="league__select">
										<div className="form-group">
											<label htmlFor="name">Kategori Adı</label>
											<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.searchLeague(e)} />
										</div>

										{this.state.searchStatus && parseInt(this.state.branchId) === 1 && this.state.isLeague && (
											<div className="league__list">
												<ul>
													{this.state.leagues.length > 0 &&
														this.state.leagues.map((item, i) => (
															<li key={i} onClick={() => this.selectLeague(item.id, item.name)}>
																{item.name}
															</li>
														))}
												</ul>
											</div>
										)}
									</div>
								}
								

								<button className="btn btn-info" disabled={this.state.name === ''}>
									Kaydet
								</button>
							</form>
						</div>
					</Modal>

					<Modal isOpen={this.state.deleteStatus} toggle={this.deleteToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Silmek istediğinize emin misiniz?</h5>
							<button type="button" onClick={() => this.setState({ deleteStatus: false, name: '', branchId: '-1' })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}

							<p>
								Sildiğinizde <b>{this.state.name}</b> ligi artık anasayfada gözükmeyecektir.
							</p>

							<button className="btn btn-info mt-4" onClick={() => this.onBranchDelete(this.state.deletingId)}>
								Sil
							</button>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default Branches;
