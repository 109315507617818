import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button, UncontrolledCollapse, Row, Col, FormGroup, Label, Alert } from 'reactstrap';
import { Picker } from 'emoji-mart';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import api from '../../../api';
import { getTokenDecoded } from '../../../utils/auth';

const i18n = {
	search: 'Ara',
	clear: 'Temizle',
	notfound: 'Emoji bulunamadı',
	skintext: 'Varsayılan cilt renginizi seçin',
	categories: {
		search: 'Arama Sonuçları',
		recent: 'Sıklıkla Kullanılanlar',
		smileys: 'Suratlar & Duygular',
		people: 'İnsanlar & Vücut',
		nature: 'Hayvanlar & Doğa',
		foods: 'Yiyecek & İçecek',
		activity: 'Aktivite',
		places: 'Seyahat & Yerler',
		objects: 'Objeler',
		symbols: 'Semboller',
		flags: 'Bayraklar',
		custom: 'Özel',
	},
	categorieslabel: 'Emoji kategoriler',
	skintones: {
		1: 'Varsayılan Cilt Rengi',
		2: 'Açık Cilt Rengi',
		3: 'Orta-Hafif Cilt Rengi',
		4: 'Orta Cilt Rengi',
		5: 'Orta-Koyu Cilt Rengi',
		6: 'Koyu Cilt Rengi',
	},
};

const Notification = ({ Title, ContentId }) => {
	const [notificationTitle, setNotificationTitle] = useState('');
	const [emojiStatus, setEmojiStatus] = useState(false);
	const [status, setStatus] = useState(false);
	const [pushStatus, setPushStatus] = useState(false);

	useEffect(() => {
		const token = getTokenDecoded(localStorage.getItem('ajstoken'));

		if (
			token.Username === 'emrepirnaz' ||
			token.Username === 'Kethuda' ||
			token.Username === 'kenanb' ||
			token.Username === 'muratduymus' ||
			token.Username === 'AkinU' ||
			token.Username === 'alibozkurt' ||
			token.Username === 'ismail.kls' ||
			token.Username === 'OrhanGLK'
		) {
			setPushStatus(true);
		}
	}, []);

	const addEmoji = (emoji) => {
		setEmojiStatus(false);
		let current_pos = document.getElementById('social-media-title').selectionStart;
		setNotificationTitle(notificationTitle.slice(0, current_pos) + emoji.native + notificationTitle.slice(current_pos));
	};

	const copyTitle = () => {
		setNotificationTitle(Title);
	};

	const sendNotification = async () => {
		setStatus(true);

		toastr.options = {
			positionClass: 'toast-top-center',
			progressBar: true,
		};
		toastr.info('Bildirim gönderiliyor lütfen bekleyin ve sayfadan ayrılmayın!');

		await api
			.sendPush({
				text: notificationTitle,
				title: 'Son Dakika',
				deeplink: `ajansspor://NewsDetail/${ContentId}`,
				weblink: `https://ajansspor.com/haber/${ContentId}`,
			})
			.then((res) => {
				setStatus(false);

				if (res.success) {
					toastr.success(res.message);
				} else {
					toastr.error(res.message);
				}
			});
	};

	return pushStatus ? (
		<Card>
			<CardBody>
				<Button className="btn btn-light p-3 w-100" id="push-notification">
					<h6 className="m-0 font-14 d-flex justify-content-between">
						<span className="text-dark">Push Bildirim</span>
						<div className="fas fa-chevron-down"></div>
					</h6>
				</Button>

				<UncontrolledCollapse toggler="#push-notification">
					<div className="mt-2">
						<Row>
							<Col lg={12}>
								<FormGroup className="select2-container">
									<Label className="control-label">Bildirim Başlığı</Label>
									<textarea
										className="form-control social__control"
										style={{ paddingRight: 45 }}
										id="notification-title"
										name="NotificationTitle"
										onChange={(e) => setNotificationTitle(e.target.value)}
										value={notificationTitle}
									/>
									<button type="button" style={{ marginRight: 10 }} className="emoji__buttons" onClick={() => setEmojiStatus(!emojiStatus)}>
										<i className="bx bx-smile"></i>
									</button>

									{emojiStatus && <Picker theme="dark" onSelect={addEmoji} i18n={i18n} />}
								</FormGroup>
							</Col>
						</Row>

						<div className="custom-control custom-checkbox mb-3">
							<input type="checkbox" className="custom-control-input" id="copy-title" name="CopyTitle" onChange={() => copyTitle()} />
							<label className="custom-control-label" htmlFor="copy-title">
								Haber başlığını kopyala
							</label>
						</div>

						{!ContentId && <Alert color="primary">Bildirim gönderebilmek için haberin kayıtlı olması gerekmektedir.</Alert>}

						{ContentId ? (
							<div className="d-flex flex-row align-items-center">
								<Button className="btn btn-primary" onClick={sendNotification}>
									{status ? <i className="bx bx-loader bx-spin"></i> : 'Push Bildirim Gönder'}
								</Button>
							</div>
						) : (
							<></>
						)}
					</div>
				</UncontrolledCollapse>
			</CardBody>
		</Card>
	) : (
		<></>
	);
};

Notification.propTypes = {
	Title: PropTypes.string,
	ContentId: PropTypes.number,
};

export default Notification;
