import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { MDBDataTable } from 'mdbreact';
import 'toastr/build/toastr.min.css';
import Select from 'react-select';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const PrivatePrograms = () => {
	const [programs, setPrograms] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [name, setName] = React.useState('');
	const [broadcasters, setBroadcasters] = React.useState([]);
	const [selectedBroadcaster, setSelectedBroadcaster] = React.useState('');
	const [programId, setProgramId] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	const fetch = () => {
		api.radyospor.programs.privateAll().then((res) => {
			let data = [];

			res.programs.map((item, i) => {
				data.push({
					...item,
					id: i + 1,
					name: item.name,
					broadcasters: (
						<div className="d-flex flex-row">
							{item.broadcasters.map((broadcaster, i) => (
								<h5 className="mr-2" key={i}>
									<span className="badge badge-xl badge-info">{broadcaster.name}</span>
								</h5>
							))}
						</div>
					),
					settings: (
						<div className="btn-group mr-2">
							<button className="btn btn-secondary" onClick={() => editProgram(item.id, item.name, item.broadcasters)}>
								<i className="bx bxs-pencil"></i>
							</button>
							<button
								className={`btn ${!item.status ? 'btn-danger' : 'btn-secondary'}`}
								onClick={() => {
									if (!item.status) {
										window.confirm('Geri almak istediğinize emin misiniz?') && deleteProgram(item.id);
									} else {
										window.confirm('Silmek istediğinize emin misiniz?') && deleteProgram(item.id);
									}
								}}
							>
								<i className="bx bx-trash"></i>
							</button>
						</div>
					),
				});
			});

			setPrograms(data);
		});

		api.radyospor.broadcaster.all().then((res) => {
			let data = [];
			res?.filter((x) => x.status === true).map((item) => data.push(item));
			setBroadcasters(data);
		});
	};

	const deleteProgram = (id) => {
		api.radyospor.programs.privateDelete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const editProgram = (id, name, broadcasters) => {
		setEditStatus(true);
		setName(name);
		setProgramId(id);
		setSelectedBroadcaster(broadcasters);
	};

	const update = (id) => {
		setLoading(true);
		let broadcasters = [];

		selectedBroadcaster.map((broadcaster) => broadcasters.push({ id: broadcaster.id, name: broadcaster.name }));

		api.radyospor.programs.privateUpdate({ name, broadcasters, id }).then((res) => {
			setLoading(false);
			setEditStatus(false);
			setName('');
			setProgramId(0);
			setSelectedBroadcaster([]);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const add = () => {
		setLoading(true);
		let broadcasters = [];

		selectedBroadcaster.map((broadcaster) => broadcasters.push({ id: broadcaster.id, name: broadcaster.name }));

		api.radyospor.programs.privateAdd({ name, broadcasters }).then((res) => {
			setLoading(false);
			setStatus(false);
			setName('');
			setProgramId(0);
			setSelectedBroadcaster([]);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const selectBroadcaster = (broadcaster) => setSelectedBroadcaster(broadcaster);

	const data = {
		columns: [
			{
				label: '#',
				field: 'id',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Program adı',
				field: 'name',
				sort: 'asc',
				width: 250,
			},
			{
				label: 'Yayıncılar',
				field: 'broadcasters',
				sort: 'asc',
				width: 150,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: programs,
	};

	React.useEffect(() => {
		fetch();
	}, []);

	return (
		<>
			<Helmet>
				<title>{`Programlar - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Program Yönetimi" breadcrumbItem="Programlar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Programlar</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Program Ekle
										</button>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal
						isOpen={editStatus}
						toggle={() => {
							setEditStatus(!editStatus);
							setName('');
							setSelectedBroadcaster([]);
						}}
						centered={true}
					>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Program düzenleyin.</h5>
							<button
								type="button"
								onClick={() => {
									setEditStatus(false);
									setName('');
									setSelectedBroadcaster([]);
								}}
								className="close"
								data-dismiss="modal"
								aria-label="Kapat"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{name}</b> programını düzenliyorsunuz.
							</p>

							<div className="form-group">
								<label htmlFor="name">Program</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayıncı(lar)</label>
								{broadcasters.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={broadcasters}
										classNamePrefix="select2-selection"
										placeholder="Yayıncı seçin"
										onChange={selectBroadcaster}
										value={selectedBroadcaster}
										isMulti
									/>
								)}
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? update(programId) : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Düzenle'}
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Program ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="name">Program</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Yayıncı(lar)</label>
								{broadcasters.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={broadcasters}
										classNamePrefix="select2-selection"
										placeholder="Yayıncı seçin"
										onChange={selectBroadcaster}
										value={selectedBroadcaster}
										isMulti
									/>
								)}
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? add() : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Ekle'}
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default PrivatePrograms;
