import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
//import { io } from 'socket.io-client';
//import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
//import 'react-circular-progressbar/dist/styles.css';

import { AuthRoutes, PublicRoutes } from './routes';
import { AuthRoute, PublicRoute } from './utils/auth/route';

import HorizontalLayout from './components/HorizontalLayout/';

import './assets/scss/theme.scss';
import './assets/scss/custom.scss';
import setAuthorizationHeader from './utils/setAuthorizationHeader';
//import api from './api';

if (localStorage.ajstoken) {
	setAuthorizationHeader(localStorage.ajstoken);
}

// function miliseconds(hrs, min, sec) {
// 	return (hrs * 60 * 60 + min * 60 + sec) * 1000;
// }

// function percentage(val, total) {
// 	return (100 * val) / total;
// }

const App = (location) => {
	// const [process, setProcess] = useState(false);
	// const [duration, setDuration] = useState(0);
	// const [current, setCurrent] = useState(0);
	// const [videoInfo, setVideoInfo] = useState(null);

	const getLayout = () => {
		return HorizontalLayout;
	};

	useEffect(() => {
		if (window.location.pathname !== '/lock' && localStorage.getItem('lockscreen')) {
			window.location.href = '/lock';
		}

		//const socket = io('http://5.189.174.67:4971/');

		// işlenen video
		// socket.on('processvideos', (data) => {
		// 	console.log(data);
		// 	if (data && data !== 'ended' && data !== '0') {
		// 		setProcess(true);
		// 	}
		// });

		// socket.on('processDuration', (data) => {
		// 	if (data && data !== 'ended') {
		// 		const times = data.split('.')[0].split(':');
		// 		const currentTimes = miliseconds(parseInt(times[0], 10), parseInt(times[1], 10), parseInt(times[2], 10));
		// 		setDuration(currentTimes);
		// 	}
		// });

		// socket.on('processTimemark', (data) => {
		// 	if (data && data !== 'ended') {
		// 		const times = data.split('.')[0].split(':');
		// 		const currentTimes = miliseconds(parseInt(times[0], 10), parseInt(times[1], 10), parseInt(times[2], 10));
		// 		setCurrent(currentTimes);
		// 	}
		// });

		// socket.on('video', (data) => {
		// 	if (data && data !== 'ended') {
		// 		setVideoInfo(data);
		// 	}
		// });
	}, []);

	// useEffect(() => {
	// 	if (current === duration) {
	// 		setProcess(false);
	// 	}
	// }, [current]);

	const Layout = getLayout();

	return (
		<BrowserRouter>
			<Switch>
				{PublicRoutes.map((route, id) => (
					<PublicRoute key={id} location={location} path={route.path} exact component={route.component} />
				))}
				{AuthRoutes.map((route, id) => (
					<AuthRoute key={id} location={location} path={route.path} layout={Layout} exact component={route.component} />
				))}
				<Redirect to="/404" />
			</Switch>

			{/* {process && (
				<div className="fixed-video-process">
					<div className="video-progress">
						<CircularProgressbar
							value={percentage(current, duration)}
							strokeWidth={10}
							styles={buildStyles({
								rotation: 0,
								strokeLinecap: 'round',
								pathTransitionDuration: 0.5,
								trailColor: 'rgba(255,255,255,0.3)',
								pathColor: '#fff',
							})}
						/>

						<div className="icon">
							<i className="bx bx-loader bx-spin"></i>
						</div>

						<div className="video-percent">{percentage(current, duration)}</div>
					</div>

					<div className="title">
						<b>{videoInfo.title}</b> başlıklı videonuz işleniyor...
					</div>
				</div>
			)} */}
		</BrowserRouter>
	);
};

export default App;
