import React from 'react';
import Helmet from 'react-helmet';

import { Query, withApollo } from 'react-apollo';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, NavItem, NavLink, Progress, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';

import { CONTENT_DETAIL } from '../../queries/ContentQuery';
import Breadcrumbs from '../../components/Common/Breadcrumb';

// News Settings
import NewsSettings from '../../components/Forms/News/NewsSettings';
import NewsInformation from '../../components/Forms/News/NewsInformation';
import NewsGalleryContent from '../../components/Forms/News/NewsGalleryContent';

moment.locale('tr');

class New extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab: 1,
			progressValue: 100 / 4,
			ContentId: parseInt(window.location.pathname.split('/').length > 3 && window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], 10),
			page: parseInt(window.location.search.split('?s=').length > 0 && window.location.search.split('?s=')[1], 10),
		};
	}

	componentDidMount() {
		if (this.state.page) {
			this.setState({
				activeTab: this.state.page,
				progressValue: (100 / 4) * this.state.page,
			});
		}
	}

	toggleTab = (tab) => {
		if (this.state.activeTab !== tab) {
			if (tab >= 1 && tab <= 4) {
				if (tab === 1) {
					window.location.href = `${window.location.origin}/news/content/${this.state.ContentId}?s=1`;
				}
				if (tab === 2) {
					window.location.href = `${window.location.origin}/news/content/${this.state.ContentId}?s=2`;
				}
				if (tab === 3) {
					window.location.href = `${window.location.origin}/news/content/${this.state.ContentId}?s=3`;
				}
				if (tab === 4) {
					window.location.href = `${window.location.origin}/news/content/${this.state.ContentId}?s=4`;
				}
			}
		}
	};

	render() {
		const { ContentId } = this.state;

		return (
			<>
				<Helmet>
					<title>{`Haber ${ContentId ? 'Düzenle' : 'Ekle'} - Haber Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content form-padding">
					<Container fluid>
						<Breadcrumbs title="Haber Yönetimi" breadcrumbItem={`Haber ${ContentId ? 'Düzenle' : 'Ekle'}`} />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>{ContentId ? 'Haber Düzenle' : 'Yeni Haber Ekle'}</CardTitle>
												<CardSubtitle>
													Artık tüm haberler &quot;<b>Galeri</b>&quot; olarak eklenmektedir.
												</CardSubtitle>
											</div>
										</div>

										<div id="progrss-wizard" className="twitter-bs-wizard mt-4">
											<ul className="twitter-bs-wizard-nav nav-justified nav nav-pills">
												<NavItem>
													<NavLink
														className={classnames({ active: this.state.activeTab === 1 })}
														onClick={() => {
															this.toggleTab(1);
														}}
													>
														<span className="step-number mr-2">01</span>
														Haber Bilgileri
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink className={classnames({ active: this.state.activeTab === 2 })} onClick={() => ContentId && this.toggleTab(2)}>
														<span className="step-number mr-2">02</span>
														<span>Galeri İçeriği</span>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink className={classnames({ active: this.state.activeTab === 3 })} onClick={() => ContentId && this.toggleTab(3)}>
														<span className="step-number mr-2">03</span>
														Haber Ayarları
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink className={classnames({ active: this.state.activeTab === 4 })} onClick={() => this.toggleTab(4)}>
														<span className="step-number mr-2">04</span>
														Sosyal Medya
													</NavLink>
												</NavItem>
											</ul>

											<div id="bar" className="mt-4">
												<Progress color="danger" striped animated value={this.state.progressValue} />
												<div className="progress-bar bg-danger progress-bar-striped progress-bar-animated"></div>
											</div>

											<Query query={CONTENT_DETAIL} variables={{ ContentId: ContentId }}>
												{({ loading, data }) => {
													if (loading) return null;

													let content;

													if (data) {
														content = data.content;
													} else {
														content = {};
													}

													return (
														<TabContent activeTab={this.state.activeTab} className="twitter-bs-wizard-tab-content">
															{this.state.activeTab === 1 && (
																<TabPane tabId={1}>
																	<NewsInformation toggle={(e) => this.toggleTab(e)} id={ContentId} {...this.props} content={content} />
																</TabPane>
															)}
															{this.state.activeTab === 2 && (
																<TabPane tabId={2}>
																	<NewsGalleryContent toggle={(e) => this.toggleTab(e)} id={ContentId} {...this.props} content={content} />
																</TabPane>
															)}
															{this.state.activeTab === 3 && (
																<TabPane tabId={3}>
																	<NewsSettings toggle={(e) => this.toggleTab(e)} id={ContentId} {...this.props} content={content} />
																</TabPane>
															)}
															{this.state.activeTab === 4 && (
																<TabPane tabId={4}>
																	<Row>
																		<Col lg={12}>
																			<Card>
																				<CardBody>
																					<CardTitle>Twitter Paylaşım Geçmişi</CardTitle>

																					{content.Twits ? (
																						content.Twits.accounts.map((account, i) => (
																							<blockquote className="blockquote" key={i}>
																								<small>{account.name} hesabında paylaşıldı.</small>
																								<br />
																								<small>&quot;{account.title}&quot;</small>
																								<footer className="blockquote-footer">
																									{account.createdBy.fullname} tarafından{' '}
																									{moment(account.createdBy.date).format('DD/MM/YYYY HH:mm:ss')} tarihinde tweet atıldı.
																								</footer>
																							</blockquote>
																						))
																					) : (
																						<p>Henüz tweet atılmamış.</p>
																					)}
																				</CardBody>
																			</Card>
																		</Col>
																	</Row>
																</TabPane>
															)}
														</TabContent>
													);
												}}
											</Query>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default withApollo(New);
