import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../api';

class SoccerPlayers extends React.Component {
	constructor() {
		super();
		// this.search = _.debounce((e) => {
		// 	console.log('Debounced Event:', e);
		// }, 1000);
	}
	state = {
		players: [],
		teams: [],
		searchText: '',
		selectedPlayer: null,
		playerTeams: [],
		alertIsOpen: false,
		deletingId: null,
		deletingTeamId: null,
		success: null,
	};

	search = _.debounce((e) => {
		api.soccerData.searchPlayer(`${e}?Limit=150`).then((res) => {
			console.log(res);
			this.setState({ players: res });
		});
	}, 1000);

	getPlayer = (id) => {
		api.soccerData.getPlayerAll(id).then((res) => {
			console.log(res);
			this.setState({ playerTeams: res });
		});
	};

	deleteTeam = () => {
		api.soccerData.deleteTeam(this.state.deletingId, this.state.deletingTeamId).then((res) => {
			console.log(res);
			this.setState({ success: true });
			setTimeout(() => {
				this.setState({ alertIsOpen: false, deletingId: null, deletingTeamId: null, searchText: '', playerTeams: [], selectedPlayer: null, players: [], teams: [] });
			}, 1000);
		});
	};

	render() {
		return (
			<>
				{this.state.alertIsOpen && (
					<SweetAlert
						title=""
						warning={true}
						success={this.state.success}
						confirmBtnText={'Sil'}
						onConfirm={() => this.deleteTeam()}
						showCancel={true}
						cancelBtnText="Vazgeç"
						reverseButtons
						onCancel={() => this.setState({ alertIsOpen: false, deletingId: null, deletingTeamId: null })}
					>
						<div dangerouslySetInnerHTML={{ __html: this.state.alertTitle }}></div>
					</SweetAlert>
				)}
				<Helmet>
					<title>{`Futbolcu Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Row>
							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Futbolcunun Takımları</CardTitle>
												<CardSubtitle>Futbolcunun veritabanına kayıtlı takımları gözükür.</CardSubtitle>
											</div>
										</div>
										<Row className="mt-3">
											<Col className="col-12">
												<div className="form-group d-flex">
													<input
														className="form-control"
														type="text"
														placeholder="Futbolcu Adı"
														value={this.state.searchText}
														onChange={(e) => {
															if (e.target.value.length > 3) {
																this.search(e.target.value);
															} else if (e.target.value.length === 0) {
																this.setState({ players: [] });
															}
															this.setState({ searchText: e.target.value });
														}}
													/>
													{this.state.selectedPlayer && (
														<button className="btn btn-danger ml-2" onClick={() => this.setState({ selectedPlayer: null, players: [], searchText: '' })}>
															Temizle
														</button>
													)}
												</div>
											</Col>
										</Row>

										{this.state.players.length > 0 && !this.state.selectedPlayer && (
											<table className="table mt-3">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Futbolcu Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.players.map((player, i) => (
														<tr key={i}>
															<td style={{ width: '100%' }}>{player.player.knownName}</td>
															<td>
																<button
																	className="btn btn-primary"
																	onClick={() => {
																		this.setState({ selectedPlayer: player, searchText: player.player.knownName });
																		this.getPlayer(player.player.id);
																	}}
																>
																	Seç
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}

										{this.state.playerTeams.length > 0 && this.state.selectedPlayer && (
											<table className="table mt-3">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Takım Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.playerTeams.map((item, i) => (
														<tr key={i}>
															<td style={{ width: '100%' }}>{item.team.name}</td>
															<td>
																<button
																	className="btn btn-warning"
																	onClick={() => this.setState({ alertIsOpen: true, deletingId: item.player.id, deletingTeamId: item.team.id })}
																>
																	Sil
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default SoccerPlayers;
