import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import DraggableList from '../../components/DraggableList';
import api from '../../api';

const Headline = () => {
	const [headlines, setHeadlines] = useState([]);

	useEffect(() => {
		api.content.headline().then((res) => {
			if (res.success) {
				setHeadlines(res.headline);
			}
		});
	}, []);

	return (
		<>
			<Helmet>
				<title>{`Manşetler | ${process.env.REACT_APP_TITLE}`}</title>
			</Helmet>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Haber Yönetimi" breadcrumbItem="Manşetler" />

					<Row>
						<Col xs="12">
							<div className="mb-3">
								<Card>{headlines.length > 0 && <DraggableList data={headlines} />}</Card>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Headline;
