import moment from 'moment';
import api from '../api';

const types = {
	USER_LOGIN: 'USER_LOGIN',
	USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
	USER_LOGIN_FAILURE: 'USER_LOGIN_FAILURE',
	USER_LOGOUT: 'USER_LOGOUT',
};

export const actions = {
	userLogin: (dispatch, email, password) => {
		dispatch({ type: types.USER_LOGIN, isFetching: true });

		return api.user.login(email, password).then((res) => {
			dispatch({ type: types.USER_LOGIN, isFetching: false });

			if (res.error) {
				dispatch({ type: types.USER_LOGIN_FAILURE, error: res.error });

				setTimeout(() => dispatch({ type: types.USER_LOGIN_FAILURE, error: null }), 2500);
			} else if (res.result.Role < 2) {
				dispatch({ type: types.USER_LOGIN_FAILURE, error: 'Yetkiniz bulunmuyor.' });

				setTimeout(() => dispatch({ type: types.USER_LOGIN_FAILURE, error: null }), 2500);
			} else {
				dispatch({ type: types.USER_LOGIN_SUCCESS, user: res.result });

				localStorage.setItem(process.env.REACT_APP_TOKEN, res.result.Token);
				localStorage.setItem('lastSeen', moment().format());
				window.location.reload();
			}
		});
	},

	userLogut: (dispatch) => dispatch({ type: types.USER_LOGOUT }),
};

const initialState = {
	isFetching: false,
	error: null,
	user: {},
};

export const reducer = (state = initialState, action) => {
	const { error, type, user, isFetching } = action;

	switch (type) {
		case types.USER_LOGIN:
			return { ...initialState, isFetching };

		case types.USER_LOGIN_SUCCESS:
			return { ...state, user, isFetching: false, error };

		case types.USER_LOGIN_FAILURE:
			return { ...state, error, isFetching: false };

		case types.USER_LOGOUT:
			return {};

		default:
			return state;
	}
};
