import { createStore, compose, applyMiddleware } from 'redux';
import { persistStore, persistCombineReducers } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
//import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import allReducers from '../redux';
import sagas from '../sagas';

const config = {
	key: 'root',
	storage,
	debug: true,
	blacklist: ['LoginReducer'],
};

const middleware = [];
const sagaMiddleware = createSagaMiddleware();

middleware.push(sagaMiddleware);
//middleware.push(createLogger());
middleware.push(thunk);

const reducers = persistCombineReducers(config, allReducers);
const enhancers = [composeWithDevTools(applyMiddleware(...middleware))];
const persistConfig = { enhancers };
const store = createStore(reducers, undefined, compose(...enhancers));
const persistor = persistStore(store, persistConfig, () => {
	//console.log(store.getState());
});

const configureStore = () => {
	return { persistor, store };
};

sagaMiddleware.run(sagas);

export default configureStore;
