/* eslint-disable react/prop-types */
import React, { Fragment } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col, CardBody, Card, Alert, Container } from 'reactstrap';

import { connect } from 'react-redux';

import validation from '../utils/validation';

import profile from '../assets/images/profile-img.png';
import logo from '../assets/images/logo.svg';

import { addLog } from '../utils/addLog';


const Login = (props) => {
	const handleValidSubmit = (event, values) => {
		if (values) {
			let logData = `${values.email} e-mail adresiyle giriş yaptı.`;
			addLog(8, logData);
			props.userLogin(values.email, values.password);
		}
	};

	return (
		<Fragment>
			<div className="account-pages my-5 pt-sm-5">
				<Container>
					<Row className="justify-content-center">
						<Col md={8} lg={6} xl={5}>
							<Card className="overflow-hidden">
								<div className="bg-soft-primary">
									<Row>
										<Col className="col-7">
											<div className="text-primary p-4">
												<h5 className="text-primary">Hoş geldin!</h5>
												<p>Devam etmek için giriş yapın.</p>
											</div>
										</Col>
										<Col className="col-5 align-self-end">
											<img src={profile} alt="" className="img-fluid" />
										</Col>
									</Row>
								</div>
								<CardBody className="pt-0">
									<div>
										<Link to="/">
											<div className="avatar-md profile-user-wid mb-4">
												<span className="avatar-title rounded-circle bg-light">
													<img src={logo} alt="" className="rounded-circle" height="34" />
												</span>
											</div>
										</Link>
									</div>
									<div className="p-2">
										<AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>
											{props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

											<div className="form-group">
												<AvField
													name="email"
													label="Eposta adresi"
													className="form-control"
													placeholder="Eposta adresi"
													type="email"
													validate={{ required: { value: true, errorMessage: validation.EmptyEmail } }}
												/>
											</div>

											<div className="form-group">
												<AvField
													name="password"
													label="Şifre"
													type="password"
													placeholder="Şifre"
													validate={{ required: { value: true, errorMessage: validation.EmptyPassword } }}
												/>
											</div>

											<div className="custom-control custom-checkbox">
												<input type="checkbox" className="custom-control-input" id="customControlInline" />
												<label className="custom-control-label" htmlFor="customControlInline">
													Beni hatırla
												</label>
											</div>

											<div className="mt-3">
												<button className="btn btn-primary btn-block waves-effect waves-light" type="submit" disabled={props.isFetching}>
													{props.isFetching ? <i className="bx bx-loader bx-spin"></i> : 'Giriş Yap'}
												</button>
											</div>

											<div className="mt-4 text-center">
												<Link to="/forgot-password" className="text-muted">
													<i className="mdi mdi-lock mr-1"></i> Şifreni mi unuttun?
												</Link>
											</div>
										</AvForm>
									</div>
								</CardBody>
							</Card>
							<div className="mt-5 text-center">
								<p>
									Üye değil misiniz? <a href="/register">Üye olun</a>
								</p>
								<p>© {new Date().getFullYear()} Ajansspor.</p>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	);
};

const mapStateToProps = (state) => {
	return {
		isFetching: state.LoginReducer.isFetching,
		error: state.LoginReducer.error,
		user: state.LoginReducer.user,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: UserLogin } = require('../redux/LoginRedux');

	return {
		...ownProps,
		...stateProps,
		userLogin: (email, password) => UserLogin.userLogin(dispatch, email, password),
	};
};

export default connect(mapStateToProps, null, mergeProps)(withRouter(Login));
