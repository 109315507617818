import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Card, CardBody, CardSubtitle, CardTitle, Col, Row } from 'reactstrap';
import Container from 'reactstrap/lib/Container';
import { MDBDataTable } from 'mdbreact';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';
import moment from 'moment';

const Videos = () => {
	const [videos, setVideos] = useState([]);

	// const onDelete = (id) => {
	// 	console.log('removed', id);
	// };

	useEffect(() => {
		api.video.all().then((res) => {
			let data = [];

			if (res.count > 0) {
				res.videos
					.sort((a, b) => b.videoid - a.videoid)
					.map((item) => {
						data.push({
							...item,
							id: item.uuid,
							categories: (
								<span>
									{item?.categories?.length > 0 ? (
										item?.categories?.map((cat, i) => (
											<span key={i} className="badge badge-info mr-2">
												{cat.name}
											</span>
										))
									) : (
										<span className="badge badge-primary">Eklenmemiş</span>
									)}
								</span>
							),
							date: (
								<>
									<span title="Eklenme Tarihi">
										{moment().add(-60, 'minutes').isBefore(moment(item.createdAt))
											? moment(item.createdAt).fromNow().toString()
											: moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss').toString()}
									</span>
									<br />
									<span title="Güncelleme Tarihi">
										{moment().add(-60, 'minutes').isBefore(moment(item.updatedAt))
											? moment(item.updatedAt).fromNow().toString()
											: moment(item.updatedAt).format('DD-MM-YYYY HH:mm:ss').toString()}
									</span>
								</>
							),
							progress: !item.status ? (
								<span className="text-warning">
									<i className="bx bx-loader bx-spin"></i> İşleniyor...
								</span>
							) : (
								<span className="text-success">İşlendi</span>
							),
							status: item.isdeleted ? <span className="text-danger">Gizli</span> : <span className="text-success">Yayında</span>,
							play: (
								<a href={`/videos/play/${item.uuid}`} className="text-warning" target="_blank" rel="noreferrer">
									<i className="bx bx-play-circle mr-1"></i> İzle
								</a>
							),
							setting: (
								<span style={{ flexWrap: 'nowrap', display: 'flex' }}>
									<button
										className="btn btn-secondary mr-2"
										onClick={() => {
											navigator.clipboard.writeText(item.url);
											alert('Yerleştirme kodu kopyalandı.');
										}}
									>
										Embed
									</button>
									<a className="btn btn-warning mr-2" href={`/videos/edit/${item.videoid}-${item.uuid}`}>
										<i className="bx bx-pencil"></i>
									</a>
									{/* <a className="btn btn-warning mr-2" href={`/videos/edit/${item.videoid}`}>
										Düzenle
									</a>
									<button
										className="btn btn-danger"
										onClick={() => {
											window.confirm('Silmek istediğine emin misiniz?') && onDelete(item.videoid);
										}}
									>
										Sil
									</button> */}
								</span>
							),
						});
					});
			}
			setVideos(data);
		});
	}, []);

	const data = {
		columns: [
			{
				label: 'ID',
				field: 'videoid',
				width: 50,
				sort: 'asc',
			},
			{
				label: 'Video ID',
				field: 'id',
				width: 50,
				sort: 'asc',
			},
			{
				label: 'Başlık',
				field: 'title',
				sort: 'asc',
				width: '80px',
			},
			{
				label: 'Kategori',
				field: 'categories',
				width: '100px',
				sort: 'asc',
			},
			{
				label: 'Ekleyen',
				field: 'fullname',
				width: '100px',
				sort: 'asc',
			},
			{
				label: 'E. / G. Tarihi',
				field: 'date',
				width: '100px',
				sort: 'asc',
			},
			{
				label: 'Durum',
				field: 'progress',
				width: '50px',
				sort: 'asc',
			},
			{
				label: '',
				field: 'status',
				width: '50px',
				sort: 'asc',
			},
			{
				label: '',
				field: 'play',
			},
			{
				label: '#',
				field: 'setting',
				sort: 'asc',
				width: '200px',
			},
		],
		rows: videos,
	};

	return (
		<>
			<Helmet>
				<title>{`Video Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Videolar" breadcrumbItem="Video Yönetimi" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between mb-4">
										<div>
											<CardTitle>Videolar</CardTitle>
											<CardSubtitle>Videoları silmediğiniz sürece her zaman ana sayfada görünür.</CardSubtitle>
										</div>

										<a href="/videos/add" className="btn btn-primary">
											Video Yükle
										</a>
									</div>

									<div className="mini-table video-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
											className="contentsTable"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Videos;
