import React from 'react';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';
import { Container, Row, Col, Card, CardTitle, CardBody, Alert } from 'reactstrap'; //Modal,
import moment from 'moment';
const { seoUrl } = require('../../utils/seoUrl');

import { addLog } from '../../utils/addLog';

class Comments extends React.Component {
	state = {
		comments: [],
		deleteStatus: false,
		confirmStatus: false,
		id: null,
		deleteError: null,
	};

	componentDidMount() {
		this.fetchComments();
		addLog(11, 'Yorum listeleme görüntülendi.');
	}

	fetchComments = () => {
		api.web.getComments('?limit=1000').then((res) => {
			let data = [];
			if (res) {
				res.comments.map((item) => {
					data.push({
						...item,
						contentTitle: (
							<a href={'https://ajansspor.com/haber/' + seoUrl(item.ContentTitle) + '-' + item.ContentId} rel="noreferrer" target="_blank" className="text-white">
								{item.ContentTitle}
							</a>
						),
						fixedDate: <span>{moment(item.Date).format('DD/MM/YYYY HH:mm')}</span>,
						setting: (
							<span>
								{item.IsDeleted ? (
									<button onClick={() => this.changeStatusComment(item._id, '1')} className="btn btn-info">
										Tekrar Onayla
									</button>
								) : (
									<button onClick={() => this.changeStatusComment(item._id, '0')} className="btn btn-danger">
										Onayını Kaldır
									</button>
								)}
							</span>
						),
					});
				});

				this.setState({ comments: data });
			}
		});
	};

	changeStatusComment = (id, status) => {
		api.web.changeStatusComment(id, status).then((res) => {
			if (!res.success) {
				alert(res.message);
				this.setState({ deleteError: res.message });
			} else {
				let logData = `Onaylanan Yorum Id'si : ${id}}`;
				addLog(12, logData);
				this.setState({
					deleteStatus: false,
					confirmStatus: false,
					deletingId: null,
					deleteError: null,
				});
				this.fetchComments();
			}
		});
	};

	deleteToggle = () => {
		this.setState((prevState) => ({
			deleteStatus: !prevState.deleteStatus,
			name: '',
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	render() {
		const data = {
			columns: [
				{
					label: 'Haber Başlığı',
					field: 'contentTitle',
					sort: 'asc',
					width: '200px',
				},
				{
					label: 'Yorum',
					field: 'Comment',
					sort: 'asc',
					width: '200px',
				},
				{
					label: 'Yorumu Yapan',
					field: 'Username',
					sort: 'asc',
					width: '100px',
				},
				{
					label: 'Tarih',
					field: 'fixedDate',
					sort: 'asc',
					width: '100px',
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
					width: '150px',
				},
			],
			rows: this.state.comments,
		};
		return (
			<>
				<Helmet>
					<title>{`Yorum Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor Web" breadcrumbItem="Yorumlar" />

						<Row>
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Haber Yorumları </CardTitle>
											</div>
										</div>

										<div className="comment-table">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					{/* <Modal isOpen={this.state.deleteStatus} toggle={this.deleteToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Silmek istediğinize emin misiniz?</h5>
							<button type="button" onClick={() => this.setState({ deleteStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}

							<p>
								Onayı kaldırılan yorum listelenmeyecektir.
							</p>

							<button className="btn btn-danger mt-4" onClick={() => this.changeStatusComment(this.state.id, '0')}>
								Onayı Kaldır
							</button>
						</div>
					</Modal>
					<Modal isOpen={this.state.confirmStatus} toggle={this.deleteToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Onaylamak istediğinize emin misiniz?</h5>
							<button type="button" onClick={() => this.setState({ confirmStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}

							<p>
								Onayladığınız yorum listelenmeye başlayacaktır.
							</p>

							<button className="btn btn-info mt-4" onClick={() => this.changeStatusComment(this.state.id, '1')}>
								Onayla
							</button>
						</div>
					</Modal> */}
				</div>
			</>
		);
	}
}

export default Comments;
