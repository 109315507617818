import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { Form, Row, Col, FormGroup, Label, Input, Container, Card, CardBody, InputGroup } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'moment/locale/tr';
import tr from 'date-fns/locale/tr';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { CREATE_CONTENT, UPDATE_CONTENT } from '../../../queries/ContentQuery';
import ReactCropper from '../../ReactCropper';
import api from '../../../api';
import { addLog } from '../../../utils/addLog';

registerLocale('tr', tr);
moment.locale('tr');

class Error extends React.Component {
	state = {
		hasError: false,
	};

	static getDerivedStateFromError(error) {
		// Bir sonraki render'da son çare arayüzünü göstermek için
		// state'i güncelleyin.
		console.log(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// Hatanızı bir hata bildirimi servisine de yollayabilirsiniz.
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// İstediğiniz herhangi bir son çare arayüzünü render edebilirsiniz.
			return <h1>Something went wrong.</h1>;
		}

		return this.props.children;
	}
}

const Holders = [
	{
		options: [
			{ label: 'Arkaplan', value: 'arkaplan' },
			{ label: 'Haber Analiz', value: 'haberanaliz' },
			{ label: 'İnceleme', value: 'inceleme' },
			{ label: 'İstatistik Haber', value: 'istatistikhaber' },
			{ label: 'Oyuncu Analiz', value: 'oyuncuanaliz' },
			{ label: 'Sezonluk Haber', value: 'sezonluk' },
			{ label: 'Transfer Haber', value: 'transfer' },
			{ label: 'Özel Haber', value: 'ozelhaber' },
			{ label: 'Dış Haber', value: 'dishaber' },
			{ label: 'Detay Haber', value: 'detayhaber' },
			{ label: 'Röportaj', value: 'roportaj' },
		],
	},
];

const validationSchema = Yup.object({
	Title: Yup.string().required('Haber başlığı boş olamaz.'),
	SeoTitle: Yup.string().required('SEO başlığı boş olamaz.'),
	Spot: Yup.string().required('Spot boş olamaz.'),
	startedDate: Yup.string().required('Yayın başlangıç tarihi boş olamaz.'),
	endedDate: Yup.string().required('Yayın bitiş tarihi boş olamaz.'),
});

const NewsInformation = ({ client, content, id }) => {
	const [imageCrop, setImageCrop] = useState('');
	const [loading, setLoading] = useState(false);
	const [holder, setHolder] = useState('');
	const [emojiStatus, setEmojiStatus] = useState(false);
	const [SocialMediaTitle, setSocialMediaTitle] = useState('');

	const onChangeImage = (image) => {
		setImageCrop(image);
	};

	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n) {
			u8arr[n - 1] = bstr.charCodeAt(n - 1);
			n -= 1; // to make eslint happy
		}
		return new File([u8arr], filename, { type: mime });
	};

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			Title: content.Title || '',
			SocialMediaTitle: content.SocialMediaTitle || '',
			MainPageTitle: content.MainPageTitle || '',
			SeoTitle: content.SeoTitle || '',
			Spot: content.Spot || '',
			SpotMediaId: content.SpotMediaId || '',
			startedDate: content.PublishDate ? new Date(content.PublishDate) : new Date(),
			endedDate: content.PublishEndDate ? new Date(content.PublishEndDate) : moment(new Date().setFullYear(new Date().getFullYear() + 10)).toDate(),
			IsHeadline: content.IsHeadline || false,
			IsMainPage: content.IsMainPage || false,
			HeadlineOrder: parseInt(content.HeadlineOrder || 0, 10),
			PostToTwitter: false,
			ImageTitle: content.MediaTitle || '',
			Twits: content.Twits || null,
		},
		validationSchema,
		onSubmit: async (values) => {
			setLoading(true);

			const createContent = async (mediaid) => {
				let PublishDate = values.startedDate;
				let PublishEndDate = values.endedDate;

				try {
					const mutation = await client.mutate({
						mutation: CREATE_CONTENT,
						variables: {
							input: {
								Title: values.Title,
								// GoogleTitle: this.state.GoogleTitle && this.state.GoogleTitle,
								// GoogleSpot: this.state.GoogleSpot && this.state.GoogleSpot,
								// HeadlineTitle: this.state.HeadlineTitle && this.state.HeadlineTitle,
								SocialMediaTitle,
								MainPageTitle: values.MainPageTitle,
								SeoTitle: values.SeoTitle,
								Spot: values.Spot,
								IsHeadline: values.IsHeadline,
								IsMainPage: values.IsMainPage,
								HeadlineOrder: parseInt(values.HeadlineOrder),
								SpotMediaId: mediaid,
								PostToTwitter: values.PostToTwitter,
								PublishDate,
								PublishEndDate,
							},
						},
					});

					if (mutation) {
						setLoading(false);

						if (mutation.data.createContent.success) {
							toastr.options = {
								positionClass: 'toast-top-center',
								progressBar: true,
							};
							toastr.success(mutation.data.createContent.message);

							let contentId = mutation.data.createContent.content.ContentId;
							let logData = `Eklenen Haber Id'si : <a href="https://admin.ajansspor.com/news/content/${contentId}" target="_blank" />${contentId}</a>, Tweet ${
								values.PostToTwitter ? 'Gönderildi' : 'Gönderilmedi'
							}`;
							addLog(1, logData);
							setTimeout(() => (window.location.href = `${window.location.origin}/news/content/${contentId}?s=2`), 5000);
						} else {
							toastr.options = {
								positionClass: 'toast-top-center',
							};
							toastr.error(mutation.data.createContent.message);
							addLog(34, mutation.data.createContent.message);
						}
					}
				} catch (e) {
					toastr.options = {
						positionClass: 'toast-top-center',
					};

					e.graphQLErrors.map(({ message }) => {
						toastr.error(message);
						addLog(34, message);
					});
				}
			};

			const updateContent = async (mediaId) => {
				let PublishDate = values.startedDate;
				let PublishEndDate = values.endedDate;

				const mutation = await client.mutate({
					mutation: UPDATE_CONTENT,
					variables: {
						input: {
							ContentId: id,
							Title: values.Title,
							// GoogleTitle: this.state.GoogleTitle && this.state.GoogleTitle,
							// GoogleSpot: this.state.GoogleSpot && this.state.GoogleSpot,
							// HeadlineTitle: this.state.HeadlineTitle && this.state.HeadlineTitle,
							//ImageTitle: values.ImageTitle,
							SocialMediaTitle,
							MainPageTitle: values.MainPageTitle,
							SeoTitle: values.SeoTitle,
							Spot: values.Spot,
							IsHeadline: values.IsHeadline,
							IsMainPage: values.IsMainPage,
							HeadlineOrder: parseInt(values.HeadlineOrder),
							SpotMediaId: mediaId,
							PostToTwitter: values.PostToTwitter,
							PublishDate,
							PublishEndDate,
						},
					},
				});

				if (mutation) {
					setLoading(false);

					if (mutation.data.updateContentInfo.success) {
						api.cache(id);
						let logData = `Eklenen Haber Id'si : ${id}, Tweet ${values.PostToTwitter ? 'Gönderildi' : 'Gönderilmedi'}`;
						addLog(2, logData);
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(mutation.data.updateContentInfo.message);
						//setTimeout(() => window.location.reload(), 5000);
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error(mutation.data.updateContentInfo.message);
						addLog(34, mutation.data.updateContentInfo.message);
					}
				}
			};

			if (id) {
				//setErrors(errors);

				if (Object.keys(errors).length === 0) {
					if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
						if (values.ImageTitle === '') {
							return;
						}
						let data = new FormData();

						let file = dataURLtoFile(imageCrop, values.ImageTitle);

						data.append('content', file);
						data.append('ImageTitle', values.ImageTitle);
						data.append('Holder', holder.value);

						await api.contentImageUpload(data).then(async (res) => {
							if (res.success) {
								if (res.mediaId) {
									addLog(16, `<a href="https://ajssarimg.mediatriple.net/${res.mediaId}.jpg" target="_blank">${res.mediaId}</a> id'li fotoğraf eklendi.`);

									updateContent(res.mediaId);
								}
							} else {
								toastr.options = {
									positionClass: 'toast-top-center',
								};
								toastr.error(res.message);
								addLog(34, res.message);
							}
						});
					} else if (imageCrop) {
						updateContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
					} else {
						updateContent(content.SpotMediaId);
					}
				}
			} else {
				// New content

				if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
					let data = new FormData();

					let file = dataURLtoFile(imageCrop, values.ImageTitle);

					data.append('content', file);
					data.append('ImageTitle', values.ImageTitle);
					data.append('Holder', holder.value);

					api.contentImageUpload(data).then(async (res) => {
						if (res.success) {
							if (res.mediaId) {
								addLog(16, `<a href="https://ajssarimg.mediatriple.net/${res.mediaId}.jpg" target="_blank">${res.mediaId}</a> id'li fotoğraf eklendi.`);
								createContent(res.mediaId);
							}
						} else {
							toastr.options = {
								positionClass: 'toast-top-center',
							};
							toastr.error(res.message);
							addLog(34, res.message);
						}
					});
				} else {
					createContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
				}
			}
		},
	});

	const selectHolder = (selectedHolder) => {
		setHolder(selectedHolder);
	};

	const addEmoji = (emoji) => {
		setEmojiStatus(false);

		let curpos = document.getElementById('social-media-title').selectionStart;

		let x = SocialMediaTitle;
		setSocialMediaTitle(x.slice(0, curpos) + emoji.native + x.slice(curpos));
	};

	const i18n = {
		search: 'Ara',
		clear: 'Temizle',
		notfound: 'Emoji bulunamadı',
		skintext: 'Varsayılan cilt renginizi seçin',
		categories: {
			search: 'Arama Sonuçları',
			recent: 'Sıklıkla Kullanılanlar',
			smileys: 'Suratlar & Duygular',
			people: 'İnsanlar & Vücut',
			nature: 'Hayvanlar & Doğa',
			foods: 'Yiyecek & İçecek',
			activity: 'Aktivite',
			places: 'Seyahat & Yerler',
			objects: 'Objeler',
			symbols: 'Semboller',
			flags: 'Bayraklar',
			custom: 'Özel',
		},
		categorieslabel: 'Emoji kategoriler',
		skintones: {
			1: 'Varsayılan Cilt Rengi',
			2: 'Açık Cilt Rengi',
			3: 'Orta-Hafif Cilt Rengi',
			4: 'Orta Cilt Rengi',
			5: 'Orta-Koyu Cilt Rengi',
			6: 'Koyu Cilt Rengi',
		},
	};

	return (
		<Error>
			<Form onSubmit={handleSubmit}>
				<Row>
					<Col lg="12">
						<FormGroup>
							<Label for="title">Haber Başlığı</Label>
							<Input type="text" className={`${errors.Title ? 'is-invalid' : ''}`} id="title" name="Title" onChange={handleChange} value={values.Title} />
							{errors.Title && <div className="invalid-feedback d-block">{errors.Title}</div>}
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<FormGroup className="social__buttons-group">
							<Label for="social-media-title">Sosyal Medya Başlığı</Label>
							<textarea
								className="form-control social__control"
								id="social-media-title"
								name="SocialMediaTitle"
								onChange={(e) => setSocialMediaTitle(e.target.value)}
								value={SocialMediaTitle}
							/>
							<button type="button" className="emoji__buttons" onClick={() => setEmojiStatus(!emojiStatus)}>
								<i className="bx bx-smile"></i>
							</button>

							{emojiStatus && <Picker theme="dark" onSelect={addEmoji} i18n={i18n} />}
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<FormGroup>
							<Label for="MainPageTitle">Anasayfa Başlığı</Label>
							<Input id="MainPageTitle" name="MainPageTitle" onChange={handleChange} value={values.MainPageTitle} />
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<FormGroup>
							<Label for="seo-title">SEO Başlığı</Label>
							<Input className={`${errors.Title ? 'is-invalid' : ''}`} id="seo-title" name="SeoTitle" onChange={handleChange} value={values.SeoTitle} />
							{errors.SeoTitle && <div className="invalid-feedback d-block">{errors.SeoTitle}</div>}
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col lg="12">
						<FormGroup>
							<Label for="spot">Spot</Label>
							<textarea id="spot" className={`form-control ${errors.Spot && 'is-invalid'}`} rows="2" name="Spot" onChange={handleChange} value={values.Spot} />
							{errors.Spot && <div className="invalid-feedback d-block">{errors.Spot}</div>}
						</FormGroup>
					</Col>
				</Row>

				<Row>
					<Col lg="6">
						<Label>Yayın Başlangıç Tarihi</Label>
						<InputGroup className="form-group">
							<DatePicker
								className={`form-control ${errors.Spot && 'is-invalid'}`}
								selected={values.startedDate}
								locale="tr"
								onChange={handleChange}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="Saat"
								dateFormat="d.MM.yyyy HH:mm"
								showMonthDropdown
								showYearDropdown
							/>
						</InputGroup>
						{errors.startedDate && <div className="invalid-feedback d-block">{errors.startedDate}</div>}
					</Col>

					<Col lg="6">
						<Label>Yayın Bitiş Tarihi</Label>
						<InputGroup className="form-group">
							<DatePicker
								className={`form-control ${errors.Spot && 'is-invalid'}`}
								selected={values.endedDate}
								locale="tr"
								onChange={handleChange}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								timeCaption="Saat"
								dateFormat="d.MM.yyyy HH:mm"
								showMonthDropdown
								showYearDropdown
							/>
						</InputGroup>
						{errors.endedDate && <div className="invalid-feedback d-block">{errors.endedDate}</div>}
					</Col>
				</Row>

				<Row>
					<Col lg="3">
						<div className="custom-control custom-checkbox mb-4 mt-2">
							<input type="checkbox" className="custom-control-input" id="is-headline" name="IsHeadline" onChange={handleChange} value={values.IsHeadline} checked={values.IsHeadline} />
							<label className="custom-control-label" htmlFor="is-headline">
								Manşette gözüksün mü?
							</label>
						</div>
					</Col>

					<Col lg="3">
						<div className="custom-control custom-checkbox mb-4 mt-2">
							<input type="checkbox" className="custom-control-input" id="is-mainpage" name="IsMainPage" onChange={handleChange} value={values.IsMainPage} checked={values.IsMainPage} />
							<label className="custom-control-label" htmlFor="is-mainpage">
								Anasayfada gözüksün mü?
							</label>
						</div>
					</Col>
				</Row>

				{values.IsHeadline && (
					<Row>
						<Col lg="12">
							<FormGroup>
								<Label for="headline-order">Manşet Sırası</Label>
								<Input type="number" id="headline-order" name="HeadlineOrder" onChange={handleChange} value={values.HeadlineOrder} />
							</FormGroup>
						</Col>
					</Row>
				)}

				<Row>
					<Col lg="12">
						<FormGroup className="select2-container">
							<Label className="control-label">Görsel Tipi</Label>
							<Select value={holder} onChange={selectHolder} options={Holders} classNamePrefix="select2-selection" placeholder="Görsel tipi seçin" />
						</FormGroup>
					</Col>
				</Row>

				{imageCrop.indexOf('/images/') <= -1 && (
					<Row>
						<Col lg="12">
							<FormGroup>
								<Label for="image-title">Fotoğraf Başlığı</Label>
								<Input className={`${errors.ImageTitle && 'is-invalid'}`} id="image-title" name="ImageTitle" onChange={handleChange} value={values.ImageTitle} />
								{errors.ImageTitle && <div className="invalid-feedback d-block">{errors.ImageTitle}</div>}
							</FormGroup>
						</Col>
					</Row>
				)}
				<ReactCropper key="3" type="information" imageChanged={(e) => onChangeImage(e)} defaultSrc={values.SpotMediaId.toString()} />

				<div className="wizard-fixed">
					<Container fluid>
						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<ul className="pager wizard twitter-bs-wizard-pager-link">
											<li className="next">
												<button type="submit" className="btn btn-primary">
													{loading ? <i className="bx bx-loader bx-spin"></i> : id ? 'Güncelle ve Devam Et' : 'Kaydet ve Devam Et'}
												</button>
											</li>

											<li className="next twitterCheckbox">
												<div className="custom-control custom-checkbox mb-4 mt-2">
													<input
														type="checkbox"
														className="custom-control-input"
														id="is-status"
														name="PostToTwitter"
														onChange={handleChange}
														checked={values.PostToTwitter}
													/>
													<label className="custom-control-label" htmlFor="is-status">
														<span className="text-danger">@ajansspor</span> Twitter&apos;da Paylaş
													</label>
												</div>
											</li>

											{content.Twits && content.Twits.accounts.find((x) => x.name === 'Ajansspor') && (
												<li className="next" style={{ marginRight: 50, paddingTop: 8 }}>
													{content.Twits?.accounts.find((x) => x.name === 'Ajansspor').createdBy?.fullname} tarafından tweet atıldı.
												</li>
											)}
										</ul>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</Form>
		</Error>
	);
};

NewsInformation.propTypes = {
	client: PropTypes.object,
	content: PropTypes.object,
	id: PropTypes.number,
};

Error.propTypes = {
	children: PropTypes.any,
};

export default NewsInformation;
