import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

import { addLog } from '../../../utils/addLog';

moment.locale('tr');
class Logs extends React.Component {
	state = {
		name: '',
		error: null,
		logs: [],
		hasFilter: false,
		loading: false,
	};

	componentDidMount() {
		this.fetchLogs('?limit=500');
		addLog(17, 'Log listeleme görüntülendi.');
	}

	fetchLogs = (term) => {
		this.setState({loading: true});
		api.logs.getLogs(term).then((res) => {
			let data = [];
			if (res) {
				res.map((item) => {
					data.push({
						...item,
						info: <div dangerouslySetInnerHTML={{ __html: item.description }} />,
						editor: (
							<div 
								onClick={() => {
									this.fetchLogs(`?limit=500&username=${item.username}`); 
									this.setState({hasFilter: true});}
								} 
								dangerouslySetInnerHTML={{ __html: `<a href='javascript:;' >${item.username}</a>` }} 
							/>
							),
						logDate: moment(item.date).format('DD/MM/YYYY HH:mm:ss'),
					});
				});
				this.setState({
					logs: data,
				});
			}
			this.setState({loading: false});
		});
	};

	render() {
		const data = {
			columns: [
				{
					label: 'ID',
					field: 'logId',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Log Adı',
					field: 'logName',
					sort: 'asc',
					width: 250,
				},
				{
					label: 'Açıklama',
					field: 'info',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Değişiklik',
					field: 'changes',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Gerçekleştiren',
					field: 'editor',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Tarih',
					field: 'logDate',
					sort: 'asc',
					width: 50,
				},
			],
			rows: this.state.logs,
		};

		return (
			<>
				<Helmet>
					<title>{`Loglar - Log Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Log Yönetimi" breadcrumbItem="Loglar" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Yönetim Paneli Logları</CardTitle>
											</div>
											{
												this.state.hasFilter &&
												<button 
													className="btn btn-rounded btn-secondary"
													onClick={() => {this.fetchLogs('?limit=500');this.setState({hasFilter: false});}}
												>
													{
														this.state.loading ?
														<i className="bx bx-loader bx-spin"></i>
														:
														<>
															Filtreyi Kaldır
															&times;
														</>
													}
													
												</button>
											}
										</div>

										<div className="mini-table">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
												o
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

Logs.propTypes = {
	getLogs: PropTypes.func,
	addLog: PropTypes.func,
	logs: PropTypes.array,
	error: PropTypes.string,
	message: PropTypes.string,
};

export default Logs;
