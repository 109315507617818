import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input, Button } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';


import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TextEditor from '../../../components/TextEditor';
import api from '../../../api';

const UpdateNews = () => {
	const [title, setTitle] = useState('');
	const [body, setBody] = useState('');
	const [image, setImage] = useState(null);
	const [imageHolder, setImageHolder] = useState(null);
	const [validateError, setValidateError] = useState({});
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null);
    
    useEffect(async () => {
        const paramsId = parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], 10);
        setId(paramsId);
        let news = await api.slowtime.news.get(paramsId);
        news = news.data;
        setTitle(news.Title);
        setBody(news.Body);
        setImage(process.env.REACT_APP_SLOWTIME_IMAGE_URL + news.Image);
        setImageHolder(process.env.REACT_APP_SLOWTIME_IMAGE_URL + news.Image);
    }, []);

	const onSubmit = () => {
		const errors = validate({ title, body });
		setValidateError(errors);

		if (Object.keys(validateError).length === 0) {
			setLoading(true);

			let data = {
				Content: body,
				Title: title,
			};

			api.slowtime.news.update(id, data).then((res) => {

				if (res.success) {
					if (image) {

                        if(image.name){
                            let imageData = new FormData();

                            imageData.append('profile_image', image);
                            imageData.append('id', id);
    
                            api.slowtime.news.upload(imageData).then((res) => {
                                if (res.success) {
                                    toastr.options = {
                                        positionClass: 'toast-top-center',
                                    };
                                    toastr.success('Haber başarıyla kaydedildi.');
                                    setTimeout(() => window.location.reload(), 2000);
                                } else {
                                    toastr.options = {
                                        positionClass: 'toast-top-center',
                                    };
                                    toastr.error('Haber kaydedilirken bir hata meydana geldi.');
                                }
                            });
                        }else{
                            toastr.options = {
                                positionClass: 'toast-top-center',
                            };
                            toastr.success('Haber başarıyla kaydedildi.');
                            setTimeout(() => window.location.reload(), 2000);
                        }
						
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.success('Haber başarıyla kaydedildi. Etkinlik fotoğrafı seçmediniz.');
					}
				}else{
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error('Haber kaydedilirken bir hata meydana geldi.');
				}
			});
		}
	};

	const onChangeImageInput = (e) => {
		e.preventDefault();

		const reader = new FileReader();

		reader.onload = () => {
			setImageHolder(reader.result);
		};

		reader.readAsDataURL(e.target.files[0]);

        console.log(e.target.files[0]);
		setImage(e.target.files[0]);
	};

	const validate = (data) => {
		const errors = {};
		if (!data.title) errors.title = 'Başlık boş olamaz.';
		if (!data.body) errors.body = 'İçerik boş olamaz.';
		return errors;
	};

	return (
		<>
			<Helmet>
				<title>{`Haberler - Slowtime Yönetimi | ${process.env.REACT_APP_SLOW_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Haber Yönetimi" breadcrumbItem="Haber Düzenle" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Haber Düzenle</CardTitle>
										</div>
									</div>

									<Form>
										<Row>
											<Col xl={12}>
												<FormGroup>
													<Label for="Title">Başlık</Label>
													<Input
														className={`${validateError.title ? 'is-invalid' : ''}`}
														id="Title"
														name="Title"
														onChange={(text) => setTitle(text.target.value)}
														value={title}
													/>
													{validateError.title && <div className="invalid-feedback d-block">{validateError.title}</div>}
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xl={12}>
												<FormGroup>
													<TextEditor onChange={(text) => setBody(text)} value={body} />
												</FormGroup>
											</Col>
										</Row>

										{image && (
											<Row>
												<Col xl={12} className="d-flex flex-column justify-content-center mb-3">
													<div className="text-center">
														<img height="200px" width="auto" src={imageHolder ? imageHolder : ''} />
													</div>
												</Col>
											</Row>
										)}

										<Row>
											<Col xl={12}>
												<Label className="btn btn-primary btn-upload w-100" htmlFor="inputImage" title="Fotoğraf seçin.">
													<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => onChangeImageInput(e)} />
													<span>Fotoğraf Yükle</span>
												</Label>
											</Col>
										</Row>

										{image && (
											<Row className="mb-3">
												<Col xl={12}>
													<Button
														className="btn btn-danger mt-4 w-100"
														onClick={() => {
															setImage(null);
														}}
													>
														Fotoğrafı Kaldır
													</Button>
												</Col>
											</Row>
										)}

										<button type="button" className="btn btn-primary" onClick={() => onSubmit()}>
											{loading ? <i className="bx bx-loader bx-spin"></i> : 'Haberi Kaydet'}
										</button>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default UpdateNews;
