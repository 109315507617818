/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

// falgs
import ajansspor from '../../../assets/images/brands/ajansspor.svg';

class LanguageDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			text: localStorage.getItem('currentBrand') ? localStorage.getItem('currentBrand') : this.props.roles[0],
		};
		this.toggle = this.toggle.bind(this);
	}

	changeBrandAction = (text) => {
		this.setState({
			text,
		});
		localStorage.setItem('currentBrand', text);

		setTimeout(() => (window.location.href = text === 'Radyospor' ? '/radyospor' : '/'), 250);
	};

	toggle() {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	}

	render() {
		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
					<DropdownToggle className="btn header-item waves-effect" tag="button">
						<img src={this.state.brand} alt="" height="16" className="mr-1" />
						<span className="align-middle">{this.state.text === 'Ajansspor' ? 'Ajansspor' : this.state.text === 'Radyospor' ? 'Radyospor' : 'Slowtime'}</span>
					</DropdownToggle>
					<DropdownMenu className="language-switch" right>
						{this.props.roles.map((brand, index) => {
							return (
								<DropdownItem key={index} tag="a" href="#" onClick={() => this.changeBrandAction(brand)} className={`notify-item ${this.state.text === brand ? 'active' : 'none'}`}>
									<span className="align-middle">{brand}</span>
								</DropdownItem>
							);
						})}
						{/* <DropdownItem tag="a" href="#" onClick={() => this.changeBrandAction('ajansspor')} className={`notify-item ${this.state.text === 'ajansspor' ? 'active' : 'none'}`}>
							<span className="align-middle">Ajansspor</span>
						</DropdownItem>

						<DropdownItem tag="a" href="#" onClick={() => this.changeBrandAction('radyospor')} className={`notify-item ${this.state.text === 'radyospor' ? 'active' : 'none'}`}>
							<span className="align-middle">Radyospor</span>
						</DropdownItem>

						<DropdownItem tag="a" href="#" onClick={() => this.changeBrandAction('radyoslowtime')} className={`notify-item ${this.state.text === 'radyoslowtime' ? 'active' : 'none'}`}>
							<span className="align-middle">Radyo Slowtime</span>
						</DropdownItem> */}
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}

LanguageDropdown.propTypes = {
	roles: PropTypes.array,
};

export default LanguageDropdown;
