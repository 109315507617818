import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, Table, Alert, Form, FormGroup, Label, Input, Container } from 'reactstrap';
import toastr from 'toastr';

import { CREATE_GALLERY, UPDATE_GALLERY, DELETE_GALLERY } from '../../../queries/ContentQuery';
import TextEditor from '../../TextEditor';
import ReactCropper from '../../ReactCropper';

import api from '../../../api';
import { addLog } from '../../../utils/addLog';

const NewsGalleryContent = ({ client, content, id, toggle }) => {
	const ContentId = id;
	const [UpdatingId, setUpdatingId] = useState(0);
	const [GalleryContentText, setGalleryContentText] = useState('');
	const [GalleryTitle, setGalleryTitle] = useState('');
	const [GalleryMediaId, setGalleryMediaId] = useState(null);
	const [GalleryOrder, setGalleryOrder] = useState(0);
	const [UpdatingSpot, setUpdatingSpot] = useState('');
	const [disabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [GalleryContentArray, setGalleryContentArray] = useState([]);
	const [imageCrop, setImageCrop] = useState('');
	const [errors, setErrors] = useState({});
	const [ImageTitle, setImageTitle] = useState('');

	// useEffect(() => {
	// 	if (GalleryContentText.length > 0) {
	// 		setDisabled(false);
	// 	} else {
	// 		setDisabled(true);
	// 	}
	// }, [GalleryContentText]);

	useEffect(() => {
		if (content.GalleryContent) {
			let tempArray = content.GalleryContent.filter((term) => term.IsDeleted === false);
			setGalleryContentArray(tempArray);
		}
	}, []);

	const onChangeImage = (image) => {
		setImageCrop(image);
	};

	const updateGalleryItem = (UpdatingId, GalleryTitle, UpdatingSpot, GalleryMediaId, GalleryOrder) => {
		let elem = document.getElementById('editGallery');
		elem.scrollIntoView();

		setUpdatingId(UpdatingId);
		setGalleryTitle(GalleryTitle);
		setUpdatingSpot(UpdatingSpot);
		setGalleryMediaId(GalleryMediaId);
		setGalleryOrder(GalleryOrder);
	};

	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n) {
			u8arr[n - 1] = bstr.charCodeAt(n - 1);
			n -= 1; // to make eslint happy
		}
		return new File([u8arr], filename, { type: mime });
	};

	const createGalleryContent = async (mediaid) => {
		const GalleryContent = JSON.stringify({ Title: GalleryTitle, Spot: GalleryContentText, Order: 1, MediaId: mediaid, Location: null, IsDeleted: false });

		const mutation = await client.mutate({
			mutation: CREATE_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					GalleryContent,
				},
			},
		});

		if (mutation.data.createGallery.success) {
			api.cache(ContentId);
			let logData = `Eklenen Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/${ContentId}" target="_blank" />${ContentId}</a>`;
			addLog(5, logData);

			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.success(mutation.data.createGallery.message);

			setTimeout(() => window.location.reload(), 3000);
		} else {
			toastr.option = {
				positionClass: 'toast-top-center',
			};
			toastr.error(mutation.data.createGallery.message);
		}
	};

	const addGalleryItem = async () => {
		setLoading(true);

		if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
			if (ImageTitle === '') {
				const errors = validate({ ImageTitle });
				setErrors(errors);
				return;
			} else {
				// Burası değiştirildi

				let data = new FormData();

				let file = dataURLtoFile(imageCrop, ImageTitle);

				data.append('content', file);
				data.append('ImageTitle', ImageTitle);

				api.contentImageUpload(data).then(async (res) => {
					if (res.success) {
						if (res.mediaId) {
							createGalleryContent(res.mediaId);
						}
					}
				});
			}
		} else if (imageCrop) {
			createGalleryContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
		} else {
			createGalleryContent(null);
		}
	};

	const updatedGalleryItem = async (mediaid) => {
		const GalleryContent = JSON.stringify({ Title: GalleryTitle, Spot: GalleryContentText, Order: GalleryOrder, MediaId: mediaid, Location: null, IsDeleted: false });

		const mutation = await client.mutate({
			mutation: UPDATE_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					Order: parseInt(UpdatingId),
					GalleryContent,
				},
			},
		});

		if (mutation) {
			setLoading(false);

			if (mutation.data.updateGallery.success) {
				api.cache(ContentId);
				let logData = `Düzenlenen Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/${id}" target="_blank" />${id}</a>`;
				addLog(4, logData);
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.updateGallery.message);

				setTimeout(() => window.location.reload(), 5000);
			} else {
				toastr.option = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.updateGallery.message);
			}
		}
	};

	const editGalleryItem = async () => {
		setLoading(true);

		if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
			if (ImageTitle === '') {
				const errors = validate({ ImageTitle });
				setErrors(errors);
				return;
			} else {
				// Burası değiştirildi

				let data = new FormData();

				let file = dataURLtoFile(imageCrop, ImageTitle);

				data.append('content', file);
				data.append('ImageTitle', ImageTitle);

				api.contentImageUpload(data).then(async (res) => {
					if (res.success) {
						if (res.mediaId) {
							setGalleryMediaId(res.mediaId);
							updatedGalleryItem(res.mediaId);
						}
					}
				});
			}
		} else if (imageCrop) {
			updatedGalleryItem(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
		} else {
			updatedGalleryItem(parseInt(GalleryMediaId, 10));
		}
	};

	const deleteGalleryItem = async (id) => {
		const mutation = await client.mutate({
			mutation: DELETE_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					Order: parseInt(id),
				},
			},
		});

		if (mutation) {
			if (mutation.data.deleteGallery.success) {
				api.cache(ContentId);
				let logData = `Silinen Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/${id}" target="_blank" />${id}</a>`;
				addLog(6, logData);

				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.deleteGallery.message);

				setTimeout(() => window.location.reload(), 3000);
			} else {
				toastr.option = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.deleteGallery.message);
			}
		}
	};

	const validate = (data) => {
		const errors = {};
		if (!data.ImageTitle) errors.ImageTitle = 'Fotoğraf başlığı boş olamaz.';
		return errors;
	};

	return (
		<>
			{content.ContentType === 27 && (
				<Row>
					<Col lg="12">
						<Card>
							<CardBody>
								{content.GalleryContent && content.GalleryContent.length > 0 ? (
									<div className="table-responsive">
										<Table className="table-centered table-nowrap table-hover">
											<thead className="thead-light">
												<tr>
													<th scope="col" style={{ width: '70px' }}>
														#
													</th>
													<th scope="col">Başlık / Spot</th>
													<th>#</th>
												</tr>
											</thead>
											<tbody className="table__width">
												{content.GalleryContent.map(
													(gallery, i) =>
														!gallery.IsDeleted && (
															<tr key={i}>
																<td>{i + 1}</td>
																<td>
																	<h5 className="font-size-14 mb-1">
																		<a href="#!" className="text-dark" title="Haberi Sitede Görüntüle">
																			{gallery.Title}
																		</a>
																	</h5>
																	<p className="text-muted mb-0 galleryInput" dangerouslySetInnerHTML={{ __html: gallery.Spot }}></p>
																</td>
																<td>
																	{UpdatingId !== gallery.Order ? (
																		<>
																			<button
																				type="button"
																				onClick={() => updateGalleryItem(gallery.Order, gallery.Title, gallery.Spot, gallery.MediaId, gallery.Order)}
																				className="btn btn-primary"
																			>
																				Düzenle
																			</button>
																			<button
																				type="button"
																				onClick={() => window.confirm('Silmek istediğinize emin misiniz?') && deleteGalleryItem(gallery.Order)}
																				className="btn btn-danger ml-1"
																			>
																				Sil
																			</button>
																		</>
																	) : (
																		<span className="btn btn-success">Düzenleniyor...</span>
																	)}
																</td>
															</tr>
														),
												)}
											</tbody>
										</Table>
									</div>
								) : (
									<Alert color="primary">Henüz galeri eklenmemiş.</Alert>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			{content.ContentType === 27 ? (
				<Form id="editGallery">
					<Row>
						<Col lg="12">
							<FormGroup className="d-flex flex-row">
								<Label for="GalleryTitle" lg="1">
									Galeri Başlığı
								</Label>
								<Input type="text" className="form-control" id="GalleryTitle" name="GalleryTitle" value={GalleryTitle} onChange={(text) => setGalleryTitle(text.target.value)} />

								{UpdatingId !== 0 && (
									<button
										type="button"
										className="btn btn-success ml-2"
										onClick={() => {
											setUpdatingId(0);
											setGalleryTitle('');
											setUpdatingSpot('');
										}}
									>
										İptal
									</button>
								)}
							</FormGroup>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<TextEditor onChange={(text) => setGalleryContentText(text)} value={UpdatingSpot} />
						</Col>
						<Col lg="12 mt-3">
							{GalleryContentArray.length % 3 === 0 && !UpdatingId && (
								<>
									<Label for="gallery-image-title">Fotoğraf Başlığı</Label>
									<Input
										className={`mb-4 ${errors.ImageTitle && 'is-invalid'}`}
										id="gallery-image-title"
										name="ImageTitle"
										onChange={(e) => setImageTitle(e.target.value)}
										value={ImageTitle}
									/>
									<ReactCropper key="1" type="gallery" imageChanged={(e) => onChangeImage(e)} />
								</>
							)}
							{UpdatingId ? (
								<>
									<Label for="gallery-image-title">Fotoğraf Başlığı</Label>
									<Input
										className={`mb-4 ${errors.ImageTitle && 'is-invalid'}`}
										id="gallery-image-title"
										name="ImageTitle"
										onChange={(e) => setImageTitle(e.target.value)}
										value={ImageTitle}
									/>
									<ReactCropper key="2" type="gallery" imageChanged={(e) => onChangeImage(e)} defaultSrc={GalleryMediaId !== null ? GalleryMediaId.toString() : ''} />
								</>
							) : (
								''
							)}
						</Col>
					</Row>
				</Form>
			) : (
				<Form id="editGallery">
					<Row>
						<Col lg="12">
							<TextEditor onChange={(text) => setGalleryContentText(text)} value={content.Body} />
						</Col>
					</Row>
				</Form>
			)}

			<div className="wizard-fixed">
				<Container fluid>
					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<ul className="pager wizard twitter-bs-wizard-pager-link twitterShare">
										<li className="previous">
											<button type="button" className="btn btn-primary" onClick={() => toggle(1)}>
												Önceki
											</button>
										</li>

										<li className="next">
											{UpdatingId === 0 ? (
												<button type="button" onClick={addGalleryItem} className="btn btn-secondary" disabled={disabled || loading}>
													{loading ? <i className="bx bx-loader bx-spin"></i> : 'Galeriyi Kaydet'}
												</button>
											) : (
												<button type="button" onClick={editGalleryItem} className="btn btn-secondary" disabled={disabled || loading}>
													{loading ? <i className="bx bx-loader bx-spin"></i> : 'Galeriyi Güncelle'}
												</button>
											)}

											<a href={`https://ajansspor.com/haber/link-${id}`} target="blank" className="btn btn-danger ml-4">
												Sitede Görüntüle
											</a>

											<button type="button" className="btn btn-primary ml-4" onClick={() => toggle(3)}>
												Sonraki
											</button>
										</li>
									</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

NewsGalleryContent.propTypes = {
	client: PropTypes.object,
	content: PropTypes.object,
	id: PropTypes.number,
	toggle: PropTypes.func,
};

export default NewsGalleryContent;
