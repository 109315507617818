import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardTitle, CardBody, Modal, FormGroup, Label } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import Select from 'react-select';


import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';
import { addLog } from '../../../utils/addLog';
import { seoUrl } from '../../../utils/seoUrl';


moment.locale('tr');
class Logs extends React.Component {
	state = {
		name: '',
		error: null,
		logs: [],
		logsDetail: false,
		detailLogs: [],
		hasFilter: false,
		loading: false,
		selectedEditorId: 0,
		selectedFrequency: {label: 'Bugün', value: 0},
	};

	componentDidMount() {
		this.fetchLogs('?limit=500');
		addLog(17, 'Log listeleme görüntülendi.');
	}

	toggleLogsDetail = () => {
		this.setState({logsDetail: !this.state.logsDetail});
	}
	

	fetchLogs = (term) => {
		this.setState({loading: true});
		api.logs.getEditorNewsLogs(term).then((res) => {
			let data = [];
			if (res) {
				let subLogsCount = 0;
				res.data.map((item) => {
					let duration = moment.duration(moment(item.endedDate).diff(item.startedDate));
					let fixedDuration = (duration.hours() < 10 ? '0'+duration.hours() : duration.hours()) + ':' + (duration.minutes() < 10 ? '0'+duration.minutes() : duration.minutes()) + ':' +(duration.seconds() < 10 ? '0'+duration.seconds() : duration.seconds());
					subLogsCount += item.subLogsCount;
					data.push({
						...item,
						editor: (
							<div 
								onClick={() => {this.fetchLogs(`?limit=500&editorId=${item.editorId}&frequency=0`); this.setState({hasFilter: true, selectedEditorId: item.editorId});}} 
								dangerouslySetInnerHTML={{ __html:`<a href='javascript:;' >${item.editorName}</a>` }} 
							/>
						),
                        title: <div dangerouslySetInnerHTML={{ __html: `<a href="https://ajansspor.com/haber/${seoUrl(item.contentTitle)}-${item.contentId}" target="_blank">${item.contentTitle}</a>` }} />,
						fixedStartedDate: moment(item.startedDate).format('DD/MM/YYYY HH:mm:ss'),
						fixedEndedDate: moment(item.endedDate).format('DD/MM/YYYY HH:mm:ss'),
						firstDifference: fixedDuration,
						changeCount: item.SubLogs.length,
						settings: (
							<div className="btn-group mr-2 mb-2 mb-sm-0">
								{
									item.SubLogs.length > 0 &&
									<button
										className="btn btn-secondary"
										onClick={() => {
											let newData = [];
											item.SubLogs.map((t) => {
												let subDuration = moment.duration(moment(t.endedDate).diff(t.startedDate));
												let subFixedDuration = (subDuration.hours() < 10 ? '0'+subDuration.hours() : subDuration.hours()) + ':' + (subDuration.minutes() < 10 ? '0'+subDuration.minutes() : subDuration.minutes()) + ':' +(subDuration.seconds() < 10 ? '0'+subDuration.seconds() : subDuration.seconds());
												newData.push({
													...t,
													title: <div dangerouslySetInnerHTML={{ __html: `<a href="https://ajansspor.com/haber/${seoUrl(t.contentTitle)}-${item.contentId}" target="_blank">${t.contentTitle}</a>` }} />,
													fixedStartedDate: moment(t.startedDate).format('DD/MM/YYYY HH:mm:ss'),
													fixedEndedDate: moment(t.endedDate).format('DD/MM/YYYY HH:mm:ss'),
													difference: subFixedDuration
												});
											});
											this.setState({logsDetail: true, detailLogs: newData});
										}}
									>
										<i className="bx bx-list-ul"></i>
									</button>
								}
								<a href={`/news/news/${item.contentId}`} className="btn btn-secondary">
									<i className="bx bxs-pencil"></i>
								</a>
								
							</div>
						)
					});
				});
				this.setState({
					logs: data,
					subLogsCount: (subLogsCount + res.subData.length)
				});
			}
			this.setState({loading: false});
		});
	};

	selectFrequency = (e) => {
		this.setState({selectedFrequency: e});
		this.fetchLogs(`?limit=500&editorId=${this.state.selectedEditorId}&frequency=${e.value}`);
	}
 s
	render() {
		const data = {
			columns: [
				{
					label: 'Editör',
					field: 'editor',
					sort: 'asc',
					width: 250,
				},
				{
					label: 'Haber',
					field: 'title',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Okunma Sayısı',
					field: 'readCount',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Kaynak',
					field: 'source',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Başlanan Tarih',
					field: 'fixedStartedDate',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Biten Tarih',
					field: 'fixedEndedDate',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'İlk Fark',
					field: 'firstDifference',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Güncelleme Sayısı',
					field: 'changeCount',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Detaylar',
					field: 'settings',
					sort: 'asc',
					width: 50,
				},
			],
			rows: this.state.logs,
		};

		const subData = {
			columns: [
				{
					label: 'Editör',
					field: 'editorName',
					sort: 'asc',
					width: 250,
				},
				{
					label: 'Haber',
					field: 'title',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Başlanan Tarih',
					field: 'fixedStartedDate',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Biten Tarih',
					field: 'fixedEndedDate',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Fark',
					field: 'difference',
					sort: 'asc',
					width: 50,
				}
			],
			rows: this.state.detailLogs
		};

		const logsFrequency = [
			{ label: 'Bugün', value: 0 },
			{ label: 'Dün', value: 1 },
			{ label: 'Son Hafta', value: 2 },
			{ label: 'Son Ay', value: 3 },
			{ label: 'Genel', value: 4 },
		];

		return (
			<>
				<Helmet>
					<title>{`Loglar - Log Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Log Yönetimi" breadcrumbItem="Loglar" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Editörlerin Haber Giriş Süreleri</CardTitle>
											</div>
											{
												this.state.hasFilter &&
												<button 
													className="btn btn-rounded btn-secondary"
													onClick={() => {this.fetchLogs('?limit=500');this.setState({hasFilter: false, selectedFrequency: {label: 'Bugün', value: 0}});}}
												>
													{
														this.state.loading ?
														<i className="bx bx-loader bx-spin"></i>
														:
														<>
															Filtreyi Kaldır
															&times;
														</>
													}
												</button>
											}
											
										</div>

										{
											this.state.hasFilter && !this.state.loading &&
											<div className="d-flex justify-content-between">
												<div className="flex-fill mt-2">
													<FormGroup className="select2-container">
														<Label className="control-label">Kategori</Label>

														<Select
															value={this.state.selectedFrequency}
															isMulti={false}
															onChange={this.selectFrequency}
															options={logsFrequency}
															classNamePrefix="select2-selection"
															placeholder="Sıklık seçiniz"
														/>
													</FormGroup>
												</div>
												<div className="d-flex flex-column flex-fill mt-2">
													<div className="d-flex flex-row justify-content-end mt-1">
														<span>
															Toplam Girdiği Haber Sayısı:&nbsp;
														</span>
														<span>
															{this.state.logs.length}
														</span>
													</div>
													<div className="d-flex flex-row justify-content-end mt-1">
														<span>
															Toplam Girdiği Galeri:&nbsp;
														</span>
														<span>
															{this.state.subLogsCount}
														</span>
													</div>
												</div>
											</div>
										}

										<div className="editors-news-logs">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
				<Modal isOpen={this.state.logsDetail} toggle={this.toggleLogsDetail} centered={true} size='lg'>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Log Detayları</h5>
						<button
							type="button"
							onClick={() => this.setState({logsDetail: false, detailLogs: []})}
							className="close"
							data-dismiss="modal"
							aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
					</div>
					<div className="modal-body">
						<MDBDataTable
							responsive
							striped
							bordered
							data={subData}
							noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
							paginationLabel={['Önceki', 'Sonraki']}
							entriesLabel="Göster"
							infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
							searchLabel="Ara"
							
					/>
					</div>
				</Modal>
			</>
		);
	}
}

Logs.propTypes = {
	getLogs: PropTypes.func,
	addLog: PropTypes.func,
	logs: PropTypes.array,
	error: PropTypes.string,
	message: PropTypes.string,
};

export default Logs;
