/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from 'reactstrap';
import SimpleBar from 'simplebar-react';

//Import images
import avatar3 from '../../../assets/images/users/avatar-3.jpg';
import avatar4 from '../../../assets/images/users/avatar-4.jpg';

class NotificationDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	}
	render() {
		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="dropdown d-inline-block" tag="li">
					<DropdownToggle className="btn header-item noti-icon waves-effect" tag="button" id="page-header-notifications-dropdown">
						<i className="bx bx-bell"></i> {/* bx-tada */}
						<span className="badge badge-danger badge-pill">0</span>
					</DropdownToggle>

					<DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
						<div className="p-3">
							<Row className="align-items-center">
								<Col>
									<h6 className="m-0">Bildirimler</h6>
								</Col>
								<div className="col-auto">
									<a href="#!" className="small">
										{' '}
										Tümünü Görüntüle
									</a>
								</div>
							</Row>
						</div>

						<SimpleBar style={{ height: '230px' }}>
							<Link to="" className="text-reset notification-item">
								<div className="media">
									<div className="media-body">
										<h6 className="mt-0 mb-1">Emre Pırnaz</h6>
										<div className="font-size-12 text-muted">
											<p className="mb-1">Yeni bir yorum yazdı.</p>
											<p className="mb-0">
												<i className="mdi mdi-clock-outline"></i> 1 saat önce{' '}
											</p>
										</div>
									</div>
								</div>
							</Link>
						</SimpleBar>
						<div className="p-2 border-top">
							<Link className="btn btn-sm btn-link font-size-14 btn-block text-center" to="#">
								Tümünü Görüntüle
							</Link>
						</div>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}
export default NotificationDropdown;
