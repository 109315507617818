import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { withApollo, Query } from 'react-apollo';
import moment from 'moment';
moment.locale('tr');

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { AGENCY_NEWS } from '../../queries/ContentQuery';

class Agencies extends React.Component {
	state = {
		Limit: 20,
		Skip: 0,
		Term: '',
		StartDate: undefined,
		EndDate: undefined,
		Ajans: '',
	};

	handleChange = (e) => {
		this.setState({
			Term: e.target.value,
		});
	};

	render() {
		const { Term, Limit, Skip, StartDate, EndDate, Ajans } = this.state;

		return (
			<>
				<Helmet>
					<title>{`Ajans Haberleri - Haber Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>

				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Haber Yönetimi" breadcrumbItem="Ajans Haberleri" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between mb-3">
											<div>
												<CardTitle>Haberler</CardTitle>
											</div>
											<div>
												<form className="app-search d-none d-lg-block content__search">
													<div className="position-relative">
														<input type="text" className="form-control" placeholder="Haber Ara..." onChange={(e) => this.handleChange(e)} value={Term} />
														<span className="bx bx-search-alt"></span>
													</div>
												</form>
											</div>
										</div>
										<div className="mt-3">
											<div className="headline__list headline__list--bordered mt-3">
												<li>
													<div className="headline__title">Başlık</div>
													<div className="d-flex ml-auto w-50 justify-content-between">
														<div className="headline__view-count">Haber Türü</div>
														<div className="headline__view-count">Haber Kaynağı</div>
														<div className="headline__order">Haber Tarihi</div>
														<div className="btn__groups">#</div>
													</div>
												</li>
											</div>

											<Query query={AGENCY_NEWS} variables={{ Limit, Skip, Ajans, Term, StartDate, EndDate }}>
												{({ loading, data }) => {
													if (loading) return null;

													console.log(data);

													return (
														<ul className="headline__list">
															{data.ajansContents.map((news, i) => (
																<li key={i}>
																	<div className="headline__title">{news.Title}</div>
																	<div className="d-flex ml-auto w-50 justify-content-between align-items-center">
																		<div className="headline__view-count">{news.AjansType}</div>
																		<div className="headline__view-count">{news.AjansName}</div>
																		<div className="headline__order">{moment(news.CreatedDate).format('DD-MM-YYYY HH:mm')}</div>
																		<div className="btn__groups">
																			<div className="btn-group mr-2 mb-2 mb-sm-0">
																				<a href={`/news/news/agency:${news.ContentId}`} className="btn btn-secondary waves-light waves-effect" title="Düzenle">
																					<i className="bx bx-edit"></i>
																				</a>
																			</div>
																		</div>
																	</div>
																</li>
															))}
														</ul>
													);
												}}
											</Query>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default withApollo(Agencies);
