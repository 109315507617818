// 1 -> Ajansspor
// 2 -> Radyospor
// 3 -> Slowtime

// 999 -> Administrator

// Ajansspor
// 101 -> Editör
// 102 -> Yazar
// 103 -> Analist
// 104 -> Reklam
// 120 -> Admin

// Radyospor
// 201 -> Editör
// 220 -> Admin

// Slowtime
// 301 -> Editör
// 320 -> Admin

const getRoles = (roles) => {
	let app = [],
		role = [];

	if (roles) {
		if (roles.includes('999')) {
			app.push('Ajansspor');
			app.push('Radyospor');
			app.push('Slowtime');
			role.push('Administrator');
		} else {
			if (roles.includes('1')) {
				app.push('Ajansspor');
			}
			if (roles.includes('2')) {
				app.push('Radyospor');
			}
			if (roles.includes('3')) {
				app.push('Slowtime');
			}
		}

		if (app.includes('Ajansspor')) {
			if (roles.includes('101')) {
				role.push('A-Editör');
			}
			if (roles.includes('102')) {
				role.push('A-Writer');
			}
			if (roles.includes('103')) {
				role.push('A-Analist');
			}
			if (roles.includes('104')) {
				role.push('A-Advertiser');
			}
			if (roles.includes('120')) {
				role.push('A-Admin');
			}
		}
		if (app.includes('Radyospor')) {
			if (roles.includes('201')) {
				role.push('R-Editör');
			}
			if (roles.includes('220')) {
				role.push('R-Admin');
			}
		}
		if (app.includes('Slowtime')) {
			if (roles.includes('301')) {
				role.push('S-Editör');
			}
			if (roles.includes('320')) {
				role.push('S-Admin');
			}
		}
	}

	return { app, role };
};

module.exports = {
	getRoles,
};
