import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import toastr from 'toastr';
import { Form, Row, Col, FormGroup, Label, Container, Card, CardBody } from 'reactstrap';
import { Query } from 'react-apollo';

moment.locale('tr');

import { UPDATE_SETTINGS, TWITTER_ACCOUNTS } from '../../../queries/ContentQuery';
import api from '../../../api';
import { addLog } from '../../../utils/addLog';

const categories = [
	{ label: 'Futbol', value: 16 },
	{ label: 'Basketbol', value: 9 },
	{ label: 'Voleybol', value: 10 },
	{ label: 'Özel Haber', value: 33145 },
	{ label: 'Dış Haber', value: 33146 },
	{ label: 'Detay Haber', value: 33147 },
	{ label: 'Analiz Haber', value: 33148 },
	{ label: 'Ajans Gazete Haber', value: 33149 },
	{ label: 'Espor', value: 34741 },
];

const NewsSettings = ({ id, client, content, toggle }) => {
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedApiTags, setSelectedApiTags] = useState([]);

	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedApiCategory, setSelectedApiCategory] = useState([]);

	const [selectedTwitter, setSelectedTwitter] = useState([]);
	const [selectedApiTwitter, setSelectedApiTwitter] = useState([]);

	const [errors, setErrors] = useState({});

	const selectTags = (selectedTags) => {
		let tags = [];

		if (Array.isArray(selectedTags)) {
			selectedTags.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiTags(tags);
			setSelectedTags(selectedTags);
		} else {
			setSelectedTags([]);
			setSelectedApiTags([]);
		}
	};

	const getTags = (inputValue, callback) => {
		if (inputValue.length > 2) {
			let tags = [];

			api.tags.search(inputValue).then((res) => {
				res.forEach(function (tag) {
					tags.push({ label: tag.Name.trim(), value: tag.TagId });
				});
			});

			setTimeout(() => callback(tags), 1000);
		}
	};

	const selectCategory = (selectedCategory) => {
		if (Array.isArray(selectedCategory)) {
			let tags = [];

			selectedCategory.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiCategory(tags);
			setSelectedCategory(selectedCategory);
		} else {
			setSelectedCategory([]);
			setSelectedApiCategory([]);
		}
	};

	const selectTwitterAccount = (selectedTwitter) => {
		if (Array.isArray(selectedTwitter)) {
			let twitterAccounts = [];

			selectedTwitter.forEach(function (account) {
				twitterAccounts.push(account.value);
			});

			setSelectedApiTwitter(twitterAccounts);
			setSelectedTwitter(selectedTwitter);
		} else {
			setSelectedTwitter([]);
			setSelectedApiTwitter([]);
		}
	};

	useEffect(() => {
		let cats = [];
		let tags = [];

		content?.TagList?.length > 0 &&
			content.TagList.map((tag) => {
				if (categories.some((cat) => cat.value === tag.TagId)) {
					categories.some((cat, i) => {
						if (cat.value === tag.TagId) {
							cats.push(categories[i]);
						}
					});
				} else {
					tags.push({ label: tag.Name, value: tag.TagId });
				}
			});

		if (tags.length > 0) {
			let newTags = [];

			tags.forEach(function (tag) {
				newTags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiTags(newTags);
			setSelectedTags(tags);
		}

		if (cats.length > 0) {
			let tags = [];

			cats.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiCategory(tags);
			setSelectedCategory(cats);
		}
	}, []);

	const updateContentSettings = async () => {
		let ContentId = id;
		let TagList = JSON.stringify(selectedApiTags);
		let CategoryList = JSON.stringify(selectedApiCategory);
		let TwitterAccounts = JSON.stringify(selectedApiTwitter);

		const errors = validate({ selectedTags, selectedCategory });
		setErrors(errors);

		if (Object.keys(errors).length === 0) {
			const mutation = await client.mutate({
				mutation: UPDATE_SETTINGS,
				variables: {
					input: {
						ContentId,
						TagList,
						CategoryList,
						TwitterAccounts,
					},
				},
			});

			if (mutation) {
				if (mutation.data.updateContentSettings.success) {
					api.cache(ContentId);
					let logData = `Düzenlenen Haber Id'si : ${ContentId}, Tweet ${TwitterAccounts ? 'Gönderildi' : 'Gönderilmedi'}`;
					addLog(7, logData);
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(mutation.data.updateContentSettings.message);
					setTimeout(() => window.location.reload(), 5000);
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error(mutation.data.updateContentSettings.message);
					addLog(34, mutation.data.updateContentInfo.message);
				}
			}
		}
	};

	const validate = (data) => {
		const errors = {};
		if (!data.selectedTags) errors.selectTags = 'Etiket alanı boş olamaz.';
		if (!data.selectedCategory) errors.selectedCategory = 'Kategori alanı boş olamaz.';
		return errors;
	};

	return (
		<>
			<Form>
				<Row>
					<Col lg="6">
						<FormGroup className="select2-container">
							<Label className="control-label">Etiketler</Label>
							<AsyncSelect
								value={selectedTags}
								isMulti
								onChange={selectTags}
								classNamePrefix="select2-selection"
								placeholder="Etiket seçiniz"
								loadOptions={getTags}
								noOptionsMessage={() => 'En az 3 karakter yazmalısınız.'}
							/>
							{errors.selectTags && <div className="invalid-feedback d-block">{errors.selectTags}</div>}
						</FormGroup>
					</Col>

					<Col lg="6">
						<FormGroup className="select2-container">
							<Label className="control-label">Tweet Gönder</Label>
							<Query query={TWITTER_ACCOUNTS}>
								{({ loading, data }) => {
									if (loading) return null;

									let accounts = [];

									data.twitterAccounts.map((acc) => accounts.push({ label: acc.name, value: acc.name }));

									return (
										<Select
											value={selectedTwitter}
											isMulti={true}
											onChange={selectTwitterAccount}
											options={accounts}
											classNamePrefix="select2-selection"
											placeholder="Twitter adresi seçiniz"
										/>
									);
								}}
							</Query>
						</FormGroup>
					</Col>
				</Row>
				<Row>
					<Col lg="6">
						<FormGroup className="select2-container">
							<Label className="control-label">Kategori</Label>
							<Select value={selectedCategory} isMulti={true} onChange={selectCategory} options={categories} classNamePrefix="select2-selection" placeholder="Kategori seçiniz" />
							{errors.selectedCategory && <div className="invalid-feedback d-block">{errors.selectedCategory}</div>}
						</FormGroup>
					</Col>
				</Row>
			</Form>

			<div className="wizard-fixed">
				<Container fluid>
					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<ul className="pager wizard twitter-bs-wizard-pager-link twitterShare">
										<li className="previous">
											<button type="button" className="btn btn-primary" onClick={() => toggle(2)}>
												Önceki
											</button>
										</li>

										<li className="next ml-1">
											<button type="button" className="btn btn-primary" onClick={() => updateContentSettings()}>
												Yayınla
											</button>
										</li>
									</ul>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

NewsSettings.propTypes = {
	id: PropTypes.number,
	client: PropTypes.object,
	content: PropTypes.object,
	toggle: PropTypes.func,
};

export default NewsSettings;
