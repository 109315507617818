import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal } from 'reactstrap';
import moment from 'moment';
moment.locale('tr');

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const Settings = () => {
	const [settings, setSettings] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [viewed, setViewed] = React.useState({});

	React.useEffect(() => {
		fetch();
	}, []);
	const fetch = () => {
		api.radyospor.settings.all().then((res) => setSettings(res));
	};

	return (
		<>
			<Helmet>
				<title>{`Ayarlar | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Form" breadcrumbItem="Ayarlar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Ayarlar</CardTitle>
										</div>
									</div>

									<div className="mini-table">
										<table className="table">
											<thead>
												<tr>
													<th>#</th>
													<th>#</th>
													<th>#</th>
												</tr>
											</thead>
											<tbody>
												{settings.length > 0 &&
													settings.map((setting, i) => (
														<tr key={i}>
															<td>{setting.key}</td>
															<td>{setting.value}</td>
															<td>
																<button
																	className="btn btn-secondary"
																	onClick={() => {
																		setStatus(true);
																		setViewed({
																			id: setting.id,
																			key: setting.key,
																			value: setting.value,
																		});
																	}}
																>
																	<i className="mdi mdi-pencil"></i>
																</button>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</CardBody>
							</Card>
						</Col>

						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Podcast Sayfası</CardTitle>
										</div>
									</div>

									<div className="mini-table">
										<table className="table">
											<thead>
												<tr>
													<th>#</th>
													<th>#</th>
													<th>#</th>
												</tr>
											</thead>
											<tbody>
												{settings.length > 0 &&
													settings.map((setting, i) => (
														<tr key={i}>
															<td>{setting.key}</td>
															<td>{setting.value}</td>
															<td>
																<button
																	className="btn btn-secondary"
																	onClick={() => {
																		setStatus(true);
																		setViewed({
																			id: setting.id,
																			key: setting.key,
																			value: setting.value,
																		});
																	}}
																>
																	<i className="mdi mdi-pencil"></i>
																</button>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">{viewed.name} mesajı</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<table className="table">
								<tbody>
									<tr>
										<td>Adı Soyadı:</td>
										<td>{viewed.name}</td>
									</tr>
									<tr>
										<td>Eposta Adresi:</td>
										<td>
											<a
												href={`mailto:${viewed.email}?bcc=developer@ajansspor.com&body=${viewed.message}&subject= Radyospor ${viewed.subject} başlıklı mesajınıza yanıt verildi`}
											>
												{viewed.email}
											</a>
										</td>
									</tr>
									<tr>
										<td>Telefon No:</td>
										<td>{viewed.phone === '' ? 'Belirtmemiş' : viewed.phone}</td>
									</tr>
									<tr>
										<td>Konu:</td>
										<td>{viewed.subject}</td>
									</tr>
									<tr>
										<td>Mesaj:</td>
										<td>{viewed.message}</td>
									</tr>
									<tr>
										<td>Tarih:</td>
										<td>{viewed.date}</td>
									</tr>
									<tr>
										<td>IP Adresi:</td>
										<td>{viewed.ip}</td>
									</tr>
								</tbody>
							</table>

							<p>
								Mesaj ile ilgili teknik bilgileri (ip adresi, tarayıcı bilgisi, işletim sistemi bilgisi, ip adresi yaklaşık konum bilgisi gibi) teknik ekipten mesaj IDsi ile
								isteyebilirsiniz. Bu mesajın IDsi:{' '}
								<code>
									<strong>{viewed.id}</strong>
								</code>
							</p>

							<button className="btn btn-info mt-4" onClick={() => setStatus(!status)}>
								Kapat
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Settings;
