import { gql } from 'apollo-boost';

/* Queries */

export const GET_PROFILE = gql`
	{
		getProfile {
			Username
			Fullname
			Email
			Gender
			IsDarkTheme
			Role
		}
	}
`;

export const GET_USERS = gql`
	{
		users {
			Username
			Fullname
			Email
			Birthdate
			Role
			Roles
			Status
			Gender
			KVKK
			NormalLogin
			TwitterLogin
			FacebookLogin
		}
	}
`;

/* Mutations */

export const UPDATE_PROFILE = gql`
	mutation updateProfile($input: UpdateProfileInput) {
		updateProfile(input: $input) {
			success
			message
			token
		}
	}
`;

export const CREATE_USER = gql`
	mutation createUser($input: UserInput) {
		createUser(input: $input) {
			success
			message
		}
	}
`;
