/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';
import Avatar from 'react-avatar';

// users
import user1 from '../../../assets/images/users/avatar-1.jpg';
import { getTokenDecoded } from '../../../utils/auth';

class ProfileMenu extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menu: false,
			name: 'Admin',
		};
		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	}

	componentDidMount() {
		const user = getTokenDecoded(localStorage.getItem('ajstoken'));

		this.setState({
			name: user.Username,
			pin: user.Pin,
		});
	}

	logout() {
		window.location.reload();
		localStorage.removeItem('ajstoken');
	}

	render() {
		return (
			<React.Fragment>
				<Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block">
					<DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
						<Avatar name={this.state.name} className="rounded-circle" size={36} />
						<span className="d-none d-xl-inline-block ml-2 mr-1">{this.state.name}</span>
						<i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
					</DropdownToggle>
					<DropdownMenu right>
						<a href="/profile" className="dropdown-item">
							<i className="bx bx-user font-size-16 align-middle mr-1"></i>
							Profil
						</a>

						{/* <a href="#!" className="dropdown-item">
							<i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
							Ayarlar
						</a> */}

						<a href={`${this.state.pin !== null ? '/lock' : '/profile?s=2'}`} className="dropdown-item">
							<i className="bx bx-lock-open font-size-16 align-middle mr-1"></i> Ekranı Kilitle
						</a>
						<div className="dropdown-divider"></div>
						<button onClick={this.logout} className="dropdown-item">
							<i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
							<span>Çıkış Yap</span>
						</button>
					</DropdownMenu>
				</Dropdown>
			</React.Fragment>
		);
	}
}

export default withRouter(ProfileMenu);
