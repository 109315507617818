import React from 'react';
import { Container, 
    Row, 
    Col, 
    Alert, 
    Card, 
    CardTitle, 
    CardSubtitle, 
    CardBody, 
    Modal, } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import moment from 'moment';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  color: 'white',
  borderRadius: '0.25rem',
  fontSize: '12px',

  // change background colour if dragging
  background: isDragging ? 'rgb(0, 0, 0, 0.2)' : 'rgb(0 0 0 / 10%)',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = (isDraggingOver) => ({
	background: isDraggingOver ? 'rgb(255, 255, 255, 0%)' : 'rgb(255, 255, 255, 0%)',
    width: '100%',
    marginTop: '1.25rem'
});

class BestOfTheWeek extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            data: [],
            items: [],
            successMessage: null,
            errorMessage: null,
            loading: true,
            bestOfTheWeekModal: false,
            dragging: true,
		};
        this.onDragEnd = this.onDragEnd.bind(this);
	}

	componentDidMount() {
        this.fetchBestOfTheWeek();
        this.fetchAllVideos();
	}

	fetchBestOfTheWeek = () => {
		api.tv.getAllBestOfTheWeek().then((res) => {
			if (res) {
				this.setState({ items: res, loading: false });
			}
		});
    };

    addToBestOfTheWeek = (videoId, title) => {
        this.setState({loading: true, bestOfTheWeekModal: false});
        api.tv.addToBestOfTheWeek(videoId, title).then((res) => {
            if(res.success){
                this.fetchBestOfTheWeek();
                this.setState({successMessage: res.message});
                setTimeout(() => {
                    this.setState({successMessage: null});
                }, 2000);
            }else{
                this.setState({errorMessage: res.message});
                setTimeout(() => {
                    this.setState({errorMessage: null, loading: false});
                }, 2000);
            }
        });
    }

    deleteToBestOfTheWeek = (videoId) => {
        this.setState({loading: true});
        api.tv.deleteToBestOfTheWeek(videoId).then((res) => {
            if(res.success){
                this.fetchBestOfTheWeek();
                this.setState({successMessage: res.message});
                setTimeout(() => {
                    this.setState({successMessage: null});
                }, 2000);
            }else{
                this.setState({errorMessage: res.message, loading: false});
            }
        });
    }

    fetchAllVideos = () => {
		api.tv.getAllVideos('?limit=1000').then((res) => {
            let data = [];
            let filtered = [];

			if (res) {
                filtered = res.videos.filter((el) => {
                    return this.state.items.filter((otherEl) => {
                        return otherEl.videoId !== el.VideoId;
                    });
                });
				filtered.map((item) => {
					data.push({
						...item,
						setting: (
							<span>
								<button className='btn btn-success' onClick={() => this.addToBestOfTheWeek(item.VideoId, item.Title)}>
									Ekle
								</button>
							</span>
						),
					});
				});

				this.setState({ data });
			}
		});
	};

    tog_center = () => {
		this.setState((prevState) => ({
			bestOfTheWeekModal: !prevState.bestOfTheWeekModal,
		}));
		this.removeBodyCss();
	};
    removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	onDragEnd(result) {
        this.setState({dragging: true});
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		const items = reorder(this.state.items, result.source.index, result.destination.index);

		this.setState({
			items
        });
		if(result.destination.index !== result.source.index){
            this.setState({loading: true});
            api.tv.updateBestOfTheWeek(result.draggableId, result.destination.index+1).then((res) => {
                if(res.success){
                    this.setState({successMessage: res.message});
                    setTimeout(() => {
                        this.setState({successMessage: null});
                        this.setState({loading: false});
                    }, 2000);
                }else{
                    this.setState({errorMessage: res.message});
                    setTimeout(() => {
                        this.setState({errorMessage: null});
                        this.setState({loading: false});
                    }, 2000);
                }
            });
        }
    }
    
    onDragStart = () => {
        this.setState({dragging: false});
    }
  
      
	render() {
        const data = {
			columns: [
				{
					label: 'Video Adı',
					field: 'Title',
					sort: 'asc',
					width: 500,
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
					width: 50,
				},
			],
			rows: this.state.data,
		};
		return (
			<>
				<Helmet>
					<title>{`Anasayfa Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className='page-content'>
					<Container fluid>
                        <Breadcrumbs title='Ajansspor TV' breadcrumbItem='Genel Ayarlar' />
                        <Row>
                            <Col className='col-12'>
                                {this.state.successMessage && <Alert color='primary'>{this.state.successMessage}</Alert>}
                                {this.state.errorMessage && <Alert color='danger'>{this.state.errorMessage}</Alert>}
                            </Col>
                            <Col className={`${this.state.loading ? 'unclickable': ''} col-12`}>
                                <Card>
                                    <CardBody>
                                        <div className='d-flex justify-content-between'>
											<div>
												<CardTitle>Haftanın Enleri </CardTitle>
                                                <CardSubtitle>Sürükleyerek listeyi değiştirebilirsiniz.</CardSubtitle>
											</div>

											<button className='btn btn-primary' onClick={this.tog_center}>
												Yeni Haftanın Eni Ekle
											</button>
										</div>
                                        {this.state.loading && <div className='loading'></div>}
                                        <DragDropContext onDragEnd={this.onDragEnd} onBeforeDragStart={this.onDragStart}>
                                            <Droppable droppableId='droppable'>
                                                {(provided, snapshot) => (
                                                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                                                        {this.state.items.map((item, index) => (
                                                            <Draggable key={item.videoId} draggableId={item.videoId} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <>
                                                                        <div className='draggable__item__row'>
                                                                            <div className='draggable__item'>
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getItemStyle(
                                                                                        snapshot.isDragging,
                                                                                        provided.draggableProps.style
                                                                                    )}
                                                                                    className='bestOfTheWeekDraggableItem'
                                                                                >
                                                                                    {(index + 1) + ' - ' + item.title}
                                                                                    <div className='d-flex justify-content-end mt-1'>
                                                                                        <span>{item.editor} - {moment(item.createdDate).format('DD.MM.YYYY HH:mm')}</span>
                                                                                    </div>
                                                                                </div> 
                                                                            </div>
                                                                            {this.state.dragging && 
                                                                                <div className='draggable__item__buttons'>
                                                                                    <span>
                                                                                        <button className='btn btn-danger' onClick={() => this.deleteToBestOfTheWeek(item.videoId)}>
                                                                                            Sil
                                                                                        </button>
                                                                                    </span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </div>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </CardBody>
                                </Card>
                                
                            </Col>
                        </Row>
					</Container>
                    <Modal isOpen={this.state.bestOfTheWeekModal} toggle={this.tog_center} centered={true} size={'lg'}>
						<div className='modal-header'>
							<h5 className='modal-title mt-0'>Yeni Haftanın Eni Ekle</h5>
							<button type='button' onClick={() => this.setState({ bestOfTheWeekModal: false })} className='close' data-dismiss='modal' aria-label='Kapat'>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<div className='modal-body'>
							{this.state.error && <Alert color='primary'>{this.state.error}</Alert>}

                                <MDBDataTable
                                    responsive
                                    striped
                                    bordered
                                    data={data}
                                    noRecordsFoundLabel='Hiçbir kayıt bulunamadı'
                                    paginationLabel={['Önceki', 'Sonraki']}
                                    entriesLabel='Göster'
                                    infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
                                    searchLabel='Ara'
                                />
						</div>
					</Modal>
                </div>
			</>
		);
	}
}

export default BestOfTheWeek;
