import api from '../api';

const types = {
	FETCH_LOGS: 'FETCH_LOGS',
	GET_LOGS: 'GET_LOGS',
	ADD_LOG_PENDING: 'ADD_LOG_PENDING',
	ADD_LOG_SUCCESS: 'ADD_LOG_SUCCESS',
	ADD_LOG_FAILURE: 'ADD_LOG_FAILURE',
};

export const actions = {
	getLogs: (dispatch) => {
		dispatch({ type: types.FETCH_LOGS, isFetching: true });

		return api.logs.getLogs().then((res) => {
			dispatch({ type: types.FETCH_LOGS, isFetching: false });

			if (res) {
				dispatch({ type: types.GET_LOGS, logs: res });
			}
		});
	},
	addLog: (dispatch, data) => {
		dispatch({ type: types.ADD_LOG_PENDING, isFetching: true });

		return api.logs.addLog(data).then((res) => {
			dispatch({ type: types.ADD_LOG_PENDING, isFetching: false });

			if (res.success) {
				dispatch({ type: types.ADD_LOG_SUCCESS, message: res.message });
			} else {
				dispatch({ type: types.ADD_LOG_FAILURE, message: res.message });
			}
		});
	},
};

const initialState = {
	isFetching: false,
	error: '',
	logs: [],
	message: '',
};

export const reducer = (state = initialState, action) => {
	const { error, type, logs, isFetching, message } = action;

	switch (type) {
		case types.FETCH_LOGS:
			return { ...initialState, isFetching };

		case types.GET_LOGS:
			return { ...state, logs, isFetching: false, error };

		case types.ADD_LOG_PENDING:
			return { ...state, isFetching };

		case types.ADD_LOG_SUCCESS:
			return { ...state, message };

		case type.ADD_LOG_FAILURE:
			return { ...state, message };

		default:
			return state;
	}
};
