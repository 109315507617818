import React, { Component } from 'react';
import { Container, Row, Col, CardBody, Card, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Avatar from 'react-avatar';

import profileImg from '../assets/images/profile-img.png';
import ajsDark from '../assets/images/ajs-mini-dark.svg';
import ajsLight from '../assets/images/ajs-mini-light.svg';
import { getTokenDecoded } from '../utils/auth';
import { addLog } from '../utils/addLog';

class LockScreen extends Component {
	state = {
		fullname: getTokenDecoded(localStorage.getItem('ajstoken')).Fullname,
		pin: getTokenDecoded(localStorage.getItem('ajstoken')).Pin,
		value: '',
		loading: false,
		error: '',
	};

	componentDidMount() {
		localStorage.setItem('lockscreen', true);
	}

	pinSubmit = (e) => {
		e.preventDefault();

		let { value, pin, fullname } = this.state;

		this.setState({ loading: true });

		if (value.length === 0) {
			this.setState({ loading: false, error: 'Pin kodunuz boş olamaz.' });
		} else if (pin !== parseInt(value, 10)) {
			this.setState({ loading: false, error: 'Pin kodunuz hatalı!' });
			addLog(44, `Hatalı pin kodu. Giriş yapan: ${fullname}, hatalı pin kodu: ${value}`);
		} else {
			localStorage.removeItem('lockscreen');
			window.location.href = '/';
		}
	};

	render() {
		let { fullname, value, loading } = this.state;

		return (
			<React.Fragment>
				<div className="account-pages my-5 pt-sm-5">
					<Container>
						<Row className="justify-content-center">
							<Col md="8" lg="6" xl="5">
								<Card className="overflow-hidden">
									<div className="bg-soft-primary">
										<Row>
											<Col xs="7">
												<div className="text-primary p-4">
													<h5 className="text-primary">Kilit Ekranı</h5>
													<p>Giriş yapmak için pin kodunuzu girmelisiniz.</p>
													<small className="d-block mb-3">Pin kodu giriş yapılan tarayıcıya özeldir.</small>
												</div>
											</Col>
											<Col xs="5" className="align-self-end">
												<img src={profileImg} alt="" className="img-fluid" />
											</Col>
										</Row>
									</div>
									<CardBody className="pt-0">
										<div>
											<a href="/">
												<div className="avatar-md profile-user-wid mb-4">
													<span className="avatar-title rounded-circle bg-light">
														<img src={window.localStorage.getItem('ajs-theme') === 'dark' ? ajsLight : ajsDark} alt="" className="rounded-circle" height="34" />
													</span>
												</div>
											</a>
										</div>
										<div className="p-2">
											<Form className="form-horizontal" onSubmit={this.pinSubmit}>
												<div className="user-thumb text-center profile-user-wid mb-4">
													<Avatar name={fullname} className="rounded-circle" size={72} />
													<h5 className="font-size-15 mt-3">{fullname}</h5>
												</div>

												<div className="form-group">
													<FormGroup>
														<Label for="password">Pin Kodu</Label>
														<Input
															name="password"
															id="password"
															type="password"
															maxLength="4"
															placeholder="Pin kodunuzu girin."
															onChange={(e) => this.setState({ value: e.target.value.replace(/\D/, '') })}
															value={value}
															className={`${this.state.error.length > 0 ? 'is-invalid' : ''}`}
														/>
														{this.state.error.length > 0 && <div className="invalid-feedback d-block">{this.state.error}</div>}
													</FormGroup>
												</div>

												<div className="form-group row mb-0">
													<Col xs="12" className="text-right">
														<Button color="primary" className=" w-md waves-effect waves-light" type="submit">
															{loading ? <i className="bx bx-loader bx-spin" /> : 'Kilit Aç'}
														</Button>
													</Col>
												</div>
											</Form>
										</div>
									</CardBody>
								</Card>
								<div className="mt-5 text-center">
									<p>
										Sen değil misin?{' '}
										<a href="/login" className="font-weight-medium text-primary">
											{' '}
											Giriş yap{' '}
										</a>{' '}
									</p>
									<p>
										© {new Date().getFullYear()} Ajansspor. Made with <i className="mdi mdi-heart text-danger"></i> by Ajansspor Dev.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default LockScreen;
