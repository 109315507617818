import React from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Button, Modal } from 'reactstrap';
import moment from 'moment';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import SweetAlert from 'react-bootstrap-sweetalert';

import { addLog } from '../../utils/addLog';
import api from '../../api';

import { DELETE_CONTENT_TO_HEADLINE, UPDATE_CONTENT_HEADLINE_ORDER, UPDATE_MAINPAGE_TITLE } from '../../queries/ContentQuery';

moment.locale('tr');

const reorder = (list, startIndex, endIndex) => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);

	return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
	userSelect: 'none',
	padding: grid * 2,
	margin: `0 0 ${grid}px 0`,
	background: isDragging ? 'rgba(0,0,0,0.3)' : 'transparent',
	...draggableStyle,
});

class DraggableInput extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			oldOrder: props.order,
			order: props.order,
			content: props.contentId,
			loading: props.loading,
		};
	}

	changeOrder = (e) => {
		if (e === '' || new RegExp(/^[0-9\b]+$/).test(e)) {
			this.setState({
				order: e !== '' ? parseInt(e, 10) : e,
			});
		}
	};

	cancelReOrder = () => {
		this.setState({
			order: this.state.oldOrder,
		});
	};

	saveReOrder = () => {
		this.setState({
			oldOrder: this.state.order,
		});

		this.props.handleOrder(parseInt(this.state.order, 10), this.state.content);
	};

	render() {
		let { order, oldOrder, loading } = this.state;

		return (
			<div className="headline__order">
				<input type="text" name="order" onChange={(e) => this.changeOrder(e.target.value)} value={order} />
				{order !== oldOrder && order !== '' && (
					<div className="d-flex">
						{loading ? (
							<button className="btn btn-primary">
								<i className="bx bx-loader bx-spin"></i>
							</button>
						) : (
							<>
								<button className="btn btn-primary" onClick={() => this.saveReOrder()}>
									<i className="bx bx-check"></i>
								</button>
								<button className="btn btn-danger ml-2" onClick={() => this.cancelReOrder()}>
									<i className="bx bx-x"></i>
								</button>
							</>
						)}
					</div>
				)}
			</div>
		);
	}
}

class DraggableList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			items: props.data,
			inputLoading: false,
			alertWarning: false,
			alertSuccess: false,
			isAlertOpen: false,
			alertTitle: '',
			deletedHeadline: 0,
			editStatus: false,
			editingContent: 0,
			headlineTitle: '',
			editHeadline: '',
		};
		this.onDragEnd = this.onDragEnd.bind(this);
	}

	toggle = () => {
		this.setState((prevState) => ({
			editStatus: !prevState.editStatus,
			headlineTitle: '',
			editHeadline: '',
			editingContent: 0,
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		const items = reorder(this.state.items, result.source.index, result.destination.index);

		let contentId = parseInt(result.draggableId);
		let destinationIndex = result.destination.index + 1;
		let sourceIndex = result.source.index + 1;

		if (destinationIndex !== sourceIndex) {
			this.setDragList(destinationIndex, contentId, sourceIndex);
		}

		this.setState({
			items,
		});
	}

	changeOrder = (id, content) => {
		id !== '' && this.setDragList(id, content);
	};

	setDragList = async (id, content, order) => {
		try {
			const mutation = await this.props.client.mutate({
				mutation: UPDATE_CONTENT_HEADLINE_ORDER,
				variables: {
					input: {
						ContentId: content,
						Order: id,
					},
				},
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.updateContentHeadlineOrder.message);

				let logData = `Manşet sırası değiştirilen Haber Id'si : <a href="https://admin.ajansspor.com/news/content/${content}" target="_blank" />${content}</a>, yeni sıra: ${id}}, eski sıra: ${order}`;
				addLog(37, logData);
				api.homepageCache();
				setTimeout(() => window.location.reload(), 2000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.updateContentHeadlineOrder.message);
				addLog(34, mutation.data.updateContentHeadlineOrder.message);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});
		}
	};

	deleteHeadline = async () => {
		this.setState({
			alertTitle: '',
			isAlertOpen: false,
			alertWarning: false,
		});

		try {
			const mutation = await this.props.client.mutate({
				mutation: DELETE_CONTENT_TO_HEADLINE,
				variables: {
					input: {
						ContentId: this.state.deletedHeadline,
					},
				},
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.deleteContentToHeadline.message);

				let logData = `Manşetten silinen haber id'si: <a href="https://admin.ajansspor.com/news/content/${this.state.deletedHeadline}" target="_blank" />${this.state.deletedHeadline}</a>.`;
				addLog(38, logData);
				api.homepageCache();
				setTimeout(() => window.location.reload(), 2000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.deleteContentToHeadline.message);
				addLog(34, mutation.data.deleteContentToHeadline.message);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});
		}
	};

	editHeadlineTitle = async () => {
		try {
			const mutation = await this.props.client.mutate({
				mutation: UPDATE_MAINPAGE_TITLE,
				variables: {
					input: {
						ContentId: this.state.editingContent,
						MainPageTitle: this.state.headlineTitle,
					},
				},
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.updateContentMainPageTitle.message);

				let logData = `Manşet başlığı düzenlenen haber id'si: <a href="https://admin.ajansspor.com/news/content/${this.state.editingContent}" target="_blank" />${this.state.editingContent}</a>.`;
				addLog(39, logData);
				setTimeout(() => window.location.reload(), 2000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.updateContentMainPageTitle.message);
				addLog(34, mutation.data.updateContentMainPageTitle.message);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};
			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});
		}
	};

	render() {
		return (
			<>
				{this.state.isAlertOpen && (
					<SweetAlert title="" warning={this.state.alertWarning} success={this.state.alertSuccess} confirmBtnText="Sil" onConfirm={() => this.deleteHeadline()}>
						<div dangerouslySetInnerHTML={{ __html: this.state.alertTitle }}></div>
					</SweetAlert>
				)}
				<div className="headline__list headline__list--bordered">
					<li>
						<div className="headline__title">Başlık</div>
						<div className="d-flex ml-auto w-50 justify-content-between">
							<div className="headline__view-count">Okunma Sayısı</div>
							<div className="headline__order">Sıra</div>
							<div className="headline__dates">Eklenme Tarihi</div>
							<div className="btn__groups">#</div>
						</div>
					</li>
				</div>

				<ul className="headline__list">
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="droppable">
							{(provided) => (
								<div {...provided.droppableProps} ref={provided.innerRef}>
									{this.state.items.map((item, index) => (
										<Draggable key={`item-${item.ContentId}`} draggableId={item.ContentId.toString()} index={index}>
											{(provided, snapshot) => (
												<li
													ref={provided.innerRef}
													{...provided.draggableProps}
													{...provided.dragHandleProps}
													style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
												>
													<div className="headline__title">{item.MainPageTitle ? item.MainPageTitle : item.Title}</div>

													<div className="d-flex ml-auto w-50 justify-content-between align-items-center">
														<div className="headline__view-count">{item.ReadCount}</div>
														<DraggableInput
															order={item.Order}
															contentId={item.ContentId}
															loading={this.state.inputLoading}
															handleOrder={(order, content) => this.changeOrder(order, content, item.Order)}
														/>
														<div className="headline__dates">
															<span>{moment(item.UpdatedDate).format('DD-MM-YYYY HH:mm')}</span>
														</div>
														<div className="btn__groups">
															<div className="btn-group mr-2 mb-2 mb-sm-0">
																<Button
																	type="button"
																	color="secondary"
																	className="waves-light waves-effect"
																	title="Sil"
																	onClick={() =>
																		this.setState({
																			isAlertOpen: true,
																			alertWarning: true,
																			alertTitle: '<span class="text-info">' + item.Title + '</span> başlıklı haberi manşetten silmek istediğinize emin misiniz?',
																			deletedHeadline: item.ContentId,
																		})
																	}
																>
																	<i className="bx bx-trash"></i>
																</Button>
																<Button
																	type="button"
																	color="secondary"
																	className="waves-light waves-effect"
																	title="Düzenle"
																	onClick={() =>
																		this.setState({ editStatus: true, editingContent: item.ContentId, editHeadline: item.Title, headlineTitle: item.MainPageTitle })
																	}
																>
																	<i className="bx bx-edit"></i>
																</Button>
																<a href={`/news/news/${item.ContentId}`} className="btn btn-secondary">
																	<i className="bx bxs-pencil"></i>
																</a>
															</div>
														</div>
													</div>
												</li>
											)}
										</Draggable>
									))}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</ul>

				<Modal isOpen={this.state.editStatus} toggle={this.deleteToggle} centered={true}>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Manşet başlığı düzenleyin.</h5>
						<button type="button" onClick={() => this.setState({ editStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<p>
							<b>{this.state.editHeadline}</b> başlığını düzenliyorsunuz.
						</p>

						<div className="form-group">
							<label htmlFor="name">Manşet başlığı</label>
							<input type="text" value={this.state.headlineTitle} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ headlineTitle: e.target.value })} />
						</div>

						<button className="btn btn-info mt-4" onClick={() => this.editHeadlineTitle()}>
							Düzenle
						</button>
					</div>
				</Modal>
			</>
		);
	}
}

DraggableInput.propTypes = {
	order: PropTypes.number,
	contentId: PropTypes.number,
	handleOrder: PropTypes.func,
	loading: PropTypes.bool,
};

DraggableList.propTypes = {
	data: PropTypes.array,
	client: PropTypes.object,
};

export default withApollo(DraggableList);
