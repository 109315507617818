import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import moment from 'moment';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'moment/locale/tr';
import { withApollo } from 'react-apollo';
import { Container, Row, Col, Card, CardBody, FormGroup, Label, Input } from 'reactstrap';

import Breadcrumbs from '../../components/Common/Breadcrumb';

import { CREATE_CONTENT } from '../../queries/ContentQuery';
import { addLog } from '../../utils/addLog';

moment.locale('tr');

class QuickContent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			Title: '',
			Spot: '',
			loading: false,
			PostToTwitter: false,
		};
	}

	createContent = async () => {
		this.setState({
			loading: true,
		});

		if (this.state.Title.trim() === '') {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			toastr.error('Haber Başlığı Boş Olamaz');
			this.setState({
				loading: false,
			});
			return;
		}

		if (this.state.Spot.trim() === '') {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			toastr.error('Spot Boş Olamaz');

			this.setState({
				loading: false,
			});
			return;
		}

		try {
			const mutation = await this.props.client.mutate({
				mutation: CREATE_CONTENT,
				variables: {
					input: {
						Title: this.state.Title,
						// GoogleTitle: this.state.GoogleTitle && this.state.GoogleTitle,
						// GoogleSpot: this.state.GoogleSpot && this.state.GoogleSpot,
						// HeadlineTitle: this.state.HeadlineTitle && this.state.HeadlineTitle,
						SocialMediaTitle: this.state.Title,
						MainPageTitle: this.state.Title,
						SeoTitle: this.state.Title,
						Spot: this.state.Spot,
						IsHeadline: false,
						IsMainPage: false,
						SpotMediaId: 1131980,
						PostToTwitter: this.state.PostToTwitter,
						PublishDate: new Date(),
						PublishEndDate: moment(new Date().setFullYear(new Date().getFullYear() + 10)).toDate(),
					},
				},
			});

			if (mutation) {
				this.setState({
					loading: false,
				});

				if (mutation.data.createContent.success) {
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(mutation.data.createContent.message);

					let contentId = mutation.data.createContent.content.ContentId;
					let logData = `Eklenen Haber Id'si : <a href="https://admin.ajansspor.com/news/content/${contentId}" target="_blank" />${contentId}</a>, Tweet ${
						this.state.PostToTwitter ? 'Gönderildi' : 'Gönderilmedi'
					}`;
					addLog(46, logData);
					setTimeout(() => (window.location.href = `${window.location.origin}/news/content/${contentId}?s=1`), 5000);
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error(mutation.data.createContent.message);
					addLog(47, mutation.data.createContent.message);
				}
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(47, message);
			});
		}
	};

	render() {
		return (
			<>
				<Helmet>
					<title> {`Hızlı Haber Ekle - Haber Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>

				<div className="page-content form-padding">
					<Container fluid>
						<Breadcrumbs title="Haber Yönetimi" breadcrumbItem="Hızlı Haber Ekle" />
						<Row>
							<Col lg="12">
								<Card>
									<CardBody>
										<FormGroup>
											<Label for="title">Haber Başlığı</Label>
											<Input
												type="text"
												onChange={(e) =>
													this.setState({
														Title: e.target.value,
													})
												}
												value={this.state.Title}
											/>
											{/* {errors.Spot && <div className="invalid-feedback d-block">{errors.Spot}</div>} */}
										</FormGroup>
										<FormGroup>
											<Label for="title">Spot</Label>
											<textarea
												className="form-control social__control"
												id="spot"
												name="spot"
												onChange={(e) =>
													this.setState({
														Spot: e.target.value,
													})
												}
												value={this.state.Spot}
											/>
											{/* {errors.Spot && <div className="invalid-feedback d-block">{errors.Spot}</div>} */}
										</FormGroup>
										<Col lg="12" className="d-flex flex-row-reverse">
											<button type="submit" className="btn btn-primary" onClick={() => this.createContent()}>
												{this.state.loading ? <i className="bx bx-loader bx-spin"></i> : 'Haberi Ekle'}
											</button>

											<div className="custom-control custom-checkbox mr-2 mt-2">
												<input
													type="checkbox"
													className="custom-control-input"
													id="is-status"
													name="PostToTwitter"
													onChange={() => this.setState({ PostToTwitter: !this.state.PostToTwitter })}
													checked={this.state.PostToTwitter}
												/>
												<label className="custom-control-label" htmlFor="is-status">
													<span className="text-danger">@ajansspor</span> Twitter&apos;da Paylaş
												</label>
											</div>
										</Col>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

QuickContent.propTypes = {
	client: PropTypes.object,
};

export default withApollo(QuickContent);
