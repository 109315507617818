import React from 'react';
import Helmet from 'react-helmet';
import Dropzone from 'react-dropzone';
let Vimeo = require('vimeo').Vimeo;
let client = new Vimeo('66dfbb055054073dfa404c2cf5b24515a19a8f5d','ColfXqFzLOE27OiX6/3nGnmDFQmplm4kLCZnkPQ9GtCHsOVu34Ot15Gv/KzX9VadIFY6KM0IFJaMgfuNX3IzP3o0eY6w6eRBxAa9gMVcUtaY/mat2AYP6vYk4ewXeM+i','c0d2df20e07466d1cd2812e0afb62632');
import { 
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Input
} from 'reactstrap';
import Breadcrumbs from '../../components/Common/Breadcrumb';

class Upload extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            src: '',
            file: []
        };
    }

    componentDidMount(){
    }

    onChange = (e) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
          files = e.dataTransfer.files;
        } else if (e.target) {
          files = e.target.files;
        }
        this.setState({file: files[0]});
        // const reader = new FileReader();
        // reader.onload = () => {
        //   this.setState({ src: reader.result });
        //   console.log(reader.result);
        // };
        // reader.readAsDataURL(files[0]);
    }

    upload = () => {
        client.request({
            method: 'GET',
            path: '/tutorial'
          }, function (error, body, status_code, headers) {
            if (error) {
              console.log(error);
            }
          
            console.log(body, status_code, headers);
          });
        let file_name = this.state.file;
            client.upload(
            file_name,
            {
                'name': 'Untitled',
                'description': 'The description goes here.'
            },
            function (uri) {
                console.log('Your video URI is: ' + uri);
            },
            function (bytes_uploaded, bytes_total) {
                let percentage = (bytes_uploaded / bytes_total * 100).toFixed(2);
                console.log(bytes_uploaded, bytes_total, percentage + '%');
            },
            function (error) {
                console.log('Failed because: ' + error);
            }
        );
    }
    onDrop = (file) => {
        this.setState({file});
      };

    render(){
        return(
            <>
                <Helmet>
                    <title>{`Video Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
                </Helmet>
                <div className='page-content'>
                    <Container fluid>
                        <Breadcrumbs title='Ajansspor Videolar' breadcrumbItem='Videolar' />
                        <Row>
                            <Col className='col-12'>
                                <Card>
                                    <CardBody>
                                        <div className='d-flex justify-content-between'>
                                            <div>
                                                <CardTitle>Video Yükleme</CardTitle>
                                                <CardSubtitle>Video yüklemek için bütün alanları doldurunuz.</CardSubtitle>
                                            </div>
                                        </div>
                                        <Row className='mt-4'>
                                            <Col className='col-12 mt-2'>
                                                <label>Video Başlığı: </label>
                                                <input className="form-control" type="text" placeholder="Video Başlığı" />
                                            </Col>
                                            <Col className='col-12 mt-3'>
                                                <label>Video Açıklaması: </label>
                                                <Input
                                                    type="textarea"
                                                    id="textarea"
                                                    //onChange={this.textareachange}
                                                    rows="3"
                                                    placeholder="Video Açıklaması"
                                                />
                                            </Col>
                                            <Col className='col-12 mt-3'>
                                                <Dropzone onDrop={this.onDrop}>
                                                    {({getRootProps, getInputProps}) => (
                                                    <section className="container">
                                                        <div {...getRootProps({className: 'dropzone'})}>
                                                            <input {...getInputProps()} />
                                                            <p>Dosya sürükleyin veya tıklayarak seçiniz.</p>
                                                            <p>Video seçtikten sonra yükleme işlemi otomatik başlayacaktır.</p>
                                                        </div>
                                                    </section>
                                                    )}
                                                </Dropzone> 
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                                
                                <input type='file' id='inputImage' name='file' accept='video/*' onChange={this.onChange}/>
                                <button type='button' onClick={() => this.upload()}></button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );
    }
}

export default Upload;