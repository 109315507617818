import React from 'react';
import PropTypes from 'prop-types';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardTitle, CardBody, Alert, Modal, Label } from 'reactstrap';
import { withApollo, Query } from 'react-apollo';
import Select from 'react-select';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { GET_USERS, CREATE_USER } from '../../queries/UserQuery';

import toastr from 'toastr';

const categories = [
	{ label: 'Ajansspor', value: 1 },
	{ label: 'Editör', value: 101 },
	{ label: 'Yazar', value: 102 },
	{ label: 'Analist', value: 103 },
	{ label: 'Reklam', value: 104 },
];

class Members extends React.Component {
	state = {
		members: [],
		newMembersModal: false,
		name: '',
		email: '',
		password: '',
		error: '',
		editUserModal: false,
		gender: '',
		status: '',
		userName: '',
		birthdate: '',
		role: '',
		roles: [],
		favoriteTeams: '',
		favoriteTournament: '',
		favoritePlayers: '',
		loginType: '',
		kvkk: false,
		contract: false,
		advertisement: false,
		NormalLogin: false,
		FacebookLogin: false,
		TwitterLogin: false,
		genders: [
			{ label: 'Kadın', value: 0 },
			{ label: 'Erkek', value: 1 },
		],
		selectedGender: {},

		statuses: [
			{ label: 'Aktif', value: 1 },
			{ label: 'Pasif', value: 0 },
		],
		selectedStatus: {},
		selectedCategory: [],
		selectedRoles: [],
	};

	deleteToggle = () => {
		this.setState((prevState) => ({
			deleteStatus: !prevState.deleteStatus,
			name: '',
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	membersModalToggle = () => {
		this.setState((prevState) => ({
			newMembersModal: !prevState.newMembersModal,
		}));
	};

	editUserModalToggle = () => {
		this.setState((prevState) => ({
			editUserModal: !prevState.editUserModal,
		}));
	};

	editMember = () => {};

	onChange = () => {};

	createUser = async (e) => {
		e.preventDefault();

		try {
			const { email, name, userName, password, role, roles } = this.state;

			const mutation = await this.props.client.mutate({
				mutation: CREATE_USER,
				variables: {
					input: {
						Email: email,
						Fullname: name,
						Username: userName,
						Password: password,
						Role: role,
						Roles: roles,
					},
				},
				refetchQueries: GET_USERS,
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.createUser.message);
				setTimeout(() => this.setState({ newMembersModal: false, modalType: 1, name: '', userName: '', password: '', username: '', email: '', role: '', roles: [] }), 5000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(mutation.data.createUser.message);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
			});
		}
	};

	selectGender = (e) => {
		this.setState({ selectedGender: e, gender: e.value });
	};

	selectStatus = (e) => {
		this.setState({ selectedStatus: e, status: e.value });
	};

	selectCategory = (e) => {
		if (Array.isArray(e)) {
			let roles = [];

			e.forEach(function (role) {
				roles.push(role.value.toString());
			});

			this.setState({ selectedCategory: e });
			this.setState({ roles });
		}
	};

	render() {
		return (
			<>
				<Helmet>
					<title>{`Üye Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor Web" breadcrumbItem="Üyeler" />

						<Row>
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Üye </CardTitle>
											</div>

											<button className="btn btn-primary" onClick={this.membersModalToggle}>
												Yeni Üye Ekle
											</button>
										</div>

										<Query query={GET_USERS}>
											{({ loading, data }) => {
												if (loading) return null;

												let tableData = [];

												data.users.map((item) => {
													tableData.push({
														...item,
														setting: (
															<span>
																<button
																	className="btn btn-secondary"
																	onClick={() => {
																		this.setState({
																			editUserModal: true,
																			modalType: 2,
																			name: item.Fullname,
																			userName: item.Username,
																			email: item.Email,
																			birthdate: item.Birthdate,
																			gender: item.Gender,
																			status: item.Status,
																			role: item.Role,
																			roles: item.Roles,
																			selectedCategory: item.Roles,
																			kvkk: item.KVKK,
																			contract: item.Contract,
																			advertisement: item.Advertisement,
																			NormalLogin: item.NormalLogin,
																			FacebookLogin: item.FacebookLogin,
																			TwitterLogin: item.TwitterLogin,
																		});

																		console.log(item.Roles);

																		this.state.genders.map((gender) => {
																			if (gender.value === item.Gender) {
																				this.setState({ selectedGender: gender });
																			}
																		});

																		this.state.statuses.map((status) => {
																			if (status.value === item.Status) {
																				this.setState({ selectedStatus: status });
																			}
																		});

																		let arr = [];

																		item.Roles.map((role) => {
																			arr.push({
																				value: parseInt(role, 10),
																				label:
																					role === '1'
																						? 'Ajansspor'
																						: role === '101'
																						? 'Editör'
																						: role === '102'
																						? 'Yazar'
																						: role === '103'
																						? 'Analist'
																						: role === '104'
																						? 'Reklam'
																						: role === '999'
																						? 'Süper Admin'
																						: '',
																			});
																		});

																		this.setState({ selectedCategory: arr });

																		console.log(arr);
																	}}
																>
																	Düzenle
																</button>
															</span>
														),
													});
												});

												const users = {
													columns: [
														{
															label: 'Kullanıcı Adı',
															field: 'Username',
															sort: 'asc',
															width: '90%',
														},
														{
															label: 'Adı',
															field: 'Fullname',
															sort: 'asc',
															width: '90%',
														},
														{
															label: 'Email',
															field: 'Email',
															sort: 'asc',
															width: '90%',
														},
														{
															label: '#',
															field: 'setting',
															sort: 'asc',
															width: '10%',
														},
													],
													rows: tableData,
												};

												return (
													<div className="comment-table">
														<MDBDataTable
															responsive
															striped
															bordered
															data={users}
															noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
															paginationLabel={['Önceki', 'Sonraki']}
															entriesLabel="Göster"
															infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
															searchLabel="Ara"
														/>
													</div>
												);
											}}
										</Query>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					<Modal isOpen={this.state.newMembersModal} toggle={this.membersModalToggle} centered={true} size={'lg'}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Üye Ekle</h5>
							<button type="button" onClick={() => this.setState({ newMembersModal: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<form method="post" onSubmit={this.createUser}>
								<div className="form-group">
									<label htmlFor="name">Kullanıcı adı</label>
									<input type="text" value={this.state.userName} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ userName: e.target.value })} />
								</div>

								<div className="form-group">
									<label htmlFor="name">Eposta adresi</label>
									<input type="text" value={this.state.email} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ email: e.target.value })} />
								</div>

								<div className="form-group">
									<label htmlFor="name">Adı</label>
									<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ name: e.target.value })} />
								</div>

								<div className="form-group">
									<label htmlFor="name">Şifre</label>
									<input type="text" value={this.state.password} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ password: e.target.value })} />
								</div>

								<div className="form-group">
									<label htmlFor="name">Rol</label>
									<input type="text" value={this.state.role} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ role: e.target.value })} />
								</div>

								{this.state.role > 1 && (
									<div className="form-group select2-container">
										<label htmlFor="name">Rol Grubu</label>
										<Select
											value={this.state.selectedCategory}
											isMulti={true}
											onChange={this.selectCategory}
											options={categories}
											classNamePrefix="select2-selection"
											placeholder="Rol(ler) seçiniz"
										/>
									</div>
								)}

								<div className="form-group">
									<Label className="btn btn-primary btn-upload import" htmlFor="inputImage" title="Fotoğraf seçin.">
										<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => this.onChange(e)} />
										<span className="docs-tooltip" id="uploadImage">
											<span className="mdi mdi-upload"></span>
										</span>
										<h5 className="ml-1 mt-2">Fotoğraf Seçin</h5>
									</Label>

									<small>Zorun değil. Şu an gözükmez. Yüklemenize gerek yok.</small>
								</div>

								<button className="btn btn-info" disabled={this.state.name.length <= 0}>
									Kaydet
								</button>
							</form>
						</div>
					</Modal>

					<Modal isOpen={this.state.editUserModal} toggle={this.editUserModalToggle} centered={true} size={'lg'}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Üye Düzenleme</h5>
							<button
								type="button"
								onClick={() => this.setState({ editUserModal: false, name: '', userName: '', email: '', birthdate: '', gender: '', status: '', role: '' })}
								className="close"
								data-dismiss="modal"
								aria-label="Kapat"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<form method="post" onSubmit={this.editMember}>
								<div className="form-group">
									<label htmlFor="name">Kullanıcı adı</label>
									<input type="text" value={this.state.userName} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ userName: e.target.value })} />
								</div>
								<div className="form-group">
									<label htmlFor="name">Adı</label>
									<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ name: e.target.value })} />
								</div>
								<div className="form-group">
									<label htmlFor="name">Doğum Tarihi</label>
									<input type="text" value={this.state.birthdate} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ birthdate: e.target.value })} />
								</div>
								<div className="form-group">
									<label htmlFor="name">Rolü</label>
									<input type="text" value={this.state.role} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ role: e.target.value })} />
								</div>
								{this.state.role > 1 && (
									<div className="form-group select2-container">
										<label htmlFor="name">Rol Grubu</label>
										<Select
											value={this.state.selectedCategory}
											isMulti={true}
											onChange={this.selectCategory}
											options={categories}
											classNamePrefix="select2-selection"
											placeholder="Rol(ler) seçiniz"
										/>
									</div>
								)}
								<div className="form-group">
									<label htmlFor="name">Durumu</label>
									<Select
										value={this.state.selectedStatus}
										isMulti={false}
										onChange={this.selectStatus}
										options={this.state.statuses}
										classNamePrefix="select2-selection"
										placeholder="Durum Seçiniz."
										className="mb-2"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="name">Cinsiyeti</label>
									<Select
										value={this.state.selectedGender}
										isMulti={false}
										onChange={this.selectGender}
										options={this.state.genders}
										classNamePrefix="select2-selection"
										placeholder="Cinsiyet Seçiniz."
										className="mb-2"
									/>
								</div>
								<div className="form-group">
									<label htmlFor="name">Favori Takımları</label>
									<input
										type="text"
										value={this.state.favoriteTeams}
										className="form-control"
										placeholder="Giriniz"
										disabled={true}
										onChange={(e) => this.setState({ favoriteTeams: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="name">Futbol Turnuvaları</label>
									<input
										type="text"
										value={this.state.favoriteTournament}
										className="form-control"
										placeholder="Giriniz"
										onChange={(e) => this.setState({ favoriteTournament: e.target.value })}
									/>
								</div>
								<div className="form-group">
									<label htmlFor="name">Favori Oyuncuları</label>
									<input
										type="text"
										value={this.state.favoritePlayers}
										className="form-control"
										placeholder="Giriniz"
										onChange={(e) => this.setState({ favoritePlayers: e.target.value })}
									/>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="kvkk"
										name="kvkk"
										onChange={() => this.setState({ kvkk: !this.state.kvkk })}
										value={this.state.kvkk}
										checked={this.state.kvkk}
									/>
									<label className="custom-control-label" htmlFor="kvkk">
										KVKK
									</label>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="contract"
										name="contract"
										onChange={() => this.setState({ contract: !this.state.contract })}
										value={this.state.contract}
										checked={this.state.contract}
									/>
									<label className="custom-control-label" htmlFor="contract">
										İletişim
									</label>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="advertisement"
										name="advertisement"
										onChange={() => this.setState({ advertisement: !this.state.advertisement })}
										value={this.state.advertisement}
										checked={this.state.advertisement}
									/>
									<label className="custom-control-label" htmlFor="advertisement">
										Reklam
									</label>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="NormalLogin"
										name="NormalLogin"
										value={this.state.NormalLogin}
										checked={this.state.NormalLogin}
										disabled={true}
									/>
									<label className="custom-control-label" htmlFor="NormalLogin">
										Normal Login
									</label>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="FacebookLogin"
										name="FacebookLogin"
										value={this.state.FacebookLogin}
										checked={this.state.FacebookLogin}
										disabled={true}
									/>
									<label className="custom-control-label" htmlFor="FacebookLogin">
										Facebook Login
									</label>
								</div>

								<div className="custom-control custom-checkbox mt-2">
									<input
										type="checkbox"
										className="custom-control-input"
										id="TwitterLogin"
										name="TwitterLogin"
										value={this.state.TwitterLogin}
										checked={this.state.TwitterLogin}
										disabled={true}
									/>
									<label className="custom-control-label" htmlFor="TwitterLogin">
										Twitter Login
									</label>
								</div>

								<button className="btn btn-info mt-2" disabled={this.state.name.length <= 0}>
									Kaydet
								</button>
							</form>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

Members.propTypes = {
	client: PropTypes.object,
};

export default withApollo(Members);
