import axios from 'axios';

const cmsUrl = 'https://api.ajansspor.com';
const serviceUrl = 'https://api.ajansspor.com';
//const serviceUrl = 'https://service.ajansspor.com';

const slowtime = 'https://saranapi.ajansspor.com/slowtime/api/front';
const radyospor = process.env.REACT_APP_RS_API;
//const radyospor = 'http://localhost:6100';
const rsupload = process.env.REACT_APP_RS_UPLOAD;

const api = process.env.REACT_APP_IO_API;
const video = process.env.REACT_APP_VIDEO_API;
const sports = 'https://ajsapicache.mediatriple.net';

export default {
	user: {
		login: (Username, Password) =>
			axios
				.post(`${serviceUrl}/api/user/login`, { Username, Password })
				.then((res) => res.data)
				.catch((err) => err),

		refreshProfile: () =>
			axios
				.post(`${cmsUrl}/api/user/JWT`, {})
				.then((res) => res.data)
				.catch((err) => err),
	},
	web: {
		getComments: (query) =>
			axios
				.get(`${cmsUrl}/api/comment/getAll${query}`)
				.then((res) => res.data)
				.catch((err) => err),
		changeStatusComment: (id, set) =>
			axios
				.post(`${cmsUrl}/api/comment/changeStatus`, { id, set })
				.then((res) => res.data)
				.catch((err) => err),
		getContacts: (query) =>
			axios
				.get(`${cmsUrl}/api/contact/all${query}`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	content: {
		search: (term) =>
			axios
				.get(`${api}/search?text=${term}`)
				.then((res) => res.data)
				.catch((err) => err),
		headline: () =>
			axios
				.get(`${api}/headline`)
				.then((res) => res.data)
				.catch((err) => err),

		get: (id) =>
			axios
				.get(`${api}/content/${id}?removeCache=1`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	tv: {
		addBranch: (name) =>
			axios
				.post(`${serviceUrl}/tv/branches/add`, { name })
				.then((res) => res.data)
				.catch((err) => err),

		getAllBranches: () =>
			axios
				.get(`${serviceUrl}/tv/branches/getAll`)
				.then((res) => res.data)
				.catch((err) => err),

		updateBranch: (id, name) =>
			axios
				.post(`${serviceUrl}/tv/branches/update`, { id, name })
				.then((res) => res.data)
				.catch((err) => err),

		deleteBranch: (id) =>
			axios
				.post(`${serviceUrl}/tv/branches/delete`, { id })
				.then((res) => res.data)
				.catch((err) => err),
		addLeague: (name, branchId, broadageId) =>
			axios
				.post(`${serviceUrl}/tv/tournaments/add`, { name, branchId, broadageId })
				.then((res) => res.data)
				.catch((err) => err),
		getAllLeagues: () =>
			axios
				.get(`${serviceUrl}/tv/tournaments/getAll`)
				.then((res) => res.data)
				.catch((err) => err),
		updateLeague: (id, name, branchId) =>
			axios
				.post(`${serviceUrl}/tv/tournaments/update`, { id, name, branchId })
				.then((res) => res.data)
				.catch((err) => err),
		deleteLeague: (id) =>
			axios
				.post(`${serviceUrl}/tv/tournaments/delete`, { id })
				.then((res) => res.data)
				.catch((err) => err),
		getAllVideos: (query) =>
			axios
				.get(`${serviceUrl}/tv/videos/get${query}`)
				.then((res) => res.data)
				.catch((err) => err),
		updateMatchId: (videoId, matchId) =>
			axios
				.post(`${serviceUrl}/tv/videos/updateMatchId`, { videoId, matchId })
				.then((res) => res.data)
				.catch((err) => err),
		updateVideo: (videoId, matchId, categoryId) =>
			axios
				.post(`${serviceUrl}/tv/videos/updateVideo`, { videoId, matchId, categoryId })
				.then((res) => res.data)
				.catch((err) => err),
		searchLeague: (name) =>
			axios
				.get(`${cmsUrl}/tv/tournaments/search?name=${name}`)
				.then((res) => res.data)
				.catch((err) => err),

		getAllBestOfTheWeek: () =>
			axios
				.get(`${serviceUrl}/tv/videos/getBestOfTheWeek`)
				.then((res) => res.data)
				.catch((err) => err),
		updateBestOfTheWeek: (videoId, index) =>
			axios
				.post(`${serviceUrl}/tv/videos/setBestOfTheWeek`, { set: '2', videoId, index })
				.then((res) => res.data)
				.catch((err) => err),
		addToBestOfTheWeek: (videoId, title, index = -1) =>
			axios
				.post(`${serviceUrl}/tv/videos/setBestOfTheWeek`, { set: '1', videoId, title, index })
				.then((res) => res.data)
				.catch((err) => err),
		deleteToBestOfTheWeek: (videoId) =>
			axios
				.post(`${serviceUrl}/tv/videos/setBestOfTheWeek`, { set: '0', videoId })
				.then((res) => res.data)
				.catch((err) => err),
		changeBanner: (data) =>
			axios
				.post(`${serviceUrl}/tv/videos/setBanner`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => res.data)
				.catch((err) => err),
	},
	image: {
		getImages: (query) =>
			axios
				.get(`${serviceUrl}/api/image/all${query}`)
				.then((res) => res.data)
				.catch((err) => err),
		search: (text) =>
			axios
				.get(`${serviceUrl}/api/image/search?text=${text}`)
				.then((res) => res.data)
				.catch((err) => err),
		getMedia: (limit, skip) =>
			axios
				.get(`${serviceUrl}/api/media/all?limit=${limit}&skip=${skip}`)
				.then((res) => res.data)
				.catch((err) => err),
		getMediaSearch: (param, limit, skip) =>
			axios
				.get(`${serviceUrl}/api/media/search?text=${param}&limit=${limit}&skip=${skip}`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	cache: (id) =>
		axios
			.get(`${serviceUrl}/api/news/updateRedis/${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	contentCache: (id) =>
		axios
			.get(`${api}/content/${id}?removeCache=1`)
			.then((res) => res.data)
			.catch((err) => err),
	contentEditor: (id) =>
		axios
			.get(`${cmsUrl}/content-editor?id=${id}`)
			.then((res) => res.data)
			.catch((err) => err),
	sendPush: (data) => {
		return axios
			.post(`${cmsUrl}/api/notification/bulk`, data)
			.then((res) => res.data)
			.catch((err) => err);
	},
	homepageCache: () => {
		return axios.get('https://ajansspor.xyz').then((res) => {
			if (res.status === 200) {
				axios
					.get(`${serviceUrl}/api/news/clearHomePagePurge`)
					.then((res) => res.data)
					.catch((err) => err);
			}
		});
	},
	getHomepage: (link) => {
		return axios
			.get(link)
			.then((res) => res)
			.catch((err) => err);
	},
	getSiteStatus: () =>
		axios
			.get('http://84.16.241.97:3000')
			.then((res) => res)
			.catch((err) => err),
	logs: {
		getLogTypes: () =>
			axios
				.get(`${serviceUrl}/api/logTypes/get`)
				.then((res) => res.data)
				.catch((err) => err),
		addLogType: (name) =>
			axios
				.post(`${serviceUrl}/api/logTypes/add`, { name })
				.then((res) => res.data)
				.catch((err) => err),
		getLogs: (data) =>
			axios
				.get(`${serviceUrl}/api/logs/get${data}`)
				.then((res) => res.data)
				.catch((err) => err),

		addLog: (data) =>
			axios
				.post(`${serviceUrl}/api/logs/add`, { data })
				.then((res) => res.data)
				.catch((err) => err),

		getEditorNewsLogs: (data) =>
			axios
				.get(`${serviceUrl}/api/editorNewsLogs/get${data}`)
				.then((res) => res.data)
				.catch((err) => err),
		addEditorNewsLogs: (data) =>
			axios
				.post(`${api}/logs/addEditorNews`, data)
				.then((res) => res.data)
				.catch((err) => err),
	},
	contentImageUpload: (data) => {
		return axios
			.post('https://upload.ajansspor.com/image/upload', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((res) => res.data)
			.catch((err) => err);
	},
	tags: {
		search: (params) =>
			axios
				.get(`${serviceUrl}/api/tags/search/${params}`)
				.then((res) => res.data)
				.catch((err) => err),
		searchV2: (name) =>
			axios
				.get(`${serviceUrl}/api/tags/searchV2/${name}`)
				.then((res) => res.data)
				.catch((err) => err),
		combine: (data) =>
			axios
				.post(`${serviceUrl}/api/tags/combine`, data)
				.then((res) => res.data)
				.catch((err) => err),
		tournamentCombine: (data) =>
			axios
				.post(`${cmsUrl}/api/tags/tournamentCombine`, data)
				.then((res) => res.data)
				.catch((err) => err),
		broadageTeamSearch: (term) =>
			axios
				.get(`${serviceUrl}/apiv2/teams/search/${term}`)
				.then((res) => res.data)
				.catch((err) => err),
		create: (name) =>
			axios
				.post(`${serviceUrl}/api/tags/add`, {
					name,
				})
				.then((res) => res.data)
				.catch((err) => err),
	},
	status: () =>
		axios
			.get(`${serviceUrl}/status`)
			.then((res) => res.data)
			.catch((err) => err),

	shortLink: (url) =>
		axios
			.post('https://ajsspr.co/create', { url })
			.then((res) => res.data)
			.catch((err) => err),

	soccerData: {
		searchTeam: (term) =>
			axios
				.get(`${cmsUrl}/apiv2/teams/search/${encodeURI(term)}`)
				.then((res) => res.data)
				.catch((err) => err),
		searchPlayer: (term) =>
			axios
				.get(`${cmsUrl}/apiv2/players/search/${encodeURI(term)}`)
				.then((res) => res.data)
				.catch((err) => err),
		getPlayerAll: (id) =>
			axios
				.get(`${cmsUrl}/apiv2/players/all/${id}`)
				.then((res) => res.data)
				.catch((err) => err),
		deleteTeam: (id, teamId) =>
			axios
				.post(`${cmsUrl}/apiv2/players/delete`, { id, teamId })
				.then((res) => res.data)
				.catch((err) => err),
		customSquadAdd: (playerId, teamId) =>
			axios
				.post(`${cmsUrl}/apiv2/soccer/tools/add`, { playerId, teamId })
				.then((res) => res.data)
				.catch((err) => err),
		customSquadDelete: (playerId, teamId) =>
			axios
				.post(`${cmsUrl}/apiv2/soccer/tools/delete`, { playerId, teamId })
				.then((res) => res.data)
				.catch((err) => err),
		customSquadFromTeam: (teamId) =>
			axios
				.get(`${cmsUrl}/apiv2/soccer/tools/team/${teamId}`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	stats: {
		lastMonth: () =>
			axios
				.get(`${serviceUrl}/api/analytics/lastMonthUser`)
				.then((res) => res.data)
				.catch((err) => err),
		liveVisitor: () =>
			axios
				.get(`${serviceUrl}/api/analytics/realtime`)
				.then((res) => res.data)
				.catch((err) => err),
		devices: () =>
			axios
				.get(`${serviceUrl}/api/analytics/operatingSystem`)
				.then((res) => res.data)
				.catch((err) => err),
		usertype: () =>
			axios
				.get(`${serviceUrl}/api/analytics/lastMonthUserType`)
				.then((res) => res.data)
				.catch((err) => err),
		appcenter: (id) =>
			axios
				.get(`${serviceUrl}/api/analytics/appcenter?id=${id}`)
				.then((res) => res.data)
				.catch((err) => err),
	},

	slowtime: {
		events: {
			list: () =>
				axios
					.get(`${slowtime}/events/takeEvents`)
					.then((res) => res.data)
					.catch((err) => err),

			create: (data) =>
				axios
					.post(`${slowtime}/events/addEvents`, data)
					.then((res) => res.data)
					.catch((err) => err),
			upload: (data) =>
				axios
					.post(`${slowtime}/image_upload/eventsImageUpload`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			delete: (id) =>
				axios
					.post(`${slowtime}/events/deleteEvent`, id)
					.then((res) => res.data)
					.catch((err) => err),
			get: (id) =>
				axios
					.get(`${slowtime}/events/getUpdateEvent/${id}`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (id, data) =>
				axios
					.post(`${slowtime}/events/setUpdateEvent/${id}`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		news: {
			list: () =>
				axios
					.get(`${slowtime}/news/getNews`)
					.then((res) => res.data)
					.catch((err) => err),
			create: (data) =>
				axios
					.post(`${slowtime}/news/addnews`, data)
					.then((res) => res.data)
					.catch((err) => err),
			upload: (data) =>
				axios
					.post(`${slowtime}/image_upload/newsImageUpload`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			delete: (id) =>
				axios
					.post(`${slowtime}/news/deleteNews`, id)
					.then((res) => res.data)
					.catch((err) => err),
			get: (id) =>
				axios
					.get(`${slowtime}/news/getUpdateNews/${id}`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (id, data) =>
				axios
					.post(`${slowtime}/news/setUpdateNews/${id}`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		albums: {
			list: () =>
				axios
					.get(`${slowtime}/albums/getAlbums`)
					.then((res) => res.data)
					.catch((err) => err),
			create: (data) =>
				axios
					.post(`${slowtime}/albums/addAlbums`, data)
					.then((res) => res.data)
					.catch((err) => err),
			upload: (data) =>
				axios
					.post(`${slowtime}/image_upload/albumsImageUpload`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			delete: (id) =>
				axios
					.post(`${slowtime}/albums/deleteAlbums`, id)
					.then((res) => res.data)
					.catch((err) => err),
			get: (id) =>
				axios
					.get(`${slowtime}/albums/getUpdateAlbum/${id}`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (id, data) =>
				axios
					.post(`${slowtime}/albums/setUpdateAlbums/${id}`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		videos: {
			list: () =>
				axios
					.get(`${slowtime}/video/videos`)
					.then((res) => res.data)
					.catch((err) => err),
			create: (data) =>
				axios
					.post(`${slowtime}/video/addVideo`, data)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${slowtime}/video/updateVideo`, data)
					.then((res) => res.data)
					.catch((err) => err),
			getLast: () =>
				axios
					.get(`${slowtime}/video/getVideo`)
					.then((res) => res.data)
					.catch((err) => err),
		},
	},
	radyospor: {
		frequencies: {
			add: (data) =>
				axios
					.post(`${radyospor}/frequency/add`, data)
					.then((res) => res.data)
					.catch((err) => err),
			list: () =>
				axios
					.get(`${radyospor}/frequency/all`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${radyospor}/frequency/update`, data)
					.then((res) => res.data)
					.catch((err) => err),

			delete: (data) =>
				axios
					.post(`${radyospor}/frequency/delete`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		programs: {
			add: (data) =>
				axios
					.post(`${radyospor}/program/add`, data)
					.then((res) => res.data)
					.catch((err) => err),

			addImage: (data) =>
				axios
					.post(`${rsupload}/program-add`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			updateImage: (data) =>
				axios
					.post(`${rsupload}/program-update`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			list: () =>
				axios
					.get(`${radyospor}/program/all`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${radyospor}/program/update`, data)
					.then((res) => res.data)
					.catch((err) => err),

			delete: (data) =>
				axios
					.post(`${radyospor}/program/delete`, data)
					.then((res) => res.data)
					.catch((err) => err),

			privateAll: () =>
				axios
					.get(`${radyospor}/program/all/special`)
					.then((res) => res.data)
					.catch((err) => err),

			privateAdd: (data) =>
				axios
					.post(`${radyospor}/program/add/special`, data)
					.then((res) => res.data)
					.catch((err) => err),

			privateUpdate: (data) =>
				axios
					.post(`${radyospor}/program/update/special`, data)
					.then((res) => res.data)
					.catch((err) => err),

			privateDelete: (data) =>
				axios
					.post(`${radyospor}/program/delete/special`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		broadcaster: {
			add: (data) =>
				axios
					.post(`${radyospor}/broadcaster/add`, data)
					.then((res) => res.data)
					.catch((err) => err),
			all: () =>
				axios
					.get(`${radyospor}/broadcaster/all`)
					.then((res) => res.data)
					.catch((err) => err),

			list: () =>
				axios
					.get(`${radyospor}/broadcaster/list`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${radyospor}/broadcaster/update`, data)
					.then((res) => res.data)
					.catch((err) => err),

			delete: (data) =>
				axios
					.post(`${radyospor}/broadcaster/delete`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		streaming: {
			add: (data) =>
				axios
					.post(`${radyospor}/streaming/add`, data)
					.then((res) => res.data)
					.catch((err) => err),
			list: () =>
				axios
					.get(`${radyospor}/streaming/all`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${radyospor}/streaming/update`, data)
					.then((res) => res.data)
					.catch((err) => err),

			delete: (data) =>
				axios
					.post(`${radyospor}/streaming/delete`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		message: {
			list: () =>
				axios
					.get(`${radyospor}/message/all`)
					.then((res) => res.data)
					.catch((err) => err),
		},
		block: {
			add: (data) =>
				axios
					.post(`${radyospor}/block/add`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
		podcasts: {
			upload: (data) =>
				axios
					.post(`${rsupload}/add`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),

			specialUpload: (data) =>
				axios
					.post(`${rsupload}/special-add`, data, {
						headers: {
							'Content-Type': 'multipart/form-data',
						},
					})
					.then((res) => res.data)
					.catch((err) => err),
			list: () =>
				axios
					.get(`${radyospor}/podcast/all`)
					.then((res) => res.data)
					.catch((err) => err),

			private: () =>
				axios
					.get(`${radyospor}/podcast/private`)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${rsupload}/update`, data)
					.then((res) => res.data)
					.catch((err) => err),
			delete: (id) =>
				axios
					.post(`${rsupload}/delete`, id)
					.then((res) => res.data)
					.catch((err) => err),

			specialUpdate: (data) =>
				axios
					.post(`${rsupload}/special-update`, data)
					.then((res) => res.data)
					.catch((err) => err),
			specialDelete: (id) =>
				axios
					.post(`${rsupload}/special-delete`, id)
					.then((res) => res.data)
					.catch((err) => err),
		},
		contacts: () =>
			axios
				.get(`${radyospor}/contact/all`)
				.then((res) => res.data)
				.catch((err) => err),
		settings: {
			add: (data) =>
				axios
					.post(`${radyospor}/settings/add`, data)
					.then((res) => res.data)
					.catch((err) => err),
			update: (data) =>
				axios
					.post(`${radyospor}/settings/update`, data)
					.then((res) => res.data)
					.catch((err) => err),
			all: (data) =>
				axios
					.get(`${radyospor}/settings/all`, data)
					.then((res) => res.data)
					.catch((err) => err),
		},
	},
	domain: () =>
		axios
			.get('https://api.ajansspor.com/apiv2/standing/soccer/full?tournamentId=83')
			.then((res) => res.data)
			.catch((err) => err),
	redis: {
		setMedia: (id) =>
			axios
				.get(`${api}/redis/media/${id}`)
				.then((res) => res.data)
				.catch((err) => err),
		getMedia: () =>
			axios
				.get(`${api}/redis/media/get`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	video: {
		all: () =>
			axios
				.get(`${video}/videos?secure=asdd`)
				.then((res) => res.data)
				.catch((err) => err),
		get: (id) =>
			axios
				.get(`${video}/video/${id}`)
				.then((res) => res.data)
				.catch((err) => err),
		upload: (data) =>
			axios
				.post(`${video}/video`, data, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((res) => res.data)
				.catch((err) => err),
		socket: () => `${video}/socket/`,
		update: (data) =>
			axios
				.post(`${video}/video/update`, data)
				.then((res) => res.data)
				.catch((err) => err),
		deleted: (data) =>
			axios
				.post(`${video}/video/deleted`, data)
				.then((res) => res.data)
				.catch((err) => err),
		league: () =>
			axios
				.get('https://sports.ajansspor.com/soccer/league')
				.then((res) => res.data)
				.catch((err) => err),
		team: (id) =>
			axios
				.get('https://sports.ajansspor.com/soccer/league/' + id + '/team/2022')
				.then((res) => res.data)
				.catch((err) => err),
		player: (id) =>
			axios
				.get('https://sports.ajansspor.com/soccer/teamplayer/' + id)
				.then((res) => res.data)
				.catch((err) => err),
		matches: (id) =>
			axios
				.get(`https://sports.ajansspor.com/soccer/fixture/team/${id}/2022?limit=50`)
				.then((res) => res.data)
				.catch((err) => err),
	},
	soccer: {
		leagues: () =>
			axios
				.get(`${sports}/soccer/league`)
				.then((res) => res.data)
				.catch((err) => err),
	},
};
