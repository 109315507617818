import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { Row, Col, Card, CardBody } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

import profileImg from '../../assets/images/profile-img.png';
import { getTokenDecoded } from '../../utils/auth';

class WelcomeComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: getTokenDecoded(localStorage.getItem('ajstoken')).Fullname,
			lastSeen: localStorage.lastSeen ? moment(localStorage.lastSeen).fromNow() : null,
		};
	}

	render() {
		return (
			<React.Fragment>
				<Card className="overflow-hidden">
					<div className="bg-soft-primary">
						<Row>
							<Col xs="7">
								<div className="text-primary p-3">
									<h5 className="text-primary">Merhaba!</h5>
									{this.state.lastSeen && <p>Son giriş: {this.state.lastSeen}</p>}
								</div>
							</Col>
							<Col xs="5" className="align-self-end">
								<img src={profileImg} alt="" className="img-fluid" />
							</Col>
						</Row>
					</div>
					<CardBody className="pt-0">
						<Row>
							<Col sm="4">
								<div className="avatar-md profile-user-wid mb-4">
									<Avatar name={this.state.name} className="rounded-circle" size={72} />
								</div>
								<h5 className="font-size-15 text-truncate">{this.state.name}</h5>
							</Col>
						</Row>
					</CardBody>
				</Card>
			</React.Fragment>
		);
	}
}

export default WelcomeComp;
