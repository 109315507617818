import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Modal, Alert } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

moment.locale('tr');

class LogTypes extends React.Component {
	state = {
		modalStatus: false,
		name: '',
		error: '',
		message: '',
	};

	tog_center = () => {
		this.setState((prevState) => ({
			modalStatus: !prevState.modalStatus,
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	addLogType = (e) => {
		e.preventDefault();

		let name = this.state.name.trim();

		if (name) {
			this.props.addLogType(name);
		}
	};

	static getDerivedStateFromProps(props) {
		return {
			error: props.error,
			message: props.message,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.message !== this.state.message) {
			this.props.getLogTypes();

			this.setState({
				modalStatus: false,
				name: '',
			});
		}
	}

	componentDidMount() {
		this.props.getLogTypes();
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{`Log Tipleri - Log Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Log Yönetimi" breadcrumbItem="Log Tipleri" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Log Tipleri</CardTitle>
												<CardSubtitle>Loglarda kullanılan loglama tipleri</CardSubtitle>
											</div>

											<button className="btn btn-primary" onClick={this.tog_center}>
												Yeni Log Tipi Ekle
											</button>
										</div>

										<div className="tags-table mt-3">
											{this.props.logTypes.length > 0 ? (
												<table className="table">
													<thead>
														<tr>
															<th style={{ width: 24 }}>#</th>
															<th>Log Adı</th>
															<th>Oluşturan</th>
															<th>Tarih</th>
														</tr>
													</thead>
													<tbody>
														{this.props.logTypes.map((log, i) => (
															<tr key={i}>
																<td>{log.id}</td>
																<td>{log.name}</td>
																<td>{log.creator}</td>
																<td>{moment(log.createdDate).format('DD MMMM YYYY - HH:mm')}</td>
															</tr>
														))}
													</tbody>
												</table>
											) : (
												<Alert color="primary">Henüz log tipi eklememişsiniz.</Alert>
											)}
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>

				<Modal isOpen={this.state.modalStatus} toggle={this.tog_center} centered={true}>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Yeni Log Tipi Ekle</h5>
						<button type="button" onClick={() => this.setState({ modalStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						{this.state.message && <Alert color="primary">{this.state.message}</Alert>}
						{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

						<form method="post" onSubmit={this.addLogType}>
							<div className="form-group">
								<label htmlFor="name">Log Adı</label>
								<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ name: e.target.value })} />
							</div>

							<button className="btn btn-info" disabled={this.state.name.length <= 0}>
								Kaydet
							</button>
						</form>
					</div>
				</Modal>
			</>
		);
	}
}
LogTypes.propTypes = {
	getLogTypes: PropTypes.func,
	logTypes: PropTypes.array,
	addLogType: PropTypes.func,
	error: PropTypes.string,
	message: PropTypes.string,
};

const mapStateToProps = (state) => {
	return {
		isFetching: state.LogTypesReducer.isFetching,
		error: state.LogTypesReducer.error,
		logTypes: state.LogTypesReducer.logTypes,
		message: state.LogTypesReducer.message,
	};
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const { dispatch } = dispatchProps;
	const { actions: LogTypes } = require('../../../redux/LogTypesRedux');

	return {
		...ownProps,
		...stateProps,
		getLogTypes: () => LogTypes.getLogTypes(dispatch),
		addLogType: (name) => LogTypes.addLogType(dispatch, name),
	};
};

export default connect(mapStateToProps, null, mergeProps)(LogTypes);
