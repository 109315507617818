import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Alert, Modal, Label } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import moment from 'moment';
import Select from 'react-select';
moment.locale('tr');

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const Podcasts = () => {
	const [podcasts, setPodcasts] = React.useState([]);
	const [programs, setPrograms] = React.useState([]);
	const [selectedProgram, setSelectedProgram] = React.useState('');
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [podcastTitle, setPodcastTitle] = React.useState('');
	const [file, setFile] = React.useState('');
	const [currentDate, setCurrentDate] = React.useState(moment().format('YYYY-MM-DD'));
	const [alert, setAlert] = React.useState({ success: false, message: '' });
	const [loading, setLoading] = React.useState(false);
	const [podcastId, setPodcastId] = React.useState(0);

	React.useEffect(() => {
		fetch();

		api.radyospor.programs.list().then((res) => {
			setPrograms(res.programs);
		});
	}, []);

	const data = {
		columns: [
			{
				label: '#',
				field: 'id',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Program adı',
				field: 'name',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Başlık',
				field: 'title',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Podcast Tarihi',
				field: 'pDate',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Eklenme Tarihi',
				field: 'createdDate',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Güncelleme Tarihi',
				field: 'updatedDate',
				sort: 'asc',
				width: 50,
			},
			{
				label: '',
				field: 'podcast',
				sort: 'asc',
				width: 50,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: podcasts,
	};

	const fetch = () => {
		api.radyospor.podcasts.list().then((res) => {
			let data = [];

			res?.podcasts?.map((item, i) => {
				data.push({
					...item,
					id: i + 1,
					name: item.program.name,
					title: item.title,
					pDate: item.date,
					createdDate: moment(item.createdAt).format('DD-MM-YYYY HH:mm'),
					updatedDate: <span className={item.createdAt !== item.updatedAt ? 'text-success' : ''}>{moment(item.updatedAt).format('DD-MM-YYYY HH:mm')}</span>,
					podcast: (
						<a href={`${process.env.REACT_APP_RS_UPLOAD}/${item.name}.mp3`} target="_blank" rel="noreferrer" title="Podcasti dinle">
							<i className="bx bx-play-circle" style={{ fontSize: 24 }}></i>
						</a>
					),
					settings: (
						<div className="btn-group mr-2">
							<button className="btn btn-secondary" onClick={() => editPodcast(item.id, item.title, item.programInfo[0], item.date)}>
								<i className="bx bxs-pencil"></i>
							</button>
							<button
								className={`btn ${!item.status ? 'btn-danger' : 'btn-secondary'}`}
								onClick={() => {
									if (!item.status) {
										window.confirm('Geri almak istediğinize emin misiniz?') && deletePodcast(item.id);
									} else {
										window.confirm('Silmek istediğinize emin misiniz?') && deletePodcast(item.id);
									}
								}}
							>
								<i className="bx bx-trash"></i>
							</button>
						</div>
					),
				});
			});

			setPodcasts(data);
		});
	};

	const selectProgram = (program) => {
		setSelectedProgram(program);
	};

	const deletePodcast = (id) => {
		api.radyospor.podcasts.delete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const editPodcast = (id, title, programInfo, updatedAt) => {
		setEditStatus(true);
		setPodcastTitle(title);
		setPodcastId(id);
		setSelectedProgram(programInfo);
		setCurrentDate(moment(updatedAt).format('YYYY-MM-DD'));
	};

	const addPodcast = (e) => {
		let file = e.target.files[0];
		setFile(file);
	};

	const update = (id) => {
		setLoading(true);

		let data = new FormData();
		data.append('id', id);
		data.append('title', podcastTitle);
		data.append('programId', selectedProgram.id);
		data.append('file', file);
		data.append('date', moment(currentDate).format('DD-MM-YYYY'));

		setAlert({ success: false, message: '' });

		api.radyospor.podcasts.update(data).then((res) => {
			setAlert({ success: res.success, message: res.message });
			setLoading(false);
			if (res.success) {
				fetch();
				setPodcastTitle('');
				setPodcastId(0);
				setSelectedProgram('');
				setFile('');
				setCurrentDate(moment().format('YYYY-MM-DD'));

				setTimeout(() => {
					setEditStatus(false);
					setAlert({ success: false, message: '' });
				}, 1000);
			} else {
				//setStatus(false);
			}
		});
	};

	const add = () => {
		setLoading(true);

		let data = new FormData();
		data.append('title', podcastTitle);
		data.append('programId', selectedProgram.id);
		data.append('file', file);
		data.append('date', moment(currentDate).format('DD-MM-YYYY'));

		setAlert({ success: false, message: '' });

		if (typeof selectedProgram.id === 'undefined') {
			setAlert({ success: false, message: 'Program seçmelisiniz.' });
			setLoading(false);
		} else {
			api.radyospor.podcasts.upload(data).then((res) => {
				setAlert({ success: res.success, message: res.message });
				setLoading(false);

				if (res.success) {
					fetch();
					setPodcastTitle('');
					setPodcastId(0);
					setSelectedProgram('');
					setFile('');
					setCurrentDate(moment().format('YYYY-MM-DD'));

					setTimeout(() => {
						setStatus(false);
						setAlert({ success: false, message: '' });
					}, 1000);
				} else {
					//setStatus(false);
				}
			});
		}
	};

	return (
		<>
			<Helmet>
				<title>{`Podcastler - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Podcast Yönetimi" breadcrumbItem="Podcastler" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Podcastler</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Podcast Ekle
										</button>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal
						isOpen={editStatus}
						toggle={() => {
							setEditStatus(!editStatus);
							setPodcastTitle('');
							setPodcastId(0);
							setSelectedProgram('');
							setCurrentDate(moment().format('YYYY-MM-DD'));
						}}
						centered={true}
					>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Podcast düzenleyin.</h5>
							<button
								type="button"
								onClick={() => {
									setEditStatus(false);
									setPodcastTitle('');
									setPodcastId(0);
									setSelectedProgram('');
									setCurrentDate(moment().format('YYYY-MM-DD'));
								}}
								className="close"
								data-dismiss="modal"
								aria-label="Kapat"
							>
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{podcastTitle}</b> podcastini düzenliyorsunuz.
							</p>

							{alert.message !== '' && <Alert color={alert.success ? 'success' : 'danger'}>{alert.message}</Alert>}

							<div className="form-group">
								<label htmlFor="name">Podcast Başlığı</label>
								<input type="text" value={podcastTitle} className="form-control" placeholder="Giriniz" onChange={(e) => setPodcastTitle(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="program">Program</label>
								{programs.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={programs}
										classNamePrefix="select2-selection"
										placeholder="Program seçin"
										onChange={selectProgram}
										value={selectedProgram}
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Podcast Tarihi</label>
								<input type="date" value={currentDate} className="form-control" placeholder="Giriniz" onChange={(e) => setCurrentDate(e.target.value)} />
							</div>

							<div className="form-group">
								<Label className="btn btn-primary btn-upload w-100 flex-row" htmlFor="inputImage" title="Podcast seçin.">
									<input type="file" className="sr-only" id="inputImage" name="file" accept="audio/mpeg" onChange={(e) => addPodcast(e)} />
									<h6 className="ml-1 mt-2">Podcast Seçin</h6>
								</Label>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? update(podcastId) : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Düzenle'}
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Podcast ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{alert.message !== '' && <Alert color={alert.success ? 'success' : 'danger'}>{alert.message}</Alert>}

							<div className="form-group">
								<label htmlFor="name">Podcast Başlığı</label>
								<input type="text" value={podcastTitle} className="form-control" placeholder="Giriniz" onChange={(e) => setPodcastTitle(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="program">Program</label>
								{programs.length > 0 && (
									<Select
										getOptionLabel={(option) => `${option.name}`}
										getOptionValue={(option) => `${option.id}`}
										options={programs}
										classNamePrefix="select2-selection"
										placeholder="Program seçin"
										onChange={selectProgram}
										value={selectedProgram}
									/>
								)}
							</div>

							<div className="form-group">
								<label htmlFor="name">Podcast Tarihi</label>
								<input type="date" value={currentDate} className="form-control" placeholder="Giriniz" onChange={(e) => setCurrentDate(e.target.value)} />
							</div>

							<div className="form-group">
								<Label className="btn btn-primary btn-upload w-100 flex-row" htmlFor="inputImage" title="Podcast seçin.">
									<input type="file" className="sr-only" id="inputImage" name="file" accept="audio/mpeg" onChange={(e) => addPodcast(e)} />
									<h6 className="ml-1 mt-2">Podcast Seçin</h6>
								</Label>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? add() : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Ekle'}
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Podcasts;
