import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal, Label } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { MDBDataTable } from 'mdbreact';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const Programs = () => {
	const [programs, setPrograms] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [name, setName] = React.useState('');
	const [info, setInfo] = React.useState('');
	const [programId, setProgramId] = React.useState(0);
	const [image, setImage] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [file, setFile] = React.useState(false);

	const fetch = () => {
		api.radyospor.programs.list().then((res) => {
			let data = [];

			res.programs.map((item, i) => {
				data.push({
					...item,
					id: i + 1,
					name: item.name,
					image: <img src={item.image} size="48" height="48" />,
					settings: (
						<div className="btn-group mr-2">
							<button className="btn btn-secondary" onClick={() => editProgram(item.id, item.name, item.image, item.info)}>
								<i className="bx bxs-pencil"></i>
							</button>
							<button
								className={`btn ${!item.status ? 'btn-danger' : 'btn-secondary'}`}
								onClick={() => {
									if (!item.status) {
										window.confirm('Geri almak istediğinize emin misiniz?') && deleteProgram(item.id);
									} else {
										window.confirm('Silmek istediğinize emin misiniz?') && deleteProgram(item.id);
									}
								}}
							>
								<i className="bx bx-trash"></i>
							</button>
						</div>
					),
				});
			});

			setPrograms(data);
		});
	};

	const deleteProgram = (id) => {
		api.radyospor.programs.delete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const editProgram = (id, name, image, info) => {
		setEditStatus(true);
		setName(name);
		setProgramId(id);
		setImage(image);
		setInfo(info);
	};

	const update = (id) => {
		setLoading(true);
		api.radyospor.programs.update({ name, id, info }).then((res) => {
			setLoading(false);
			setEditStatus(false);
			setName('');
			setInfo('');
			setProgramId(0);
			setImage(false);

			if (res.success) {
				let data = new FormData();

				data.append('file', file);
				data.append('programId', id);

				api.radyospor.programs.updateImage(data).then((resp) => {
					if (resp.success) {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(res.message);

						api.radyospor.programs.update({ image: true, id });
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(`${res.message} | Fotoğraf/resim yüklenemedi.`);
					}
				});

				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const add = () => {
		setLoading(true);

		api.radyospor.programs.add({ name, info }).then((res) => {
			setLoading(false);
			setStatus(false);
			setName('');
			setInfo('');
			setProgramId(0);

			if (res.success) {
				let data = new FormData();

				data.append('file', file);
				data.append('programId', res.program.id);

				api.radyospor.programs.addImage(data).then((resp) => {
					if (resp.success) {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(res.message);

						api.radyospor.programs.update({ image: true, id: res.program.id });
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(`${res.message} | Fotoğraf/resim yüklenemedi.`);
					}
				});

				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const data = {
		columns: [
			{
				label: '#',
				field: 'id',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Program adı',
				field: 'name',
				sort: 'asc',
				width: 250,
			},
			{
				label: 'Program Görseli',
				field: 'image',
				sort: 'asc',
				width: 250,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: programs,
	};

	const addImage = (e) => {
		const file = e.target.files[0];
		setFile(file);
	};

	React.useEffect(() => {
		fetch();
	}, []);

	return (
		<>
			<Helmet>
				<title>{`Programlar - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Program Yönetimi" breadcrumbItem="Programlar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Programlar</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Program Ekle
										</button>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal isOpen={editStatus} toggle={() => setEditStatus(!editStatus)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Program düzenleyin.</h5>
							<button type="button" onClick={() => setEditStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{name}</b> programını düzenliyorsunuz.
							</p>

							<div className="form-group">
								<label htmlFor="name">Program</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Program Bilgisi (1 paragraflık kısa açıklama)</label>
								<textarea value={info} className="form-control" placeholder="Giriniz" onChange={(e) => setInfo(e.target.value)} />
							</div>

							<p>Mevcut Görsel (varsa)</p>
							<br />
							<img src={`${process.env.REACT_APP_RS_UPLOAD_DEV}/images/program/${image ? programId : 0}.webp`} alt="" width="160" height="160" />

							<div className="form-group">
								<Label className="btn btn-primary btn-upload w-100 flex-row" htmlFor="inputImage" title="Podcast seçin." style={{ marginTop: 20 }}>
									<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => addImage(e)} />
									<h6 className="ml-1 mt-2">Program Görseli Seçin</h6>
								</Label>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? update(programId) : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Düzenle'}
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Program ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="name">Program</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Program Bilgisi (1 paragraflık kısa açıklama)</label>
								<textarea value={info} className="form-control" placeholder="Giriniz" onChange={(e) => setInfo(e.target.value)} />
							</div>

							<div className="form-group">
								<Label className="btn btn-primary btn-upload w-100 flex-row" htmlFor="inputImage" title="Podcast seçin.">
									<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => addImage(e)} />
									<h6 className="ml-1 mt-2">Program Görseli Seçin</h6>
								</Label>
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? add() : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Ekle'}
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Programs;
