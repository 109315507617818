import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';

import { UPDATE_PROFILE } from '../../../queries/UserQuery';
import { addLog } from '../../../utils/addLog';

const validationSchema = Yup.object({
	Fullname: Yup.string().required('Haber başlığı boş olamaz.'),
});

const Genders = [
	{
		options: [
			{ label: 'Kadın', value: 0 },
			{ label: 'Erkek', value: 1 },
		],
	},
];

const PersonalInfo = ({ data, client }) => {
	const [loading, setLoading] = useState(false);
	const [gender, setGender] = useState(data.Gender !== null ? (data.Gender === 0 ? { label: 'Kadın', value: 0 } : { label: 'Erkek', value: 1 }) : 0);

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			Fullname: data.Fullname,
			Username: data.Username,
			Email: data.Email,
		},
		validationSchema,
		onSubmit: async (values) => {
			setLoading(true);

			try {
				const mutation = await client.mutate({
					mutation: UPDATE_PROFILE,
					variables: {
						input: {
							Fullname: values.Fullname,
							Gender: parseInt(gender.value),
						},
					},
				});

				if (mutation) {
					setLoading(false);

					if (mutation.data.updateProfile.success) {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(mutation.data.updateProfile.message);

						localStorage.setItem(process.env.REACT_APP_TOKEN, mutation.data.updateProfile.token);

						setTimeout(() => {
							window.location.reload();
						}, 2000);

						addLog(1, `${values.Username} kullanıcı adlı editör bilgilerini düzenledi. Ad soyad: ${values.Fullname}, cinsiyet: ${gender === 0 ? 'Kadın' : 'Erkek'}`);
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error(mutation.data.updateProfile.message);

						addLog(43, mutation.data.updateProfile.message);
					}
				}
			} catch (e) {
				setLoading(false);

				toastr.options = {
					positionClass: 'toast-top-center',
				};

				e.graphQLErrors.map(({ message }) => {
					toastr.error(message);
					addLog(43, message);
				});
			}
		},
	});

	const selectGender = (selectedGender) => {
		setGender(selectedGender);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Row>
				<Col col="12">
					<FormGroup>
						<Label for="fullname">Adınız soyadınız</Label>
						<Input className={`${errors.Fullname ? 'is-invalid' : ''}`} type="text" name="Fullname" id="fullname" value={values.Fullname} onChange={handleChange} />
						{errors.Fullname && <div className="invalid-feedback d-block">{errors.Fullname}</div>}
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col col="12">
					<FormGroup>
						<Label for="username">Kullanıcı adınız</Label>
						<Input type="text" name="username" id="username" value={values.Username} disabled />
						<small className="text-muted">Bu bilginizi düzenleyemezsiniz.</small>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col col="12">
					<FormGroup>
						<Label for="email">Eposta adresiniz</Label>
						<Input type="text" name="email" id="email" value={values.Email} disabled />
						<small className="text-muted">Bu bilginizi düzenleyemezsiniz.</small>
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col lg="12">
					<FormGroup className="select2-container">
						<Label className="control-label">Cinsiyetiniz</Label>
						<Select value={gender} onChange={selectGender} options={Genders} classNamePrefix="select2-selection" placeholder="Cinsiyet seçin" />
					</FormGroup>
				</Col>
			</Row>

			<div className="mt-4">
				<Button type="submit" color="danger">
					{loading ? <i className="bx bx-loader bx-spin" /> : 'Güncelle'}
				</Button>
			</div>
		</Form>
	);
};

PersonalInfo.propTypes = {
	data: PropTypes.object,
	client: PropTypes.object,
};

export default PersonalInfo;
