import React from 'react';
import Helmet from 'react-helmet';
import _ from 'lodash';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import api from '../../api';

class SoccerSquads extends React.Component {
	state = {
		players: [],
		teamPlayers: [],
		teams: [],
		searchText: '',
		teamSearchText: '',
		selectedTeam: null,
		selectedPlayer: null,
		playerDeleteAlert: false,
		playerTeams: [],
		alertIsOpen: false,
		deletingPlayerId: null,
		deletingTeamId: null,
		success: null,
	};

	teamSearch = _.debounce((e) => {
		api.soccerData.searchTeam(e).then((res) => {
			this.setState({ teams: res });
		});
	}, 1000);

	search = _.debounce((e) => {
		api.soccerData.searchPlayer(`${e}?Limit=150`).then((res) => {
			this.setState({ players: res });
		});
	}, 1000);

	getTeamPlayers = (teamId) => {
		api.soccerData.customSquadFromTeam(teamId).then((res) => {
			this.setState({ teamPlayers: res.data });
		});
	};

	deletePlayerFromTeam = async (playerId, teamId) => {
		api.soccerData.customSquadDelete(playerId, teamId).then((res) => {
			if (res.success) {
				api.soccerData.customSquadFromTeam(teamId).then(async () => {
					this.getTeamPlayers(this.state.deletingTeamId);
					this.setState({ deletingPlayerId: null, deletingTeamId: null, playerDeleteAlert: false });
				});
			}
		});
	};

	addPlayerToTeam = async (playerId, teamId) => {
		api.soccerData.customSquadAdd(playerId, teamId).then(async (res) => {
			if (res.success) {
				this.getTeamPlayers(teamId);
				this.setState({ searchText: '', players: [] });
			}
		});
	};

	getPlayer = (id) => {
		api.soccerData.getPlayerAll(id).then((res) => {
			this.setState({ playerTeams: res });
		});
	};

	deleteTeam = () => {
		api.soccerData.deleteTeam(this.state.deletingId, this.state.deletingTeamId).then(() => {
			this.setState({ success: true });
			setTimeout(() => {
				this.setState({ alertIsOpen: false, deletingId: null, deletingTeamId: null, searchText: '', playerTeams: [], selectedPlayer: null, players: [], teams: [] });
			}, 1000);
		});
	};

	render() {
		return (
			<>
				{this.state.playerDeleteAlert && (
					<SweetAlert
						title=""
						warning={true}
						success={this.state.success}
						confirmBtnText={'Sil'}
						onConfirm={() => this.deletePlayerFromTeam(this.state.deletingPlayerId, this.state.deletingTeamId)}
						showCancel={true}
						cancelBtnText="Vazgeç"
						reverseButtons
						onCancel={() => this.setState({ alertIsOpen: false, deletingId: null, deletingTeamId: null })}
					>
						<div dangerouslySetInnerHTML={{ __html: this.state.alertTitle }}></div>
					</SweetAlert>
				)}
				<Helmet>
					<title>{`Kadro Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Row>
							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Takım Ara</CardTitle>
												<CardSubtitle>Kadro düzenlemesi için takım ara.</CardSubtitle>
											</div>
										</div>
										<Row className="mt-3">
											<Col className="col-12">
												<div className="form-group d-flex">
													<input
														className="form-control"
														type="text"
														placeholder="Takım Adı"
														value={this.state.teamSearchText}
														onChange={(e) => {
															if (e.target.value.length > 3) {
																this.teamSearch(e.target.value);
															} else if (e.target.value.length === 0) {
																this.setState({ teams: [] });
															}
															this.setState({ teamSearchText: e.target.value });
														}}
													/>
													{this.state.selectedTeam && (
														<button
															className="btn btn-danger ml-2"
															onClick={() => this.setState({ selectedTeam: null, teamPlayers: [], teams: [], teamSearchText: '', searchText: '', players: [] })}
														>
															Temizle
														</button>
													)}
												</div>
											</Col>
										</Row>

										{this.state.teams.length > 0 && !this.state.selectedTeam && (
											<table className="table mt-3">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Takım Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.teams.map((team, i) => (
														<tr key={i}>
															<td style={{ width: '100%' }}>{team.name}</td>
															<td>
																<button
																	className="btn btn-primary"
																	onClick={() => {
																		this.setState({ selectedTeam: team, teamSearchText: team.name });
																		this.getTeamPlayers(team.id);
																	}}
																>
																	Seç
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}

										{this.state.selectedTeam && this.state.teamPlayers.length > 0 && (
											<table className="table mt-3">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Futbolcu Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.teamPlayers.map((player, i) => (
														<tr key={i}>
															<td style={{ width: '100%' }}>{player.player.player.knownName}</td>
															<td>
																<button
																	className="btn btn-primary"
																	onClick={() => {
																		this.setState({ playerDeleteAlert: true, deletingPlayerId: player.playerId, deletingTeamId: player.teamId });
																	}}
																>
																	Sil
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}
									</CardBody>
								</Card>
							</Col>

							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Futbolcu Ara</CardTitle>
												<CardSubtitle>Futbolcu ara ve takım kadrosuna ekle.</CardSubtitle>
											</div>
										</div>
										<Row className="mt-3">
											<Col className="col-12">
												<div className="form-group d-flex">
													<input
														className="form-control"
														type="text"
														placeholder="Futbolcu Adı"
														value={this.state.searchText}
														onChange={(e) => {
															if (e.target.value.length > 3) {
																this.search(e.target.value);
															} else if (e.target.value.length === 0) {
																this.setState({ players: [] });
															}
															this.setState({ searchText: e.target.value });
														}}
													/>
													{this.state.selectedPlayer && (
														<button className="btn btn-danger ml-2" onClick={() => this.setState({ selectedPlayer: null, players: [], searchText: '' })}>
															Temizle
														</button>
													)}
												</div>
											</Col>
										</Row>

										{this.state.players.length > 0 && !this.state.selectedPlayer && (
											<table className="table mt-3">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Futbolcu Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.players.map((player, i) => (
														<tr key={i}>
															<td style={{ width: '100%' }}>{player.player.knownName}</td>
															<td>
																<button
																	className="btn btn-primary"
																	onClick={() => {
																		// this.setState({ selectedPlayer: player, searchText: player.player.knownName });
																		// this.getPlayer(player.player.id);
																		this.addPlayerToTeam(player.player.id, this.state.selectedTeam.id);
																	}}
																>
																	Ekle
																</button>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default SoccerSquads;
