/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Form, FormGroup, Label, Input, InputGroup, Button, UncontrolledCollapse, Alert, Modal } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';
import tr from 'date-fns/locale/tr';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { Picker } from 'emoji-mart';
import Pagination from 'react-js-pagination';
import 'emoji-mart/css/emoji-mart.css';
import { Query } from 'react-apollo';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import lodash from 'lodash';

registerLocale('tr', tr);
moment.locale('tr');

function getBrowser() {
	if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf('Edg') !== -1) {
		return 'Edge';
	} else if (navigator.userAgent.indexOf('Chrome') !== -1) {
		return 'Chrome';
	} else if (navigator.userAgent.indexOf('Safari') !== -1) {
		return 'Safari';
	} else if (navigator.userAgent.indexOf('Firefox') !== -1) {
		return 'Firefox';
	} else if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
		//IF IE > 10
		return 'IE';
	} else {
		return 'Bilinmiyor';
	}
}

import Breadcrumbs from '../../Common/Breadcrumb';
import {
	TWITTER_ACCOUNTS,
	CREATE_NEW_CONTENT,
	UPDATE_NEW_CONTENT,
	CREATE_GALLERY,
	UPDATE_GALLERY,
	DELETE_GALLERY,
	DELETE_AGENCY_GALLERY,
	POST_TWEET,
	POST_SCHEDULED_TWEET,
} from '../../../queries/ContentQuery';
import { SCHEDULED_CONTENT_TWITS } from '../../../queries/TwitterQuery';
import ImageCropper from '../../ReactCropper';
import TextEditor from '../../TextEditor';

import api from '../../../api';
import { addLog } from '../../../utils/addLog';
import { seoUrl } from '../../../utils/seoUrl';
import { getTokenDecoded } from '../../../utils/auth';
import Notification from './Notification';

const categories = [
	{ label: 'Futbol', value: 16 },
	{ label: 'Basketbol', value: 9 },
	{ label: 'Voleybol', value: 10 },
	{ label: 'Özel Haber', value: 33145 },
	{ label: 'Dış Haber', value: 33146 },
	{ label: 'Detay Haber', value: 33147 },
	{ label: 'Analiz Haber', value: 33148 },
	{ label: 'Ajans Gazete Haber', value: 33149 },
	{ label: 'Espor', value: 34741 },
];

const titleTypes = [
	{ label: 'Tek Başlık Tip 1', value: 0 },
	{ label: 'İki Başlık Tip 1', value: 2 },
	{ label: 'İki Başlık Tip 2', value: 3 },
	{ label: 'İki Başlık Tip 3', value: 4 },
	{ label: 'Üç Başlık Tip 1', value: 1 },
	{ label: 'Üç Başlık Tip 2', value: 5 },
];

const i18n = {
	search: 'Ara',
	clear: 'Temizle',
	notfound: 'Emoji bulunamadı',
	skintext: 'Varsayılan cilt renginizi seçin',
	categories: {
		search: 'Arama Sonuçları',
		recent: 'Sıklıkla Kullanılanlar',
		smileys: 'Suratlar & Duygular',
		people: 'İnsanlar & Vücut',
		nature: 'Hayvanlar & Doğa',
		foods: 'Yiyecek & İçecek',
		activity: 'Aktivite',
		places: 'Seyahat & Yerler',
		objects: 'Objeler',
		symbols: 'Semboller',
		flags: 'Bayraklar',
		custom: 'Özel',
	},
	categorieslabel: 'Emoji kategoriler',
	skintones: {
		1: 'Varsayılan Cilt Rengi',
		2: 'Açık Cilt Rengi',
		3: 'Orta-Hafif Cilt Rengi',
		4: 'Orta Cilt Rengi',
		5: 'Orta-Koyu Cilt Rengi',
		6: 'Koyu Cilt Rengi',
	},
};

const Holders = [
	{
		options: [
			{ label: 'Görsel Tipi Kullanma', value: '' },
			{ label: 'Arkaplan', value: 'arkaplan' },
			{ label: 'Haber Analiz', value: 'haberanaliz' },
			{ label: 'İnceleme', value: 'inceleme' },
			{ label: 'İstatistik Haber', value: 'istatistikhaber' },
			{ label: 'Oyuncu Analiz', value: 'oyuncuanaliz' },
			{ label: 'Sezonluk Haber', value: 'sezonluk' },
			{ label: 'Transfer Haber', value: 'transfer' },
			{ label: 'Özel Haber', value: 'ozelhaber' },
			{ label: 'Dış Haber', value: 'dishaber' },
			{ label: 'Detay Haber', value: 'detayhaber' },
			{ label: 'Röportaj', value: 'roportaj' },
		],
	},
];

const validationSchema = Yup.object({
	Title: Yup.string().required('Birinci başlık boş olamaz.'),
	Spot: Yup.string().required('Spot boş olamaz.'),
	startedDate: Yup.string().required('Yayın başlangıç tarihi boş olamaz.'),
	endedDate: Yup.string().required('Yayın bitiş tarihi boş olamaz.'),
	MainPageTitle: Yup.string().required('Başlık boş olamaz.'),
});

const Information = ({ client, content, ContentId, ContentType = 0 }) => {
	const [newType, setNewType] = useState('general');
	const [status, setStatus] = useState(false);
	const [selectedTags, setSelectedTags] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [SocialMediaTitle, setSocialMediaTitle] = useState('');
	const [emojiStatus, setEmojiStatus] = useState(false);
	const [selectedTwitter, setSelectedTwitter] = useState([]);
	const [selectedApiTwitter, setSelectedApiTwitter] = useState([]);
	const [imageCrop, setImageCrop] = useState('');
	const [galleryImageCrop, setGalleryImageCrop] = useState('');
	const [galleryContent, setGalleryContent] = useState('');
	const [selectedApiTags, setSelectedApiTags] = useState([]);
	const [selectedApiCategory, setSelectedApiCategory] = useState([]);
	const [holder, setHolder] = useState('');
	const [GalleryTitle, SetGalleryTitle] = useState('');
	const [UpdatingId, setUpdatingId] = useState(0);
	const [GalleryMediaId, setGalleryMediaId] = useState(null);
	const [GalleryOrder, setGalleryOrder] = useState(0);
	const [GalleryContentArray, setGalleryContentArray] = useState([]);
	const [GalleryImageTitle, setGalleryImageTitle] = useState('');
	const [validateErrors, setErrors] = useState({});
	const [isDraft, setDraft] = useState(true);
	const [scheduledTweet, setScheduledTweet] = useState(false);
	const [startedDate, setStartedDate] = useState();
	const [galleryModal, setGalleryModal] = useState(false);
	const [imagesData, setImagesData] = useState({});
	const [currentPage, setCurrentPage] = useState(1);
	const [totalImageCount, setTotalImageCount] = useState(0);
	const [selectedImage, setSelectedImage] = useState(null);
	const [selectedFromLocal, setSelectedFromLocal] = useState(false);
	const [searchedImage, setSearchedImage] = useState('');
	const [selectedTitleType, setSelectedTitleType] = useState(0);
	const [selectedTitleTypeOption, setSelectedTitleTypeOption] = useState({ label: 'Tek Başlık Tip 1', value: 0 });
	const [titleTypeModal, setTitleTypeModal] = useState(false);
	const [hideTitle, setHideTitle] = useState(false);
	const [isLive, setIsLive] = useState(false);
	const [relatedNews, setRelatedNews] = useState({});
	const [relatedNewsList, setRelatedNewsList] = useState([]);
	const [relatedNewsArray, setRelatedNewsArray] = useState([]);
	const [relatedNewsOrderList, setRelatedNewsOrderList] = useState([]);
	const [relatedOrder, setRelatedOrder] = useState([]);
	const [spotCharCount, setSpotCharCount] = useState(0);
	const [seoTitleCharCount, setSeoTitleCharCount] = useState(0);
	const [contentBody, setContentBody] = useState(null);
	const [isVideoNews, setIsVideoNews] = useState(false);

	const [lastMediaId, setLastMediaId] = useState(0);
	const [editorInfo, setEditorInfo] = useState({});

	// function readingTime() {
	// 	const text = document.getElementById("article").innerText;
	// 	const wpm = 225;
	// 	const words = text.trim().split(/\s+/).length;
	// 	const time = Math.ceil(words / wpm);
	// 	document.getElementById("time").innerText = time;
	//   }

	const readTime = (letter) => {
		const words = letter.trim().split(/\s+/).length;

		console.log(words);
	};

	useEffect(() => {
		let cats = [];
		let tags = [];

		// api.image.getMedia(21, 21 * (currentPage - 1)).then((res) => {
		// 	setImagesData(res);
		// 	setTotalImageCount(res.count);
		// });

		api.redis.getMedia().then((res) => {
			if (res.success) {
				setLastMediaId(res.lastId);
			}
		});

		if (content.ContentId) {
			api.contentEditor(content.ContentId).then((res) => {
				if (res.result) {
					setEditorInfo({ name: res.result.editorName, date: content.PublishDate });
				}
			});
		}

		removeLocalStorage('Title');
		removeLocalStorage('Spot');
		removeLocalStorage('Category');
		removeLocalStorage('Tags');
		removeLocalStorage('MainPageTitle');
		removeLocalStorage('GalleryTitle');
		removeLocalStorage('GalleryContent');

		content?.TagList?.length > 0 &&
			content.TagList.map((tag) => {
				if (categories.some((cat) => cat.value === tag.TagId)) {
					categories.some((cat, i) => {
						if (cat.value === tag.TagId) {
							cats.push(categories[i]);
						}
					});
				} else {
					tags.push({ label: tag.Name, value: tag.TagId });
				}
			});

		content?.TitleType &&
			titleTypes.map((type) => {
				if (type.value === content.TitleType) {
					setSelectedTitleTypeOption(type);
					setSelectedTitleType(type.value);
				}
			});

		content?.HideTitle && setHideTitle(content.HideTitle);
		content?.IsLive && setIsLive(content.IsLive);

		content?.IsVideoNews && setIsVideoNews(content.IsVideoNews);

		content?.RelatedNews?.Title &&
			(setRelatedNews({ label: content.RelatedNews.Title, value: { Title: content.RelatedNews.Title, SpotMediaId: content.RelatedNews.SpotMediaId, Url: content.RelatedNews.Url } }),
			setRelatedNewsList([{ label: content.RelatedNews.Title, value: { Title: content.RelatedNews.Title, SpotMediaId: content.RelatedNews.SpotMediaId, Url: content.RelatedNews.Url } }]),
			setRelatedOrder({ label: `${content.RelatedNews.Order}. Galeri Sonu`, value: content.RelatedNews.Order }));

		if (content && content.RelatedNewList && Array.isArray(content.RelatedNewList)) {
			let arr = [];
			for (let i = 0; i < content.RelatedNewList.length; i++) {
				arr.push({
					index: content.RelatedNewList[i].Order,
					value: {
						label: content.RelatedNewList[i].Title,
						value: {
							Title: content.RelatedNewList[i].Title,
							SpotMediaId: content.RelatedNewList[i].SpotMediaId,
							Url: content.RelatedNewList[i].Url,
						},
					},
				});
			}
			setRelatedNewsArray(arr);
		}
		// content?.RelatedNewList && Array.isArray(content?.RelatedNewList) && (console.log(content.RelatedNewList), setRelatedNewsArray(content.RelatedNewList));

		content?.Body && setContentBody(content.Body);

		content?.Spot && setSpotCharCount(content.Spot.length);

		content?.SeoTitle && setSeoTitleCharCount(content.SeoTitle.length);

		if (tags.length > 0) {
			let newTags = [];

			tags.forEach(function (tag) {
				newTags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiTags(newTags);
			setSelectedTags(tags);
		}

		if (cats.length > 0) {
			let tags = [];

			cats.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiCategory(tags);
			setSelectedCategory(cats);
		}

		if (content.GalleryContent) {
			let tempArray = content.GalleryContent.filter((term) => term.IsDeleted === false);
			setGalleryContentArray(tempArray);
			let arr = [];
			tempArray.map((item, index) => {
				arr.push({ label: `${index + 1}. Galeri Sonu`, value: index + 1 });
			});
			setRelatedNewsOrderList(arr);
		}

		localStorage.setItem('newsCreateStartedDate', moment().toISOString());

		// if (getLocalStorage('GalleryContent')) {
		// 	setGalleryContent(getLocalStorage('GalleryContent'));
		// }
	}, [content]);

	const setLocalStorage = (key, value) => localStorage.setItem(key, value);
	const getLocalStorage = (key) => localStorage.getItem(key);
	const removeLocalStorage = (key) => localStorage.removeItem(key);

	const { handleSubmit, handleChange, values, errors, setFieldValue } = useFormik({
		initialValues: {
			Title: content.Title || '',
			SecondTitle: content.SecondTitle || '',
			ThirthTitle: content.ThirthTitle || '',
			TitleType: content.TitleType || 0,
			MainPageTitle: content.MainPageTitle || '',
			startedDate: new Date(),
			endedDate: moment(new Date().setFullYear(new Date().getFullYear() + 10)).toDate(),
			SeoTitle: content.SeoTitle || '',
			Spot: content.Spot || '',
			SpotMediaId: content.SpotMediaId || '',
			ImageTitle: content.MediaTitle || 'image',
			IsHeadline: content.IsHeadline || false,
			IsMainPage: content.IsMainPage || false,
			HeadlineOrder: content.HeadlineOrder || 0,
			Source: content.Source || '',
			Body: content.Body || null,
			Video: content.Video || '',
		},
		validationSchema,
		onSubmit: async (values) => {
			let TagList = JSON.stringify(selectedApiTags);
			let CategoryList = JSON.stringify(selectedApiCategory);
			let TwitterAccounts = JSON.stringify(selectedApiTwitter);

			setStatus(true);

			const createContent = async (mediaid, gallerymediaid) => {
				let PublishDate = values.startedDate;
				let PublishEndDate = values.endedDate;
				let GalleryContent;
				let GalleryContentAgency = [];

				if (ContentType === 0) {
					if (galleryContent) {
						GalleryContent = JSON.stringify([
							{
								Title: GalleryTitle,
								Spot: galleryContent,
								Order: 1,
								MediaId: gallerymediaid,
								Location: null,
								IsDeleted: false,
								CreatedAt: moment().format(),
								UpdatedAt: moment().format(),
							},
						]);
					} else {
						GalleryContent = JSON.stringify([
							{
								Title: '',
								Spot: '<p>Detaylar geliyor...</p>',
								Order: 1,
								MediaId: gallerymediaid,
								Location: null,
								IsDeleted: false,
								CreatedAt: moment().format(),
								UpdatedAt: moment().format(),
							},
						]);
					}
				} else {
					if (content.GalleryContent) {
						content.GalleryContent.map((x) => {
							GalleryContentAgency.push({
								Title: x.Title,
								Spot: x.Spot,
								Order: x.Order,
								MediaId: x.MediaId,
								Location: x.Location,
								IsDeleted: x.IsDeleted,
								CreatedAt: moment().format(),
								UpdatedAt: moment().format(),
							});
						});

						GalleryContent = JSON.stringify(GalleryContentAgency);
					} else {
						GalleryContent = JSON.stringify([
							{
								Title: '',
								Spot: '<p>Detaylar geliyor...</p>',
								Order: 1,
								MediaId: gallerymediaid,
								Location: null,
								IsDeleted: false,
								CreatedAt: moment().format(),
								UpdatedAt: moment().format(),
							},
						]);
					}
				}

				try {
					let relatedData = {};
					if (relatedNews?.value && relatedOrder?.value) {
						relatedData = relatedNews.value;
						relatedData.Order = relatedOrder.value;
					}

					const mutation = await client.mutate({
						mutation: CREATE_NEW_CONTENT,
						variables: {
							input: {
								Title: values.Title,
								SecondTitle: values.SecondTitle,
								ThirthTitle: values.ThirthTitle,
								TitleType: selectedTitleType,
								HideTitle: hideTitle,
								IsLive: isLive,
								RelatedNews: JSON.stringify(relatedData),
								RelatedNewList: JSON.stringify(relatedNewsArray),
								SocialMediaTitle,
								MainPageTitle: newType === 'general' ? values.MainPageTitle : values.Title,
								SeoTitle: newType === 'general' ? values.MainPageTitle : values.Title,
								Spot: values.Spot,
								IsHeadline: values.IsHeadline,
								IsMainPage: values.IsMainPage,
								HeadlineOrder: parseInt(values.HeadlineOrder),
								SpotMediaId: mediaid,
								PostToTwitter: values.PostToTwitter,
								PublishDate,
								PublishEndDate,
								IsDraft: isDraft,
								TwitterAccounts,
								CategoryList,
								TagList,
								GalleryContent,
								Source: values.Source,
								Video: values.Video,
								IsVideoNews: isVideoNews,
							},
						},
					});

					if (mutation) {
						if (mutation.data.createNewContent.success) {
							toastr.options = {
								positionClass: 'toast-top-center',
								progressBar: true,
							};
							toastr.success(mutation.data.createNewContent.message);

							let contentId = mutation.data.createNewContent.content.ContentId;

							//api.getHomepage(`https://ajansspor.com/haber/${seoUrl(mutation.data.createNewContent.content.Title + '-' + contentId)}`);

							if (values.IsMainPage || values.IsHeadline) {
								resetMainpageCache();
							}

							let logData = `Eklenen Haber Id'si : <a href="https://admin.ajansspor.com/news/news/${contentId}" target="_blank" />${contentId}</a>, Tweet ${
								values.PostToTwitter ? 'Gönderildi' : 'Gönderilmedi'
							}`;
							addLog(1, logData);
							const token = getTokenDecoded(localStorage.getItem('ajstoken'));
							await api.logs.addEditorNewsLogs({
								editorId: token.UserId,
								editorName: token.Fullname,
								contentId: contentId,
								contentTitle: values.Title,
								date: moment(localStorage.getItem('newsCreateStartedDate')).format('YYYY-MM-DDTHH:mm:ss'),
								browser: getBrowser(),
							});

							// Localstorage'i temizle
							removeLocalStorage('Title');
							removeLocalStorage('Spot');
							removeLocalStorage('Category');
							removeLocalStorage('Tags');
							removeLocalStorage('MainPageTitle');
							removeLocalStorage('GalleryTitle');
							removeLocalStorage('GalleryContent');

							setTimeout(() => (window.location.href = `${window.location.origin}/news/news/${contentId}`), 5000);
						} else {
							toastr.options = {
								positionClass: 'toast-top-center',
							};
							toastr.error(mutation.data.createNewContent.message);
							addLog(34, mutation.data.createNewContent.message);
						}
						setStatus(false);
					}
				} catch (e) {
					toastr.options = {
						positionClass: 'toast-top-center',
					};

					e.graphQLErrors.map(({ message }) => {
						toastr.error(message);
						addLog(34, message);
					});
				}
			};

			const updateContent = async (mediaid) => {
				let PublishDate = values.startedDate;
				let PublishEndDate = values.endedDate;

				let relatedData = {};
				if (relatedNews?.value && relatedOrder?.value) {
					relatedData = relatedNews.value;
					relatedData.Order = relatedOrder.value;
				}

				const mutation = await client.mutate({
					mutation: UPDATE_NEW_CONTENT,
					variables: {
						input: {
							ContentId,
							SocialMediaTitle,
							Title: values.Title,
							SecondTitle: values.SecondTitle,
							ThirthTitle: values.ThirthTitle,
							TitleType: selectedTitleType,
							HideTitle: hideTitle,
							IsLive: isLive,
							IsVideoNews: isVideoNews,
							RelatedNews: JSON.stringify(relatedData),
							RelatedNewList: JSON.stringify(relatedNewsArray),
							Spot: values.Spot,
							MainPageTitle: values.MainPageTitle,
							SeoTitle: values.MainPageTitle,
							SpotMediaId: mediaid,
							TwitterAccounts,
							PublishDate,
							PublishEndDate,
							IsMainPage: values.IsMainPage,
							IsHeadline: values.IsHeadline,
							HeadlineOrder: values.HeadlineOrder,
							CategoryList,
							TagList,
							Source: values.Source,
							Body: contentBody,
							Video: values.Video,
						},
					},
				});

				if (mutation) {
					if (mutation.data.updateNewContent.success) {
						await api.cache(ContentId);
						await api.contentCache(ContentId);
						let logData = `Eklenen Haber Id'si : ${ContentId}, Tweet ${values.PostToTwitter ? 'Gönderildi' : 'Gönderilmedi'}`;
						addLog(2, logData);
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(mutation.data.updateNewContent.message);

						//api.getHomepage(`https://ajansspor.com/haber/${seoUrl(mutation.data.updateNewContent.content.Title + '-' + ContentId)}`);

						if (values.IsMainPage || values.IsHeadline) {
							resetMainpageCache();
						}

						//setTimeout(() => window.location.reload(), 1500);
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error(mutation.data.updateNewContent.message);
						addLog(34, mutation.data.updateNewContent.message);
					}
					setStatus(false);
				}
			};

			if (ContentType === 0 && ContentId) {
				// update content
				if (Object.keys(errors).length === 0) {
					if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
						if (values.ImageTitle === '') {
							return;
						}
						let data = new FormData();

						let file = dataURLtoFile(imageCrop, values.ImageTitle);

						data.append('content', file);
						data.append('ImageTitle', values.ImageTitle);
						if (!holder.value) {
							data.append('Holder', holder.value);
						}

						await api.contentImageUpload(data).then(async (res) => {
							if (res.success) {
								if (res.mediaId) {
									addLog(16, `<a href="https://ajssarimg.mediatriple.net/${res.mediaId}.jpg" target="_blank">${res.mediaId}</a> id'li fotoğraf eklendi.`);

									api.redis.setMedia(res.mediaId).then((res) => console.log(res));

									updateContent(res.mediaId);
								}
							} else {
								toastr.options = {
									positionClass: 'toast-top-center',
								};
								toastr.error(res.message);
								addLog(34, res.message);
							}
						});
					} else if (imageCrop) {
						updateContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
					} else {
						updateContent(content.SpotMediaId);
					}
				}
			} else {
				// create content
				if (imageCrop && imageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
					let data = new FormData();

					let file = dataURLtoFile(imageCrop, values.ImageTitle);

					data.append('content', file);
					data.append('ImageTitle', values.ImageTitle);
					if (!holder.value) {
						data.append('Holder', holder.value);
					}

					api.contentImageUpload(data).then(async (res) => {
						if (res.success) {
							if (res.mediaId) {
								addLog(16, `<a href="https://ajssarimg.mediatriple.net/${res.mediaId}.jpg" target="_blank">${res.mediaId}</a> id'li fotoğraf eklendi.`);
								api.redis.setMedia(res.mediaId).then((res) => console.log(res));

								if (galleryImageCrop && galleryImageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
									let galleryData = new FormData();
									let galleryFile = dataURLtoFile(galleryImageCrop, GalleryTitle);

									galleryData.append('content', galleryFile);
									galleryData.append('ImageTitle', GalleryTitle);

									api.contentImageUpload(galleryData).then(async (response) => {
										if (response.success) {
											if (response.mediaId) {
												addLog(16, `<a href="https://ajssarimg.mediatriple.net/${response.mediaId}.jpg" target="_blank">${response.mediaId}</a> id'li fotoğraf eklendi.`);
												api.redis.setMedia(response.mediaId).then((res) => console.log(res));
												createContent(res.mediaId, response.mediaId);
											}
										} else {
											toastr.options = {
												positionClass: 'toast-top-center',
											};
											toastr.error(response.message);
											addLog(34, response.message);
										}
									});
								} else if (galleryImageCrop) {
									createContent(res.mediaId, parseInt(galleryImageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
								} else {
									createContent(res.mediaId, null);
								}
							}
						} else {
							toastr.options = {
								positionClass: 'toast-top-center',
							};
							toastr.error(res.message);
							addLog(34, res.message);
						}
					});
				} else if (imageCrop) {
					if (galleryImageCrop && galleryImageCrop.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
						let galleryData = new FormData();
						let galleryFile = dataURLtoFile(galleryImageCrop, GalleryTitle);

						galleryData.append('content', galleryFile);
						galleryData.append('ImageTitle', GalleryTitle);

						api.contentImageUpload(galleryData).then(async (response) => {
							if (response.success) {
								if (response.mediaId) {
									addLog(16, `<a href="https://ajssarimg.mediatriple.net/${response.mediaId}.jpg" target="_blank">${response.mediaId}</a> id'li fotoğraf eklendi.`);
									api.redis.setMedia(response.mediaId).then((res) => console.log(res));
									createContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10), response.mediaId);
								}
							} else {
								toastr.options = {
									positionClass: 'toast-top-center',
								};
								toastr.error(response.message);
								addLog(34, response.message);
							}
						});
					} else if (galleryImageCrop) {
						createContent(
							parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10),
							parseInt(galleryImageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10),
						);
					} else {
						createContent(parseInt(imageCrop.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10), null);
					}
				} else {
					if (newType === 'general') {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error('Haber fotoğrafı seçmelisiniz.');
						setStatus(false);
					} else {
						createContent(1131980, 1131980);
					}
				}
			}
		},
	});

	const selectTags = (selectedTags) => {
		let tags = [];

		if (Array.isArray(selectedTags)) {
			selectedTags.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiTags(tags);
			setSelectedTags(selectedTags);
			//setLocalStorage('Tags', JSON.stringify(selectedTags));
		} else {
			setSelectedTags([]);
			setSelectedApiTags([]);
			//setLocalStorage('Tags', []);
		}
	};

	const selectNews = (selectedNews, index) => {
		let array = relatedNewsArray;
		array[index].value = selectedNews;
		setRelatedNewsArray(array);
		setRelatedNews(selectedNews);
	};

	const getTags = async (inputValue, callback) => {
		if (inputValue.length >= 2) {
			let tags = [];

			await api.tags.search(inputValue).then((res) => {
				res.forEach(function (tag) {
					tags.push({ label: tag.Name.trim(), value: tag.TagId });
				});
			});

			callback(tags);
		}
	};

	const getAsyncOptions = async (text) => {
		return api.content.search(text).then((response) => {
			if (response.success) {
				return response.content.map((item) => ({
					label: item.MainPageTitle ? item.MainPageTitle : item.Title,
					value: { Title: item.MainPageTitle ? item.MainPageTitle : item.Title, SpotMediaId: item.SpotMediaId, Url: seoUrl(item.Title + '-' + item.ContentId) },
				}));
			}
		});
	};

	//const loadOptions = (text) => getAsyncOptions(text);
	//const debounceLoadOptions = lodash.debounce(loadOptions, 500);

	const loadOptions = useCallback(
		lodash.debounce((inputText, callback) => {
			getAsyncOptions(inputText).then((options) => callback(options));
		}, 500),
		[],
	);

	useEffect(() => {
		//console.log(loadOptions);
	}, [loadOptions]);

	const getNews = async (inputValue, callback) => {
		if (inputValue.length >= 2) {
			// let news = [];
			// await api.content.search(inputValue).then((res) => {
			// 	if (res.success) {
			// 		res.content.forEach(function (item) {
			// 			news.push({
			// 				label: item.MainPageTitle ? item.MainPageTitle : item.Title,
			// 				value: { Title: item.MainPageTitle ? item.MainPageTitle : item.Title, SpotMediaId: item.SpotMediaId, Url: seoUrl(item.Title + '-' + item.ContentId) },
			// 			});
			// 		});
			// 		setRelatedNewsList(news);
			// 		callback(news);
			// 	}
			// });
		}
	};

	const selectRelatedNewsOrder = (inputValue, index) => {
		let array = relatedNewsArray;
		array[index].index = inputValue.value;
		setRelatedNewsArray(array);
		setRelatedOrder(inputValue);
	};

	const selectCategory = (selectedCategory) => {
		if (Array.isArray(selectedCategory)) {
			let tags = [];

			selectedCategory.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedCategory(selectedCategory);
			setSelectedApiCategory(tags);
			//setLocalStorage('Category', JSON.stringify(selectedCategory));
		} else {
			setSelectedCategory([]);
			setSelectedApiCategory([]);
		}
	};

	const selectTitleType = (e) => {
		setSelectedTitleTypeOption(e);
		setSelectedTitleType(e.value);
	};

	const addEmoji = (emoji) => {
		setEmojiStatus(false);
		let curpos = document.getElementById('social-media-title').selectionStart;
		setSocialMediaTitle(SocialMediaTitle.slice(0, curpos) + emoji.native + SocialMediaTitle.slice(curpos));
	};

	const selectTwitterAccount = (selectedTwitter) => {
		if (Array.isArray(selectedTwitter)) {
			let twitterAccounts = [];

			selectedTwitter.forEach(function (account) {
				twitterAccounts.push(account.value);
			});

			setSelectedApiTwitter(twitterAccounts);
			setSelectedTwitter(selectedTwitter);
		} else {
			setSelectedTwitter([]);
			setSelectedApiTwitter([]);
		}
	};

	const onChangeImage = (image) => {
		setImageCrop(image);

		if (image && image.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
			setFieldValue('ImageTitle', '');
		} else {
			setFieldValue('ImageTitle', 'image');
		}
	};

	const onChangeGalleryImage = (image) => {
		setGalleryImageCrop(image);
	};

	const gallery_toggle = () => {
		setGalleryModal(!galleryModal);
	};

	const titleType_toggle = () => {
		setTitleTypeModal(!titleTypeModal);
	};

	const onChangeImageInput = (e) => {
		e.preventDefault();
		setGalleryModal(false);
		setSelectedFromLocal(true);
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			setSelectedImage(reader.result);
		};

		reader.readAsDataURL(files[0]);
	};

	const dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n) {
			u8arr[n - 1] = bstr.charCodeAt(n - 1);
			n -= 1; // to make eslint happy
		}
		return new File([u8arr], filename, { type: mime });
	};

	const selectHolder = (selectedHolder) => {
		setHolder(selectedHolder);
	};

	const createGallery = () => {
		// if (galleryContent.length > 0) {
		if (selectedImage && selectedImage.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
			const errors = validate({ GalleryImageTitle });
			setErrors(errors);

			if (Object.keys(errors).length === 0) {
				let galleryData = new FormData();
				let galleryFile = dataURLtoFile(selectedImage, GalleryTitle);

				galleryData.append('content', galleryFile);
				galleryData.append('ImageTitle', GalleryTitle);

				api.contentImageUpload(galleryData).then(async (response) => {
					if (response.success) {
						if (response.mediaId) {
							addLog(16, `<a href="https://ajssarimg.mediatriple.net/${response.mediaId}.jpg" target="_blank">${response.mediaId}</a> id'li fotoğraf eklendi.`);
							api.redis.setMedia(response.mediaId).then((res) => console.log(res));
							createGalleryItem(response.mediaId);
						}
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error(response.message);
						addLog(34, response.message);
					}
				});
			}
		} else if (selectedImage) {
			createGalleryItem(parseInt(selectedImage.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
		} else {
			createGalleryItem(null);
		}
		// } else {
		// 	toastr.options = {
		// 		positionClass: 'toast-top-center',
		// 	};
		// 	toastr.warning('Galeri içeriği girmelisiniz.');
		// }
	};

	const createGalleryItem = async (mediaid) => {
		setStatus(true);
		let GalleryContent = JSON.stringify({
			Title: GalleryTitle,
			Spot: galleryContent,
			Order: 1,
			MediaId: mediaid,
			Location: null,
			IsDeleted: false,
			CreatedAt: moment().format(),
			UpdatedAt: moment().format(),
		});

		try {
			const mutation = await client.mutate({
				mutation: CREATE_GALLERY,
				variables: {
					input: {
						ContentId,
						GalleryContent,
					},
				},
			});

			if (mutation) {
				if (mutation.data.createGallery.success) {
					await api.cache(ContentId);
					await api.contentCache(ContentId);
					let logData = `Eklenen Galeri Id'si : <a href="https://admin.ajansspor.com/news/news/${ContentId}" target="_blank" />${ContentId}</a>`;
					addLog(5, logData);
					// await api.logs.addEditorNewsLogs({
					// 	ContentTitle: values.Title,
					// 	StartedDate: localStorage.getItem('newsCreateStartedDate'),
					// 	EndedDate: moment(),
					// 	ParentId: ContentId,
					// });
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(mutation.data.createGallery.message);

					setTimeout(() => window.location.reload(), 3000);

					removeLocalStorage('GalleryTitle');
					removeLocalStorage('GalleryContent');
				} else {
					toastr.option = {
						positionClass: 'toast-top-center',
					};
					toastr.error(mutation.data.createGallery.message);
				}
				setStatus(false);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};

			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});
		}
	};

	const updateGalleryItem = (UpdatingId, GalleryTitle, UpdatingSpot, GalleryMediaId, GalleryOrder) => {
		// let elem = document.getElementById('editGallery');
		// elem.scrollIntoView();

		setUpdatingId(UpdatingId);
		SetGalleryTitle(GalleryTitle);
		setGalleryContent(UpdatingSpot);
		//setGalleryMediaId(GalleryMediaId);
		setGalleryOrder(GalleryOrder);
		if (GalleryMediaId) {
			setSelectedImage(`https://ajssarimg.mediatriple.net/${GalleryMediaId}.jpg`);
		} else {
			setSelectedImage(null);
		}
	};

	const deleteGalleryItem = async (id) => {
		setStatus(true);
		const mutation = await client.mutate({
			mutation: DELETE_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					Order: parseInt(id),
				},
			},
		});
		if (mutation) {
			if (mutation.data.deleteGallery.success) {
				await api.cache(ContentId);
				await api.contentCache(ContentId);
				let logData = `Silinen Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/${id}" target="_blank" />${id}</a>`;
				addLog(6, logData);
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.deleteGallery.message);
				setTimeout(() => window.location.reload(), 3000);
			} else {
				toastr.option = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.deleteGallery.message);
			}
			setStatus(false);
		}
	};

	const deleteAgencyGalleryItem = async (id) => {
		setStatus(true);
		const mutation = await client.mutate({
			mutation: DELETE_AGENCY_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					Order: parseInt(id),
				},
			},
		});
		if (mutation) {
			if (mutation.data.deleteAgencyGallery.success) {
				await api.cache(ContentId);
				await api.contentCache(ContentId);
				let logData = `Silinen Ajans Haber Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/agency:${id}" target="_blank" />${id}</a>`;
				addLog(6, logData);
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.deleteAgencyGallery.message);
				setTimeout(() => window.location.reload(), 3000);
			} else {
				toastr.option = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.deleteAgencyGallery.message);
			}
			setStatus(false);
		}
	};

	const validate = (data) => {
		const errors = {};
		if (!data.GalleryImageTitle) errors.GalleryImageTitle = 'Fotoğraf başlığı boş olamaz.';
		return errors;
	};

	const editGallery = () => {
		if (selectedImage && selectedImage.indexOf(`.${process.env.REACT_APP_IMG_SPLIT}/`) < 0) {
			if (GalleryImageTitle === '') {
				const errors = validate({ GalleryImageTitle });
				setErrors(errors);
				return;
			} else {
				// Burası değiştirildi

				let data = new FormData();

				let file = dataURLtoFile(selectedImage, GalleryImageTitle);

				data.append('content', file);
				data.append('ImageTitle', GalleryImageTitle);

				api.contentImageUpload(data).then(async (res) => {
					if (res.success) {
						if (res.mediaId) {
							api.redis.setMedia(res.mediaId).then((res) => console.log(res));
							setGalleryMediaId(res.mediaId);
							updatedGalleryItem(res.mediaId);
						}
					}
				});
			}
		} else if (selectedImage) {
			updatedGalleryItem(parseInt(selectedImage.split(`.${process.env.REACT_APP_IMG_SPLIT}/`)[1].split('.')[0], 10));
		} else {
			updatedGalleryItem(selectedImage);
		}
	};

	const updatedGalleryItem = async (mediaid) => {
		setStatus(true);

		const GalleryContent = JSON.stringify({
			Title: GalleryTitle,
			Spot: galleryContent,
			Order: GalleryOrder,
			MediaId: mediaid,
			Location: null,
			IsDeleted: false,
			CreatedAt: moment().format(),
			UpdatedAt: moment().format(),
		});

		const mutation = await client.mutate({
			mutation: UPDATE_GALLERY,
			variables: {
				input: {
					ContentId: ContentId,
					Order: parseInt(UpdatingId),
					GalleryContent,
				},
			},
		});

		if (mutation) {
			if (mutation.data.updateGallery.success) {
				await api.cache(ContentId);
				await api.contentCache(ContentId);
				let logData = `Düzenlenen Galeri Id'si : <a href="https://admin.ajansspor.com/news/content/${ContentId}" target="_blank" />${ContentId}</a>`;
				addLog(4, logData);
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.updateGallery.message);

				setTimeout(() => window.location.reload(), 5000);
			} else {
				toastr.option = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.updateGallery.message);
			}
			setStatus(false);
		}
	};

	const sendTweet = async () => {
		let title = SocialMediaTitle;
		let accounts = JSON.stringify(selectedApiTwitter);
		let contentId = ContentId;

		if (!scheduledTweet) {
			const mutation = await client.mutate({
				mutation: POST_TWEET,
				variables: {
					input: {
						title,
						accounts,
						contentId,
					},
				},
			});

			if (mutation) {
				if (mutation.data.postTwit.success) {
					let logData = `${ContentId} id'li habere tweet gönderildi.`;
					addLog(2, logData);
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(mutation.data.postTwit.message);
					//setTimeout(() => window.location.reload(), 5000);
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error(mutation.data.postTwit.message);
					addLog(34, mutation.data.postTwit.message);
				}
			}
		} else {
			if (startedDate) {
				let now = moment().isAfter(startedDate);

				if (now) {
					toastr.options = {
						progressBar: true,
						positionClass: 'toast-top-center',
					};
					toastr.warning('Geçmiş zaman dilimi kullandınız. Gelecekteki bir zamanı seçin.');
				} else {
					const mutation = await client.mutate({
						mutation: POST_SCHEDULED_TWEET,
						variables: {
							input: {
								title,
								accounts,
								contentId,
								scheduleTime: startedDate,
							},
						},
					});

					if (mutation) {
						if (mutation.data.createScheduledTwit.success) {
							let logData = `${ContentId} id'li habere tweet gönderildi.`;
							addLog(2, logData);
							toastr.options = {
								positionClass: 'toast-top-center',
								progressBar: true,
							};
							toastr.success(mutation.data.createScheduledTwit.message);
							//setTimeout(() => window.location.reload(), 5000);
						} else {
							toastr.options = {
								positionClass: 'toast-top-center',
							};
							toastr.error(mutation.data.createScheduledTwit.message);
							addLog(34, mutation.data.createScheduledTwit.message);
						}
					}
				}
			} else {
				toastr.options = {
					progressBar: true,
					positionClass: 'toast-top-center',
				};
				toastr.warning('Planlanan tarih/saati girin.');
			}
		}
	};

	const resetMainpageCache = async (link) => {
		api.homepageCache();
		api.getHomepage(link);
	};

	const galleryPageChange = (pageNumber) => {
		searchImage(searchedImage, pageNumber);
	};

	const searchImage = (param, pageNumber) => {
		// setSearchedImage(param);
		// if (pageNumber > 0) {
		// 	setCurrentPage(pageNumber);
		// }
		// if (param.length > 4) {
		// 	api.image.getMediaSearch(param, 21, 21 * (pageNumber - 1)).then((res) => {
		// 		setImagesData(res);
		// 		setTotalImageCount(res.count);
		// 	});
		// } else if (param === '') {
		// 	api.image.getMedia(21, 21 * (pageNumber - 1)).then((res) => {
		// 		setImagesData(res);
		// 		setTotalImageCount(res.count);
		// 	});
		// }
	};

	const removeRealtedNew = (index) => {
		const newArray = relatedNewsArray;
		newArray.splice(index, 1);
		setRelatedNewsArray(newArray);
	};

	const changeSpotCharCount = (e) => {
		setSpotCharCount(e.target.value.length);
	};
	const changeSeoTitleCharCount = (e) => {
		setSeoTitleCharCount(e.target.value.length);
	};

	const spotCharLimit = 150;
	const seoTitleCharLimit = 70;

	return (
		<div className="page-content form-padding">
			<Container fluid>
				<Breadcrumbs title="Haber Yönetimi" breadcrumbItem={`Haber ${ContentId ? 'Düzenle' : 'Ekle'}`} />

				<Row>
					<Col className="col-12">
						<Card>
							<CardBody>
								<div className="d-flex justify-content-between">
									<div>
										<CardTitle>{ContentId ? 'Haber Düzenle' : 'Yeni Haber Ekle'}</CardTitle>

										{Object.keys(editorInfo).length > 0 && (
											<CardSubtitle>
												Bu haber <b>{editorInfo.name}</b> tarafından <b>{moment(editorInfo.date).format('DD MMMM YYYY HH:mm:ss')}</b> tarihinde eklenmiştir.
												<br />
											</CardSubtitle>
										)}
									</div>
								</div>
							</CardBody>
						</Card>
					</Col>
				</Row>

				<Form onSubmit={handleSubmit}>
					<Row>
						<Col lg={8}>
							<Card>
								<CardBody>
									<div className="d-flex flex-row justify-content-between align-items-center">
										<CardTitle className="mb-0">Haber Bilgileri </CardTitle>
										<button type="submit" className="btn btn-primary btn-label">
											<i className={`bx bx-loader bx-spin label-icon ${status ? 'active' : ''}`}></i>
											Kaydet
										</button>
									</div>

									{!ContentId && (
										<div className="mt-4 mb-4">
											<h5 className="font-size-14 mb-3">Haber Tipi Seçin</h5>

											<div className="d-flex flex-row">
												<div className="custom-control custom-radio mr-3">
													<input
														type="radio"
														id="quick-news"
														name="customRadio"
														className="custom-control-input"
														onChange={() => setNewType('quick')}
														checked={newType === 'quick'}
													/>
													<label className="custom-control-label" htmlFor="quick-news">
														Hızlı Haber
													</label>
												</div>
												<div className="custom-control custom-radio">
													<input
														type="radio"
														id="general-news"
														name="customRadio"
														className="custom-control-input"
														onChange={() => setNewType('general')}
														checked={newType === 'general'}
													/>
													<label className="custom-control-label" htmlFor="general-news">
														Genel Haber
													</label>
												</div>
											</div>
										</div>
									)}

									{newType === 'general' && (
										<Row className="mt-3">
											{/* Burası aynı zamanda SEO Başlık. */}
											<Col lg="12">
												<FormGroup>
													<Label for="MainPageTitle">Haber Başlığı (Seo Başlık)</Label>
													<Input
														id="MainPageTitle"
														name="MainPageTitle"
														onChange={(e) => {
															handleChange(e);
															changeSeoTitleCharCount(e);
															//'MainPageTitle', e.target.value);
														}}
														value={newType === 'general' ? values.MainPageTitle : values.Title}
													/>
													{errors.MainPageTitle && <div className="invalid-feedback d-block">{errors.MainPageTitle}</div>}
													<span
														style={{ color: `${seoTitleCharCount >= seoTitleCharLimit ? '#f46a6a' : seoTitleCharCount >= seoTitleCharLimit - 20 ? '#f1b44c' : 'green'}` }}
													>
														{seoTitleCharCount} / {seoTitleCharLimit}
													</span>
												</FormGroup>
											</Col>

											{/* <Col lg={12}>
												<FormGroup className="select2-container">
													<Label className="control-label">Haber Başlık</Label>
													<Input
														id="seo-title"
														name="SeoTitle"
														onChange={(e) => {
															handleChange(e);
															changeSeoTitleCharCount(e);
														}}
														value={newType === 'general' ? values.SeoTitle : values.Title}
													/>
													{errors.SeoTitle && <div className="invalid-feedback d-block">{errors.SeoTitle}</div>}
													<span
														style={{ color: `${seoTitleCharCount >= seoTitleCharLimit ? '#f46a6a' : seoTitleCharCount >= seoTitleCharLimit - 20 ? '#f1b44c' : 'green'}` }}
													>
														{seoTitleCharCount} / {seoTitleCharLimit}
													</span>
												</FormGroup>
											</Col> */}

											<Col lg="12">
												<FormGroup>
													<Label for="spot">Spot</Label>
													<textarea
														id="spot"
														className="form-control"
														rows="2"
														name="Spot"
														onChange={(e) => {
															handleChange(e);
															changeSpotCharCount(e);
															//setLocalStorage('Spot', e.target.value);
														}}
														value={values.Spot}
													/>
													{errors.Spot && <div className="invalid-feedback d-block">{errors.Spot}</div>}
													<span style={{ color: `${spotCharCount >= spotCharLimit ? '#f46a6a' : spotCharCount >= spotCharLimit - 20 ? '#f1b44c' : 'green'}` }}>
														{spotCharCount} / {spotCharLimit}
													</span>
												</FormGroup>
											</Col>
										</Row>
									)}

									<Row className="mt-2">
										<Col lg="12">
											<FormGroup>
												<Label for="title">Manşet Başlığı</Label>
												<Input
													type="text"
													id="title"
													name="Title"
													onChange={(e) => {
														handleChange(e);
														//setLocalStorage('Title', e.target.value);
													}}
													value={values.Title}
													placeholder="Ana Sayfa Başlık (Zorunlu)"
												/>
												{errors.Title && <div className="invalid-feedback d-block">{errors.Title}</div>}
												{newType === 'general' && (
													<Input
														type="text"
														id="secondTitle"
														name="SecondTitle"
														onChange={handleChange}
														value={values.SecondTitle}
														className="mt-3"
														placeholder="İkinci Başlık"
													/>
												)}
												{newType === 'general' && (
													<Input
														type="text"
														id="thirthTitle"
														name="ThirthTitle"
														onChange={handleChange}
														value={values.ThirthTitle}
														className="mt-3"
														placeholder="Üçüncü Başlık"
													/>
												)}
											</FormGroup>
										</Col>
									</Row>

									{newType === 'general' && (
										<Row>
											<Col lg="12">
												<FormGroup className="select2-container">
													<Label className="control-label">Başlık Tipi</Label>
													<Select
														value={selectedTitleTypeOption}
														isMulti={false}
														onChange={selectTitleType}
														options={titleTypes}
														classNamePrefix="select2-selection"
														placeholder="Başlık Tipi Seçiniz."
														className="mb-2"
													/>
													<a
														href="#!"
														className="text-dark"
														title="Haberi Sitede Görüntüle"
														onClick={() => {
															setTitleTypeModal(true);
														}}
													>
														Örneği Görüntüle
													</a>
												</FormGroup>
											</Col>
										</Row>
									)}

									{newType === 'general' && (
										<Row className="mt-1">
											<Col lg="12">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														id="hide-title"
														name="HideTitle"
														onChange={() => setHideTitle(!hideTitle)}
														value={hideTitle}
														checked={hideTitle}
													/>
													<label className="custom-control-label" htmlFor="hide-title">
														Manşette başlığı gizle.
													</label>
												</div>
											</Col>
										</Row>
									)}

									{newType === 'general' && (
										<Row className="mt-2">
											<Col lg="12">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														id="isVideoNews"
														name="isVideoNews"
														onChange={() => setIsVideoNews(!isVideoNews)}
														value={isVideoNews}
														checked={isVideoNews}
													/>
													<label className="custom-control-label" htmlFor="isVideoNews">
														Video Haber Mi?
													</label>
												</div>
											</Col>
											{isVideoNews && (
												<Col lg={12} className="mt-3">
													<FormGroup>
														<Label for="Video">Video İframe</Label>
														<Input id="Video" name="Video" onChange={handleChange} value={values.Video} />
													</FormGroup>
												</Col>
											)}
										</Row>
									)}

									{newType === 'general' && (
										<Row className="mt-1">
											<Col lg="12">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														className="custom-control-input"
														id="is-live"
														name="IsLive"
														onChange={() => setIsLive(!isLive)}
														value={isLive}
														checked={isLive}
													/>
													<label className="custom-control-label" htmlFor="is-live">
														Canlı Haber Mi?
													</label>
													<br />
													<small style={{ color: 'tomato' }}>
														ÖNEMLİ: Canlı haber tamamlandığında canlı haber durumunu pasif hale getirmelisiniz. Biten canlı haberi tekrar canlı habere almamalısınız.
													</small>
												</div>
											</Col>
										</Row>
									)}

									{newType === 'general' && (
										<Row className="mt-3">
											<Col lg={12}>
												<FormGroup>
													<Label for="Source">Kaynak</Label>
													<Input id="Source" name="Source" onChange={handleChange} value={values.Source} />
												</FormGroup>
											</Col>
										</Row>
									)}

									{newType === 'general' && (
										<Row>
											<Col lg="6">
												<Label>Yayın Başlangıç Tarihi</Label>
												<InputGroup className="form-group">
													<DatePicker
														className={`form-control ${errors.startedDate && 'is-invalid'}`}
														selected={values.startedDate}
														onChange={handleChange}
														locale="tr"
														showTimeSelect
														timeFormat="HH:mm"
														timeIntervals={15}
														timeCaption="Saat"
														dateFormat="d.MM.yyyy HH:mm"
														showMonthDropdown
														showYearDropdown
													/>
												</InputGroup>
												{errors.startedDate && <div className="invalid-feedback d-block">{errors.startedDate}</div>}
											</Col>

											<Col lg="6">
												<Label>Yayın Bitiş Tarihi</Label>
												<InputGroup className="form-group">
													<DatePicker
														className={`form-control ${errors.endedDate && 'is-invalid'}`}
														selected={values.endedDate}
														onChange={handleChange}
														locale="tr"
														showTimeSelect
														timeFormat="HH:mm"
														timeIntervals={15}
														timeCaption="Saat"
														dateFormat="d.MM.yyyy HH:mm"
														showMonthDropdown
														showYearDropdown
													/>
												</InputGroup>
												{errors.endedDate && <div className="invalid-feedback d-block">{errors.endedDate}</div>}
											</Col>
										</Row>
									)}
								</CardBody>
							</Card>

							{ContentId && newType === 'general' ? (
								<Row>
									{content.ContentType === 27 && (
										<Col lg={12}>
											<Card>
												<CardBody>
													<div className="d-flex flex-row justify-content-between align-items-center">
														<CardTitle className="mb-0">Galeri Ekle</CardTitle>
													</div>

													<div className="mt-4 mb-4">
														<Row>
															<Col xl={12}>
																<FormGroup>
																	<Label for="GalleryTitle">Galeri Başlığı</Label>

																	<div className="d-flex flex-row">
																		<Input
																			id="GalleryTitle"
																			name="GalleryTitle"
																			onChange={(e) => {
																				SetGalleryTitle(e.target.value);
																				//setLocalStorage('GalleryTitle', e.target.value);
																			}}
																			value={GalleryTitle}
																		/>

																		{UpdatingId !== 0 && (
																			<button
																				type="button"
																				className="btn btn-success ml-2"
																				onClick={() => {
																					setUpdatingId(0);
																					SetGalleryTitle('');
																					setGalleryContent('');
																					setSelectedImage(null);
																				}}
																			>
																				İptal
																			</button>
																		)}
																	</div>
																</FormGroup>
															</Col>
														</Row>

														<Row>
															<Col xl={12}>
																<TextEditor
																	onChange={(text) => {
																		setGalleryContent(text);
																		//setTimeout(() => setLocalStorage('GalleryContent', text), 50);
																	}}
																	value={galleryContent}
																/>
															</Col>
														</Row>
														{/* {GalleryContentArray.length % 3 === 0 && !UpdatingId && (
															<Row className="mt-2">
																<Col xl={12}>
																	<FormGroup>
																		<Label for="GalleryImageTitle">Fotoğraf Başlığı</Label>
																		<Input
																			className={`mb-4 ${validateErrors.GalleryImageTitle && 'is-invalid'}`}
																			id="GalleryImageTitle"
																			name="GalleryImageTitle"
																			onChange={(e) => setGalleryImageTitle(e.target.value)}
																			value={GalleryImageTitle}
																		/>
																	</FormGroup>
																</Col>

																<Col xl={12}>
																	<ImageCropper key="1" type="gallery" imageChanged={(e) => onChangeGalleryImage(e)} />
																</Col>
															</Row>
														)} */}

														<Row className="mt-4">
															{selectedFromLocal && (
																<Col xl={12}>
																	<FormGroup>
																		<Label for="GalleryImageTitle">Fotoğraf Başlığı</Label>
																		<Input
																			className={`mb-2 ${validateErrors.GalleryImageTitle ? 'is-invalid' : ''}`}
																			id="GalleryImageTitle"
																			name="GalleryImageTitle"
																			onChange={(e) => setGalleryImageTitle(e.target.value)}
																			value={GalleryImageTitle}
																		/>
																	</FormGroup>
																</Col>
															)}
															{selectedImage && (
																<Col xl={9} className="d-flex flex-column justify-content-center">
																	<div className="text-center">
																		<img height="200px" width="auto" src={selectedImage ? selectedImage : ''} />
																	</div>
																</Col>
															)}

															<Col xl={selectedImage ? 3 : 12} className="d-flex flex-column justify-content-center ">
																<Button className="btn btn-warning mb-4 w-100" onClick={() => gallery_toggle()}>
																	Galeriden Seç
																</Button>
																<Label className="btn btn-primary btn-upload w-100" htmlFor="inputImage" title="Fotoğraf seçin.">
																	<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={(e) => onChangeImageInput(e)} />
																	<span>Fotoğraf Yükle</span>
																</Label>
																{selectedImage !== null ? (
																	<Button
																		className="btn btn-danger mt-4 w-100"
																		onClick={() => {
																			setSelectedImage(null);
																			setSelectedFromLocal(false);
																		}}
																	>
																		Fotoğrafı Kaldır
																	</Button>
																) : (
																	<></>
																)}
															</Col>
														</Row>

														{/* {UpdatingId ? (
															<Row className="mt-2">
																<Col xl={12}>
																	<FormGroup>
																		<Label for="GalleryImageTitle">Fotoğraf Başlığı</Label>
																		<Input
																			className={`mb-4 ${validateErrors.GalleryImageTitle && 'is-invalid'}`}
																			id="GalleryImageTitle"
																			name="GalleryImageTitle"
																			onChange={(e) => setGalleryImageTitle(e.target.value)}
																			value={GalleryImageTitle}
																		/>
																	</FormGroup>
																</Col>

																<Col className='text-center' xl={12}>
																	<ImageCropper
																		key="1"
																		type="gallery"
																		imageChanged={(e) => onChangeGalleryImage(e)}
																		defaultSrc={GalleryMediaId !== null ? GalleryMediaId.toString() : ''}
																	/>
																</Col>
															</Row>
														) : (
																''
															)} */}
													</div>
												</CardBody>
											</Card>
										</Col>
									)}

									{content.ContentType === 27 && (
										<Col lg={12}>
											<Card>
												<CardBody>
													<div className="d-flex flex-row justify-content-between align-items-center">
														<CardTitle className="mb-0">Haber Galerisi</CardTitle>
													</div>

													{content.GalleryContent && content.GalleryContent.length > 0 ? (
														<>
															<div className="headline__list headline__list--bordered gallery__list">
																<li>
																	<div className="headline__order">#</div>
																	<div className="headline__title">Başlık / Spot</div>
																	<div className="d-flex ml-auto justify-content-between">
																		<div className="btn__groups">#</div>
																	</div>
																</li>
															</div>

															<div className="headline__list gallery__list">
																{content.GalleryContent.map(
																	(gallery, i) =>
																		!gallery.IsDeleted && (
																			<li key={i}>
																				<div className="headline__order">{i + 1}</div>
																				<div className="headline__title">
																					<h5 className="font-size-14 mb-1">
																						<a href="#!" className="text-dark" title="Haberi Sitede Görüntüle">
																							{gallery.Title}
																						</a>
																					</h5>
																					<p className="text-muted mb-0 galleryInput" dangerouslySetInnerHTML={{ __html: gallery.Spot }}></p>
																				</div>
																				<div className="d-flex ml-auto justify-content-between">
																					<div className="btn__groups">
																						{UpdatingId !== gallery.Order ? (
																							<>
																								<button
																									type="button"
																									onClick={() =>
																										updateGalleryItem(gallery.Order, gallery.Title, gallery.Spot, gallery.MediaId, gallery.Order)
																									}
																									className="btn btn-primary"
																								>
																									Düzenle
																								</button>
																								{ContentType === 0 ? (
																									<button
																										type="button"
																										onClick={() =>
																											window.confirm('Silmek istediğinize emin misiniz?') && deleteGalleryItem(gallery.Order)
																										}
																										className="btn btn-danger ml-1"
																									>
																										Sil
																									</button>
																								) : (
																									<button
																										type="button"
																										onClick={() =>
																											window.confirm('Silmek istediğinize emin misiniz?') &&
																											deleteAgencyGalleryItem(gallery.Order)
																										}
																										className="btn btn-danger ml-1"
																									>
																										Sil
																									</button>
																								)}
																							</>
																						) : (
																							<span className="btn btn-success">Düzenleniyor...</span>
																						)}
																					</div>
																				</div>
																			</li>
																		),
																)}
															</div>
														</>
													) : (
														<Alert color="primary">Henüz galeri eklenmemiş.</Alert>
													)}
												</CardBody>
											</Card>
										</Col>
									)}

									{content.ContentType === 6 && (
										<Col lg={12}>
											<TextEditor id="body" name="Body" onChange={(text) => setContentBody(text)} value={contentBody} />
										</Col>
									)}
								</Row>
							) : (
								<></>
							)}
						</Col>

						<Col lg={4}>
							{newType === 'general' && (
								<>
									<Card>
										<CardBody>
											<Button className="btn btn-light p-3 w-100" id="news-settings">
												<h6 className="m-0 font-14 d-flex justify-content-between">
													<span className="text-dark">Haber Ayarları</span>
													<div className="fas fa-chevron-down"></div>
												</h6>
											</Button>

											<UncontrolledCollapse toggler="#news-settings">
												<div className="mt-2">
													<Row>
														<Col lg={12}>
															<FormGroup className="select2-container">
																<Label className="control-label">Etiketler</Label>
																<AsyncSelect
																	value={selectedTags}
																	isMulti
																	onChange={selectTags}
																	classNamePrefix="select2-selection"
																	placeholder="Etiket seçiniz"
																	loadOptions={getTags}
																	noOptionsMessage={() => 'En az 3 karakter yazmalısınız.'}
																/>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col lg={12}>
															<FormGroup className="select2-container">
																<Label className="control-label">Kategori</Label>

																<Select
																	value={selectedCategory}
																	isMulti={true}
																	onChange={selectCategory}
																	options={categories}
																	classNamePrefix="select2-selection"
																	placeholder="Kategori seçiniz"
																/>
															</FormGroup>
														</Col>
													</Row>

													<Row>
														<Col lg={12}>
															<div className="custom-control custom-checkbox mb-4 mt-2">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id="is-headline"
																	name="IsHeadline"
																	onChange={(e) => {
																		handleChange(e);
																	}}
																	value={values.IsHeadline}
																	checked={values.IsHeadline}
																/>
																<label className="custom-control-label" htmlFor="is-headline">
																	Manşette gözüksün mü?
																</label>
															</div>
														</Col>

														{values.IsHeadline && (
															<Col lg="12">
																<FormGroup>
																	<Label for="headline-order">Manşet Sırası</Label>
																	<Input type="number" id="headline-order" name="HeadlineOrder" onChange={handleChange} value={values.HeadlineOrder} />
																</FormGroup>
															</Col>
														)}

														<Col lg={12}>
															<div className="custom-control custom-checkbox mb-4 mt-2">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id="is-mainpage"
																	name="IsMainPage"
																	onChange={handleChange}
																	value={values.IsMainPage}
																	checked={values.IsMainPage}
																/>
																<label className="custom-control-label" htmlFor="is-mainpage">
																	Anasayfada gözüksün mü?
																</label>
															</div>
														</Col>
													</Row>
												</div>
											</UncontrolledCollapse>
										</CardBody>
									</Card>

									<Card>
										<CardBody>
											<Button className="btn btn-light p-3 w-100" id="news-media">
												<h6 className="m-0 font-14 d-flex justify-content-between">
													<span className="text-dark">Medya</span>
													<div className="fas fa-chevron-down"></div>
												</h6>
											</Button>

											<UncontrolledCollapse toggler="#news-media">
												<div className="mt-2">
													<Row>
														{values.ImageTitle !== 'image' && (
															<Col lg={12}>
																<FormGroup className="select2-container">
																	<Label className="control-label">Fotoğraf Başlığı</Label>
																	<Input id="image-title" name="ImageTitle" onChange={handleChange} value={values.ImageTitle} />
																	{errors.ImageTitle && <div className="invalid-feedback d-block">{errors.ImageTitle}</div>}
																</FormGroup>
															</Col>
														)}

														<Col lg="12">
															<FormGroup className="select2-container">
																<Label className="control-label">Görsel Tipi</Label>
																<Select value={holder} onChange={selectHolder} options={Holders} classNamePrefix="select2-selection" placeholder="Görsel tipi seçin" />
															</FormGroup>
														</Col>

														<Col lg={12}>
															<FormGroup className="select2-container">
																<Label className="control-label">Medya</Label>
																<ImageCropper imageChanged={(e) => onChangeImage(e)} defaultSrc={values.SpotMediaId.toString()} />
															</FormGroup>
														</Col>
													</Row>
												</div>
											</UncontrolledCollapse>
										</CardBody>
									</Card>

									{ContentId > 0 && (
										<Card>
											<CardBody>
												<Button className="btn btn-light p-3 w-100" id="related-news">
													<h6 className="m-0 font-14 d-flex justify-content-between">
														<span className="text-dark">(İç Link) Önerilen Haber</span>
														<div className="fas fa-chevron-down"></div>
													</h6>
												</Button>

												<UncontrolledCollapse toggler="#related-news">
													<div className="mt-2">
														{relatedNewsArray &&
															relatedNewsArray.map((item, index) => (
																<Row key={index} className="mt-3">
																	<Col lg={12} style={{ textAlign: 'center' }}>
																		<Label>{index + 1}. Önerilen Haber</Label>
																	</Col>
																	<Col lg={12}>
																		<FormGroup className="select2-container">
																			<Label className="control-label">Haber Ara</Label>
																			<AsyncSelect
																				// defaultInputValue={item}
																				value={item.value}
																				onChange={(e) => selectNews(e, index)}
																				classNamePrefix="select2-selection"
																				placeholder="Haber seçiniz"
																				loadOptions={loadOptions}
																				noOptionsMessage={() => 'En az 3 karakter yazmalısınız.'}
																			/>
																		</FormGroup>
																	</Col>
																	<Col lg={12}>
																		<FormGroup className="select2-container">
																			<Label className="control-label">Galeri Sırası Seç</Label>
																			<Select
																				defaultValue={
																					content?.RelatedNews?.Order
																						? { label: `${content.RelatedNews.Order}. Galeri Sonu`, value: content.RelatedNews.Order }
																						: {}
																				}
																				value={item.index ? { label: `${item.index}. Galeri Sonu`, value: item.index } : {}}
																				onChange={(e) => selectRelatedNewsOrder(e, index)}
																				options={relatedNewsOrderList}
																				classNamePrefix="select2-selection"
																				placeholder="Galeri sırası seçiniz"
																			/>
																		</FormGroup>
																	</Col>
																	<Col lg={12}>
																		<a
																			href="#!"
																			className="text-dark"
																			title="Haberi Sitede Görüntüle"
																			onClick={() => {
																				setRelatedOrder({});
																				setRelatedNews({});
																				removeRealtedNew(index);
																			}}
																		>
																			Önerilen Haberi Kaldır
																		</a>
																	</Col>
																</Row>
															))}
														<div className="mt-4">
															<a
																onClick={() => {
																	const newArray = [...relatedNewsArray, {}];
																	setRelatedNewsArray(newArray);
																}}
															>
																Yeni Ekle
															</a>
														</div>
													</div>
													{/* <div className="mt-2">
														<Row>
															<Col lg={12}>
																<FormGroup className="select2-container">
																	<Label className="control-label">Haber Ara</Label>
																	<AsyncSelect
																		defaultInputValue={relatedNewsList[0]}
																		value={relatedNews}
																		onChange={selectNews}
																		classNamePrefix="select2-selection"
																		placeholder="Haber seçiniz"
																		loadOptions={getNews}
																		noOptionsMessage={() => 'En az 3 karakter yazmalısınız.'}
																	/>
																</FormGroup>
															</Col>
															<Col lg={12}>
																<FormGroup className="select2-container">
																	<Label className="control-label">Galeri Sırası Seç</Label>
																	<Select
																		defaultValue={
																			content?.RelatedNews?.Order ? { label: `${content.RelatedNews.Order}. Galeri Sonu`, value: content.RelatedNews.Order } : {}
																		}
																		value={relatedOrder}
																		onChange={selectRelatedNewsOrder}
																		options={relatedNewsOrderList}
																		classNamePrefix="select2-selection"
																		placeholder="Galeri sırası seçiniz"
																	/>
																</FormGroup>
															</Col>
															<Col lg={12}>
																<a
																	href="#!"
																	className="text-dark"
																	title="Haberi Sitede Görüntüle"
																	onClick={() => {
																		setRelatedOrder({});
																		setRelatedNews({});
																	}}
																>
																	Önerilen Haberi Kaldır
																</a>
															</Col>
														</Row>
													</div> */}
												</UncontrolledCollapse>
											</CardBody>
										</Card>
									)}

									{/* <Card>
										<CardBody>
											<Button className="btn btn-light p-3 w-100" id="news-seo-settings">
												<h6 className="m-0 font-14 d-flex justify-content-between">
													<span className="text-dark">SEO Ayarları</span>
													<div className="fas fa-chevron-down"></div>
												</h6>
											</Button>

											<UncontrolledCollapse toggler="#news-seo-settings">
												<div className="mt-2">
													<Row>
														<Col lg={12}>
															<FormGroup className="select2-container">
																<Label className="control-label">SEO Başlık</Label>
																<Input id="seo-title" name="SeoTitle" onChange={handleChange} value={newType === 'general' ? values.SeoTitle : values.Title} />
																{errors.SeoTitle && <div className="invalid-feedback d-block">{errors.SeoTitle}</div>}
															</FormGroup>
														</Col>
													</Row>
												</div>
											</UncontrolledCollapse>
										</CardBody>
									</Card> */}
								</>
							)}

							<Card>
								<CardBody>
									<Button className="btn btn-light p-3 w-100" id="news-social-media">
										<h6 className="m-0 font-14 d-flex justify-content-between">
											<span className="text-dark">Sosyal Medya</span>
											<div className="fas fa-chevron-down"></div>
										</h6>
									</Button>

									<UncontrolledCollapse toggler="#news-social-media">
										<div className="mt-2">
											<Row>
												<Col lg={12}>
													<FormGroup className="select2-container">
														<Label className="control-label">Sosyal Medya Başlık</Label>
														<textarea
															className="form-control social__control"
															style={{ paddingRight: 45 }}
															id="social-media-title"
															name="SocialMediaTitle"
															onChange={(e) => setSocialMediaTitle(e.target.value)}
															value={SocialMediaTitle}
														/>
														<button type="button" style={{ marginRight: 10 }} className="emoji__buttons" onClick={() => setEmojiStatus(!emojiStatus)}>
															<i className="bx bx-smile"></i>
														</button>

														{emojiStatus && <Picker theme="dark" onSelect={addEmoji} i18n={i18n} />}
													</FormGroup>
												</Col>
											</Row>

											<Row className="mb-3">
												<Col lg={12}>
													<div className="custom-control custom-checkbox">
														<input
															type="checkbox"
															className="custom-control-input"
															id="is-scheduledTweet"
															name="scheduledTweet"
															onChange={() => setScheduledTweet(!scheduledTweet)}
															value={scheduledTweet}
															checked={scheduledTweet}
														/>
														<label className="custom-control-label" htmlFor="is-scheduledTweet">
															Planlı Tweet Gönder
														</label>
													</div>
												</Col>
											</Row>

											{scheduledTweet && (
												<FormGroup>
													<Label>Tweetin Yayına Giriş Tarihi</Label>
													<InputGroup className="form-group">
														<DatePicker
															className="form-control"
															selected={startedDate}
															locale="tr"
															onChange={(e) => {
																setStartedDate(e);
															}}
															showTimeSelect
															timeFormat="HH:mm"
															timeIntervals={15}
															timeCaption="Saat"
															dateFormat="d.MM.yyyy HH:mm"
															showMonthDropdown
															showYearDropdown
														/>
													</InputGroup>
												</FormGroup>
											)}

											<FormGroup className="select2-container">
												<Label className="control-label">Tweet Gönder</Label>
												<Query query={TWITTER_ACCOUNTS}>
													{({ loading, data }) => {
														if (loading) return null;

														let accounts = [];

														data?.twitterAccounts?.map((acc) => accounts.push({ label: acc.name, value: acc.name }));

														return (
															<Select
																value={selectedTwitter}
																isMulti={true}
																onChange={selectTwitterAccount}
																options={accounts}
																classNamePrefix="select2-selection"
																placeholder="Twitter adresi seçiniz"
															/>
														);
													}}
												</Query>
											</FormGroup>

											<div className="d-flex flex-row align-items-center">
												<Button className="btn btn-primary" onClick={sendTweet}>
													Tweet Gönder
												</Button>
											</div>
										</div>

										<hr />

										<CardTitle>Twitter Paylaşım Geçmişi</CardTitle>

										{content.Twits ? (
											content.Twits.accounts.map((account, i) => (
												<blockquote className="blockquote" key={i}>
													<small>{account.name} hesabında paylaşıldı.</small>
													<br />
													<small>&quot;{account.title}&quot;</small>
													<footer className="blockquote-footer">
														{account.createdBy.fullname} tarafından {moment(account.createdBy.date).format('DD/MM/YYYY HH:mm:ss')} tarihinde tweet atıldı.
													</footer>
												</blockquote>
											))
										) : (
											<p>Henüz tweet atılmamış.</p>
										)}

										<FormGroup>
											<Query query={SCHEDULED_CONTENT_TWITS} variables={{ ContentId: content.ContentId }}>
												{({ loading, data }) => {
													if (loading) return null;

													return (
														<>
															{data?.scheduledContentTwits && data?.scheduledContentTwits.length > 0 && <Label className="mt-2">Önceki Planlanmış Tweetler</Label>}
															{data?.scheduledContentTwits &&
																data?.scheduledContentTwits.map((item, index) => {
																	return (
																		<>
																			<Row>
																				<Col lg="12">
																					<Label className="mt-1" key={index}>
																						{item.title} başlığıyla, {item.createdBy.fullname} isimli kullanıcı,{' '}
																						{moment(item.scheduleTime).format('DD-MM-YYYY - HH:mm')} tarihinde tweet atılmasını planladı.
																					</Label>
																				</Col>
																			</Row>
																		</>
																	);
																})}
														</>
													);
												}}
											</Query>
										</FormGroup>
									</UncontrolledCollapse>
								</CardBody>
							</Card>

							<Notification Title={values.MainPageTitle} ContentId={ContentId} />
						</Col>
					</Row>

					<div className="wizard-fixed">
						<Container fluid>
							<Row>
								<Col className="col-12">
									<Card>
										<CardBody>
											<Row>
												<Col lg={12}>
													<div className="d-flex flex-row align-items-center">
														{ContentId && content.ContentType === 27 ? (
															UpdatingId === 0 ? (
																<button type="button" className="btn btn-warning mr-3" onClick={() => createGallery()}>
																	{status ? <i className="bx bx-loader bx-spin"></i> : 'Galeriyi Kaydet'}
																</button>
															) : (
																<button type="button" className="btn btn-warning mr-3" onClick={() => editGallery()}>
																	{status ? <i className="bx bx-loader bx-spin"></i> : 'Galeriyi Güncelle'}
																</button>
															)
														) : (
															<></>
														)}

														<div className="d-flex flex-row align-items-center">
															<button type="submit" className="btn btn-primary">
																{status ? <i className="bx bx-loader bx-spin"></i> : 'Haberi Kaydet'}
															</button>

															{!ContentId ? (
																<div className="custom-control custom-checkbox ml-3">
																	<input
																		type="checkbox"
																		className="custom-control-input"
																		id="is-draft"
																		name="IsDraft"
																		onChange={() => {
																			setDraft(!isDraft);
																		}}
																		value={isDraft}
																		checked={isDraft}
																	/>
																	<label className="custom-control-label" htmlFor="is-draft">
																		Taslak olarak kaydedilsin
																	</label>
																</div>
															) : (
																<></>
															)}
														</div>
													</div>
												</Col>
											</Row>
										</CardBody>
									</Card>
								</Col>
							</Row>
						</Container>
					</div>
				</Form>
			</Container>
			<Modal isOpen={galleryModal} toggle={gallery_toggle} centered={true} size={'lg'}>
				<div className="modal-header">
					<Label className="btn btn-primary btn-upload import" htmlFor="inputImage" title="Fotoğraf seçin.">
						<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" />
						<span className="docs-tooltip" id="uploadImage">
							<span className="mdi mdi-upload"></span>
						</span>
						<h5 className="ml-1 mt-2">Fotoğraf Seçin</h5>
					</Label>
					<button type="button" onClick={() => setGalleryModal(false)} className="close" data-dismiss="modal" aria-label="Kapat">
						<span aria-hidden="true">
							<i className="bx bx-x"></i>
						</span>
					</button>
				</div>
				<div className="modal-body">
					{/* <div className="row">
						<label className="col-md-2 col-form-label">Arama: </label>
						<div className="col-md-10">
							<input className="form-control" type="text" onChange={(e) => searchImage(e.target.value)} placeholder="Fotoğraf Ara..."></input>
						</div>
					</div> */}
					<div className="row text-center mt-4">
						{/* {imagesData?.images?.map((item) => {
							return (
								<div
									key={item.MediaId}
									className="col-lg-4 col-6 mt-2"
									onClick={() => {
										setSelectedImage(`https://ajssarimg.mediatriple.net/${item.MediaId}.jpg`);
										setGalleryModal(false);
										setSelectedFromLocal(false);
									}}
								>
									<img className="mb-2" src={`https://ajssarimg.mediatriple.net/${item.MediaId}.jpg`} style={{ width: '100%' }} />
									<span>{item.title}</span>
								</div>
							);
						})} */}

						{lastMediaId === 0 ? (
							<span>Gösterilecek medya yok...</span>
						) : (
							[...Array(21)].map((_, i) => {
								return (
									<div
										key={lastMediaId - i}
										className="col-lg-4 col-6 mt-2"
										onClick={() => {
											setSelectedImage(`https://ajssarimg.mediatriple.net/${lastMediaId - i}.jpg`);
											setGalleryModal(false);
											setSelectedFromLocal(false);
										}}
									>
										<img className="mb-2" src={`https://ajssarimg.mediatriple.net/${lastMediaId - i}.jpg`} style={{ width: '100%' }} />
										{/* <span>{item.title}</span> */}
									</div>
								);
							})
						)}
					</div>

					<div className="text-center mt-2">
						<Pagination activePage={currentPage} itemsCountPerPage={21} totalItemsCount={totalImageCount} pageRangeDisplayed={5} onChange={(e) => galleryPageChange(e)} />
					</div>
				</div>
			</Modal>
			<Modal isOpen={titleTypeModal} toggle={titleType_toggle} centered={true}>
				<div className="modal-header">
					<h5 className="modal-title mt-0">Seçitiğiniz Başlık Yapısı Bu Şekildedir.</h5>
					<button type="button" onClick={() => setTitleTypeModal(false)} className="close" data-dismiss="modal" aria-label="Kapat">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div className="modal-body">
					<Row>
						<Col xs="12">
							<img src={`https://cdn.ajansspor.com/assets/images/headlineTitles/${selectedTitleType}.png`} style={{ width: '100%' }} />
						</Col>
					</Row>
				</div>
			</Modal>
		</div>
	);
};

Information.propTypes = {
	contents: PropTypes.object,
	content: PropTypes.object,
	ContentId: PropTypes.number,
	client: PropTypes.object,
	ContentType: PropTypes.number,
};

export default Information;
