import api from '../api';

export const addLog = (logId, description) => {
	const token = localStorage.getItem('ajstoken');
	const data = {
		logId: token ? logId : 33,
		description,
	};

	api.logs.addLog(data);
};
