import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ApolloProvider } from 'react-apollo';
import { createUploadLink } from 'apollo-upload-client';

//import { persistStore } from 'redux-persist';
//import { PersistGate } from 'redux-persist/es/integration/react';

import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import { getToken } from './utils/auth';

import { API_URL } from './utils/Constant';

const { store } = configureStore();
//const persistor = persistStore(store);

const AuthLink = (operation, next) => {
	const token = getToken('token');

	operation.setContext((context) => ({
		...context,
		headers: {
			...context.headers,
			Authorization: token ? `Bearer ${token}` : '',
		},
	}));

	return next(operation);
};

const link = ApolloLink.from([AuthLink, createUploadLink({ uri: API_URL })]);

const client = new ApolloClient({
	link,
	cache: new InMemoryCache(),
});

const Apollo = () => (
	<ApolloProvider client={client}>
		<Provider store={store}>
			{/* <PersistGate persistor={persistor}>
			
		</PersistGate> */}

			<App />
		</Provider>
	</ApolloProvider>
);

ReactDOM.render(<Apollo />, document.getElementById('root'));
serviceWorker.unregister();
