/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { Card, CardBody, Col, Container, Form, FormGroup, Input, Label, Row, Alert } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import api from '../../api';

moment.locale('tr');

const validationSchema = Yup.object({
	title: Yup.string().required('Başlık girmek zorundasınız'),
	description: Yup.string().required('Açıklama girmek zorundasınız'),
});

function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const Add = () => {
	const [selectedApiTags, setSelectedApiTags] = useState([]);
	const [selectedTags, setSelectedTags] = useState([]);
	const [video, setVideo] = useState(null);
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState(false);

	const [league, setLeague] = useState('');
	const [leagues, setLeagues] = useState([]);

	const [team, setTeam] = useState('');
	const [teams, setTeams] = useState([]);

	const [player, setPlayer] = useState('');
	const [players, setPlayers] = useState([]);

	const [match, setMatch] = useState('');
	const [matches, setMatches] = useState([]);

	const videoRef = useRef();

	const selectTags = (selectedTags) => {
		let tags = [];

		if (Array.isArray(selectedTags)) {
			selectedTags.forEach(function (tag) {
				tags.push({ TagId: tag.value, Name: tag.label });
			});

			setSelectedApiTags(tags);
			setSelectedTags(selectedTags);
		} else {
			setSelectedTags([]);
			setSelectedApiTags([]);
		}
	};

	const getTags = async (inputValue, callback) => {
		if (inputValue.length >= 2) {
			let tags = [];

			await api.tags.search(inputValue).then((res) => {
				res.forEach(function (tag) {
					tags.push({ label: tag.Name.trim(), value: tag.TagId });
				});
			});

			callback(tags);
		}
	};

	const onChangeVideoInput = (e) => {
		e.preventDefault();

		if (e.target.files.length > 0) {
			setVideo(e.target.files);
		} else {
			setVideo(e.target.files[0]);
		}
	};

	useEffect(() => {
		videoRef.current?.load();
	}, [video]);

	const { handleSubmit, handleChange, values, errors } = useFormik({
		initialValues: {
			title: '',
			description: '',
			tags: '',
		},
		// validationSchema,
		onSubmit: async (values) => {
			const arr = [];
			const apiTeams = [];
			const apiPlayers = [];

			const formData = new FormData();

			if (selectedApiTags) {
				selectedApiTags.map((item) => arr.push({ id: item.TagId, name: item.Name }));
				formData.append('categories', JSON.stringify(arr));
			}

			if (team) {
				team.map((item) => apiTeams.push({ id: item.value, name: item.label }));
				formData.append('teams', JSON.stringify(apiTeams));
			}

			if (player) {
				player.map((item) => apiPlayers.push({ id: item.value, name: item.label.split('] ')[1] }));
				formData.append('players', JSON.stringify(apiPlayers));
			}

			if (match) {
				formData.append('match', JSON.stringify([{ id: match.value, name: match.label }]));
			}

			if (league) {
				formData.append('leagues', JSON.stringify([{ id: league.value, name: league.label }]));
			}

			if (video.length > 0) {
				for (let i = 0; i < video.length; i++) {
					formData.append('file', video[i]);
				}
			} else {
				formData.append('file', video);
			}

			formData.append('title', values.title);
			formData.append('description', values.description);

			setLoading(true);

			if (video) {
				api.video.upload(formData).then((res) => {
					setLoading(false);

					if (res.status) {
						setMessage(true);
						setTimeout(() => {
							window.location.href = '/videos';
						}, 3000);
					}
				});
			} else {
				setLoading(false);
			}
		},
	});

	const selectLeague = (league) => {
		setLeague(league);

		fetchGetTeams(league.value);
	};

	const selectTeam = (team) => {
		setTeam(team);
	};

	const selectPlayer = (player) => {
		setPlayer(player);
	};

	const selectMatch = (match) => {
		setMatch(match);
	};

	useEffect(() => {
		api.video.league().then((res) => {
			if (res && res.data && res.data.length > 0) {
				const lgs = [];

				res.data.map((c) => {
					lgs.push({ label: c.league.name, value: c.league.id });
				});

				setLeagues(lgs);
			}
		});
	}, []);

	function fetchGetTeams(id) {
		api.video.team(id).then((res) => {
			if (res && res.data && res.data.length > 0) {
				const tms = [];

				res.data
					.sort((a, b) => a.team.name.localeCompare(b.team.name))
					.map((c) => {
						tms.push({ label: c.team.name, value: c.team.id });
					});

				setTeams(tms);
			}
		});
	}

	function searchPlayer() {
		if (team.length > 0) {
			let prms = [];
			let mtcs = [];

			for (let i = 0; i < team.length; i++) {
				prms.push(api.video.player(team[i].value));
				mtcs.push(api.video.matches(team[i].value));
			}

			Promise.all(prms).then((res) => {
				let plyrs = [];

				res.map((e) => {
					e.data[0].players
						.sort((a, b) => a.name.localeCompare(b.name))
						.map((c) => {
							plyrs.push({ label: `[${e.data[0].team.name}] ` + c.name, value: c.id });
						});
				});

				setPlayers(plyrs);
			});

			Promise.all(mtcs).then((res) => {
				let mtchs = [];

				res.map((e) => {
					e.data
						.sort((a, b) => a.fixture.timestamp - b.fixture.timestamp)
						.map((c) => {
							mtchs.push({
								label: `[${c.fixture.status.short === 'NS' || c.fixture.status.short === 'TBD' ? 'Başlamadı' : 'Bitti'}] - ${c.teams.home.name} ${
									(c.fixture.status.short !== 'NS' || c.fixture.status.short !== 'TBD') && c.goals.home ? c.goals.home : ''
								} - ${(c.fixture.status.short !== 'NS' || c.fixture.status.short !== 'TBD') && c.goals.away ? c.goals.away : ''} ${c.teams.away.name} [${moment(c.fixture.date).format(
									'DD MMMM | HH:mm',
								)}]`,
								value: c.fixture.id,
							});
						});
				});

				setMatches(mtchs);
			});
		} else {
			alert('Oyuncu yada maç aramak için önce takım(ları) seçmelisiniz.');
		}
	}

	return (
		<>
			<Helmet>
				<title>{`Video Yükle | ${process.env.REACT_APP_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									{message && (
										<div>
											<Alert>
												<h4 className="alert-heading">Başarılı</h4>
												<p>Videonuz başarıyla yüklendi ve işleme alındı. Videoyu kullanabilmek için işlemenin tamamlanması gerekmektedir.</p>
												<hr />
												<p className="mb-0">Video işlendiğinizde bildirim panelinizde görünür olacaktır. Şimdi video listesine yönlendiriliyorsunuz...</p>
											</Alert>
										</div>
									)}

									<Form onSubmit={handleSubmit}>
										<Row>
											<Col className="col-12 mb-2">
												<FormGroup>
													<Label for="title">Başlık</Label>
													<Input
														type="text"
														className={`${errors.title ? 'is-invalid' : ''}`}
														id="title"
														name="title"
														placeholder="Video başlığı"
														value={values.title}
														onChange={handleChange}
													/>

													{errors.title && <div className="invalid-feedback d-block">{errors.title}</div>}
												</FormGroup>
											</Col>

											<Col className="col-12 mb-2">
												<FormGroup>
													<Label for="description">Açıklama</Label>
													<textarea
														type="text"
														className={`form-control ${errors.description ? 'is-invalid' : ''}`}
														id="description"
														placeholder="Video açıklaması"
														value={values.description}
														onChange={handleChange}
													></textarea>

													{errors.description && <div className="invalid-feedback d-block">{errors.description}</div>}
												</FormGroup>
											</Col>

											<Col className="col-12">
												<FormGroup className="select2-container">
													<Label className="control-label">Etiketler</Label>
													<AsyncSelect
														value={selectedTags}
														isMulti
														onChange={selectTags}
														classNamePrefix="select2-selection"
														placeholder="Etiket seçiniz"
														loadOptions={getTags}
														noOptionsMessage={() => 'En az 3 karakter yazmalısınız.'}
													/>
												</FormGroup>
											</Col>

											<Col className="col-12">
												<Row>
													<Col lg="4">
														<FormGroup className="select2-container">
															<Label className="control-label">Lig Seçin</Label>
															<Select value={league} onChange={selectLeague} options={leagues} classNamePrefix="select2-selection" placeholder="Lig seçin" />
														</FormGroup>
													</Col>

													{teams && teams.length > 0 && (
														<Col lg="4">
															<FormGroup className="select2-container">
																<Label className="control-label">Takım Seçin</Label>

																<Row>
																	<Col sm="10">
																		<Select
																			value={team}
																			onChange={selectTeam}
																			options={teams}
																			classNamePrefix="select2-selection"
																			placeholder="Takım seçin"
																			isMulti
																		/>
																	</Col>
																	<Col sm="2">
																		<div className="btn btn-primary" onClick={() => searchPlayer()}>
																			<span className="bx bx-search-alt"></span>
																		</div>
																	</Col>
																</Row>

																<small style={{ color: 'tomato' }}>Tüm Takımları seçtikten sonra oyuncuları aramanızı öneririz.</small>
															</FormGroup>
														</Col>
													)}

													{players && players.length > 0 && (
														<Col lg="4">
															<FormGroup className="select2-container">
																<Label className="control-label">Oyuncu Seçin</Label>
																<Select
																	value={player}
																	onChange={selectPlayer}
																	options={players}
																	classNamePrefix="select2-selection"
																	placeholder="Oyuncu seçin"
																	isMulti
																/>
															</FormGroup>
														</Col>
													)}
												</Row>
											</Col>

											{matches && matches.length > 0 && (
												<Col lg="12">
													<FormGroup className="select2-container">
														<Label className="control-label">Maç Seçin</Label>
														<Select value={match} onChange={selectMatch} options={matches} classNamePrefix="select2-selection" placeholder="Maç seçin" />
													</FormGroup>
												</Col>
											)}

											<Col className="col-12">
												<Label className="btn btn-primary btn-upload w-100" htmlFor="inputImage" title="Fotoğraf seçin.">
													<input
														type="file"
														multiple
														className="sr-only"
														id="inputImage"
														name="file"
														accept="video/mp4,video/x-m4v,video/*"
														onChange={(e) => onChangeVideoInput(e)}
													/>
													<span>Video Seçin</span>
												</Label>
											</Col>

											{video && (
												<Col className="col-12 mt-3">
													<div className="text-center">
														{video.length > 0 ? (
															<video ref={videoRef} width="320" controls>
																<source src={URL.createObjectURL(video[0])} />
															</video>
														) : (
															<video ref={videoRef} width="320" controls>
																<source src={URL.createObjectURL(video)} />
															</video>
														)}
													</div>

													<div className="video-upload-container">
														<div className="video-info">
															<span className="badge">Video Adı: </span>
															<span className="badge badge-success">{video.length > 0 ? video[0].name : video.name}</span>
															{}
														</div>

														<div className="video-size">
															<span className="badge">Boyut: </span>
															<span className="badge badge-success">{formatBytes(video.length > 0 ? video[0].size : video.size)}</span>
														</div>
													</div>

													<small className="d-block mt-2 text-warning">
														Video ismi yüklendikten sonra otomatik olarak değiştirilir. Yüklemeden sonra videonuza Başlık yada UUID bilgisi ile erişebilirsiniz.
													</small>
												</Col>
											)}

											<Col className="col-12">
												<button disabled={loading} type="submit" className="btn btn-primary mt-2">
													{loading ? <i className="bx bx-loader bx-spin"></i> : 'Yükle'}
												</button>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Add;
