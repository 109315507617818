/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Query, withApollo } from 'react-apollo';

import { CONTENT_DETAIL, AGENCY_NEW } from '../../queries/ContentQuery';
import Information from '../../components/Forms/News/Information';
import api from '../../api';

const NewNews = ({ client }) => {
	const [ContentId, setContentId] = useState(0);
	const [ContentType, setContentType] = useState(0);
	const [content, setContent] = useState({});

	useEffect(() => {
		if (window.location.pathname.split('/').length > 3) {
			let id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

			if (id.indexOf(':') > -1) {
				setContentType(1);
				setContentId(parseInt(id.split(':')[1], 10));
			} else {
				fetchContent(id);

				setContentId(parseInt(id));
				setContentType(0);
			}
		}
	}, [ContentId]);

	async function fetchContent(id) {
		const content = await api.content.get(id);

		if (content) {
			setContent(content.content);
		}
	}

	return (
		<>
			<Helmet>
				<title>{`Haber ${ContentId ? 'Düzenle' : 'Ekle'} - Haber Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
			</Helmet>

			{ContentType === 0 && ContentId !== 0 && Object.keys(content).length > 0 && <Information client={client} content={content} ContentId={ContentId} />}

			{ContentId === 0 && ContentType === 0 && <Information client={client} content={content} ContentId={ContentId} />}

			{ContentType === 1 && (
				<Query query={AGENCY_NEW} variables={{ ContentId }}>
					{({ loading, data }) => {
						if (loading) return null;

						let content;

						if (data) {
							content = data.ajansContent;
						} else {
							content = {};
						}

						return <Information client={client} content={content} ContentId={ContentId} ContentType={ContentType} />;
					}}
				</Query>
			)}
		</>
	);
};

NewNews.propTypes = {
	client: PropTypes.object,
};

export default withApollo(NewNews);
