import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Alert } from 'reactstrap';

//Import Countdown
import Countdown from 'react-countdown';

//Import Images
import logo from '../assets/images/logo-dark.svg';
import maintanence from '../assets/images/maintenance.png';

import api from '../api';

class Comingsoon extends Component {
	constructor() {
		super();
		this.state = {
			status: false,
			date: '',
			title: '',
			message: '',
			completed: false,
		};
		this.renderer.bind(this);
	}

	componentDidMount() {
		api.status().then((res) => {
			let parser = res.message.split('|');
			let date = parser[0];
			let title = parser[1];
			let message = parser[2];

			this.setState(
				{
					status: res.status,
					date,
					title,
					message,
				},
				() => {
					if (this.state.status) {
						window.location.href = '/';
					}
				},
			);
		});

		// if (window.location.pathname === '/maintenance') {
		// 	setInterval(() => {
		// 		this.fetchStatus();
		// 	}, 10000);
		// }
	}

	fetchStatus = () => {
		api.status().then((res) => {
			if (res.status) {
				this.setState(
					{
						status: res.status,
						completed: true,
					},
					() => {
						setTimeout(() => (window.location.href = '/'), 2000);
					},
				);
			} else {
				this.setState({
					status: res.status,
					completed: false,
				});
			}
		});
	};

	renderer = ({ days, hours, minutes, seconds }) => {
		if (this.state.completed) {
			return (
				<span className="text-center" style={{ margin: '0 auto' }}>
					<Alert color="primary">Görünüşe göre dashboard açık. Yönlendiriliyorsunuz...</Alert>
				</span>
			);
		} else {
			return (
				<>
					<div className="coming-box">
						{days} <span>Gün</span>
					</div>{' '}
					<div className="coming-box">
						{hours} <span>Saat</span>
					</div>{' '}
					<div className="coming-box">
						{minutes} <span>Dakika</span>
					</div>{' '}
					<div className="coming-box">
						{seconds} <span>Saniye</span>
					</div>
				</>
			);
		}
	};

	render() {
		let { date, title, message } = this.state;

		return (
			<React.Fragment>
				<div className="home-btn d-none d-sm-block">
					<Link to="/" className="text-white">
						<i className="fas fa-home h2"></i>
					</Link>
				</div>

				<div className="my-5 pt-sm-5">
					<Container>
						<Row>
							<Col lg="12">
								<div className="text-center">
									<Link to="/">
										<img src={logo} alt="logo" height="24" />
									</Link>

									{!this.state.completed && (
										<>
											<Row className="justify-content-center mt-5">
												<Col sm="4">
													<div className="maintenance-img">
														<img src={maintanence} alt="" className="img-fluid mx-auto d-block" />
													</div>
												</Col>
											</Row>
											<h4 className="mt-5">{title}</h4>
											<p className="text-muted">{message}</p>
										</>
									)}

									<Row className="justify-content-center mt-5">
										<Col md="12">
											<div className="counter-number">{date && <Countdown date={date} renderer={this.renderer} />}</div>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
			</React.Fragment>
		);
	}
}

export default Comingsoon;
