import React from 'react';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, Alert } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Switch from 'react-switch';
import toastr from 'toastr';

import api from '../../api';
import { addLog } from '../../utils/addLog';

class CombineTags extends React.Component {
	constructor(props) {
		super(props);
	}
	state = {
		datas: [
			{ id: 1, tag: 'Fenerbahçe' },
			{ id: 2, tag: 'Fener' },
			{ id: 3, tag: 'Fenerbah' },
		],
		checkedItems: [],
		selectedItem: 0,
		isAlertOpen: false,
		alertTitle: '',
		modalStatus: false,
		name: '',
		searchedTags: '',
		combinedTags: [],
		searchedTeams: [],
		searchedTournaments: [],
		teamSearch: '',
		checkedTeamId: 0,
		checkedTeamName: '',
		checkedTournamentId: 0,
		checkedTournamentName: '',
		alertSuccess: false,
		alertWarning: false,
		searchedTeamsText: '',
		searchedTournamentText: '',
		combinedTagText: '',
		newTagName: '',
		newTagLoading: false,
		teamOrTournament: false,
	};

	checkboxChange = (id) => {
		const item = parseInt(id);

		let array = [...this.state.checkedItems];
		const index = this.state.checkedItems.indexOf(item);

		if (index > -1) {
			array.splice(index, 1);
			this.setState({
				checkedItems: array,
			});
		} else {
			array.push(item);
			this.setState({
				checkedItems: array,
			});
		}
	};

	checkboxChangeTeam = (id, name) => {
		const item = parseInt(id);
		this.setState({
			checkedTeamId: item,
			checkedTeamName: name,
		});
	};

	checkboxChangeTournament = (id, name) => {
		const item = parseInt(id);
		this.setState({
			checkedTournamentId: item,
			checkedTournamentName: name,
		});
	};

	combineTags = () => {
		if (this.state.combinedTags < 1) {
			this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: 'Birleştirilecek etiket(leri) seçmelisiniz.' });
		} else if (this.state.checkedTeamId < 1 || this.state.checkedTeamName === '') {
			this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: 'Birleşecek etiket seçmelisiniz.' });
		} else {
			api.tags
				.combine({
					selectedTags: this.state.checkedItems,
					tagName: this.state.checkedTeamName,
					broadageId: this.state.checkedTeamId,
				})
				.then((res) => {
					if (res.success) {
						let logData = `${this.state.checkedItems} idli tagler, ${this.state.checkedTeamName} isimli tagde ${this.state.checkedTeamId} broadage idli tagde birleştirildi.`;
						addLog(14, logData);
						this.setState({
							isAlertOpen: true,
							alertSuccess: true,
							combinedTags: [],
							checkedItems: [],
							searchedTeams: [],
							checkedTeamName: '',
							checkedTeamId: 0,
							alertTitle: 'Etiket(ler) birleştirildi.',
							searchedTeamsText: '',
							combinedTagText: '',
						});
					} else {
						this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: res.message });
					}
				});
		}
	};

	combineTournamentTags = () => {
		console.log(this.state.checkedTournamentId, ' -- ', this.state.checkedTournamentName);
		if (this.state.combinedTags < 1) {
			this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: 'Birleştirilecek etiket(leri) seçmelisiniz.' });
		} else if (this.state.checkedTournamentId < 1 || this.state.checkedTournamentName === '') {
			this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: 'Birleşecek etiket seçmelisiniz.' });
		} else {
			api.tags
				.tournamentCombine({
					selectedTags: this.state.checkedItems,
					broadageId: this.state.checkedTournamentId,
				})
				.then((res) => {
					if (res.success) {
						// let logData = `${this.state.checkedItems} idli tagler, ${this.state.checkedTournamentName} isimli tagde ${this.state.checkedTournamentId} broadage idli tagde birleştirildi.`;
						// addLog(14, logData);
						this.setState({
							isAlertOpen: true,
							alertSuccess: true,
							combinedTags: [],
							checkedTournamentId: 0,
							checkedTournamentName: '',
							checkedItems: [],
							searchedTeams: [],
							searchedTournaments: [],
							checkedTeamName: '',
							checkedTeamId: 0,
							alertTitle: 'Etiket(ler) birleştirildi.',
							searchedTeamsText: '',
							combinedTagText: '',
							searchedTournamentText: '',
						});
					} else {
						this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: res.message });
					}
				});
		}
	};

	newTagAdd = () => {
		if (this.state.checkedItems.length <= 0) {
			this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: 'Önce birleştirilecek etiket(leri) seçmelisiniz.' });
		} else {
			this.setState({
				modalStatus: true,
			});
		}
	};

	addTag = (e) => {
		e.preventDefault();

		api.tags
			.combine({
				selectedTags: this.state.checkedItems,
				tagName: this.state.name,
			})
			.then((res) => {
				if (res.success) {
					this.setState({
						isAlertOpen: true,
						alertSuccess: true,
						combinedTags: [],
						checkedItems: [],
						searchedTeams: [],
						name: '',
						checkedTeamId: 0,
						alertTitle: 'Etiket(ler) birleştirildi.',
						searchedTeamsText: '',
						combinedTagText: '',
						modalStatus: false,
					});
				} else {
					this.setState({ isAlertOpen: true, alertWarning: true, alertTitle: res.message });
				}
			});
	};

	createTag = async () => {
		this.setState({ newTagLoading: true });
		toastr.options = {
			positionClass: 'toast-top-center',
			progressBar: true,
			timeOut: 2000,
		};
		if (this.state.newTagName.length <= 0) {
			toastr.error('Etiket adı girmelisiniz.');
			this.setState({ newTagLoading: false });
		} else {
			const data = await api.tags.create(this.state.newTagName);
			if (data.success) {
				toastr.success('Etiket başarıyla eklendi.');
				addLog(52, `${this.state.newTagName} adıyla tag eklendi.`);
				this.setState({ newTagName: '', newTagLoading: false });
			} else {
				toastr.error(data.error || 'Bir şeyler ters gitti.');
				addLog(53, `Tag eklerken hata alındı. Hata: ${data.error || 'CORS'}`);
				this.setState({ newTagName: '', newTagLoading: false });
			}
		}
	};

	search = (value) => {
		this.setState(
			{
				combinedTagText: value,
			},
			() => {
				if (value.length > 2) {
					api.tags.searchV2(this.state.combinedTagText).then((res) => {
						this.setState({
							combinedTags: res,
						});
					});
				} else {
					this.setState({
						combinedTags: [],
					});
				}
			},
		);
	};

	broadageTeamSearch = (value) => {
		this.setState(
			{
				searchedTeamsText: value,
			},
			() => {
				if (value.length > 2) {
					api.tags.broadageTeamSearch(this.state.searchedTeamsText).then((res) => {
						this.setState({
							searchedTeams: res,
						});
					});
				} else {
					this.setState({
						searchedTeams: [],
					});
				}
			},
		);
	};

	broadageTournamentSearch = (value) => {
		this.setState(
			{
				searchedTournamentText: value,
			},
			() => {
				if (value.length > 2) {
					api.tv.searchLeague(value).then((res) => {
						this.setState({ searchedTournaments: res });
					});
				} else {
					this.setState({ searchedTournaments: [] });
				}
			},
		);
	};

	render() {
		const Offsymbol = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						fontSize: 12,
						color: '#fff',
						paddingRight: 2,
					}}
				>
					{' '}
					Takım
				</div>
			);
		};

		const OnSymbol = () => {
			return (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
						fontSize: 12,
						color: '#fff',
						paddingLeft: '8px',
					}}
				>
					{' '}
					Turnuva
				</div>
			);
		};
		return (
			<>
				<Helmet>
					<title>{`Etiket Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				{this.state.isAlertOpen ? (
					<SweetAlert
						title=""
						warning={this.state.alertWarning}
						success={this.state.alertSuccess}
						onConfirm={() => this.setState({ isAlertOpen: false, alertSuccess: false, alertWarning: false })}
					>
						{this.state.alertTitle}
					</SweetAlert>
				) : null}
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Etiket Yönetimi" breadcrumbItem="Etiket Birleştir / Ekle" />

						<Row>
							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Birleştirilecek Etiketler</CardTitle>
												<CardSubtitle>
													Seçtiğiniz etiketler yeni etiket ile birleştirilir. <small>Şu an sadece &quot;Futbol&quot; etiketlerini birleştirin.</small>
												</CardSubtitle>
											</div>
										</div>

										<div className="tags-table mt-3">
											<Row>
												<Col className="col-12">
													<div className="form-group">
														<input
															className="form-control"
															type="text"
															placeholder="Etiket Adı"
															value={this.state.combinedTagText}
															onChange={(e) => this.search(e.target.value)}
														/>
													</div>
												</Col>
											</Row>
											<table className="table">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Etiket Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{this.state.combinedTags.map((tag, i) => (
														<tr key={i} id={parseInt(tag.TagId)} onClick={() => this.checkboxChange(tag.TagId)}>
															<td>
																<input type="checkbox" onChange={() => false} checked={this.state.checkedItems.indexOf(tag.TagId) > -1 ? true : false} />
															</td>
															<td style={{ width: '100%' }}>{tag.Name}</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</CardBody>
								</Card>
							</Col>

							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Birleşecek Etiket</CardTitle>
												<CardSubtitle>Seçtiğiniz etiketleri buradaki etiket ile birleştirir.</CardSubtitle>
											</div>
											<div>
												<Switch
													width={100}
													uncheckedIcon={<Offsymbol />}
													className="mr-1"
													checkedIcon={<OnSymbol />}
													onColor="#626ed4"
													onChange={() => this.setState({ teamOrTournament: !this.state.teamOrTournament })}
													checked={this.state.teamOrTournament}
												/>
											</div>
										</div>

										<div className="tags-table mt-3">
											<Row>
												<Col className="col-9">
													<div className="form-group">
														<input
															className="form-control"
															type="text"
															placeholder={`Broadage ${this.state.teamOrTournament ? 'Turnuva' : 'Takım'} Adı`}
															value={this.state.teamOrTournament ? this.state.searchedTournamentText : this.state.searchedTeamsText}
															onChange={(e) => {
																this.state.teamOrTournament ? this.broadageTournamentSearch(e.target.value) : this.broadageTeamSearch(e.target.value);
															}}
														/>
													</div>
												</Col>
												<Col className="col-3" style={{ textAlign: 'right' }}>
													<div className="d-flex flex-row">
														<Dropdown className="ml-2" isOpen={this.state.singlebtn} toggle={() => this.setState({ singlebtn: !this.state.singlebtn })}>
															<DropdownToggle className="btn btn-secondary" caret>
																Güncelle
															</DropdownToggle>
															<DropdownMenu>
																{!this.state.teamOrTournament && (
																	<>
																		<DropdownItem onClick={this.combineTags}>Birleştir</DropdownItem>
																		<DropdownItem onClick={this.newTagAdd}>Yeni Etiket Ekle</DropdownItem>
																	</>
																)}
																{this.state.teamOrTournament && (
																	<>
																		<DropdownItem onClick={this.combineTournamentTags}>Birleştir</DropdownItem>
																	</>
																)}
															</DropdownMenu>
														</Dropdown>
													</div>
												</Col>
											</Row>

											<table className="table">
												<thead style={{ display: 'block' }}>
													<tr>
														<th style={{ width: 24 }}>#</th>
														<th style={{ width: '100%' }}>Adı</th>
													</tr>
												</thead>
												<tbody style={{ height: 200, overflowY: 'auto', display: 'block' }}>
													{!this.state.teamOrTournament &&
														this.state.searchedTeams.map((team, i) => (
															<tr key={i} id={parseInt(team.id)} onClick={() => this.checkboxChangeTeam(team.id, team.name)}>
																<td>
																	<input type="radio" name="team" onChange={() => false} />
																</td>
																<td style={{ width: '100%' }}>{team.name}</td>
															</tr>
														))}
													{this.state.teamOrTournament &&
														this.state.searchedTournaments.map((tournament, i) => (
															<tr key={i} id={parseInt(tournament.id)} onClick={() => this.checkboxChangeTournament(tournament.id, tournament.name)}>
																<td>
																	<input type="radio" name="team" onChange={() => false} />
																</td>
																<td style={{ width: '100%' }}>{tournament.name}</td>
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</CardBody>
								</Card>
							</Col>
							<Col xs="12" md="6">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Etiket Ekleme</CardTitle>
											</div>
										</div>

										<div className="tags-table mt-3">
											<Row>
												<Col className="col-10">
													<div className="form-group">
														<input
															className="form-control"
															type="text"
															placeholder="Etiket Adı"
															value={this.state.newTagName}
															onChange={(e) => this.setState({ newTagName: e.target.value })}
														/>
													</div>
												</Col>
												<Col className="col-2" style={{ textAlign: 'right' }}>
													<button className="btn btn-primary btn-block" onClick={() => this.createTag()} disabled={this.state.newTagLoading ? true : ''}>
														{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : 'Ekle'}
													</button>
												</Col>
											</Row>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					<Modal isOpen={this.state.modalStatus} toggle={this.tog_center} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yeni Etiket Ekle</h5>
							<button type="button" onClick={() => this.setState({ modalStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<form method="post" onSubmit={this.addTag}>
								<div className="form-group">
									<label htmlFor="name">Etiket Adı</label>
									<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ name: e.target.value })} />
								</div>

								<button className="btn btn-info" disabled={this.state.name.length <= 0}>
									Kaydet
								</button>
							</form>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default CombineTags;
