/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

// Other Layout related Component
import Navbar from './Navbar';
import Header from './Header';
import Footer from './Footer';
import Rightbar from '../CommonForBoth/Rightbar';

import api from '../../api';
import { getTokenDecoded } from '../../utils/auth';

class Layout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isMenuOpened: false,
			serverStatus: true,
			status: true,
			serverStatusMessage: '',
			page: window.location.pathname === '/404' || window.location.pathname === '/lock' ? false : true,
			isDarkMode: localStorage.getItem('ajs-theme') ? (localStorage.getItem('ajs-theme') === 'dark' ? true : false) : false,
		};
		this.toggleRightSidebar = this.toggleRightSidebar.bind(this);

		document.getElementsByTagName('html')[0].removeAttribute('class');
		document.getElementsByTagName('html')[0].setAttribute('class', localStorage.getItem('ajs-theme') ? (localStorage.getItem('ajs-theme') === 'dark' ? 'dark' : 'light') : 'light');
	}

	/**
	 * Open/close right sidebar
	 */
	toggleRightSidebar() {
		this.props.toggleRightSidebar();
	}

	componentDidMount() {
		if (this.props.isPreloader === true) {
			document.getElementById('preloader').style.display = 'block';
			document.getElementById('status').style.display = 'block';

			setTimeout(function () {
				document.getElementById('preloader').style.display = 'none';
				document.getElementById('status').style.display = 'none';
			}, 2500);
		} else {
			document.getElementById('preloader').style.display = 'none';
			document.getElementById('status').style.display = 'none';
		}

		// Scrollto 0,0
		window.scrollTo(0, 0);

		this.fetchServerStatus();

		// if (window.location.pathname !== '/maintenance') {
		// 	setInterval(() => {
		// 		this.fetchServerStatus();
		// 	}, 10000);
		// }
	}

	/**
	 * Opens the menu - mobile
	 */
	openMenu = (e) => {
		this.setState({ isMenuOpened: !this.state.isMenuOpened });
	};

	fetchServerStatus = () => {
		let status = getTokenDecoded(localStorage.getItem('ajstoken'));

		// api.status().then((res) => {
		// 	this.setState(
		// 		{
		// 			serverStatus: status.Role === 999 ? true : res.status,
		// 			status: res.status,
		// 			serverStatusMessage: res.message,
		// 		},
		// 		() => {
		// 			// if (!this.state.serverStatus) {
		// 			// 	if (window.location.pathname !== '/maintenance') {
		// 			// 		window.location.href = '/maintenance';
		// 			// 	}
		// 			// }
		// 		},
		// 	);
		// });
	};

	render() {
		return (
			<React.Fragment>
				<div id="preloader">
					<div id="status">
						<div className="spinner-chase">
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
							<div className="chase-dot"></div>
						</div>
					</div>
				</div>

				<div id="layout-wrapper">
					{this.state.page && (
						<>
							<Header
								serverStatus={this.state.serverStatus}
								status={this.state.status}
								darkMode={this.state.isDarkMode}
								theme={this.props.topbarTheme}
								isMenuOpened={this.state.isMenuOpened}
								toggleRightSidebar={this.toggleRightSidebar}
								openLeftMenuCallBack={this.openMenu}
							/>
							<Navbar serverStatus={this.state.serverStatus} menuOpen={this.state.isMenuOpened} />
						</>
					)}
					<div className="main-content">{this.props.children}</div>
					{this.state.page && <Footer serverStatus={this.state.serverStatus} />}
				</div>

				<Rightbar />
			</React.Fragment>
		);
	}
}

export default withRouter(Layout);
