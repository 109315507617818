import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, FormGroup, Label, Input, Button } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useFormik } from 'formik';

import { UPDATE_PROFILE } from '../../../queries/UserQuery';
import { addLog } from '../../../utils/addLog';

const PasswordInfo = ({ client }) => {
	const [loading, setLoading] = useState(false);
	const [pin, setPin] = useState('');

	const { handleSubmit, handleChange, values } = useFormik({
		initialValues: {
			Password: '',
			Pin: '',
		},
		onSubmit: async (values) => {
			setLoading(true);

			try {
				const mutation = await client.mutate({
					mutation: UPDATE_PROFILE,
					variables: {
						input: {
							Password: values.Password,
							Pin: parseInt(pin, 10),
						},
					},
				});

				if (mutation) {
					setLoading(false);

					if (mutation.data.updateProfile.success) {
						toastr.options = {
							positionClass: 'toast-top-center',
							progressBar: true,
						};
						toastr.success(mutation.data.updateProfile.message);
						localStorage.setItem(process.env.REACT_APP_TOKEN, mutation.data.updateProfile.token);

						setTimeout(() => {
							window.location.reload();
						}, 2000);

						addLog(1, `${values.Username} kullanıcı adlı editör bilgilerini düzenledi.`);
					} else {
						toastr.options = {
							positionClass: 'toast-top-center',
						};
						toastr.error(mutation.data.updateProfile.message);

						addLog(43, mutation.data.updateProfile.message);
					}
				}
			} catch (e) {
				setLoading(false);

				toastr.options = {
					positionClass: 'toast-top-center',
				};

				e.graphQLErrors.map(({ message }) => {
					toastr.error(message);
					addLog(43, message);
				});
			}
		},
	});
	return (
		<Form onSubmit={handleSubmit}>
			<Row>
				<Col col="12">
					<FormGroup>
						<Label for="password">Yeni Şifreniz</Label>
						<Input type="password" name="Password" id="password" onChange={handleChange} value={values.Password} />
					</FormGroup>
				</Col>
			</Row>

			<Row>
				<Col col="12">
					<FormGroup>
						<Label for="pin">Pin Kodunuz</Label>
						<Input type="password" maxLength="4" name="pin" id="pin" onChange={(e) => setPin(e.target.value.replace(/\D/, ''))} value={pin} />
						<small className="text-muted">Pin kodu 4 haneli ve yalnızca rakamlardan oluşmalıdır.</small>
					</FormGroup>
				</Col>
			</Row>

			<div className="mt-4">
				<Button type="submit" color="danger">
					{loading ? <i className="bx bx-loader bx-spin" /> : 'Güncelle'}
				</Button>
			</div>
		</Form>
	);
};

PasswordInfo.propTypes = {
	client: PropTypes.object,
};

export default PasswordInfo;
