import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardTitle, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

import { addLog } from './../../../utils/addLog';
class ServerInfo extends React.Component {
	componentDidMount() {
		addLog(19, 'Sunucu bilgileri görüntülendi.');
	}

	render() {
		return (
			<>
				<Helmet>
					<title>{`Sunucu Bilgileri - Araçlar | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Araçlar" breadcrumbItem="Sunucu Bilgileri" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Sunucu Bilgileri</CardTitle>
											</div>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>

						<Row>
							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												DB Sunucusu (Mediatriple)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 185.70.96.20
											<br />
											Kullanıcı adı: Administrator
											<br />
											Şifre: YuhBgy7SS
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Yönetim Paneli Eski (Mediatriple)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 46.45.158.8
											<br />
											Kullanıcı adı: mediatriple
											<br />
											Şifre: MediatripleTempPass7
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Origin 1 (Mediatriple)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 84.16.241.97
											<br />
											Kullanıcı adı: root
											<br />
											Şifre: uApAeT2Dk294
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Origin 2 (Contabo)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 95.111.232.164
											<br />
											Kullanıcı adı: root
											<br />
											Şifre: Srn2020Ajs
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Origin 3 (Contabo)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 173.249.25.201
											<br />
											Kullanıcı adı: root
											<br />
											Şifre: mubC2wN4AEK3
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Counter ve Cronjob (Contabo)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 173.212.253.126
											<br />
											Kullanıcı adı: root
											<br />
											Şifre: 6w8kSp4EbwD373
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Radyospor (DGN Bursa)
											</Link>
										</h5>
										<p className="text-muted">
											IP: 46.20.4.18:52000
											<br />
											Kullanıcı adı: administrator
											<br />
											Şifre: rto!123rto!
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Contabo Üyelik Bilgileri
											</Link>
										</h5>
										<p className="text-muted">
											Adres:{' '}
											<a href="https://my.contabo.com/account/login" target="_blank" rel="noreferrer">
												Contabo
											</a>
											<br />
											Kullanıcı adı: developer@ajansspor.com
											<br />
											Şifre: 6VRNp53TaC8B
										</p>
									</CardBody>
								</Card>
							</Col>

							<Col xl="3" sm="6">
								<Card className="text-center">
									<CardBody>
										<h5 className="font-size-15">
											<Link to="#" className="text-dark">
												Contabo 2. Üyelik Bilgileri
											</Link>
										</h5>
										<p className="text-muted">
											Adres:{' '}
											<a href="https://my.contabo.com/account/login" target="_blank" rel="noreferrer">
												Contabo
											</a>
											<br />
											Kullanıcı adı: server@ajansspor.com
											<br />
											Şifre: mubC2wN4AEK3
										</p>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default ServerInfo;
