import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const NewVideo = () => {
	const [title, setTitle] = useState('');
	const [video, setVideo] = useState('');
	const [validateError, setValidateError] = useState({});
	const [loading, setLoading] = useState(false);
    const [id, setId] = useState(null);

	useEffect(async () => {
        const paramsId = parseInt(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1], 10);
        setId(paramsId);
        let video = await api.slowtime.videos.getLast();
        video = video.data;
		setTitle(video.Title);
		setVideo(video.Embed);
    }, []);

	const onSubmit = () => {
		const errors = validate({ title, video });
		setValidateError(errors);

		if (Object.keys(validateError).length === 0) {
			setLoading(true);

			let data = {
				Id: id,
				Embed: video,
				Title: title,
			};

			api.slowtime.videos.update(data).then((res) => {
				if (res.success) {
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.success('Video başarıyla kaydedildi.');
					setTimeout(() => window.location.reload(), 2000);
							
				}else{
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error('Video kaydedilirken bir hata meydana geldi.');
				}
			});
		}
	};


	const validate = (data) => {
		const errors = {};
		if (!data.title) errors.title = 'Başlık boş olamaz.';
		if (!data.video) errors.video = 'Video boş olamaz.';
		return errors;
	};

	return (
		<>
			<Helmet>
				<title>{`Videolar - Slowtime Yönetimi | ${process.env.REACT_APP_SLOW_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Video Yönetimi" breadcrumbItem="Video Ekle" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Video Ekle</CardTitle>
										</div>
									</div>

									<Form>
										<Row>
											<Col xl={12}>
												<FormGroup>
													<Label for="Title">Başlık</Label>
													<Input id="Title" name="Title" onChange={(text) => setTitle(text.target.value)} value={title} />
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col xl={12}>
												<FormGroup>
													<Label for="Url">Video Adresi (Embed URL)</Label>
													<Input id="Url" name="Url" onChange={(text) => setVideo(text.target.value)} value={video} />
												</FormGroup>
											</Col>
										</Row>

										<button type="button" className="btn btn-primary" onClick={() => onSubmit()}>
											{loading ? <i className="bx bx-loader bx-spin"></i> : 'Videoyu Kaydet'}
										</button>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default NewVideo;
