import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';
import LanguageDropdown from '../CommonForBoth/TopbarDropdown/LanguageDropdown';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';

import logoLightSvg from '../../assets/images/logo-light.svg';
import logoDarkSvg from '../../assets/images/logo-dark.svg';

import slowtimelogo from '../../assets/images/brands/slowtime.svg';
import radyosporlogo from '../../assets/images/brands/radyospor.svg';

import { addLog } from '../../utils/addLog';
import { getTokenDecoded } from '../../utils/auth';
import { getRoles } from '../../utils/getRoles';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSearch: false,
			isDarkMode: props.darkMode,
			user: getTokenDecoded(localStorage.ajstoken),
			brand: '',
			userBrands: [],
			currentBrand: '',
		};

		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleRightbar = this.toggleRightbar.bind(this);
		this.toggleFullscreen = this.toggleFullscreen.bind(this);
		this.toggleSearch = this.toggleSearch.bind(this);
	}

	componentDidMount = () => {
		let userBrands = getRoles(this.state.user.Roles).app;
		this.setState({ brand: userBrands[0], userBrands, currentBrand: localStorage.getItem('currentBrand') });
		if (!localStorage.getItem('currentBrand')) {
			localStorage.setItem('currentBrand', userBrands[0]);
		}
	};

	toggleSearch = () => {
		this.setState({ isSearch: !this.state.isSearch });
	};

	toggleMenu() {
		this.props.openLeftMenuCallBack();
	}

	toggleRightbar() {
		this.props.toggleRightSidebar();
	}

	toggleFullscreen() {
		if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			// current working methods
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	setDarkMode = () => {
		let logData = localStorage.getItem('ajs-theme') === 'dark' ? 'Açık tema seçildi' : 'Dark tema seçildi.';
		addLog(21, logData);
		this.setState(
			{
				isDarkMode: !this.state.isDarkMode,
			},
			() => {
				document.getElementsByTagName('html')[0].removeAttribute('class');
				document.getElementsByTagName('html')[0].setAttribute('class', this.state.isDarkMode ? 'dark' : 'light');
				localStorage.setItem('ajs-theme', this.state.isDarkMode ? 'dark' : 'light');
			},
		);
	};

	render() {
		return (
			<React.Fragment>
				<header id="page-topbar" className={!this.props.serverStatus ? 'd-none' : ''}>
					<div className="navbar-header">
						<div className="d-flex">
							<div className="navbar-brand-box text-left d-flex flex-row align-items-center">
								{this.state.currentBrand === 'Ajansspor' && (
									<>
										<a href="/" className="logo logo-dark">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={logoDarkSvg} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={logoDarkSvg} alt="" height="17" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={logoLightSvg} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={logoLightSvg} alt="" height="17" />
													</span>
												</>
											)}
										</a>

										<a href="/" className="logo logo-light">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={logoDarkSvg} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={logoDarkSvg} alt="" height="19" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={logoLightSvg} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={logoLightSvg} alt="" height="19" />
													</span>
												</>
											)}
										</a>
									</>
								)}

								{this.state.currentBrand === 'Slowtime' && (
									<>
										<a href="/" className="logo logo-dark">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={slowtimelogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={slowtimelogo} alt="" height="17" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={slowtimelogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={slowtimelogo} alt="" height="17" />
													</span>
												</>
											)}
										</a>

										<a href="/" className="logo logo-light">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={slowtimelogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={slowtimelogo} alt="" height="19" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={slowtimelogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={slowtimelogo} alt="" height="19" />
													</span>
												</>
											)}
										</a>
									</>
								)}

								{this.state.currentBrand === 'Radyospor' && (
									<>
										<a href="/radyospor" className="logo logo-dark">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={radyosporlogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={radyosporlogo} alt="" height="17" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={radyosporlogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={radyosporlogo} alt="" height="17" />
													</span>
												</>
											)}
										</a>

										<a href="/radyospor" className="logo logo-light">
											{this.state.isDarkMode ? (
												<>
													<span className="logo-sm">
														<img src={radyosporlogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={radyosporlogo} alt="" height="19" />
													</span>
												</>
											) : (
												<>
													<span className="logo-sm">
														<img src={radyosporlogo} alt="" height="22" />
													</span>
													<span className="logo-lg">
														<img src={radyosporlogo} alt="" height="19" />
													</span>
												</>
											)}
										</a>
									</>
								)}

								<span className={`d-block ml-2 header__status header__status--${this.props.status ? 'available' : 'unavailable'}`} id="server-status"></span>

								<UncontrolledTooltip placement="bottom" target="server-status">
									Sunucu durumu {this.props.status ? 'aktif.' : 'aktif değil!'}
								</UncontrolledTooltip>
							</div>

							<button
								type="button"
								className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
								data-toggle="collapse"
								onClick={this.toggleMenu}
								data-target="#topnav-menu-content"
							>
								<i className="fa fa-fw fa-bars"></i>
							</button>

							<form className="app-search d-none d-lg-block">
								<div className="position-relative">
									<input type="text" className="form-control" placeholder="Ara..." />
									<span className="bx bx-search-alt"></span>
								</div>
							</form>
						</div>

						<div className="d-flex">
							<div className="dropdown d-inline-block d-lg-none ml-2">
								<button
									type="button"
									className="btn header-item noti-icon waves-effect"
									id="page-header-search-dropdown"
									onClick={() => {
										this.setState({ isSearch: !this.state.isSearch });
									}}
								>
									<i className="mdi mdi-magnify"></i>
								</button>
								<div
									className={this.state.isSearch ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show' : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'}
									aria-labelledby="page-header-search-dropdown"
								>
									<form className="p-3">
										<div className="form-group m-0">
											<div className="input-group">
												<input type="text" className="form-control" placeholder="Ara..." aria-label="Bir şeyler arayın" />
												<div className="input-group-append">
													<button className="btn btn-primary" type="submit">
														<i className="mdi mdi-magnify"></i>
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>

							<LanguageDropdown roles={getRoles(this.state.user.Roles).app} />

							{/* <Dropdown className="d-none d-lg-inline-block ml-1" isOpen={this.state.socialDrp} toggle={() => { this.setState({ socialDrp: !this.state.socialDrp }) }}>
                <DropdownToggle className="btn header-item noti-icon waves-effect" caret tag="button">
                  <i className="bx bx-customize"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg" right>
                  <div className="px-lg-2">
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={github} alt="Github" />
                          <span>GitHub</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={bitbucket} alt="bitbucket" />
                          <span>Bitbucket</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dribbble} alt="dribbble" />
                          <span>Dribbble</span>
                        </Link>
                      </Col>
                    </Row>
                    <Row className="no-gutters">
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={dropbox} alt="dropbox" />
                          <span>Dropbox</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={mail_chimp} alt="mail_chimp" />
                          <span>Mail Chimp</span>
                        </Link>
                      </Col>
                      <Col>
                        <Link className="dropdown-icon-item" to="#">
                          <img src={slack} alt="slack" />
                          <span>Slack</span>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </DropdownMenu>
              </Dropdown> */}

							<div className="dropdown d-none d-lg-inline-block ml-1">
								<button type="button" className="btn header-item noti-icon waves-effect" onClick={this.toggleFullscreen} data-toggle="fullscreen">
									<i className="bx bx-fullscreen"></i>
								</button>
							</div>

							<NotificationDropdown />

							<ProfileMenu />

							<div className="dropdown d-inline-block">
								<button type="button" onClick={this.setDarkMode} className="btn header-item noti-icon right-bar-toggle waves-effect">
									<i className={`fas fa-${this.state.isDarkMode ? 'sun' : 'moon'}`}></i>
								</button>
							</div>
						</div>
					</div>
				</header>
			</React.Fragment>
		);
	}
}

Header.propTypes = {
	openLeftMenuCallBack: PropTypes.func,
	toggleRightSidebar: PropTypes.func,
	serverStatus: PropTypes.bool,
	status: PropTypes.bool,
	darkMode: PropTypes.bool,
};

export default Header;
