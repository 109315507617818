import jwtDecode from 'jwt-decode';

export const setToken = (token) => {
	localStorage.setItem(process.env.REACT_APP_TOKEN, token);
};

export const getToken = () => {
	return localStorage.getItem(process.env.REACT_APP_TOKEN);
};

export const getTokenDecoded = (token) => {
	const decode = jwtDecode(token);
	return decode;
};

export const getExpired = (token) => {
	if (token) {
		try {
			const decode = jwtDecode(token);

			if (Date.now() >= decode.exp * 1000) {
				return true;
			} else {
				return false;
			}
		} catch (e) {
			localStorage.removeItem(process.env.REACT_APP_TOKEN);
			return false;
		}
	}
};

export const isAuthenticated = () => {
	const jwt = localStorage.getItem(process.env.REACT_APP_TOKEN);
	let isAuthenticated = false;
	if (jwt) isAuthenticated = true;
	return isAuthenticated;
};

export const isValid = () => {
	// eslint-disable-next-line
	const jwt = localStorage.getItem(process.env.REACT_APP_TOKEN);
	return true;
};

export const logOut = () => {
	localStorage.removeItem(process.env.REACT_APP_TOKEN);
};
