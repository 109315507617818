import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

moment.locale('tr');

const Events = () => {
	const [events, setEvents] = useState([]);

	const data = {
		columns: [
			{
				label: 'Başlık',
				field: 'Title',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Açıklama',
				field: 'content',
				sort: 'asc',
				width: 250,
			},
			{
				label: 'Konum',
				field: 'Location',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Fotoğraf',
				field: 'image',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Tarih',
				field: 'date',
				sort: 'asc',
				width: 50,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: events,
	};

	const deleteEvent = (id) => {
		api.slowtime.events.delete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.success('Etkinlik başarıyla silindi.');

				setTimeout(() => window.location.reload(), 1500);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error('Etkinlik silinirken bir hata meydana geldi.');
			}
		});
	};

	const fetchEvents = () => {
		api.slowtime.events.list().then((res) => {
			let data = [];

			if (res.success) {
				res.data.map((item) => {
					data.push({
						...item,
						content: <div dangerouslySetInnerHTML={{ __html: item.Content }} />,
						image: <img src={process.env.REACT_APP_SLOWTIME_IMAGE_URL + item.Image} alt="" height="80" />,
						date: moment(item.Date).format('DD-MM-YYYY HH:mm'),
						settings: (
							<div className="btn-group mr-2 mb-2 mb-sm-0">
								<a href={`/slowtime/events/edit/${item.Id}`} className="btn btn-secondary">
									<i className="bx bxs-pencil"></i>
								</a>

								<button
									className={`btn ${item.isDeleted ? 'btn-danger' : 'btn-secondary'}`}
									onClick={() => {
										if(item.isDeleted){
											window.confirm('Geri almak istediğinize emin misiniz?') && deleteEvent(item.Id);
										}else{
											window.confirm('Silmek istediğinize emin misiniz?') && deleteEvent(item.Id);
										}
									}}
								>
									<i className="bx bx-trash"></i>
								</button>
							</div>
						),
					});
				});

				setEvents(data);
			}
		});
	};

	useEffect(() => {
		fetchEvents();
	}, []);

	return (
		<>
			<Helmet>
				<title>{`Etkinlikler - Slowtime Yönetimi | ${process.env.REACT_APP_SLOW_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Etkinlik Yönetimi" breadcrumbItem="Etkinlikler" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Etkinlikler</CardTitle>
										</div>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default Events;
