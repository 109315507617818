import api from '../api';

const types = {
	FETCH_LOG_TYPES: 'FETCH_LOG_TYPES',
	GET_LOG_TYPES: 'GET_LOG_TYPES',
	NEW_LOG_TYPE_PENDING: 'NEW_LOG_TYPE_PENDING',
	NEW_LOG_TYPE_FAILURE: 'NEW_LOG_TYPE_FAILURE',
	NEW_LOG_TYPE_SUCCESS: 'NEW_LOG_TYPE_SUCCESS',
};

export const actions = {
	getLogTypes: (dispatch) => {
		dispatch({ type: types.FETCH_LOG_TYPES, isFetching: true });

		return api.logs.getLogTypes().then((res) => {
			dispatch({ type: types.FETCH_LOG_TYPES, isFetching: false });

			if (res) {
				dispatch({ type: types.GET_LOG_TYPES, logTypes: res });
			}
		});
	},
	addLogType: (dispatch, name) => {
		dispatch({ type: types.NEW_LOG_TYPE_PENDING, isFetching: true });

		return api.logs.addLogType(name).then((res) => {
			dispatch({ type: types.NEW_LOG_TYPE_PENDING, isFetching: false });

			if (!res.success) {
				dispatch({ type: types.NEW_LOG_TYPE_FAILURE, error: res.message });

				setTimeout(() => dispatch({ type: types.NEW_LOG_TYPE_FAILURE, error: '', message: '' }), 2500);
			} else {
				dispatch({ type: types.NEW_LOG_TYPE_SUCCESS, message: res.message });
				setTimeout(() => dispatch({ type: types.NEW_LOG_TYPE_SUCCESS, error: '', message: '' }), 2500);
			}
		});
	},
};

const initialState = {
	isFetching: false,
	error: '',
	logTypes: [],
	message: '',
};

export const reducer = (state = initialState, action) => {
	const { error, type, logTypes, isFetching, message } = action;

	switch (type) {
		case types.FETCH_LOG_TYPES:
			return { ...initialState, isFetching };

		case types.GET_LOG_TYPES:
			return { ...state, logTypes, isFetching: false, error };

		case types.NEW_LOG_TYPE_SUCCESS:
			return { ...state, isFetching: false, error, message };

		case types.NEW_LOG_TYPE_PENDING:
			return { ...state, isFetching };

		case types.NEW_LOG_TYPE_FAILURE:
			return { ...state, isFetching: false, error };

		default:
			return state;
	}
};
