import React from 'react';
import { Alert, Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Modal, ModalBody, ModalHeader, ModalFooter, Label, UncontrolledTooltip, Button, ButtonGroup } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { MDBDataTable } from 'mdbreact';
import api from '../../api';
//Image Cropper
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

class Banner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			videos: [],
			imagesData: { count: 0, images: [] },
			imageSearch: [],
			galleryPage: 1,
			galleryTotalPage: 0,
			galleryCountPerPage: 30,
			selectedVideo: null,
			noCrop: false,
			cropModal: false,
			videosModal: false,
			galleryModal: false,
			pictureError: false,
			pictureErrorMessage: '',
			success: false,
			error: false,
			src: '',
			cropResult: null,
			ratio1: 16,
			ratio2: 9,
			zoom: 0.5,
			dragMode: 'crop',
			rotate: 0,
			scaleX: 1,
			scaleY: 1,
			enable: true,
			disable: false,
			modal_1: false,
			modal_2: false,
			modal_3: false,
			imgWidth: '',
			imgHeight: '',
			viewMode: 0,
			imgTitle: '',
		};
		this.cropImage = this.cropImage.bind(this);
		this.onChange = this.onChange.bind(this);
		this.useDefaultImage = this.useDefaultImage.bind(this);
		this.changeRatio.bind(this);
		this.tog_1 = this.tog_1.bind(this);
	}

	componentDidMount() {
		this.fetchAllVideos();
		this.fetchImages();
	}

	fetchAllVideos = () => {
		api.tv.getAllVideos('?limit=1000').then((res) => {
			let data = [];

			if (res) {
				res.videos.map((item) => {
					if (item.ProgressStatus !== false) {
						data.push({
							...item,
							setting: (
								<span>
									<button
										className="btn btn-success"
										onClick={() => {
											this.setState({ selectedVideo: item });
											this.videos_modal();
										}}
									>
										Seç
									</button>
								</span>
							),
						});
					}
				});

				this.setState({ videos: data });
			}
		});
	};

	fetchImages = (limit = 1000, skip = 0) => {
		api.image.getImages(`?limit=${limit}&skip=${skip}`).then((res) => {
			this.setState({ imagesData: res, galleryTotalPage: Math.ceil(res.count / this.state.galleryCountPerPage) });
		});
	};

	searchImage = (text, limit = 1000, skip = 0) => {
		if (text.length > 4) {
			api.image.search(text + `&limit=${limit}&skip=${skip}`).then((res) => {
				this.setState({ imagesData: res });
			});
		} else if (text === '') {
			api.image.getImages(`?limit=${limit}&skip=${skip}`).then((res) => {
				this.setState({ imagesData: res });
			});
		}
	};

	paginationRender = () => {
		for (let i = 0; i < this.state.galleryTotalPage; i++) {
			return (
				<li className="page-item">
					<span className="page-link" to="#">
						{i + 1}
					</span>
				</li>
			);
		}
	};

	tog_1() {
		this.setState((prevState) => ({
			modal_1: !prevState.modal_1,
		}));
	}

	gallery_toggle = () => {
		this.setState((prevState) => ({
			galleryModal: !prevState.galleryModal,
		}));
	};

	onChange(e) {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
	}

	cropImage(width, height, crop) {
		if (typeof this.cropper === 'undefined') {
			this.setState({ pictureErrorMessage: 'Fotoğraf Seçin', pictureError: true });
			setTimeout(() => {
				this.setState({ pictureError: false });
			}, 4000);
			return;
		}
		if (typeof this.cropper.getCroppedCanvas() === 'undefined' || !this.cropper.getCroppedCanvas()) {
			this.setState({ pictureErrorMessage: 'Fotoğraf Seçin', pictureError: true });
			setTimeout(() => {
				this.setState({ pictureError: false });
			}, 4000);
			return;
		}
		if (!this.state.selectedVideo) {
			this.setState({ pictureErrorMessage: 'Video Seçin', pictureError: true });
			setTimeout(() => {
				this.setState({ pictureError: false });
			}, 4000);
			return;
		}
		if (crop) {
			this.setState({
				cropResult: this.cropper.getCroppedCanvas().toDataURL(),
			});
		} else {
			this.setState({
				cropResult: this.state.src,
			});
		}

		this.setState({
			imgWidth: width,
			imgHeight: height,
		});
		this.tog_1();
	}

	useDefaultImage() {
		this.setState({ src: '' });
	}

	changeRatio(e, r1, r2) {
		if (e.target.checked) {
			this.setState({ ratio1: r1, ratio2: r2 });
		}
	}

	dataURLtoFile = (dataurl, filename) => {
		const arr = dataurl.split(',');
		const mime = arr[0].match(/:(.*?);/)[1];
		const bstr = atob(arr[1]);
		let n = bstr.length;
		const u8arr = new Uint8Array(n);
		while (n) {
			u8arr[n - 1] = bstr.charCodeAt(n - 1);
			n -= 1; // to make eslint happy
		}
		return new File([u8arr], filename, { type: mime });
	};

	upload = (crop) => {
		let data = new FormData();
		if (crop) {
			let file = this.dataURLtoFile(this.state.cropResult, this.state.imgTitle);
			data.append('banner_pic', file, file.name);
		} else {
			data.append('imageUrl', this.state.src);
		}
		data.append('videoId', this.state.selectedVideo.VideoId);
		data.append('imageTitle', this.state.imgTitle);
		api.tv.changeBanner(data).then((res) => {
			if (res.success) {
				this.tog_1();
				this.setState({ src: '', selectedVideo: null, cropResult: null, success: true, imgTitle: '' });
				setTimeout(() => {
					this.setState({ success: false });
				}, 4000);
			} else {
				this.tog_1();
				this.setState({ src: '', selectedVideo: null, cropResult: null, error: true, imgTitle: '' });
				setTimeout(() => {
					this.setState({ error: false });
				}, 4000);
			}
		});
	};

	videos_modal = () => {
		this.setState((prevState) => ({
			videosModal: !prevState.videosModal,
		}));
		this.removeBodyCss();
	};
	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	render() {
		const data = {
			columns: [
				{
					label: 'Video Adı',
					field: 'Title',
					sort: 'asc',
					width: 500,
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
					width: 50,
				},
			],
			rows: this.state.videos,
		};
		return (
			<>
				<Helmet>
					<title>{`Banner Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor TV" breadcrumbItem="Genel Ayarlar" />
						<Row>
							<Col className="col-12">
								{this.state.success && <Alert color="info">Kayıt Başarılı</Alert>}
								{this.state.error && <Alert color="danger">Daha sonra tekrar deneyiniz.</Alert>}
							</Col>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Banner </CardTitle>
												<CardSubtitle>Banner için video ve fotoğraf seçin.</CardSubtitle>
											</div>

											<div>
												{this.state.selectedVideo ? (
													<>
														<Label className="btn btn-primary mr-2 mb-0 btn-upload" htmlFor="inputImage" title="Upload image file">
															<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={this.onChange} />
															<span className="docs-tooltip" id="uploadImage">
																<span className="mdi mdi-upload">Fotoğraf Yükle</span>
															</span>
														</Label>

														<button className="btn btn-primary mr-2" onClick={this.gallery_toggle}>
															Galeriden Seç
														</button>
													</>
												) : (
													<></>
												)}
												<button className="btn btn-primary" onClick={this.videos_modal}>
													Video Seç
												</button>
											</div>
										</div>
										<Row className="mt-2">
											<Col className="col-12 text-center">
												<span className="card-title">
													{this.state.selectedVideo ? (
														this.state.selectedVideo.Title
													) : (
														<div className="video__error" onClick={this.videos_modal}>
															Lütfen Bir Video Seçiniz.
														</div>
													)}
												</span>
											</Col>
											<Col className="col-12">{this.state.pictureError && <Alert color="danger">{this.state.pictureErrorMessage}</Alert>}</Col>
										</Row>
										{this.state.selectedVideo && (
											<>
												<Row className="mt-3">
													<Col xl="12">
														{this.state.src !== '' ? (
															<Cropper
																style={{ height: 400, width: '100%' }}
																aspectRatio={this.state.ratio1 / this.state.ratio2}
																preview=".img-preview"
																guides={true}
																src={this.state.src}
																ref={(cropper) => {
																	this.cropper = cropper;
																}}
																zoomTo={this.state.zoom}
																dragMode={this.state.dragMode}
																moveTo={[this.state.moveX, this.state.moveY]}
																rotateTo={this.state.rotate}
																scaleX={this.state.scaleX}
																scaleY={this.state.scaleY}
																enable={this.state.enable}
																disable={this.state.disable}
																viewMode={this.state.viewMode}
															/>
														) : (
															<div className="video__error">
																<Label className="btn btn-upload video__picture__error" htmlFor="inputImage" title="Upload image file">
																	<input type="file" className="sr-only" id="inputImage" name="file" accept="image/*" onChange={this.onChange} />
																	<span>Fotoğraf Yükle</span>
																</Label>
															</div>
														)}
													</Col>
												</Row>

												{this.state.src !== '' ? (
													<Row id="actions">
														<Col xl="12" className="img-crop-preview-btns docs-buttons">
															<div className="button-items mt-2 ">
																<Modal isOpen={this.state.modal_1} toggle={this.tog_1} autoFocus={true}>
																	<ModalHeader toggle={this.tog_1}>İndir / Kaydet</ModalHeader>
																	<ModalBody>
																		<img
																			style={{ width: '100%' }}
																			src={this.state.cropResult}
																			height={this.state.imgHeight}
																			width={this.state.imgWidth}
																			alt="cropped result"
																		/>
																		<div className="form-group row mt-2">
																			<label htmlFor="example-text-input" className="col-md-2 col-form-label">
																				Başlık
																			</label>
																			<div className="col-md-10">
																				<input
																					className="form-control"
																					type="text"
																					value={this.state.imgTitle}
																					onChange={(e) => this.setState({ imgTitle: e.target.value })}
																					placeholder="Fenerbahçe, Süper Lig, Beşiktaş ..."
																				/>
																			</div>
																		</div>
																	</ModalBody>
																	<ModalFooter>
																		<Button type="button" color="secondary" onClick={this.tog_1}>
																			Kapat
																		</Button>
																		<a
																			download={this.state.imgTitle !== '' ? this.state.imgTitle + '.png' : 'banner.png'}
																			href={this.state.cropResult}
																			className="btn btn-primary"
																		>
																			İndir
																		</a>
																		<Button
																			type="button"
																			color="success"
																			onClick={() => {
																				this.state.cropResult === this.state.src ? this.upload(false) : this.upload(true);
																			}}
																			disabled={this.state.imgTitle.length > 3 ? false : true}
																		>
																			Kaydet
																		</Button>
																	</ModalFooter>
																</Modal>

																<Button
																	type="button"
																	color="secondary"
																	onClick={() => {
																		this.setState({ moveX: 0, moveY: 0 });
																	}}
																>
																	<span className="docs-tooltip" id="move5">
																		Move to [0,0]
																	</span>
																	<UncontrolledTooltip placement="top" target="move5">
																		moveTo(0)
																	</UncontrolledTooltip>
																</Button>
																<Button type="button" color="secondary" onClick={() => this.setState({ zoom: 1 })}>
																	<span className="docs-tooltip" id="zoom3">
																		Zoom to 100%
																	</span>
																	<UncontrolledTooltip placement="top" target="zoom3">
																		zoomTo(1)
																	</UncontrolledTooltip>
																</Button>
																<Button type="button" color="secondary" onClick={() => this.setState({ rotate: 180 })}>
																	<span className="docs-tooltip" id="rotate3">
																		Rotate 180°
																	</span>
																	<UncontrolledTooltip placement="top" target="rotate3">
																		rotate(180)
																	</UncontrolledTooltip>
																</Button>
																<Button
																	type="button"
																	color="secondary"
																	onClick={() => {
																		this.setState({ scaleX: -2, scaleY: -1 });
																	}}
																>
																	<span className="docs-tooltip" id="scale3">
																		Scale (-2, -1)
																	</span>
																	<UncontrolledTooltip placement="top" target="scale3">
																		scale(-2, -1)
																	</UncontrolledTooltip>
																</Button>

																<ButtonGroup>
																	<Button
																		type="button"
																		color="primary"
																		onClick={() => {
																			this.setState({ dragMode: 'move' });
																		}}
																	>
																		<span className="docs-tooltip" id="dragMode1">
																			<span className="mdi mdi-arrow-all"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="dragMode1">
																			dragMode(&quot;move&quot;)
																		</UncontrolledTooltip>
																	</Button>
																	<Button
																		type="button"
																		color="primary"
																		onClick={() => {
																			this.setState({ dragMode: 'crop' });
																		}}
																	>
																		<span className="docs-tooltip" id="dragMode2">
																			<span className="mdi mdi-crop"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="dragMode2">
																			dragMode(&quot;crop&quot;)
																		</UncontrolledTooltip>
																	</Button>
																</ButtonGroup>

																<ButtonGroup>
																	<Button type="button" color="primary" onClick={() => this.setState({ zoom: this.state.zoom + 0.1 })}>
																		<span className="docs-tooltip" id="zoom1">
																			<span className="mdi mdi-magnify-plus-outline"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="zoom1">
																			zoomTo(0.1)
																		</UncontrolledTooltip>
																	</Button>
																	<Button type="button" color="primary" onClick={() => this.setState({ zoom: this.state.zoom - 0.1 })}>
																		<span className="docs-tooltip" id="zoom2">
																			<span className="mdi mdi-magnify-minus-outline"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="zoom2">
																			zoomTo(-0.1)
																		</UncontrolledTooltip>
																	</Button>
																</ButtonGroup>

																<ButtonGroup>
																	<Button type="button" color="primary" onClick={() => this.setState({ rotate: this.state.rotate - 45 })} title="Rotate Left">
																		<span className="docs-tooltip" id="rotate1">
																			<span className="mdi mdi-rotate-left"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="rotate1">
																			rotate(-45)
																		</UncontrolledTooltip>
																	</Button>
																	<Button type="button" color="primary" onClick={() => this.setState({ rotate: this.state.rotate + 45 })} title="Rotate Right">
																		<span className="docs-tooltip" id="rotate2">
																			<span className="mdi mdi-rotate-right"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="rotate2">
																			rotate(45)
																		</UncontrolledTooltip>
																	</Button>
																</ButtonGroup>

																<ButtonGroup>
																	<Button
																		type="button"
																		color="primary"
																		onClick={() => {
																			this.state.scaleX === 1 ? this.setState({ scaleX: -1 }) : this.setState({ scaleX: 1 });
																		}}
																		title="Flip Horizontal"
																	>
																		<span className="docs-tooltip" id="scale1">
																			<span className="mdi mdi-arrow-left-right"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="scale1">
																			scaleX(-1)
																		</UncontrolledTooltip>
																	</Button>
																	<Button
																		type="button"
																		color="primary"
																		onClick={() => {
																			this.state.scaleY === 1 ? this.setState({ scaleY: -1 }) : this.setState({ scaleY: 1 });
																		}}
																		title="Flip Vertical"
																	>
																		<span className="docs-tooltip" id="scale2">
																			<span className="mdi mdi-arrow-up-down"></span>
																		</span>
																		<UncontrolledTooltip placement="top" target="scale1">
																			scaleY(-1)
																		</UncontrolledTooltip>
																	</Button>
																</ButtonGroup>
																<Button type="button" color="success" onClick={() => this.cropImage('', '', true)}>
																	<span className="docs-tooltip" id="imgResult1">
																		Kırp ve Kaydet
																	</span>
																</Button>
																{this.state.noCrop ? (
																	<Button type="button" color="success" onClick={() => this.cropImage('', '', false)}>
																		<span className="docs-tooltip" id="imgResult1">
																			Kırpmadan Kaydet
																		</span>
																	</Button>
																) : (
																	<></>
																)}
															</div>
														</Col>
													</Row>
												) : (
													<></>
												)}
											</>
										)}
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
					<Modal isOpen={this.state.videosModal} toggle={this.tog_center} centered={true} size={'lg'}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Video Seç</h5>
							<button type="button" onClick={() => this.setState({ videosModal: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<MDBDataTable
								responsive
								striped
								bordered
								data={data}
								noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
								paginationLabel={['Önceki', 'Sonraki']}
								entriesLabel="Göster"
								infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
								searchLabel="Ara"
							/>
						</div>
					</Modal>
					<Modal isOpen={this.state.galleryModal} toggle={this.gallery_toggle} centered={true} size={'lg'}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Video Seç</h5>
							<button type="button" onClick={() => this.setState({ galleryModal: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<label className="col-md-2 col-form-label">Arama: </label>
								<input className="col-md-10 form-control" type="text" onChange={(e) => this.searchImage(e.target.value)} placeholder="Fotoğraf Ara..."></input>
							</div>
							<div className="row text-center">
								{this.state.imagesData.images.map((item) => {
									return (
										<div key={item.id} className="col-lg-4 col-6 mt-2" onClick={() => this.setState({ src: item.path, noCrop: true })}>
											<img className="mb-2" src={item.path} style={{ width: '100%' }} />
											<span>{item.title}</span>
										</div>
									);
								})}
							</div>

							<nav className="mt-4" aria-label="Page navigation example">
								<ul className="pagination justify-content-center">
									{this.state.galleryPage > 1 && (
										<li className="page-item">
											<span className="page-link" to="#">
												Önceki
											</span>
										</li>
									)}
									{this.paginationRender()}
									{this.state.galleryPage !== Math.ceil(this.state.imagesData.count / this.state.galleryCountPerPage) &&
										Math.ceil(this.state.imagesData.count / this.state.galleryCountPerPage) !== 0 && (
											<li className="page-item">
												<span className="page-link" to="#">
													Sonraki
												</span>
											</li>
										)}
								</ul>
							</nav>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default Banner;
