import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody } from 'reactstrap';
import Select from 'react-select';
import toastr from 'toastr';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';

class Notification extends React.Component {
	state = {
		title: '',
		text: '',
		id: '',
		loading: false,
		deeplink: [
			{ label: 'Haber', value: 'ajansspor://NewsDetail/' },
			{ label: 'Maç', value: 'ajansspor://Match/' },
		],
		selectedDeeplink: { label: 'Haber', value: 'ajansspor://NewsDetail/' },
	};

	selectDeeplink = (e) => {
		this.setState({ selectedDeeplink: e });
	};

	sendPush = (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
		});

		toastr.options = {
			positionClass: 'toast-top-center',
			progressBar: true,
		};

		if (this.state.title === '') {
			toastr.error('Bildirim Başlığı boş olamaz!');
			this.setState({ loading: false });
			return;
		}

		if (this.state.id === '') {
			toastr.error('Bildirim ID boş olamaz!');
			this.setState({ loading: false });
			return;
		}

		api.sendPush({
			title: this.state.title,
			text: this.state.text,
			deeplink: this.state.selectedDeeplink.value + this.state.id,
		}).then((res) => {
			if (res.success) {
				toastr.success(res.message);
				this.setState({
					title: '',
					text: '',
					id: '',
					selectedDeeplink: { label: 'Haber', value: 'ajansspor://NewsDetail/' },
					loading: false,
				});
			} else {
				toastr.error(res.message);
				this.setState({
					loading: false,
				});
			}
		});
	};

	render() {
		return (
			<>
				<Helmet>
					<title>{`Push Gönderme | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor" breadcrumbItem="Push Gönder" />
						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Push Gönderme </CardTitle>
												<CardSubtitle className="mb-3">Tüm kullanıcılara bildirim gidecektir.</CardSubtitle>
											</div>
										</div>

										<form onSubmit={this.sendPush}>
											<div className="form-group">
												<label htmlFor="newsId">Bildirim Başlığı</label>
												<input type="text" className="form-control" value={this.state.title} onChange={(e) => this.setState({ title: e.target.value })} />
											</div>
											<div className="form-group">
												<label htmlFor="newsId">Bildirim İçeriği</label>
												<input type="text" className="form-control" value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })} />
											</div>
											<div className="form-group">
												<label htmlFor="name">Deeplink Seçeneği</label>
												<Select
													value={this.state.selectedDeeplink}
													isMulti={false}
													onChange={this.selectDeeplink}
													options={this.state.deeplink}
													classNamePrefix="select2-selection"
													placeholder="Deeplink Seçiniz."
													className="mb-2"
													theme={(theme) => ({
														...theme,
														colors: {
															primary: '#2e3548',
														},
													})}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="newsId">{this.state.selectedDeeplink.label} ID</label>
												<input
													type="text"
													className="form-control"
													value={this.state.id}
													pattern="[0-9]*"
													onChange={(e) => this.setState({ id: e.target.validity.valid ? e.target.value : this.state.id })}
												/>
											</div>

											<button className="btn btn-primary" disabled={!(this.state.id.length > 0 && this.state.title.length > 0 && this.state.text.length > 0)}>
												{this.state.loading ? <i className="bx bx-loader bx-spin"></i> : 'Push Gönder'}
											</button>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default Notification;
