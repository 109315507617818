/* eslint-disable react/prop-types */
import React from 'react';
import { Container, Row, Col, Card, DropdownItem, DropdownMenu, DropdownToggle, Media, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from 'reactstrap';
import Helmet from 'react-helmet';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import classnames from 'classnames';
import moment from 'moment';
moment.locale('tr');

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Avatar from 'react-avatar';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

import { getTokenDecoded } from '../../../utils/auth';

class Dashboard extends React.Component {
	state = {
		activeTab: '1',
		search_Menu: false,
		programs: [],
		messages: [],
		blockedMessage: [],
		groupedMessages: [],
		today: moment().format('YYYY-MM-DD'),
		tomorrow: moment().add(-1, 'days').format('YYYY-MM-DD'),
		currentProgram: 0,
		loading: false,
		blocker: getTokenDecoded(localStorage.getItem('ajstoken')).UserId,
		fullname: getTokenDecoded(localStorage.getItem('ajstoken')).Fullname,
		fetching: false,
	};

	toggleSearch() {
		this.setState((prevState) => ({
			search_Menu: !prevState.search_Menu,
		}));
	}

	toggleTab(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab,
			});
		}
	}

	componentDidMount() {
		api.radyospor.streaming.list().then((res) => {
			this.setState({
				programs: res.streaming,
			});
		});

		this.fetchMessages();

		setInterval(() => {
			this.fetchMessages();
			this.setState(
				{
					fetching: true,
				},
				() =>
					setTimeout(() => {
						this.setState({ fetching: false });
					}, 2000),
			);
		}, 10000);
	}

	fetchMessages = () => {
		api.radyospor.message.list().then((res) => {
			this.setState(
				{
					messages: res?.messages?.length > 0 ? res?.messages?.filter((x) => x.status === true) : [],
					blockedMessages: res?.messages?.length > 0 ? res?.messages?.filter((x) => x.status === false) : [],
				},
				() => {
					this.messagesGrouped(this.state.messages);
				},
			);
		});
	};

	messagesGrouped = (data) => {
		const groups = data.reduce((groups, game) => {
			const date = game.createdAt.split('T')[0];
			if (!groups[date]) {
				groups[date] = [];
			}
			groups[date].push(game);
			return groups;
		}, {});

		const groupedMessages = Object.keys(groups).map((date) => {
			return {
				date,
				messages: groups[date],
			};
		});

		this.setState({
			groupedMessages,
		});
	};

	changeProgram = (id) => {
		this.setState(
			{
				loading: true,
				currentProgram: id,
				groupedMessages: [],
			},
			() => {
				let grouped = this.state.messages?.filter((x) => x.programId === id);
				let _this = this;

				setTimeout(() => {
					if (id !== 0) {
						_this.messagesGrouped(grouped);
					} else {
						_this.messagesGrouped(_this.state.messages);
					}

					_this.setState({
						loading: false,
					});
				}, 100);
			},
		);
	};

	saveMessage = (id) => {
		console.log(id);
	};

	blockEmail = (email, id) => {
		api.radyospor.block.add({ type: 'email', email, blocker: this.state.blocker, program: id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	blockIP = (ip, id) => {
		api.radyospor.block.add({ type: 'ip', ip, blocker: this.state.blocker, program: id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	render() {
		let { activeTab, programs, groupedMessages, today, tomorrow, loading, currentProgram, fullname } = this.state;

		return (
			<div className="page-content">
				<Helmet>
					<title>{`${process.env.REACT_APP_RS_TITLE}`}</title>
				</Helmet>

				<Container fluid>
					<Breadcrumbs title="Mesaj Yönetimi" breadcrumbItem="Mesajlar" />

					<Row>
						<Col lg="12">
							<div className="d-lg-flex">
								<div className="chat-leftsidebar mr-lg-4">
									<div className="">
										<div className="py-4 border-bottom">
											<Media className="d-flex flex-row align-items-center">
												<div className="align-self-center mr-3">
													<Avatar name={fullname} className="rounded-circle" size={36} />
												</div>
												<Media body>
													<h5 className="font-size-15 mt-0 mb-1">{fullname}</h5>
												</Media>
											</Media>
										</div>

										{/* <div className="search-box chat-search-box py-4">
											<div className="position-relative">
												<Input type="text" className="form-control" placeholder="Ara..." />
												<i className="bx bx-search-alt search-icon"></i>
											</div>
										</div> */}

										<div className="chat-leftsidebar-nav">
											<Nav pills justified>
												<NavItem>
													<NavLink
														className={classnames({ active: activeTab === '1' })}
														onClick={() => {
															this.toggleTab('1');
														}}
													>
														<i className="bx bx-chat font-size-20 d-sm-none"></i>
														<span className="d-none d-sm-block">Mesajlar</span>
													</NavLink>
												</NavItem>
												{/* <NavItem>
													<NavLink
														className={classnames({ active: activeTab === '2' })}
														onClick={() => {
															this.toggleTab('2');
														}}
													>
														<i className="bx bx-group font-size-20 d-sm-none"></i>
														<span className="d-none d-sm-block">Kaydedilenler</span>
													</NavLink>
												</NavItem> */}
											</Nav>
											<TabContent activeTab={activeTab} className="py-4">
												<TabPane tabId="1">
													<div>
														<h5 className="font-size-14 mb-3">Programlar</h5>
														<ul className="list-unstyled chat-list">
															<PerfectScrollbar style={{ height: '410px' }}>
																<li className={currentProgram === 0 ? 'active' : ''}>
																	<a href="#!" onClick={() => this.changeProgram(0)}>
																		<Media className="overflow-hidden" body>
																			<h5 className="text-truncate font-size-14 mb-1">Canlı Akış</h5>
																			<p className="text-truncate mb-0">Tüm programları gösterir.</p>
																		</Media>
																	</a>
																</li>

																{/* {programs.length > 0 &&
																	programs.map((program, i) => (
																		<li key={i} className={currentProgram === program.id ? 'active' : ''}>
																			<a href="#!" onClick={() => this.changeProgram(program.id)}>
																				<Media>
																					<div className="align-self-center mr-3">
																						<i className="mdi mdi-circle text-success font-size-10"></i>
																					</div>
																					<Media className="overflow-hidden" body>
																						<h5 className="text-truncate font-size-14 mb-1">{program.programInfo[0].name}</h5>
																						<p className="text-truncate mb-0">
																							{program.broadcasters.map((broadcaster, i) => (
																								<span key={i}>
																									{i > 0 ? ' - ' : ''}
																									{broadcaster.name}
																								</span>
																							))}
																						</p>
																					</Media>
																					<div className="font-size-11">
																						{program.startTime} - {program.endTime}
																					</div>
																				</Media>
																			</a>
																		</li>
																	))} */}
															</PerfectScrollbar>
														</ul>
													</div>
												</TabPane>

												<TabPane tabId="2">
													<div>
														<h5 className="font-size-14 mb-3">Programlar</h5>
														<ul className="list-unstyled chat-list">
															<PerfectScrollbar style={{ height: '410px' }}>
																<li className="active">
																	<a href="#">
																		<Media className="overflow-hidden" body>
																			<h5 className="text-truncate font-size-14 mb-1">Canlı Akış</h5>
																			<p className="text-truncate mb-0">Tüm programları gösterir.</p>
																		</Media>
																	</a>
																</li>

																{programs.length > 0 &&
																	programs.map((program, i) => (
																		<li key={i}>
																			<a href="#">
																				<Media>
																					<div className="align-self-center mr-3">
																						<i className="mdi mdi-circle text-success font-size-10"></i>
																					</div>
																					<Media className="overflow-hidden" body>
																						<h5 className="text-truncate font-size-14 mb-1">{program.programInfo[0].name}</h5>
																						<p className="text-truncate mb-0">
																							{program.broadcasters.map((broadcaster, i) => (
																								<span key={i}>
																									{broadcaster.name}
																									{i > 0 && i < program.broadcasters.length ? '' : ' - '}
																								</span>
																							))}
																						</p>
																					</Media>
																					<div className="font-size-11">10:00</div>
																				</Media>
																			</a>
																		</li>
																	))}
															</PerfectScrollbar>
														</ul>
													</div>
												</TabPane>
											</TabContent>
										</div>
									</div>
								</div>
								<div className="w-100 user-chat">
									<Card>
										<div className="p-4 border-bottom ">
											<Row>
												<Col md="4" xs="9">
													<h5 className="font-size-15 mb-1">Canlı Akış</h5>

													{/* <p className="text-muted mb-0">
														<i className="mdi mdi-circle text-success align-middle mr-1"></i>
														yayında
													</p> */}
												</Col>
												{/* <Col md="8" xs="3">
													<ul className="list-inline user-chat-nav text-right mb-0">
														<li className="list-inline-item d-none d-sm-inline-block">
															<Dropdown isOpen={search_Menu} toggle={() => this.toggleSearch()}>
																<DropdownToggle className="btn nav-btn" tag="i">
																	<i className="bx bx-search-alt-2"></i>
																</DropdownToggle>
																<DropdownMenu className="dropdown-menu-md" right>
																	<Form className="p-3">
																		<FormGroup className="m-0">
																			<InputGroup>
																				<Input type="text" className="form-control" placeholder="Ara ..." aria-label="Recipient's username" />
																				<InputGroupAddon addonType="append">
																					<Button color="primary" type="submit">
																						<i className="mdi mdi-magnify"></i>
																					</Button>
																				</InputGroupAddon>
																			</InputGroup>
																		</FormGroup>
																	</Form>
																</DropdownMenu>
															</Dropdown>
														</li>
													</ul>
												</Col> */}
											</Row>
										</div>

										<div>
											<div className="chat-conversation p-3">
												{loading && (
													<div className="container-loader">
														<i className="bx bx-loader bx-spin"></i>
													</div>
												)}

												<ul className="list-unstyled">
													<PerfectScrollbar style={{ height: '470px' }}>
														{groupedMessages?.length > 0 ? (
															groupedMessages?.map((message, i) => (
																<div key={i}>
																	<li>
																		<div className="chat-day-title">
																			<span className="title">
																				{message.date === today ? 'Bugün' : message.date === tomorrow ? 'Dün' : moment(message.date).format('DD-MM-YYYY')}
																			</span>
																		</div>
																	</li>

																	{message.messages.map((msg, j) => (
																		<li key={j}>
																			<div className="conversation-list" style={{ maxWidth: '80%' }}>
																				<UncontrolledDropdown>
																					<DropdownToggle href="#" className="btn nav-btn" tag="i">
																						<i className="bx bx-dots-vertical-rounded"></i>
																					</DropdownToggle>
																					<DropdownMenu direction="left">
																						{/* <DropdownItem href="#!" onClick={() => this.saveMessage(msg.id)}>
																							Kaydet
																						</DropdownItem> */}
																						<DropdownItem href="#!" onClick={() => this.blockEmail(msg.email, msg.programId)}>
																							Eposta adresini engelle
																						</DropdownItem>
																						<DropdownItem href="#!" onClick={() => this.blockIP(msg.ipaddress, msg.programId)}>
																							IP adresini engelle
																						</DropdownItem>
																					</DropdownMenu>
																				</UncontrolledDropdown>
																				<div className="ctext-wrap">
																					<div className="conversation-name">{msg.name}</div>
																					<p>{msg.message}</p>
																					<p className="chat-time mb-0" title={moment(msg.createdAt).format('DD-MM-YYYY HH:mm')}>
																						<i className="bx bx-time-five align-middle mr-1"></i> {moment(msg.createdAt).fromNow()}
																					</p>
																				</div>
																			</div>
																		</li>
																	))}
																</div>
															))
														) : (
															<span>Henüz mesaj gönderilmemiş.</span>
														)}
													</PerfectScrollbar>
												</ul>
											</div>
										</div>
									</Card>
								</div>
							</div>
						</Col>
					</Row>
				</Container>

				<div className={`message-control ${this.state.fetching ? 'active' : ''}`}>
					<i className="bx bx-loader bx-spin"></i>
					<span>Yeni mesajlar kontrol ediliyor</span>
				</div>
			</div>
		);
	}
}

export default Dashboard;
