/* eslint-disable no-unused-vars */
import React from 'react';
import Helmet from 'react-helmet';
import { MDBDataTable } from 'mdbreact';
import PropTypes from 'prop-types';
import { Query, withApollo } from 'react-apollo';
import { Container, Row, Col, Card, CardTitle, CardBody, Modal, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
//import { MDBDataTable } from 'mdbreact';
import moment from 'moment';
import 'moment/locale/tr';
import toastr from 'toastr';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { ADD_CONTENT_TO_HEADLINE, CONTENT_SEARCH, UPDATE_CONTENT_ISMAINPAGE, DELETE_CONTENT } from '../../queries/ContentQuery';

import { addLog } from '../../utils/addLog';
import { seoUrl } from '../../utils/seoUrl';
import api from '../../api';
import { getTokenDecoded } from '../../utils/auth';

class Contents extends React.Component {
	state = {
		name: '',
		error: null,
		search: '',
		searchText: '',
		editStatus: false,
		headlineTitle: '',
		headlineOrder: 0,
		toHeadlineTitle: '',
		toHeadlineStatus: false,
		isAlertOpen: false,
		alertWarning: false,
		alertSuccess: false,
		alertTitle: '',
		deletingId: 0,
		deleteStatus: false,
		page: 1,
		limit: 10,
		isDelete: false,
		shortLinkModal: false,
		selectedShortLink: '',
		shortLinkLoading: false,
		isNotificationOpen: false,
		pushStatus: false,
		menu: false,
	};

	toggle = () => {
		this.setState((prevState) => ({
			editStatus: !prevState.editStatus,
			name: '',
			toHeadlineTitle: '',
			headlineTitle: '',
			headlineOrder: 0,
		}));
		this.removeBodyCss();
	};

	toggleShortLink = () => {
		this.setState({ shortLinkModal: !this.state.shortLinkModal });
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	componentDidMount() {
		const token = getTokenDecoded(localStorage.getItem('ajstoken'));

		if (
			token.Username === 'emrepirnaz' ||
			token.Username === 'Kethuda' ||
			token.Username === 'kenanb' ||
			token.Username === 'muratduymus' ||
			token.Username === 'AkinU' ||
			token.Username === 'alibozkurt' ||
			token.Username === 'ismail.kls' ||
			token.Username === 'OrhanGLK'
		) {
			this.setState({
				pushStatus: true,
			});
		}

		if (window.location.search.split('?s=').length > 0 && window.location.search.split('?s=')[1]) {
			let page = 1;
			if (parseInt(window.location.search.split('?s=')[1], 10) <= 0) {
				page = 1;
			} else if (parseInt(window.location.search.split('?s=')[1], 10) <= 1000) {
				page = parseInt(window.location.search.split('?s=')[1], 10);
			} else {
				page = 1000;
			}
			this.setState({
				page,
			});
		}
		addLog(10, 'Haber listeleme görüntülendi');
	}

	searchNew = (e) => {
		e.preventDefault();
		this.setState({ searchText: e.target.value });
	};

	onSubmit = (e) => {
		e.preventDefault();
		if (this.state.searchText !== '') {
			this.setState({
				limit: 100,
				page: 1,
			});
		} else {
			this.setState({
				limit: 10,
			});
		}
		this.setState({ search: this.state.searchText });
	};

	changeIsMainPage = async (ContentId, IsMainPage) => {
		try {
			const mutation = await this.props.client.mutate({
				mutation: UPDATE_CONTENT_ISMAINPAGE,
				variables: {
					input: {
						ContentId,
						IsMainPage,
					},
				},
			});

			if (mutation.data.updateContentIsMainPage.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.updateContentIsMainPage.message);

				setTimeout(() => window.location.reload(), 2000);

				addLog(35, `<a href="https://admin.ajansspor.com/news/content/${ContentId}" target="_blank">${ContentId}</a> id'li haber anasayfaya eklendi.`);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.updateContentIsMainPage.message);
				addLog(36, `<a href="https://admin.ajansspor.com/news/content/${ContentId}" target="_blank">${ContentId}</a> id'li haber anasayfadan çıkarıldı.`);
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};
			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});
		}
	};

	editHeadlineTitle = async () => {
		this.setState({
			toHeadlineStatus: true,
		});

		try {
			const mutation = await this.props.client.mutate({
				mutation: ADD_CONTENT_TO_HEADLINE,
				variables: {
					input: {
						ContentId: this.state.toHeadlineId,
						Order: this.state.headlineOrder,
						MainPageTitle: this.state.toHeadlineTitle,
					},
				},
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.addContentToHeadline.message);

				this.setState({
					toHeadlineStatus: false,
				});

				let logData = `Manşete haber id'si: <a href="https://admin.ajansspor.com/news/content/${this.state.toHeadlineId}" target="_blank" />${this.state.editingContent}</a>. Anasayfa başlığı: ${this.state.toHeadlineTitle}, manşet sırası: ${this.state.headlineOrder}`;
				addLog(40, logData);
				await api.homepageCache();
				setTimeout(() => window.location.reload(), 2000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.addContentToHeadline.message);
				addLog(34, mutation.data.addContentToHeadline.message);

				this.setState({
					toHeadlineStatus: false,
				});
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};
			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});

			this.setState({
				toHeadlineStatus: false,
			});
		}
	};

	deleteNews = async () => {
		this.setState({
			deleteStatus: true,
			isAlertOpen: false,
		});

		try {
			const mutation = await this.props.client.mutate({
				mutation: DELETE_CONTENT,
				variables: {
					input: {
						ContentId: this.state.deletingId,
					},
				},
			});

			if (mutation) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(mutation.data.deleteContent.message);

				setTimeout(() => {
					toastr.warning('Haber cacheleri güncelleniyor. Sayfanın yenilenmesini bekleyin.');
				}, 1500);

				this.setState({
					deleteStatus: false,
				});

				let logData = `<a href="https://admin.ajansspor.com/news/content/${this.state.deletingId}" target="_blank" />${this.state.deletingId}</a> id'li haber silindi.`;
				await api.cache(this.state.deletingId);
				await api.contentCache(this.state.deletingId);
				await api.homepageCache();
				addLog(41, logData);
				setTimeout(() => window.location.reload(), 2000);
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
				};
				toastr.error(mutation.data.deleteContent.message);
				addLog(34, mutation.data.deleteContent.message);

				this.setState({
					deleteStatus: false,
				});
			}
		} catch (e) {
			toastr.options = {
				positionClass: 'toast-top-center',
			};
			e.graphQLErrors.map(({ message }) => {
				toastr.error(message);
				addLog(34, message);
			});

			this.setState({
				deleteStatus: false,
			});
		}
	};

	pagination = () => {
		if (this.state.searchText === '') {
			return (
				<Row>
					<div className="col-12 d-flex flex-row-reverse">
						<nav aria-label="Page navigation example text-right">
							<ul className="pagination text-right d-flex">
								{this.state.page === 1 ? (
									<>
										<li className="page-item">
											<span className="page-link pagination-active">1</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(2)}>
												2
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(3)}>
												3
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(4)}>
												4
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(5)}>
												5
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" aria-label="Next" role="button" onClick={() => this.changeUrl(this.state.page + 1)}>
												<span aria-hidden="true">&raquo;</span>
												<span className="sr-only" role="button">
													Next
												</span>
											</span>
										</li>
									</>
								) : this.state.page !== 1000 ? (
									<>
										<li className="page-item">
											<span className="page-link" aria-label="Previous" role="button" onClick={() => this.changeUrl(this.state.page - 1)}>
												<span aria-hidden="true">&laquo;</span>
												<span className="sr-only">Geri</span>
											</span>
										</li>
										{this.state.page - 2 > 0 && (
											<li className="page-item">
												<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 2)}>
													{this.state.page - 2}
												</span>
											</li>
										)}

										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 1)}>
												{this.state.page - 1}
											</span>
										</li>
										<li className="page-item">
											<span className="page-link pagination-active" role="button" onClick={() => this.changeUrl(this.state.page)}>
												{this.state.page}
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page + 1)}>
												{this.state.page + 1}
											</span>
										</li>
										{this.state.page + 2 <= 1000 && (
											<li className="page-item">
												<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page + 2)}>
													{this.state.page + 2}
												</span>
											</li>
										)}

										<li className="page-item">
											<span className="page-link" aria-label="Next" role="button" onClick={() => this.changeUrl(this.state.page + 1)}>
												<span aria-hidden="true">&raquo;</span>
												<span className="sr-only">Next</span>
											</span>
										</li>
									</>
								) : (
									<>
										<li className="page-item">
											<span className="page-link" aria-label="Previous" role="button" onClick={() => this.changeUrl(this.state.page - 1)}>
												<span aria-hidden="true">&laquo;</span>
												<span className="sr-only">Geri</span>
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 4)}>
												996
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 3)}>
												997
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 2)}>
												998
											</span>
										</li>
										<li className="page-item">
											<span className="page-link" role="button" onClick={() => this.changeUrl(this.state.page - 1)}>
												999
											</span>
										</li>
										<li className="page-item">
											<span className="page-link pagination-active">1000</span>
										</li>
									</>
								)}
							</ul>
						</nav>
					</div>
				</Row>
			);
		} else {
			return '';
		}
	};

	changeUrl = (page) => {
		window.history.pushState({ page: 'news' }, 'another page', `news?s=${page}`);
		this.setState({
			page,
		});
	};

	shortUrl = async (url, platform = '') => {
		this.setState({ shortLinkLoading: true });
		const data = await api.shortLink('https://ajansspor.com/haber/' + url);
		toastr.options = {
			positionClass: 'toast-top-center',
			progressBar: true,
			timeOut: 2000,
		};
		if (data.success) {
			navigator.clipboard.writeText(data.link + platform);
			toastr.success('Kısa Link Kopyalandı.');
			addLog(50, `<a href="https://ajansspor.com/haber/${url}" target="_blank" />Haberin</a> linki kısaltıldı / kopyalandı.`);
		} else {
			toastr.error(data.error);
			addLog(51, `<a href="https://ajansspor.com/haber/${url}" target="_blank" />Haberin</a> linki kısaltılamadı / kopyalanamadı. Hata: ${data.error}`);
		}
		this.setState({ shortLinkModal: false, shortLinkLoading: false });
	};

	sendNotification = async (ContentId, MainPageTitle, IsDeleted) => {
		if (IsDeleted) {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Bildirim göndermek için haberin yayında olması gerekiyor!');
		} else {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.info('Lütfen bildirim gönderilene kadar sayfadan ayrılmayın. Bunun için uyarı alacaksınız.');

			await api
				.sendPush({
					text: MainPageTitle,
					title: 'Son Dakika',
					deeplink: `ajansspor://NewsDetail/${ContentId}`,
					weblink: `https://ajansspor.com/haber/${ContentId}`,
				})
				.then((res) => {
					if (res.success) {
						toastr.success(res.message);
					} else {
						toastr.error(res.message);
					}
				});
		}
	};

	toggleDropdown = () => {
		this.setState((prevState) => ({
			menu: !prevState.menu,
		}));
	};

	render() {
		return (
			<>
				{this.state.isAlertOpen && (
					<SweetAlert
						title=""
						warning={this.state.alertWarning}
						success={this.state.alertSuccess}
						confirmBtnText={this.state.isDelete ? 'Göster' : 'Sil'}
						onConfirm={() => !this.state.deleteStatus && this.deleteNews()}
						showCancel={true}
						cancelBtnText="Vazgeç"
						reverseButtons
						onCancel={() => this.setState({ alertWarning: false, alertSuccess: false, isAlertOpen: false, alertTitle: '', deletingId: 0 })}
					>
						<div dangerouslySetInnerHTML={{ __html: this.state.alertTitle }}></div>
					</SweetAlert>
				)}

				<Helmet>
					<title>{`Haberler - Haber Yönetimi | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Haber Yönetimi" breadcrumbItem="Haberler" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Haberler</CardTitle>
											</div>
											<div>
												<form className="app-search d-none d-lg-block content__search" onSubmit={(e) => this.onSubmit(e)}>
													<div className="position-relative">
														<input type="text" className="form-control" placeholder="Haber Ara..." onChange={(e) => this.searchNew(e)} />
														<span className="bx bx-search-alt"></span>
													</div>
												</form>
											</div>
										</div>

										<Query query={CONTENT_SEARCH} variables={{ Term: this.state.search, Skip: (this.state.page - 1) * 10, Limit: this.state.limit }}>
											{({ loading, data }) => {
												if (loading) return null;
												let tableData = [];

												data.contentSearch.map((item) => {
													tableData.push({
														...item,
														isMainPage: (
															<span>
																{item.IsMainPage ? (
																	<span className="badge badge-soft-success">Aktif</span>
																) : (
																	<span className="badge badge-soft-warning">Aktif Değil</span>
																)}
															</span>
														),
														isHeadline: (
															<span>
																{item.IsHeadline ? (
																	<span className="badge badge-soft-success">Aktif</span>
																) : (
																	<span className="badge badge-soft-warning">Aktif Değil</span>
																)}
															</span>
														),
														fixedDate: (
															<>
																<span title="Eklenme Tarihi">
																	{moment().add(-60, 'minutes').isBefore(moment(item.CreatedDate))
																		? moment(item.CreatedDate).fromNow().toString()
																		: moment(item.CreatedDate).format('DD-MM-YYYY HH:mm:ss').toString()}
																</span>
																<br />
																<span title="Güncelleme Tarihi">
																	{moment().add(-60, 'minutes').isBefore(moment(item.UpdatedDate))
																		? moment(item.UpdatedDate).fromNow().toString()
																		: moment(item.UpdatedDate).format('DD-MM-YYYY HH:mm:ss').toString()}
																</span>
															</>
														),
														tags: (
															<span>
																{item.TagList.length > 0 ? (
																	item.TagList.map((tag, i) => (
																		<span key={i} className="badge badge-info mr-2">
																			{tag.Name}
																		</span>
																	))
																) : (
																	<span className="badge badge-primary">Mevcut değil</span>
																)}
															</span>
														),

														setting: (
															<div className="btn-group mr-2 mb-2 mb-sm-0">
																<button
																	className="btn btn-secondary"
																	onClick={() => {
																		this.setState({ shortLinkModal: true, selectedShortLink: seoUrl(item.Title + '-' + item.ContentId) });
																	}}
																>
																	<i className="bx bx-link"></i>
																</button>

																<button
																	className={`btn ${item.IsDeleted ? 'btn-danger' : 'btn-secondary'}`}
																	onClick={() =>
																		this.setState({
																			alertTitle: item.Title + ` başlıklı haberi ${item.IsDeleted ? 'göstermek' : 'silmek'} istediğinize emin misin?`,
																			isAlertOpen: true,
																			alertWarning: true,
																			deletingId: item.ContentId,
																			isDelete: item.IsDeleted,
																		})
																	}
																>
																	<i className="bx bx-trash"></i>
																</button>

																<a href={`/news/news/${item.ContentId}`} className="btn btn-secondary">
																	<i className="bx bxs-pencil"></i>
																</a>

																{item.IsHeadline ? (
																	<a href={window.location.href + '/headline'} className={`btn ${item.IsHeadline ? 'btn-warning' : 'btn-secondary'}`}>
																		<i className="bx bx-star"></i>
																	</a>
																) : (
																	<button
																		className={`btn ${item.IsHeadline ? 'btn-warning' : 'btn-secondary'}`}
																		onClick={() =>
																			this.setState({
																				toHeadlineId: item.ContentId,
																				toHeadlineTitle: item.MainPageTitle,
																				headlineTitle: item.Title,
																				editStatus: true,
																				headlineOrder: item.HeadlineOrder ? item.HeadlineOrder : 0,
																			})
																		}
																	>
																		<i className="bx bx-star"></i>
																	</button>
																)}

																<button
																	className={`btn ${item.IsMainPage ? 'btn-success' : 'btn-secondary'}`}
																	onClick={() => this.changeIsMainPage(item.ContentId, !item.IsMainPage)}
																>
																	<i className="bx bx-desktop"></i>
																</button>

																{this.state.pushStatus && (
																	<button
																		className={`btn ${item.IsDeleted ? 'btn-danger' : 'btn-secondary'}`}
																		onClick={() => this.sendNotification(item.ContentId, item.Title, item.IsDeleted)}
																	>
																		<i className="bx bxs-bell-ring"></i>
																	</button>
																)}

																<UncontrolledDropdown className="ml-1">
																	<DropdownToggle href="#" className="btn btn-secondary" tag="i">
																		<i className="bx bx-dots-vertical-rounded"></i>
																	</DropdownToggle>
																	<DropdownMenu direction="right" right>
																		<DropdownItem
																			href={`https://facebook.com/sharer/sharer.php?u=https://ajansspor.com/haber/${seoUrl(item.Title)}-${item.ContentId}`}
																			target="_blank"
																		>
																			Facebookta paylaş
																		</DropdownItem>
																		<DropdownItem
																			href={`https://twitter.com/intent/tweet/?text=${item.Title}&url=https://ajansspor.com/haber/${seoUrl(item.Title)}-${
																				item.ContentId
																			}`}
																			target="_blank"
																		>
																			Twitterda paylaş
																		</DropdownItem>
																		<DropdownItem
																			href={`https://t.me/share/url?url=https://ajansspor.com/haber/${seoUrl(item.Title)}-${item.ContentId}&text=${item.Title}`}
																			target="_blank"
																		>
																			Telegramda paylaş
																		</DropdownItem>
																		<DropdownItem
																			href={`whatsapp://send?text=${item.Title}%20https://ajansspor.com/haber/${seoUrl(item.Title)}-${item.ContentId}`}
																			target="_blank"
																		>
																			Whatsappta paylaş
																		</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															</div>
														),
													});
												});
												const contents = {
													columns: [
														{
															label: 'ID',
															field: 'ContentId',
															sort: 'asc',
															width: '100px',
														},
														{
															label: 'Başlık',
															field: 'Title',
															sort: 'asc',
															width: '100px',
														},
														{
															label: 'Banner',
															field: 'isHeadline',
															sort: 'asc',
															width: '50px',
														},
														{
															label: 'Anasayfa',
															field: 'isMainPage',
															sort: 'asc',
															width: '50px',
														},
														{
															label: 'Etiketler',
															field: 'tags',
															sort: 'asc',
															width: '100px',
														},
														{
															label: 'Okunma Sayısı',
															field: 'ReadCount',
															sort: 'asc',
															width: '50px',
														},
														{
															label: 'Eklenme / Güncellenme Tarihi',
															field: 'fixedDate',
															sort: 'asc',
															width: '100px',
														},
														{
															label: 'Detay',
															field: 'setting',
															sort: 'asc',
															width: '150px',
														},
													],
													rows: tableData,
												};
												return (
													<div className="mini-table">
														<MDBDataTable
															responsive
															striped
															bordered
															data={contents}
															noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
															paginationLabel={['Önceki', 'Sonraki']}
															entriesLabel="Göster"
															infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
															searchLabel="Ara"
															searching={false}
															className="contentsTable"
															paging={false}
														/>
														{this.pagination()}
													</div>
												);
											}}
										</Query>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>

				<Modal isOpen={this.state.editStatus} toggle={this.toggle} centered={true}>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Başlığı manşete taşıyın.</h5>
						<button
							type="button"
							onClick={() => this.setState({ editStatus: false, headlineTitle: '', toHeadlineTitle: '', headlineOrder: 0 })}
							className="close"
							data-dismiss="modal"
							aria-label="Kapat"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<p>
							<b>{this.state.headlineTitle}</b> başlığını düzenliyorsunuz.
						</p>

						<div className="form-group">
							<label htmlFor="name">Manşet başlığı</label>
							<input type="text" value={this.state.toHeadlineTitle} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ toHeadlineTitle: e.target.value })} />
						</div>

						<div className="form-group">
							<label htmlFor="name">Manşet sırası</label>
							<input
								type="text"
								value={this.state.headlineOrder}
								className={`form-control ${this.state.headlineOrder < 1 && 'is-invalid'}`}
								placeholder="Giriniz"
								onChange={(e) => this.setState({ headlineOrder: new RegExp(/^[0-9\b]+$/).test(e.target.value) ? parseInt(e.target.value, 10) : '' })}
							/>
							{this.state.headlineOrder < 1 && <div className="invalid-feedback d-block">Sıra en az 1 olabilir.</div>}
						</div>

						<button className="btn btn-info mt-4" onClick={() => !this.state.toHeadlineStatus && this.editHeadlineTitle()}>
							{this.state.toHeadlineStatus ? <i className="bx bx-loader bx-spin"></i> : 'Manşete Al'}
						</button>
					</div>
				</Modal>
				<Modal isOpen={this.state.shortLinkModal} toggle={this.togleShortLink} centered={true}>
					<div className="modal-header">
						<h5 className="modal-title mt-0">Paylaşılacak Platform Seçiniz.</h5>
						<button type="button" onClick={() => this.setState({ selectedShortLink: '', shortLinkModal: false })} className="close" data-dismiss="modal" aria-label="Kapat">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div className="modal-body">
						<Row>
							<Col xs="6" className="text-center">
								<button
									className="btn btn-primary btn-block btn-lg d-flex align-items-center justify-content-center"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink, '?pt=twitter');
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : <i className="bx bxl-twitter mr-1"></i>}
									Twitter
								</button>
							</Col>
							<Col xs="6" className="text-center">
								<button
									className="btn btn-primary btn-block btn-lg d-flex align-items-center justify-content-center"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink, '?pt=facebook');
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : <i className="bx bxl-facebook mr-1"></i>}
									Facebook
								</button>
							</Col>
							<Col xs="6" className="text-center mt-3">
								<button
									className="btn btn-primary btn-block btn-lg d-flex align-items-center justify-content-center"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink, '?pt=whatsapp');
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : <i className="bx bxl-whatsapp mr-1"></i>}
									Whatsapp
								</button>
							</Col>
							<Col xs="6" className="text-center mt-3">
								<button
									className="btn btn-primary btn-block btn-lg d-flex align-items-center justify-content-center"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink, '?pt=telegram');
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : <i className="bx bxl-telegram mr-1"></i>}
									Telegram
								</button>
							</Col>
							<Col xs="6" className="text-center mt-3">
								<button
									className="btn btn-primary btn-block btn-lg d-flex align-items-center justify-content-center"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink, '?pt=instagram');
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									{this.state.shortLinkLoading ? <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i> : <i className="bx bxl-instagram mr-1"></i>}
									Instagram
								</button>
							</Col>
							<Col xs="6" className="text-center mt-3">
								<button
									className="btn btn-secondary btn-block btn-lg"
									onClick={() => {
										this.shortUrl(this.state.selectedShortLink);
									}}
									disabled={this.state.shortLinkLoading ? true : ''}
								>
									Diğer
								</button>
							</Col>
						</Row>
					</div>
				</Modal>
			</>
		);
	}
}

Contents.propTypes = {
	client: PropTypes.object,
};

export default withApollo(Contents);
