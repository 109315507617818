import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Modal, Alert } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import Helmet from 'react-helmet';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import api from '../../api';
import { addLog } from '../../utils/addLog';
class Branches extends React.Component {
	state = {
		modalStatus: false,
		deleteStatus: false,
		deleteError: null,
		name: '',
		error: null,
		data: [],
		onEditing: false,
		editingId: null,
		deletingId: null,
	};

	tog_center = () => {
		this.setState((prevState) => ({
			modalStatus: !prevState.modalStatus,
		}));
		this.removeBodyCss();
	};

	deleteToggle = () => {
		this.setState((prevState) => ({
			deleteStatus: !prevState.deleteStatus,
			name: '',
		}));
		this.removeBodyCss();
	};

	removeBodyCss() {
		document.body.classList.add('no_padding');
	}

	componentDidMount() {
		this.fetchBranches();
		addLog(25, 'Tv Branş Listeleme ekranı görüntülendi.');
	}

	onBranchEdit = (id, name) => {
		this.setState({
			name,
			onEditing: true,
			editingId: id,
			modalStatus: true,
		});
	};

	onBranchDelete = (id) => {
		api.tv.deleteBranch(id).then((res) => {
			if (!res.success) {
				alert(res.message);

				this.setState({ deleteError: res.message });
			} else {
				this.setState({
					deleteStatus: false,
					deletingId: null,
					deleteError: null,
				});
				this.fetchBranches();
			}
		});
	};

	fetchBranches = () => {
		api.tv.getAllBranches().then((res) => {
			let data = [];

			if (res) {
				res.branches.map((item) => {
					data.push({
						...item,
						setting: (
							<span>
								<button onClick={() => this.onBranchEdit(item.id, item.name)} className="btn btn-success">
									Düzenle
								</button>{' '}
								<button onClick={() => this.setState({ deleteStatus: true, name: item.name, deletingId: item.id })} className="btn btn-danger">
									Sil
								</button>
							</span>
						),
					});
				});

				this.setState({ data });
			}
		});
	};

	addBranch = (e) => {
		e.preventDefault();

		let name = this.state.name;

		if (name) {
			if (!this.state.onEditing) {
				api.tv.addBranch(name).then((res) => {
					this.setState({ name: '', error: res.message }, () => {
						let _this = this;
						setTimeout(() => {
							_this.setState({
								error: null,
								modalStatus: false,
							});

							_this.fetchBranches();
						}, 2000);
					});
				});
			} else {
				api.tv.updateBranch(this.state.editingId, name).then((res) => {
					this.setState({ name: '', onEditing: false, editingId: null, error: res.message }, () => {
						let _this = this;

						setTimeout(() => {
							_this.setState({
								error: null,
								modalStatus: false,
							});

							_this.fetchBranches();
						}, 2000);
					});
				});
			}
		}
	};

	render() {
		const data = {
			columns: [
				{
					label: 'ID',
					field: 'id',
					sort: 'asc',
					width: 50,
				},
				{
					label: 'Branş Adı',
					field: 'name',
					sort: 'asc',
					width: 250,
				},
				{
					label: '#',
					field: 'setting',
					sort: 'asc',
					width: 50,
				},
			],
			rows: this.state.data,
		};

		return (
			<>
				<Helmet>
					<title>{`Branş Yönetim | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor TV" breadcrumbItem="Branşlar" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>TV Branşları </CardTitle>
												<CardSubtitle className="mb-3">Branşları gizlemediğiniz sürece her zaman anasayfada görünür.</CardSubtitle>
											</div>

											<button className="btn btn-primary" onClick={this.tog_center}>
												Yeni Branş Ekle
											</button>
										</div>

										<div className="mini-table">
											<MDBDataTable
												responsive
												striped
												bordered
												data={data}
												noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
												paginationLabel={['Önceki', 'Sonraki']}
												entriesLabel="Göster"
												infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
												searchLabel="Ara"
											/>
										</div>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>

					<Modal isOpen={this.state.modalStatus} toggle={this.tog_center} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yeni Branş Ekle</h5>
							<button type="button" onClick={() => this.setState({ modalStatus: false })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.error && <Alert color="primary">{this.state.error}</Alert>}

							<form method="post" onSubmit={this.addBranch}>
								<div className="form-group">
									<label htmlFor="name">Branş Adı</label>
									<input type="text" value={this.state.name} className="form-control" placeholder="Giriniz" onChange={(e) => this.setState({ name: e.target.value })} />
								</div>

								<button className="btn btn-info" disabled={this.state.name.length <= 0}>
									Kaydet
								</button>
							</form>
						</div>
					</Modal>

					<Modal isOpen={this.state.deleteStatus} toggle={this.deleteToggle} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Silmek istediğinize emin misiniz?</h5>
							<button type="button" onClick={() => this.setState({ deleteStatus: false, name: '' })} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							{this.state.deleteError && <Alert color="primary">{this.state.deleteError}</Alert>}

							<p>
								Sildiğinizde <b>{this.state.name}</b> branşı artık anasayfada gözükmeyecektir.
							</p>

							<button className="btn btn-info mt-4" onClick={() => this.onBranchDelete(this.state.deletingId)}>
								Sil
							</button>
						</div>
					</Modal>
				</div>
			</>
		);
	}
}

export default Branches;
