import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import classname from 'classnames';
import { getTokenDecoded } from '../../utils/auth';
import { getRoles } from '../../utils/getRoles';

class Navbar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: getTokenDecoded(localStorage.ajstoken),
			brand: '',
			userBrands: [],
		};
	}

	componentDidMount() {
		let matchingMenuItem = null;
		let ul = document.getElementById('navigation');
		let items = ul.getElementsByTagName('a');
		for (let i = 0; i < items.length; ++i) {
			if (this.props.location.pathname === items[i].pathname) {
				matchingMenuItem = items[i];
				break;
			}
		}
		if (matchingMenuItem) {
			this.activateParentDropdown(matchingMenuItem);
		}

		this.setState({
			userBrands: getRoles(this.state.user.Roles).role,
			brand: localStorage.getItem('currentBrand') ? localStorage.getItem('currentBrand') : getRoles(this.state.user.Roles).app[0],
		});
	}

	activateParentDropdown = (item) => {
		item.classList.add('active');
		const parent = item.parentElement;
		if (parent) {
			parent.classList.add('active'); // li
			const parent2 = parent.parentElement;
			parent2.classList.add('active'); // li
			const parent3 = parent2.parentElement;
			if (parent3) {
				parent3.classList.add('active'); // li
				const parent4 = parent3.parentElement;
				if (parent4) {
					parent4.classList.add('active'); // li
					const parent5 = parent4.parentElement;
					if (parent5) {
						parent5.classList.add('active'); // li
						const parent6 = parent5.parentElement;
						if (parent6) {
							parent6.classList.add('active'); // li
						}
					}
				}
			}
		}
		return false;
	};

	render() {
		return (
			<React.Fragment>
				<div className={`topnav ${!this.props.serverStatus ? 'd-none' : ''}`}>
					<div className="container-fluid">
						<nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
							<Collapse isOpen={this.props.menuOpen} className="navbar-collapse" id="topnav-menu-content">
								<ul className="navbar-nav">
									<li className="nav-item">
										<a className="nav-link  arrow-none" href={this.state.brand === 'Radyospor' ? '/radyospor' : '/'}>
											<i className="bx bx-home-circle mr-2"></i>
										</a>
									</li>

									{(this.state.brand === 'Ajansspor' || !this.state.brand) && (
										<>
											{(this.state.userBrands.includes('A-Editör') ||
												this.state.userBrands.includes('A-Writer') ||
												this.state.userBrands.includes('A-Admin') ||
												this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-news mr-2"></i> Haber Yönetimi <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/news" className="dropdown-item">
															Haberler
														</a>
														{/* <a href="/news/content" className="dropdown-item">
															Haber Ekle
														</a> */}
														<a href="/news/news" className="dropdown-item">
															Haber Ekle
														</a>
														{/* <a href="/news/quick-content" className="dropdown-item">
															Hızlı Haber Ekle
														</a> */}
														<a href="/news/headline" className="dropdown-item">
															Manşetler
														</a>
														<a href="/news/agencies" className="dropdown-item">
															Ajans Haberleri
														</a>
													</div>
												</li>
											)}

											<li className="nav-item">
												<a href="/videos" className="nav-link">
													<i className="bx bx-tv mr-2"></i> Videolar
												</a>
											</li>

											{/* {(this.state.userBrands.includes('A-Editör') || this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-tv mr-2"></i> Ajansspor TV <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/tv/branches" className="dropdown-item">
															Branşlar
														</a>
														<a href="/tv/leagues" className="dropdown-item">
															Ligler
														</a>
														<a href="/tv/videos" className="dropdown-item">
															Videolar
														</a>
													</div>
												</li>
											)} */}

											{(this.state.userBrands.includes('A-Editör') || this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-comment-detail mr-2"></i> Yorum Yönetimi <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/web/comments" className="dropdown-item">
															Yorumlar
														</a>
														<a href="/web/contacts" className="dropdown-item">
															İletişim Formu
														</a>
													</div>
												</li>
											)}

											{/* {(this.state.userBrands.includes('A-Editör') || this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-user mr-2"></i> Kullanıcı Yönetimi <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/users/normal" className="dropdown-item">
															Üyeler
														</a>
														<a href="/users/editors" className="dropdown-item">
															Editörler
														</a>
														<a href="/users/writers" className="dropdown-item">
															Yazarlar
														</a>
													</div>
												</li>
											)} */}

											{(this.state.userBrands.includes('A-Editör') || this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-purchase-tag mr-2"></i> Etiket Yönetimi <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/tags/combine" className="dropdown-item">
															Etiket Birleştir / Ekle
														</a>
													</div>
												</li>
											)}

											{(this.state.userBrands.includes('A-Editör') || this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bx-wrench mr-2"></i> Araçlar <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														{/* <a href="/notification" className="dropdown-item">
															Push Gönderme
														</a> */}
														<a href="/cache" className="dropdown-item">
															Cache Temizle
														</a>
														<a href="/soccer/players" className="dropdown-item">
															Futbolcular
														</a>
														<a href="/soccer/squads" className="dropdown-item">
															Kadro Düzenleme
														</a>
													</div>
												</li>
											)}

											{(this.state.userBrands.includes('A-Admin') || this.state.userBrands.includes('Administrator')) && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bxs-keyboard mr-2"></i> Loglar <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/logs/editorNews" className="dropdown-item">
															Editör Logları
														</a>
														<a href="/logs/log" className="dropdown-item">
															Loglar
														</a>
														<a href="/users/normal" className="dropdown-item">
															Üye Yönetimi
														</a>
													</div>
												</li>
											)}

											{this.state.userBrands.includes('Administrator') && (
												<li className="nav-item dropdown">
													<a to="/#" className="nav-link dropdown-toggle arrow-none">
														<i className="bx bxs-keyboard mr-2"></i> Developer <div className="arrow-down"></div>
													</a>
													<div className={classname('dropdown-menu', { show: this.state.appState })}>
														<a href="/logs/logtypes" className="dropdown-item">
															Log Tipleri
														</a>
														<a href="/tools/servers" className="dropdown-item">
															Sunucu Bilgileri
														</a>
														<a href="/tools/status" className="dropdown-item">
															Sunucu Durumu
														</a>
														<a href="/tools/analytics" className="dropdown-item">
															Analytics Bilgileri
														</a>
													</div>
												</li>
											)}
										</>
									)}

									{this.state.brand === 'Slowtime' && (
										<>
											<li className="nav-item dropdown">
												<a to="/#" className="nav-link dropdown-toggle arrow-none">
													<i className="bx bx-comment-detail mr-2"></i> Etkinlik Yönetimi <div className="arrow-down"></div>
												</a>
												<div className={classname('dropdown-menu', { show: this.state.appState })}>
													<a href="/slowtime/events" className="dropdown-item">
														Etkinlikler
													</a>
													<a href="/slowtime/events/new" className="dropdown-item">
														Etkinlik Ekle
													</a>
												</div>
											</li>

											<li className="nav-item dropdown">
												<a to="/#" className="nav-link dropdown-toggle arrow-none">
													<i className="bx bx-news mr-2"></i> Haber Yönetimi <div className="arrow-down"></div>
												</a>
												<div className={classname('dropdown-menu', { show: this.state.appState })}>
													<a href="/slowtime/news" className="dropdown-item">
														Haberler
													</a>
													<a href="/slowtime/news/new" className="dropdown-item">
														Haber Ekle
													</a>
												</div>
											</li>

											<li className="nav-item dropdown">
												<a to="/#" className="nav-link dropdown-toggle arrow-none">
													<i className="bx bx-image mr-2"></i> Albüm Yönetimi <div className="arrow-down"></div>
												</a>
												<div className={classname('dropdown-menu', { show: this.state.appState })}>
													<a href="/slowtime/albums" className="dropdown-item">
														Albümler
													</a>
													<a href="/slowtime/albums/new" className="dropdown-item">
														Albüm Ekle
													</a>
												</div>
											</li>

											<li className="nav-item dropdown">
												<a to="/#" className="nav-link dropdown-toggle arrow-none">
													<i className="bx bx-video mr-2"></i> Video Yönetimi <div className="arrow-down"></div>
												</a>
												<div className={classname('dropdown-menu', { show: this.state.appState })}>
													<a href="/slowtime/video" className="dropdown-item">
														Geçmiş Haftaların Videoları
													</a>
													<a href="/slowtime/video/new" className="dropdown-item">
														Yeni Video Ekle
													</a>
												</div>
											</li>
										</>
									)}

									{this.state.brand === 'Radyospor' && (
										<>
											<li className="nav-item">
												<a href="/radyospor/frequencies" className="nav-link arrow-none">
													Frekans Yönetimi
												</a>
											</li>
											<li className="nav-item">
												<a href="/radyospor/programs" className="nav-link arrow-none">
													Program Yönetimi
												</a>
											</li>
											<li className="nav-item">
												<a href="/radyospor/broadcasters" className="nav-link arrow-none">
													Yayıncı Yönetimi
												</a>
											</li>
											<li className="nav-item">
												<a href="/radyospor/streams" className="nav-link arrow-none">
													Yayın Akışı Yönetimi
												</a>
											</li>
											<li className="nav-item">
												<a href="/radyospor/podcasts" className="nav-link arrow-none">
													Podcast Yönetimi
												</a>
											</li>
											<li className="nav-item dropdown">
												<a to="/#" className="nav-link dropdown-toggle">
													Özel Podcast Yönetimi
												</a>

												<div className={classname('dropdown-menu', { show: this.state.appState })}>
													<a href="/radyospor/special-programs" className="dropdown-item">
														Programlar
													</a>
													<a href="/radyospor/special-podcasts" className="dropdown-item">
														Podcastler
													</a>
												</div>
											</li>
											<li className="nav-item">
												<a href="/radyospor/contacts" className="nav-link arrow-none">
													İletişim Formu
												</a>
											</li>
											<li className="nav-item">
												<a href="/radyospor/settings" className="nav-link arrow-none">
													Ayarlar
												</a>
											</li>
										</>
									)}
								</ul>
							</Collapse>
						</nav>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

Navbar.propTypes = {
	location: PropTypes.object,
	menuOpen: PropTypes.bool,
	serverStatus: PropTypes.bool,
};

export default withRouter(Navbar);
