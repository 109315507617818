/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap';
import { withApollo } from 'react-apollo';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { UPDATE_SERVER_STATUS } from '../../../queries/ContentQuery';
import { addLog } from '../../../utils/addLog';

import api from '../../../api';
import Breadcrumbs from '../../../components/Common/Breadcrumb';

const ServerStatus = ({ client }) => {
	const [statusMessage, setStatusMessage] = useState('');
	const [status, setStatus] = useState(true);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		api.status().then((res) => {
			setStatus(res.status);
			setStatusMessage(res.message);
		});
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (status && statusMessage === '') {
			toastr.options = {
				positionClass: 'toast-top-center',
				progressBar: true,
			};
			toastr.error('Dashboard kapalıysa neden kapandığını belirtmelisiniz.');
		} else {
			setLoading(true);

			const mutation = await client.mutate({
				mutation: UPDATE_SERVER_STATUS,
				variables: {
					input: {
						status,
						message: statusMessage,
					},
				},
			});

			if (mutation) {
				setLoading(false);

				if (mutation.data.updateServerStatus.success) {
					let logData = `Sunucu durumu ${!status ? 'kapalı' : 'açık'} olarak değiştirildi.${!status ? 'Değiştirme nedeni:' + statusMessage : ''}`;
					addLog(42, logData);
					toastr.options = {
						positionClass: 'toast-top-center',
						progressBar: true,
					};
					toastr.success(mutation.data.updateServerStatus.message);
					//setTimeout(() => window.location.reload(), 5000);
				} else {
					toastr.options = {
						positionClass: 'toast-top-center',
					};
					toastr.error(mutation.data.updateServerStatus.message);
					addLog(42, mutation.data.updateServerStatus.message);
				}
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>{`Sunucu Durumu - Araçlar | ${process.env.REACT_APP_TITLE}`}</title>
			</Helmet>
			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Araçlar" breadcrumbItem="Sunucu Durumu" />

					<Row>
						<Col col="12">
							<Card>
								<CardBody>
									<CardTitle>Sunucu Durumu</CardTitle>

									<Form onSubmit={handleSubmit}>
										<Row>
											<Col lg="12">
												<FormGroup>
													<Label for="statusMessage">Durum Başlığı</Label>
													<Input type="text" id="statusMessage" name="StatusMessage" onChange={(e) => setStatusMessage(e.target.value)} value={statusMessage} />
													<small className="text-danger mt-2">Zorunlu!!! Format tarih saat(2020-12-22T15:30:00)|başlık|açıklama şeklinde olmalı.</small>
												</FormGroup>
											</Col>
										</Row>

										<Row>
											<Col lg="12">
												<div className="custom-control custom-checkbox mb-4 mt-2">
													<input
														type="checkbox"
														className="custom-control-input"
														id="is-status"
														name="Status"
														onChange={() => setStatus(!status)}
														value={!status}
														checked={!status}
													/>
													<label className="custom-control-label" htmlFor="is-status">
														Dashboard kapatılsın mı?
													</label>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg="12">
												<button type="submit" className="btn btn-primary" disabled={loading}>
													{loading ? <i className="bx bx-loader bx-spin" /> : 'Güncelle'}
												</button>
											</Col>
										</Row>
									</Form>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

ServerStatus.propTypes = {
	client: PropTypes.object,
};

export default withApollo(ServerStatus);
