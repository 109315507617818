import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import api from '../../api';

const leaguesNames = [
	{
		en: '1. Lig',
		tr: '1. Lig',
		code: '',
		sponsor: 'Spor Toto',
		tag: 156,
	},
	{
		en: 'Süper Lig',
		tr: 'Süper Lig',
		code: '',
		sponsor: 'Spor Toto',
		tag: 84833,
	},
	{
		en: '3. Lig - Grup 2',
		tr: '3. Lig - Grup 2',
		code: '',
		sponsor: 'TFF',
		tag: 57040,
	},
	{
		en: 'Cup',
		tr: 'Türkiye Kupası',
		code: '',
		sponsor: 'Ziraat',
		tag: 1877,
	},
	{
		en: '2. Lig',
		tr: '2. Lig',
		code: '',
		sponsor: 'TFF',
		tag: 42871,
	},
	{
		en: '3. Lig - Group 1',
		tr: '3. Lig - Grup 1',
		code: '',
		sponsor: 'TFF',
		tag: 183,
	},
	{
		en: '3. Lig - Group 3',
		tr: '3. Lig - Grup 3',
		code: '',
		sponsor: 'TFF',
		tag: 51646,
	},
	{
		en: 'Super Cup',
		tr: 'Süper Kupa',
		code: '',
		sponsor: '',
		tag: 42606,
	},
	{
		en: 'Premier League',
		tr: 'Premier Lig',
		code: '',
		sponsor: '',
		tag: 43857,
	},
	{
		en: 'Ligue 1',
		tr: 'Ligue 1',
		code: '',
		sponsor: '',
		tag: 44674,
	},
	{
		en: 'World Cup',
		tr: 'Dünya Kupası',
		code: '',
		sponsor: '',
		tag: 36384,
	},
	{
		en: 'Euro Championship',
		tr: 'Avrupa Futbol Şampiyonası',
		code: 'Euro 2022',
		sponsor: '',
		tag: 54052,
	},
	{
		en: 'UEFA Champions League',
		tr: 'Şampiyonlar Ligi',
		code: '',
		sponsor: '',
		tag: 36328,
	},
	{
		en: 'Championship',
		tr: 'Championship',
		code: '',
		sponsor: '',
		tag: 36397,
	},
	{
		en: 'EFL Trophy',
		tr: 'EFL Kupası',
		code: '',
		sponsor: '',
		tag: 53544,
	},
	{
		en: 'FA Cup',
		tr: 'FA Cup',
		code: '',
		sponsor: '',
		tag: 34762,
	},
	{
		en: 'UEFA Europa League',
		tr: 'Avrupa Ligi',
		code: '',
		sponsor: '',
		tag: 76207,
	},
	{
		en: 'UEFA Nations League',
		tr: 'Uluslar Ligi',
		code: '',
		sponsor: '',
		tag: 104260,
	},
	{
		en: 'Eredivisie',
		tr: 'Eredivisie',
		code: '',
		sponsor: '',
		tag: 114905,
	},
	{
		en: 'Jupiler Pro League',
		tr: 'Jupiler Pro Ligi',
		code: '',
		sponsor: '',
		tag: 31765,
	},
	{
		en: 'Primeira Liga',
		tr: 'Primeira Liga',
		code: '',
		sponsor: '',
		tag: 37440,
	},
	{
		en: 'Serie A',
		tr: 'Serie A',
		code: '',
		sponsor: '',
		tag: 40546,
	},
	{
		en: 'Premiership',
		tr: 'Premiership',
		code: '',
		sponsor: '',
		tag: 46508,
	},
	{
		en: 'Ligue 2',
		tr: 'Ligue 2',
		code: '',
		sponsor: '',
		tag: 107147,
	},
	{
		en: 'Bundesliga',
		tr: 'Bundesliga',
		code: '',
		sponsor: '',
		tag: 33207,
	},
	{
		en: 'La Liga',
		tr: 'La Liga',
		code: '',
		sponsor: '',
		tag: 33230,
	},
	{
		en: '2. Bundesliga',
		tr: '2. Bundesliga',
		code: '',
		sponsor: '',
		tag: 40604,
	},
	{
		en: 'DFB Pokal',
		tr: 'DFB Pokal',
		code: '',
		sponsor: '',
		tag: 33423,
	},
	{
		en: 'Premyer Liqa',
		tr: 'Azerbaycan Premier Ligi',
		code: '',
		sponsor: '',
		tag: 40506,
	},
	{
		en: 'Major League Soccer',
		tr: 'Major League Soccer',
		code: 'Mls',
		sponsor: '',
		tag: 36385,
	},
	{
		en: 'Segunda División',
		tr: 'Segunda División',
		code: '',
		sponsor: '',
		tag: 33230,
	},
	{
		en: 'Serie B',
		tr: 'Serie B',
		code: '',
		sponsor: '',
		tag: 115417,
	},
	{
		en: 'Coppa Italia',
		tr: 'Coppa Italia',
		code: '',
		sponsor: '',
		tag: 53541,
	},
	{
		en: 'Czech Liga',
		tr: 'Czech Liga',
		code: '',
		sponsor: '',
		tag: 40063,
	},
	{
		en: 'Copa del Rey',
		tr: 'Kral Kupası',
		code: '',
		sponsor: '',
		tag: 40164,
	},
	{
		en: 'UEFA Europa Conference League',
		tr: 'Konferans Ligi',
		code: '',
		sponsor: '',
		tag: 115388,
	},
];

const LocaleLeagueName = (str) => {
	const get = leaguesNames.find((x) => x.en === str);

	if (get) {
		if (get.tr) return get.tr;
		else return get.en;
	} else {
		return str;
	}
};

const TextEditor = ({ onChange, value }) => {
	const handleEditorChange = (e) => {
		onChange(e);
	};

	return (
		<Editor
			value={value}
			init={{
				language: 'tr',
				height: 500,
				selector: 'textarea',
				menubar: false,
				paste_as_text: true,
				default_link_target: '_blank',
				content_css: window.localStorage.getItem('ajs-theme') === 'dark' ? 'dark' : '',
				plugins: ['advlist lists image colorpicker', 'searchreplace', 'media table paste', 'link', 'code', 'table'],
				// autosave_interval: '5s',
				// autosave_prefix: '{path}{query}-',
				toolbar:
					'addmatchwidget | addvideo | addlmt | formatselect forecolor | bold italic | undo redo | \
					alignleft aligncenter alignright | \
					bullist numlist outdent indent | removeformat | link | code | table',
				extended_valid_elements:
					'img[class=myclass|!src|border:0|alt|title|width|height|style],script[type|src],iframe[allowfullscreen|webkitallowfullscreen|mozallowfullscreen|src|style|scrolling|marginwidth|marginheight|frameborder|width|height]',
				setup: (editor) => {
					editor.ui.registry.addButton('addmatchwidget', {
						text: 'Maç Widgetı Ekle',
						onAction: async () => {
							editor.windowManager.open({
								title: 'Maç Widgetı Ekle',
								body: {
									type: 'panel',
									items: [
										{
											type: 'input',
											name: 'matchid',
											label: 'ID',
										},
									],
								},
								buttons: [
									{
										type: 'submit',
										name: 'submitButton',
										text: 'Ekle',
										primary: true,
									},
								],
								onSubmit: function (api) {
									let data = api.getData();
									editor.insertContent('[live-match=' + data.matchid + ']');
									api.close();
								},
							});
						},
					});

					editor.ui.registry.addButton('addvideo', {
						text: 'Video Ekle',
						onAction: async () => {
							editor.windowManager.open({
								title: 'Video Ekle',
								body: {
									type: 'panel',
									items: [
										{
											type: 'input',
											name: 'videoid',
											label: 'ID',
										},
									],
								},
								buttons: [
									{
										type: 'submit',
										name: 'submitButton',
										text: 'Ekle',
										primary: true,
									},
								],
								onSubmit: function (api) {
									let data = api.getData();
									editor.insertContent('[video=' + data.videoid + ']');
									api.close();
								},
							});
						},
					});

					editor.ui.registry.addMenuButton('addlmt', {
						text: 'Puan Durumu Ekle',
						fetch: async (callback) => {
							const leagues = await api.soccer.leagues();

							const items = [];

							leagues?.data?.map((c) => {
								items.push({
									type: 'menuitem',
									text: LocaleLeagueName(c.league.name),
									onAction: () =>
										editor.insertContent(
											`<iframe src="https://widget.ajansspor.com/point-status/${c.league.id}/2022" style="width: 100%; height: 902px;" width="428" height="902"></iframe>`,
										),
								});
							});
							callback(items);
						},
					});

					editor.ui.registry.addButton('addpodcast', {
						text: 'Podcast Ekle',
						onAction: async () => {
							editor.windowManager.open({
								title: 'Podcast Ekle',
								body: {
									type: 'panel',
									items: [
										{
											type: 'input',
											name: 'podcastid',
											label: 'ID',
										},
									],
								},
								buttons: [
									{
										type: 'submit',
										name: 'submitButton',
										text: 'Ekle',
										primary: true,
									},
								],
								onSubmit: function (api) {
									let data = api.getData();
									editor.insertContent('[podcast=' + data.podcastid + ']');
									api.close();
								},
							});
						},
					});

					// editor.ui.registry.addButton('draft', {
					// 	text: 'Taslaktan Getir',
					// 	onAction: async() => {
					// 		console.log(window.location.pathname + window.location.search);
					// 		handleEditorChange(localStorage.getItem(`${window.location.pathname + window.location.search}-draft`));
					// 		console.log(localStorage.getItem(`${window.location.pathname + window.location.search}-draft`));
					// 	}
					// });
				},
			}}
			onEditorChange={handleEditorChange}
			//onEditorChange={(e) => console.log(e)}
			apiKey="f5icsse7uiuxbw3g5mq6xsqtonwudus9eu49bdavf34iraui"
		/>
	);
};

TextEditor.propTypes = {
	onChange: PropTypes.func,
	value: PropTypes.string,
};

export default TextEditor;
