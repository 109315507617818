import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Modal } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const Broadcaster = () => {
	const [broadcasters, setBroadcasters] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [name, setName] = React.useState('');
	const [twitter, setTwitter] = React.useState('');
	const [ajansspor, setAjansspor] = React.useState('');
	const [broadcasterId, setBroadcasterId] = React.useState(0);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		fetch();
	}, []);

	const data = {
		columns: [
			{
				label: '#',
				field: 'id',
				sort: 'asc',
				width: 50,
			},
			{
				label: 'Yayıncı adı',
				field: 'name',
				sort: 'asc',
				width: 100,
			},
		],
		rows: broadcasters,
	};

	const fetch = () => {
		api.radyospor.broadcaster.list().then((res) => {
			let data = [];

			res?.map((item, i) => {
				data.push({
					...item,
					id: i + 1,
					name: item.name,
				});
			});

			setBroadcasters(data);
		});
	};

	// const deleteBroadcaster = (id) => {
	// 	api.radyospor.broadcaster.delete({ id }).then((res) => {
	// 		if (res.success) {
	// 			toastr.options = {
	// 				positionClass: 'toast-top-center',
	// 				progressBar: true,
	// 			};
	// 			toastr.success(res.message);
	// 			fetch();
	// 		} else {
	// 			toastr.options = {
	// 				positionClass: 'toast-top-center',
	// 				progressBar: true,
	// 			};
	// 			toastr.error(res.message);
	// 		}
	// 	});
	// };

	// const editBroadcaster = (id, name, twitter, ajansspor) => {
	// 	setEditStatus(true);
	// 	setName(name);
	// 	setTwitter(twitter);
	// 	setAjansspor(ajansspor);
	// 	setBroadcasterId(id);
	// };

	const update = (id) => {
		setLoading(true);

		api.radyospor.broadcaster.update({ name, twitter, ajansspor, id }).then((res) => {
			setLoading(false);
			setEditStatus(false);
			setName('');
			setTwitter('');
			setAjansspor('');
			setBroadcasterId(0);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const add = () => {
		setLoading(true);

		api.radyospor.broadcaster.add({ name, twitter, ajansspor }).then((res) => {
			setLoading(false);
			setStatus(false);
			setName('');
			setTwitter('');
			setAjansspor('');
			setBroadcasterId(0);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	return (
		<>
			<Helmet>
				<title>{`Yayıncılar - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Yayıncı Yönetimi" breadcrumbItem="Yayıncılar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Yayıncılar</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Yayıncı Ekle
										</button>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal isOpen={editStatus} toggle={() => setEditStatus(!editStatus)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yayıncı düzenleyin.</h5>
							<button type="button" onClick={() => setEditStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{name}</b> yayıncıyı düzenliyorsunuz.
							</p>

							<div className="form-group">
								<label htmlFor="name">Yayıncı adı</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Twitter</label>
								<input type="text" value={twitter} className="form-control" placeholder="Giriniz" onChange={(e) => setTwitter(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Ajansspor</label>
								<input type="text" value={ajansspor} className="form-control" placeholder="Giriniz" onChange={(e) => setAjansspor(e.target.value)} />
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? update(broadcasterId) : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Düzenle'}
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Yayıncı ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="name">Adı</label>
								<input type="text" value={name} className="form-control" placeholder="Giriniz" onChange={(e) => setName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Twitter</label>
								<input type="text" value={twitter} className="form-control" placeholder="Giriniz" onChange={(e) => setTwitter(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Ajansspor</label>
								<input type="text" value={ajansspor} className="form-control" placeholder="Giriniz" onChange={(e) => setAjansspor(e.target.value)} />
							</div>

							<button className="btn btn-info mt-4" onClick={() => (!loading ? add() : null)}>
								{loading ? <i className="bx bx-loader bx-spin"></i> : 'Ekle'}
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Broadcaster;
