import React from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle, Table, Alert, Modal } from 'reactstrap';
//import { MDBDataTable } from 'mdbreact';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const Frequencies = () => {
	const [frequencies, setFrequencies] = React.useState([]);
	const [status, setStatus] = React.useState(false);
	const [editStatus, setEditStatus] = React.useState(false);
	const [frequencyName, setFrequencyName] = React.useState('');
	const [frequencyLocation, setFrequencyLocation] = React.useState('');
	const [frequencyId, setFrequencyId] = React.useState(0);

	React.useEffect(() => {
		fetch();
	}, []);

	const fetch = () => {
		api.radyospor.frequencies.list().then((res) => setFrequencies(res.frequencies));
	};

	const deleteFrequency = (id) => {
		api.radyospor.frequencies.delete({ id }).then((res) => {
			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const editFrequency = (id) => {
		let freq = frequencies.find((x) => x.id === id);

		setEditStatus(true);
		setFrequencyName(freq.name);
		setFrequencyLocation(freq.value);
		setFrequencyId(freq.id);
	};

	const update = (id) => {
		api.radyospor.frequencies.update({ name: frequencyName, value: frequencyLocation, id }).then((res) => {
			setEditStatus(false);
			setFrequencyName('');
			setFrequencyLocation('');
			setFrequencyId(0);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	const add = () => {
		api.radyospor.frequencies.add({ name: frequencyName, value: frequencyLocation }).then((res) => {
			setStatus(false);
			setFrequencyName('');
			setFrequencyLocation('');
			setFrequencyId(0);

			if (res.success) {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.success(res.message);
				fetch();
			} else {
				toastr.options = {
					positionClass: 'toast-top-center',
					progressBar: true,
				};
				toastr.error(res.message);
			}
		});
	};

	return (
		<>
			<Helmet>
				<title>{`Frekanslar - Radyospor Yönetimi | ${process.env.REACT_APP_RS_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Frekans Yönetimi" breadcrumbItem="Frekanslar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Frekanslar</CardTitle>
										</div>

										<button className="btn btn-primary" onClick={() => setStatus(true)}>
											Yeni Frekans Ekle
										</button>
									</div>

									<div className="table-responsive mt-2">
										<Table className="table-centered table-nowrap table-hover">
											<thead className="thead-light">
												<tr>
													<th scope="col" style={{ width: '70px' }}>
														#
													</th>
													<th scope="col">Frekans</th>
													<th scope="col">Konum</th>
													<th>#</th>
												</tr>
											</thead>
											<tbody className="table__width">
												{frequencies.length > 0 ? (
													frequencies.map((freq, i) => (
														<tr key={i}>
															<td>{i + 1}</td>
															<td>{freq.name}</td>
															<td>{freq.value}</td>
															<td>
																<div className="btn-group mr-2">
																	<button className="btn btn-secondary" onClick={() => editFrequency(freq.id)}>
																		<i className="bx bxs-pencil"></i>
																	</button>
																	<button
																		className={`btn ${!freq.status ? 'btn-danger' : 'btn-secondary'}`}
																		onClick={() => {
																			if (!freq.status) {
																				window.confirm('Geri almak istediğinize emin misiniz?') && deleteFrequency(freq.id);
																			} else {
																				window.confirm('Silmek istediğinize emin misiniz?') && deleteFrequency(freq.id);
																			}
																		}}
																	>
																		<i className="bx bx-trash"></i>
																	</button>
																</div>
															</td>
														</tr>
													))
												) : (
													<Alert color="primary">Henüz frekans eklenmemiş.</Alert>
												)}
											</tbody>
										</Table>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Modal isOpen={editStatus} toggle={() => setEditStatus(!editStatus)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Frekans düzenleyin.</h5>
							<button type="button" onClick={() => setEditStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<p>
								<b>{frequencyName}</b> frekansını düzenliyorsunuz.
							</p>

							<div className="form-group">
								<label htmlFor="name">Frekans</label>
								<input type="text" value={frequencyName} className="form-control" placeholder="Giriniz" onChange={(e) => setFrequencyName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Konum</label>
								<input type="text" value={frequencyLocation} className="form-control" placeholder="Giriniz" onChange={(e) => setFrequencyLocation(e.target.value)} />
							</div>

							<button className="btn btn-info mt-4" onClick={() => update(frequencyId)}>
								Düzenle
							</button>
						</div>
					</Modal>

					<Modal isOpen={status} toggle={() => setStatus(!status)} centered={true}>
						<div className="modal-header">
							<h5 className="modal-title mt-0">Frekans ekleyin.</h5>
							<button type="button" onClick={() => setStatus(false)} className="close" data-dismiss="modal" aria-label="Kapat">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="form-group">
								<label htmlFor="name">Frekans</label>
								<input type="text" value={frequencyName} className="form-control" placeholder="Giriniz" onChange={(e) => setFrequencyName(e.target.value)} />
							</div>

							<div className="form-group">
								<label htmlFor="name">Konum</label>
								<input type="text" value={frequencyLocation} className="form-control" placeholder="Giriniz" onChange={(e) => setFrequencyLocation(e.target.value)} />
							</div>

							<button className="btn btn-info mt-4" onClick={() => add()}>
								Ekle
							</button>
						</div>
					</Modal>
				</Container>
			</div>
		</>
	);
};

export default Frequencies;
