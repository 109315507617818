import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { MDBDataTable } from 'mdbreact';

import Breadcrumbs from '../../../components/Common/Breadcrumb';
import api from '../../../api';

const VideoWeek = () => {
	const [videos, setVideos] = useState([]);


	const data = {
		columns: [
			{
				label: 'Başlık',
				field: 'Title',
				sort: 'asc',
				width: 50,
			},
			{
				label: '#',
				field: 'settings',
				sort: 'asc',
				width: 50,
			},
		],
		rows: videos,
	};

	const fetchEvents = () => {
		api.slowtime.videos.list().then((res) => {
			let data = [];


			if(res.success){
				res.data.map((item) => {
					data.push({
						...item,
						settings: (
							<div className="btn-group mr-2 mb-2 mb-sm-0">
								<a href={`/slowtime/video/edit/${item.Id}`} className="btn btn-secondary">
									<i className="bx bxs-pencil"></i>
								</a>
							</div>
						),
					});
				});

				setVideos(data);
			}
		});
	};


	useEffect(() => {
		fetchEvents();
	}, []);

	return (
		<>
			<Helmet>
				<title>{`Videolar - Slowtime Yönetimi | ${process.env.REACT_APP_SLOW_TITLE}`}</title>
			</Helmet>

			<div className="page-content">
				<Container fluid>
					<Breadcrumbs title="Video Yönetimi" breadcrumbItem="Videolar" />

					<Row>
						<Col className="col-12">
							<Card>
								<CardBody>
									<div className="d-flex justify-content-between">
										<div>
											<CardTitle>Haftanın Videosu Seçimleri</CardTitle>
										</div>
									</div>

									<div className="mini-table">
										<MDBDataTable
											responsive
											striped
											bordered
											data={data}
											noRecordsFoundLabel="Hiçbir kayıt bulunamadı"
											paginationLabel={['Önceki', 'Sonraki']}
											entriesLabel="Göster"
											infoLabel={['Gösterilen', '-', ', toplam', 'kayıt']}
											searchLabel="Ara"
											o
										/>
									</div>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};

export default VideoWeek;
