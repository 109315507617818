import React from 'react';
import { Container, Row, Col, Card, CardTitle, CardSubtitle, CardBody, Alert } from 'reactstrap';
import Helmet from 'react-helmet';
import Breadcrumbs from '../components/Common/Breadcrumb';
import api from '../api';
import { addLog } from '../utils/addLog';

class Branches extends React.Component {
	state = {
		id: '',
		error: null,
		loading: false,
		domLoading: false,
	};

	onRemoveCache = (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
		});
		api.contentCache(this.state.id);

		api.cache(this.state.id).then(() => {
			this.setState(
				{
					loading: true,
					error: 'Cache temizlendi.',
				},
				() => {
					let logData = `${this.state.id} id'li haberin cache'leri temizlendi`;
					addLog(15, logData);
					this.setState({
						loading: false,
						id: '',
					});

					let _this = this;
					setTimeout(() => {
						_this.setState({
							error: null,
						});
					}, 2000);
				},
			);
		});
	};

	onRemoveHomePageCache = (e) => {
		e.preventDefault();
		this.setState({
			loading: true,
		});

		api.homepageCache().then((res) => {
			this.setState(
				{
					loading: false,
					error: res.message,
				},
				() => {
					addLog(15, 'Anasayfada cache temizlendi.');
					let _this = this;
					setTimeout(() => {
						_this.setState({
							error: null,
						});
					}, 2000);
				},
			);
		});
	};

	onCheckDomain = (e) => {
		e.preventDefault();

		this.setState({
			domLoading: true,
		});

		api.domain().then((res) => {
			console.log(res);

			this.setState({
				domLoading: false,
			});
		});
	};

	render() {
		return (
			<>
				<Helmet>
					<title>{`Cache Temizle | ${process.env.REACT_APP_TITLE}`}</title>
				</Helmet>
				<div className="page-content">
					<Container fluid>
						<Breadcrumbs title="Ajansspor TV" breadcrumbItem="Cache Temizle" />

						<Row>
							<Col className="col-12">
								<Card>
									<CardBody>
										<div className="d-flex justify-content-between">
											<div>
												<CardTitle>Cache Temizle </CardTitle>
												<CardSubtitle className="mb-3">Ajansspor.com ve Ajansspor App üzerindeki habere ait tüm cacheleri temizler.</CardSubtitle>
											</div>

											<div>
												<button className="btn btn-primary" onClick={this.onRemoveHomePageCache}>
													{this.state.loading ? <i className="bx bx-loader bx-spin"></i> : 'Anasayfa Cache Temizle'}
												</button>

												<button className="btn btn-primary ml-2" onClick={this.onCheckDomain}>
													{this.state.domLoading ? <i className="bx bx-loader bx-spin"></i> : 'Domaini Kontrol Et'}
												</button>
											</div>
										</div>

										{this.state.error && <Alert>{this.state.error}</Alert>}

										<form onSubmit={this.onRemoveCache}>
											<div className="form-group">
												<label htmlFor="newsId">Haber ID</label>
												<input
													type="text"
													className="form-control"
													value={this.state.id}
													pattern="[0-9]*"
													onChange={(e) => this.setState({ id: e.target.validity.valid ? e.target.value : this.state.id })}
												/>
											</div>

											<button className="btn btn-primary" disabled={!this.state.id.length > 0}>
												{this.state.loading ? <i className="bx bx-loader bx-spin"></i> : 'Sil'}
											</button>
										</form>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
				</div>
			</>
		);
	}
}

export default Branches;
